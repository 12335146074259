import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty, isNull } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getSucursalByUserAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';
import { getEstadoResultadoAsync } from '../../../api/AdmonReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { PrintReportGerencial } from '../../../components/PrintReportGerencial';

const ReporteEstadoResultado = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');

	const compRef = useRef();
	const { selectedSucursal, fechaDesde, fechaHasta, cuentas0 } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal,
				incluideCuentas0: cuentas0 === '1'
			};

			setIsLoading(true);
			const result = await getEstadoResultadoAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 5mm; }
`;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			if (item.cuenta != null) {
				let obj = {
					noCuenta: item.cuenta.countNumber,
					descripcion: item.cuenta.descripcion,
					saldoAnterior: item.saldoAnterior,
					subtotal: item.subtotalCredito,
					total: item.total,
					acumulado: item.acumulado
				};
				exp.push(obj);
			}
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'estadoperdidasyganancidas');
		XLSX.writeFile(bk, 'EstadodePerdidasyGanancias.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Estado de Perdidas y Ganancias
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton variant="outlined">
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>
				</Stack>

				<Stack display="flex" justifyContent="center">
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Stack direction={'row'} spacing={1}>
									<Typography fontSize={11} textAlign="left">
										Desde:
									</Typography>

									<Typography fontSize={11} textAlign="left">
										{moment(fechaDesde).format('L')}
									</Typography>
								</Stack>
								<Stack direction={'row'} spacing={1}>
									<Typography fontSize={11} textAlign="left">
										Hasta:
									</Typography>

									<Typography fontSize={11} textAlign="left">
										{moment(fechaHasta).format('L')}
									</Typography>
								</Stack>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												No de Cuenta
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Descripción de Cuenta
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Saldo Anterior
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Sub - Total
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Total
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Acumulado
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map((row) => {
											const {
												cuenta,
												saldoAnterior,
												subtotalCredito,
												total,
												acumulado
											} = row;
											return isNull(cuenta) ? (
												subtotalCredito === -1 ? (
													<TableRow key={-1}>
														<TableCell />
														<TableCell
															align="left"
															component="th"
															scope="row"
															style={{ fontSize: 14 }}
														>
															GANANCIA / PERDIDA
														</TableCell>
														<TableCell />
														<TableCell />

														<TableCell
															align="center"
															style={{
																fontSize: 14,
																borderBottomWidth: 2,
																borderBottomColor: '#2979ff'
															}}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(total)}
														</TableCell>
														<TableCell
															align="center"
															style={{
																fontSize: 14,
																borderBottomWidth: 2,
																borderBottomColor: '#2979ff'
															}}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(acumulado)}
														</TableCell>
													</TableRow>
												) : (
													<TableRow key={-2}>
														<TableCell />
														<TableCell />
														<TableCell />
														<TableCell />

														<TableCell
															align="center"
															component="th"
															scope="row"
															style={{
																fontSize: 13,
																borderBottomWidth: 2,
																borderBottomColor: '#2979ff'
															}}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(total)}
														</TableCell>
														<TableCell
															align="center"
															component="th"
															scope="row"
															style={{
																fontSize: 13,
																borderBottomWidth: 2,
																borderBottomColor: '#2979ff'
															}}
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(acumulado)}
														</TableCell>
													</TableRow>
												)
											) : (
												<TableRow key={cuenta.id}>
													<TableCell
														align="left"
														component="th"
														scope="row"
														style={{ fontSize: 12 }}
													>
														{cuenta.countNumber}
													</TableCell>
													<TableCell align="left">
														<Typography
															style={{
																fontSize: 11,
																marginLeft: cuenta.nivel * 10
															}}
														>
															{cuenta.descripcion}
														</Typography>
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoAnterior)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(subtotalCredito)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(total)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(acumulado)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReportGerencial
					ref={compRef}
					titulo={'Reporte Estado de Resultado'}
					fecha={`Fecha: ${moment().format('L')}`}
					rango={`Del ${moment(fechaDesde).format('L')} al ${moment(fechaHasta).format(
						'L'
					)}`}
					isLandscape={false}
				>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parametros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Sucursal: ${selectedSucursal === 't' ? 'Todas' : sucursalName}`}
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{rol}
							</Typography>
						</Stack>
					</Stack>

					<hr />

					<Container sx={{ textAlign: 'center' }}>
						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													#.Cuenta
												</TableCell>
												<TableCell
													align="left"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Cuenta
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													S. Anterior
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Sub - Total
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Total
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Acumulado
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((row) => {
												const {
													cuenta,
													saldoAnterior,
													subtotalCredito,
													total,
													acumulado
												} = row;
												return isNull(cuenta) ? (
													subtotalCredito === -1 ? (
														<TableRow
															key={-3}
															style={{ alignItems: 'center' }}
														>
															<TableCell />
															<TableCell
																align="left"
																component="th"
																scope="row"
																style={{ fontSize: 13 }}
																className="text-dark"
															>
																GANANCIA / PERDIDA
															</TableCell>
															<TableCell />
															<TableCell />

															<TableCell
																align="left"
																style={{
																	fontSize: 13,
																	borderBottomWidth: 2,
																	borderBottomColor: '#2979ff'
																}}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(total)}
															</TableCell>
															<TableCell
																align="center"
																style={{
																	fontSize: 13,
																	borderBottomWidth: 2,
																	borderBottomColor: '#2979ff'
																}}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(acumulado)}
															</TableCell>
														</TableRow>
													) : (
														<TableRow key={-4}>
															<TableCell colSpan={4} />

															<TableCell
																align="left"
																component="th"
																scope="row"
																style={{
																	fontSize: 12,
																	borderBottomWidth: 2,
																	borderBottomColor: '#2979ff'
																}}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(total)}
															</TableCell>
															<TableCell
																align="center"
																style={{
																	fontSize: 12,
																	borderBottomWidth: 2,
																	borderBottomColor: '#2979ff'
																}}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(acumulado)}
															</TableCell>
														</TableRow>
													)
												) : (
													<TableRow key={cuenta.id}>
														<TableCell
															align="left"
															component="th"
															scope="row"
															style={{ fontSize: 11 }}
															className="text-dark"
														>
															{cuenta.countNumber}
														</TableCell>
														<TableCell align="left">
															<Typography
																style={{
																	fontSize: 11
																	// marginLeft: cuenta.nivel * 5
																}}
																className="text-dark"
															>
																{cuenta.descripcion}
															</Typography>
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(saldoAnterior)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(subtotalCredito)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(total)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 11 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(acumulado)}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						)}

						<Stack
							direction={'row'}
							spacing={2}
							marginTop={10}
							justifyContent="space-around"
							alignItems="flex-start"
						>
							<Stack justifyContent="center">
								<span>----------------------------------------------------</span>
								<Typography textAlign={'center'} color="#212121" fontSize={13}>
									Elaborado por
								</Typography>
							</Stack>

							<Stack justifyContent="center">
								<span>----------------------------------------------------</span>

								<Typography textAlign={'center'} color="#212121" fontSize={13}>
									Revisado Por
								</Typography>
							</Stack>

							<Stack justifyContent="center">
								<span>----------------------------------------------------</span>

								<Typography textAlign={'center'} color="#212121" fontSize={13}>
									Autorizado Por
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</PrintReportGerencial>
			</div>
		</div>
	);
};

export default ReporteEstadoResultado;
