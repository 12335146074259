import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Container,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { addCajaOutAsync } from "../../../api/Cash";
import {
  getCuentasGastoLevel4Async,
  getCuentasGastoLevel5Async,
} from "../../../api/Contabilidad";
import { getSucursalByUserAsync } from "../../../api/Users";

const AddGasto = ({
  setShowModal,
  localReload,
  setLocalReload,
  selectedSucursal,
}) => {
  const [monto, setMonto] = useState("");
  const [cuentasGastoLevel4, setCuentasGastolevel4] = useState([]);
  const [selectedCuentasGastoLevel4, setSelectedCuentasGastolevel4] = useState(
    []
  );
  const [cuentasGastoLevel5, setCuentasGastolevel5] = useState([]);
  const [selectedCuentasGastoLevel5, setSelectedCuentasGastolevel5] = useState(
    []
  );

  const [sucursalList, setSucursalList] = useState([]);
  const [sucursal, setSucursal] = useState(selectedSucursal);

  const [numRecibo, setNumRecibo] = useState("");
  const [beneficiario, setBeneficiario] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  const getCuentasgastoLevel4 = async () => {
    setIsLoading(true);
    const result = await getCuentasGastoLevel4Async(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setCuentasGastolevel4(result.data.result);
  };

  const getCuentasgastoLevel5 = async (id) => {
    setIsLoading(true);
    const result = await getCuentasGastoLevel5Async(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setCuentasGastolevel5(result.data.result);
  };

  useEffect(() => {
    (async () => {
      await getSucursals();
      await getCuentasgastoLevel4();
      // await getConceptosGasto(tipoGasto);
    })();
  }, [localReload]);

  const onChangeGastoLevel4 = async (id) => {
    setSelectedCuentasGastolevel4(id);
    await getCuentasgastoLevel5(id);
  };

  const montoMov = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMonto(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (monto.length === 0) {
      simpleMessage("Agrege un monto válido...", "error");
      return;
    }
    if (beneficiario.length === 0) {
      simpleMessage("Agrege un beneficiario...", "error");
      return;
    }

    const data = {
      sucursalId: sucursal,
      numRecibo,
      monto,
      gastoId: selectedCuentasGastoLevel5,
      benficiario: beneficiario,
    };
    setIsLoading(true);
    const result = await addCajaOutAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Movimiento Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Paper style={{ padding: 20 }}>
              <Stack spacing={2}>
                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSucursal(e.target.value)}
                    value={selectedSucursal}
                    label="Sucursal"
                  >
                    {sucursalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  required
                  onChange={(e) => montoMov(e.target.value)}
                  label={"Monto a debitar"}
                  value={monto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C$</InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  required
                  onChange={(e) => setNumRecibo(e.target.value)}
                  label={"Numero recibo"}
                  value={numRecibo}
                />
              </Stack>
            </Paper>
          </Grid>

          <Grid item sm={12} md={6}>
            <Paper style={{ padding: 20 }}>
              <Stack spacing={2}>
                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Tipo de Gasto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => onChangeGastoLevel4(e.target.value)}
                    value={selectedCuentasGastoLevel4}
                    label="Tipo de Gasto"
                  >
                    {cuentasGastoLevel4.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descripcion}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Gasto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) =>
                      setSelectedCuentasGastolevel5(e.target.value)
                    }
                    value={selectedCuentasGastoLevel5}
                    label="Gasto"
                  >
                    {cuentasGastoLevel5.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descripcion}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  required
                  onChange={(e) =>
                    setBeneficiario(e.target.value.toUpperCase())
                  }
                  label={"A nombre de"}
                  value={beneficiario}
                />
              </Stack>
            </Paper>
          </Grid>

          <Grid item sm={12}>
            <Button
              fullWidth
              variant="outlined"
              style={{
                padding: 14,
                color: "#f50057",
                borderColor: "#f50057",
              }}
              startIcon={<FontAwesomeIcon icon={faSave} />}
              onClick={() => saveChangesAsync()}
            >
              Agregar Salida de efectivo
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddGasto;
