import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getClientsReportAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";

import * as XLSX from "xlsx/xlsx.mjs";
import { getSucursalByUserAsync } from "../../../api/Users";

const ClientesGestor = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, selectedSortBy, selectedStatus } = useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        selectedSortBy,
        selectedStatus,
      };
      setIsLoading(true);
      const result = await getClientsReportAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );
    return result.data.result;
  };

  const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  
  @page { size: landscape;  margin: 8mm; }
`;

  const exportExcel = () => {
    let exp = [];

    data.map((item) => {
      let obj = {
        nombreCompleto: item.fullName,
        telefono: item.phone,
        direccion: item.address,
        actividadEconomica: item.actividadEconomica,
        ciclos: item.creditos,
        promedio: item.promedio,
        sucursal: item.sucursal,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "clientes");
    XLSX.writeFile(bk, "Clientes.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Clientes
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    // style={{ position: "fixed", right: 20, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Tipo Cliente: ${
                    selectedStatus === "0"
                      ? "Clintes Activos"
                      : selectedStatus === "1"
                      ? "Clientes Inactivos"
                      : "Clientes Activos e Inactivos"
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Ordenados por: ${
                    parseInt(selectedSortBy) === 0 ? "Nombre" : "Promedio"
                  }`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="text-primary fw-bold">
                      Nombre Completo
                    </TableCell>

                    <TableCell align="center" className="text-primary fw-bold">
                      Teléfono
                    </TableCell>
                    <TableCell align="left" className="text-primary fw-bold">
                      Dirección
                    </TableCell>
                    <TableCell align="left" className="text-primary fw-bold">
                      Actividad Económica
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      Ciclos
                    </TableCell>
                    {selectedSucursal === "t" ? (
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Sucursal
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell align="center" className="text-primary fw-bold">
                      Promedio
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell align="left" component="th" scope="row">
                          {row.fullName}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {row.phone}
                        </TableCell>
                        <TableCell align="left" scope="row">
                          {row.address}
                        </TableCell>
                        <TableCell align="left" scope="row">
                          {row.actividadEconomica}
                        </TableCell>

                        <TableCell align="center" scope="row">
                          {row.creditos}
                        </TableCell>
                        {selectedSucursal === "t" ? (
                          <TableCell align="center" scope="row">
                            {row.sucursal}
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        <TableCell align="center" scope="row">
                          {row.promedio}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <hr />

          <Stack direction="row" flex="row" justifyContent="space-around">
            <Stack textAlign="center">
              <span style={{ fontWeight: "bold", color: "#03a9f4" }}>
                Total Clientes
              </span>
              <span>{data.length}</span>
            </Stack>
          </Stack>

          <hr />
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Clientes"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container
            fixed
            maxWidth="xl"
            sx={{ textAlign: "center", marginTop: 1 }}
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Tipo Cliente: ${
                    selectedStatus === "0"
                      ? "Clintes Activos"
                      : selectedStatus === "1"
                      ? "Clientes Inactivos"
                      : "Clientes Activos e Inactivos"
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Ordenados por: ${
                    parseInt(selectedSortBy) === 0 ? "Nombre" : "Promedio"
                  }`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container fixed maxWidth="xl" sx={{ textAlign: "center" }}>
            <hr />
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        Nombre Completo
                      </TableCell>

                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Teléfono
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Dirección
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Actividad Económica
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Ciclos
                      </TableCell>
                      {selectedSucursal === "t" ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Sucursal
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Promedio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.fullName}
                          </TableCell>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.phone}
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.address}
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.actividadEconomica}
                          </TableCell>

                          <TableCell
                            align="center"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.creditos}
                          </TableCell>
                          {selectedSucursal === "t" ? (
                            <TableCell
                              className="text-dark"
                              style={{ fontSize: 9 }}
                              align="center"
                              scope="row"
                            >
                              {row.sucursal}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell
                            align="center"
                            scope="row"
                            className="text-dark"
                            style={{ fontSize: 9 }}
                          >
                            {row.promedio}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <hr />

            <Stack direction="row" flex="row" justifyContent="space-around">
              <Stack textAlign="center">
                <span style={{ fontWeight: "bold", color: "#03a9f4" }}>
                  Total Clientes
                </span>
                <span>{data.length}</span>
              </Stack>
            </Stack>

            <hr />
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ClientesGestor;
