import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Container,
  Button,
  Stack,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";

import { isAccess, simpleMessage } from "../../../../helpers/Helpers";
import {
  deleteCuotaByIdAsync,
  getPrestamoByIdAsync,
} from "../../../../api/Employees";
import { isEmpty } from "lodash";
import NoData from "../../../../components/NoData";
import SmallModal from "../../../../components/modals/SmallModal";
import AddAbono from "./AddAbono";

const UpdatePrestamo = ({ selected, setShowModal, reload, setReload }) => {
  const [dataPrestamo, setDataPrestamo] = useState([]);
  const [abonos, setAbonos] = useState([]);
  const [abonoTotal, setAbonoTotal] = useState(0);
  //   const [monto, setMonto] = useState("");

  const [localReload, setLocalReload] = useState(false);
  const [showAbonoModal, setShowAbonoModal] = useState(false);

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
  const token = getToken();

  const getDataPrestamo = async () => {
    setIsLoading(true);
    const result = await getPrestamoByIdAsync(token, selected.id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setAbonos(result.data.result.abonos);
    getTotalAbonado(result.data.result.abonos);
    setDataPrestamo(result.data.result);
    setIsLoading(false);
  };

  useEffect(() => {
    getDataPrestamo();
  }, [localReload]);

  const deleteCuota = async (id) => {
    Swal.fire({
      title: "Desea eliminar esta cuota?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);

          const result = await deleteCuotaByIdAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Abono Eliminado.", "success");
        })();
      }
    });
  };

  const getTotalAbonado = async (abonoList) => {
    let total = 0;
    abonoList.map((item) => (total += item.montoAbono));
    setAbonoTotal(total);
  };

  return (
    <div>
      <Container style={{ width: 850 }}>
        <Typography style={{ textAlign: "center" }}>
          {`PRESTAMO #: ${dataPrestamo.id}`}
        </Typography>
        <Typography style={{ textAlign: "center" }}>
          {`DESCRIPCION: ${dataPrestamo.descripcion}`}
        </Typography>
        <hr />

        <Stack
          spacing={1}
          direction={{ xs: "column", sm: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Stack spacing={1}>
            <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
              Fecha:
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {moment(dataPrestamo.fechaCreacion).format("L")}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
              Monto:
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("es-NI", {
                style: "currency",
                currency: "NIO",
              }).format(dataPrestamo.monto)}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
              T. Abonado:
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("es-NI", {
                style: "currency",
                currency: "NIO",
              }).format(abonoTotal)}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
              Saldo:
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("es-NI", {
                style: "currency",
                currency: "NIO",
              }).format(selected.monto - abonoTotal)}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
              Cancelado:
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {selected.isCanceled ? "SI" : "NO"}
            </Typography>
          </Stack>
        </Stack>

        <Paper
          elevation={10}
          style={{
            padding: 20,
            marginTop: 10,
          }}
        >
          <Stack
            marginTop={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Abonos
            </Typography>

            <Button
              onClick={() => {
                setShowAbonoModal(!showAbonoModal);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faCirclePlus}
              />
              Agregar
            </Button>
          </Stack>

          <hr />

          {isEmpty(abonos) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Concepto
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        F.Pago
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Monto
                      </TableCell>

                      {isAccess(access, "NOMINA DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {abonos.map((row) => {
                      const { id, concepto, fechaPago, montoAbono } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="center">{concepto}</TableCell>
                          <TableCell align="center">
                            {moment(fechaPago).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(montoAbono)}
                          </TableCell>
                          {isAccess(access, "NOMINA DELETE") ? (
                            <TableCell align="center">
                              <IconButton
                                style={{
                                  color: "#f50057",
                                  width: 40,
                                  height: 40,
                                }}
                                onClick={() => deleteCuota(row.id)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Abono Manual"}
        isVisible={showAbonoModal}
        setVisible={setShowAbonoModal}
      >
        <AddAbono
          id={selected.id}
          setShowModal={setShowAbonoModal}
          reload={localReload}
          setReload={setLocalReload}
          saldo={selected.monto - abonoTotal}
        />
      </SmallModal>
    </div>
  );
};

export default UpdatePrestamo;
