import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Button,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faExternalLink,
  faPrint,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

import { DataContext } from "../../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";
import { useNavigate, useParams } from "react-router-dom";
import {
  cerrarNominaAsync,
  getNominaByIdAsync,
} from "../../../../api/Employees";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import NoData from "../../../../components/NoData";

const NominaDetails = () => {
  const [dataNomina, setDataNomina] = useState([]);
  const [detalleNomina, setDetalleNomina] = useState([]);

  const [localReload, setLocalReload] = useState(false);

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const { id } = useParams();

  const getNomina = async () => {
    setIsLoading(true);
    const result = await getNominaByIdAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setDataNomina(result.data.result);
    setDetalleNomina(result.data.result.detalleNominas);
    setIsLoading(false);
  };

  useEffect(() => {
    getNomina();
  }, [localReload]);

  const cerrarNomina = async () => {
    setIsLoading(true);
    const result = await cerrarNominaAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setLocalReload(!localReload);
  };

  return isEmpty(dataNomina) ? (
    <></>
  ) : (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <Button
                onClick={() => {
                  navigate(`${ruta}/nomina`);
                }}
                style={{
                  color: "#2979ff",
                  borderColor: "#2979ff",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faChevronCircleLeft}
                />
                Regresar
              </Button>

              <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
                Detalle Nomina
              </Typography>
            </Stack>

            <Stack direction={"row"} alignItems="center" spacing={1}>
              {dataNomina.estado.id === 2 ? (
                <></>
              ) : (
                <Button
                  onClick={() => {
                    cerrarNomina();
                  }}
                  style={{
                    color: "#ffc400",
                    borderColor: "#ffc400",
                  }}
                  variant="outlined"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: 10, fontSize: 20 }}
                    icon={faPowerOff}
                  />
                  Cerrar Nomina
                </Button>
              )}
              {/* {dataNomina.estado.id === 1 ? (
                <></>
              ) : (
                <Tooltip title="Imprimir todos los comprobantes">
                  <Button
                    onClick={() => {
                      // setShowModal(!showModal);
                    }}
                    style={{
                      color: "#2979ff",
                      borderColor: "#2979ff",
                    }}
                    variant="outlined"
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10, fontSize: 20 }}
                      icon={faPrint}
                    />
                    Imprimir
                  </Button>
                </Tooltip>
              )} */}
            </Stack>
          </Stack>
          <hr />

          {isEmpty(detalleNomina) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Empleado
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Desde
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Hasta
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Sub Total
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Total
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {detalleNomina.map((row) => {
                      const {
                        empleado,
                        desde,
                        hasta,
                        totalDevengado,
                        totalPercibir,
                      } = row;
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">
                            {empleado.fullName}
                          </TableCell>
                          <TableCell align="center">
                            {moment(desde).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {moment(hasta).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalDevengado)}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalPercibir)}
                          </TableCell>

                          <TableCell align="center">
                            {isAccess(access, "NOMINA UPDATE") ? (
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  navigate(
                                    `${ruta}/nominadetails/${id}/${row.id}`
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLink} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default NominaDetails;
