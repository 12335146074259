import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Container,
  Paper,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faCirclePlus,
  faPenToSquare,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import {
  deleteCommunityAsync,
  getMunicipalityByIdAsync,
} from "../../../api/Locations";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";

import Swal from "sweetalert2";
import CommunityAdd from "./communities/CommunityAdd";
import { CommunityEdit } from "./communities/CommunityEdit";

export const Communities = () => {
  let ruta = getRuta();
  const {
    setIsLoading,
    reload,
    setReload,
    setIsLogged,
    access,
    setIsUnautorized,
  } = useContext(DataContext);

  const token = getToken();
  let navigate = useNavigate();
  const { id } = useParams();

  const [municipality, setMunicipality] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = communityList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.name.toString().includes(searchTerm)) {
      return val;
    }
  });

  //Para la paginacion
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getMunicipalityByIdAsync(token, id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setMunicipality(result.data.result);
      setCommunityList(result.data.result.communities);
      setIsLoading(false);
    })();
  }, [reload]);

  const deleteCommunity = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteCommunityAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }

          setIsLoading(false);
          setReload(!reload);

          Swal.fire("Eliminado!", "Comunidad Eliminada.", "success");
        })();
      }
    });
  };

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack direction={"row"} alignItems="center" spacing={1}>
            <Button
              onClick={() => {
                navigate(`${ruta}/department/${municipality.department.id}`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              Comunidades Municipio:
            </Typography>

            <Typography variant="h5" fontWeight={"bold"}>
              {municipality.name}
            </Typography>
          </Stack>

          <hr />

          <Stack direction={"row"} justifyContent="space-between">
            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              Lista de Comunidades
            </Typography>
            {isAccess(access, "CONFIG CREATE") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Comunidad
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar Comunidad"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Comunidad
                      </TableCell>
                      {isAccess(access, "CONFIG UPDATE") ||
                      isAccess(access, "CONFIG DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          {isAccess(access, "CONFIG UPDATE") ||
                          isAccess(access, "CONFIG DELETE") ? (
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent="center"
                              >
                                {isAccess(access, "CONFIG UPDATE") ? (
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      setSelectedCommunity(row);
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {isAccess(access, "CONFIG DELETE") ? (
                                  <IconButton
                                    style={{
                                      color: "#f50057",
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => deleteCommunity(row)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Comunidad"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <CommunityAdd setShowModal={setShowModal} idMunicipality={id} />
      </SmallModal>

      <SmallModal
        titulo={`Editar: ${selectedCommunity.name}`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <CommunityEdit
          selectedCommunity={selectedCommunity}
          setShowModal={setShowEditModal}
        />
      </SmallModal>
    </div>
  );
};
