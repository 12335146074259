import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  Container,
  Button,
  Paper,
  Typography,
  Stack,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faTrashAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { getToken } from "../../../../api/Account";
import { isAccess, simpleMessage } from "../../../../helpers/Helpers";
import { DataContext } from "../../../../context/DataContext";
import { isEmpty } from "lodash";
import NoData from "../../../../components/NoData";
import SmallModal from "../../../../components/modals/SmallModal";
import {
  deletePrestamoAsync,
  getPrestamosByEmployeeAsync,
} from "../../../../api/Employees";

import AddPrestamo from "../prestamos/AddPrestamo";
import UpdatePrestamo from "../prestamos/UpdatePrestamo";
import MediumModal from "../../../../components/modals/MediumModal";

const Prestamos = ({ id }) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const [dataEmployee, setDataEmployee] = useState([]);
  const [histoyPrestamos, setHistoryPrestamos] = useState([]);
  const [selectedPrestamo, setSelectedPrestamo] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [localReload, setLocalReload] = useState(false);

  useEffect(() => {
    getPrestamos();
  }, [localReload]);

  const getPrestamos = async () => {
    setIsLoading(true);
    const result = await getPrestamosByEmployeeAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setDataEmployee(result.data.result);
    setHistoryPrestamos(result.data.result);
    setIsLoading(false);
  };

  const withSearch = histoyPrestamos.filter((val) => {
    return val;
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const deletePrestamo = async (id) => {
    Swal.fire({
      title: "¿Confirmar Eliminar?",
      // text: "...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deletePrestamoAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Prestamo Eliminado.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            marginTop={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Historial de Prestamos
            </Typography>

            <Button
              onClick={() => {
                setShowModal(!showModal);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faCirclePlus}
              />
              Agregar
            </Button>
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        F.Solicitud
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Monto
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        T.Abonado
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Saldo
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Cancelado
                      </TableCell>

                      {isAccess(access, "NOMINA UPDATE") ||
                      isAccess(access, "NOMINA DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const {
                        descripcion,
                        id,
                        fechaCreacion,
                        isCancelled,
                        totalAbonado,
                        monto,
                      } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="left">{descripcion}</TableCell>
                          <TableCell align="center">
                            {moment(fechaCreacion).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(monto)}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalAbonado)}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(monto - totalAbonado)}
                          </TableCell>
                          <TableCell align="center">
                            {isCancelled ? "SI" : "NO"}
                          </TableCell>

                          <TableCell align="center">
                            {isAccess(access, "NOMINA UPDATE") ? (
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  setShowEditModal(true);
                                  setSelectedPrestamo(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                            {isAccess(access, "NOMINA DELETE") ? (
                              <IconButton
                                style={{
                                  color: "#f50057",
                                  width: 40,
                                  height: 40,
                                }}
                                onClick={() => deletePrestamo(row.id)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Prestamo"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddPrestamo
          id={id}
          setShowModal={setShowModal}
          reload={localReload}
          setReload={setLocalReload}
        />
      </SmallModal>

      <MediumModal
        titulo={`Detalle Prestamo`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <UpdatePrestamo
          selected={selectedPrestamo}
          setShowModal={setShowEditModal}
          reload={localReload}
          setReload={setLocalReload}
        />
      </MediumModal>
    </div>
  );
};

export default Prestamos;
