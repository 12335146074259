import React, { useState, useLayoutEffect, useContext, useRef, useEffect } from 'react';
import { Container, Stack, AppBar, Toolbar, Typography, Dialog } from '@mui/material';
import { isEmpty } from 'lodash';
// import mapboxgl, { Map, Marker, Popup } from 'mapbox-gl';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { getToken, getUser } from '../../../api/Account';
import NoData from '../../../components/NoData';
import moment from 'moment';
import { simpleMessage } from '../../../helpers/Helpers';
import { getUserTrackingAsync } from '../../../api/AdmonReport';
import { getGestoresBySucursalAccessAsync } from '../../../api/Users';

const ReporteUserTracking = () => {
	const [data, setData] = useState([]);
	const [gestorName, setGestorName] = useState('');

	const [center, setCenter] = useState([]);
	const [recorrido, setRecorrido] = useState([]);

	const { selectedGestor, selectedFecha } = useParams();
	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const mark = require('../../../components/media/marker.png');

	const token = getToken();
	const user = getUser();

	const getData = async () => {
		const data = {
			fecha: selectedFecha.toString(),
			userId: selectedGestor
		};

		setIsLoading(true);
		const result = await getUserTrackingAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setData(result.data.result);
		return result.data.result;
	};

	useEffect(() => {
		(async () => {
			await getGestores();
			const data = await getData();

			let coords = [];
			let pints = [];

			data.map((item) => {
				const coord = [item.latitude, item.longitude, item.fecha];
				const point = [item.latitude, item.longitude];

				coords.push(coord);
				pints.push(point);
			});
			// setRecorrido(coords);
			setRecorrido(pints);
			setCenter([coords[0][0], coords[0][1]]);
		})();
	}, []);

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresBySucursalAccessAsync(token);

		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		let gestores = result.data.result;
		let name = gestores.filter((item) => item.id.toString() === selectedGestor);

		setGestorName(name[0].fullName);
	};

	const customIcon = new Icon({
		iconUrl: mark,
		iconSize: [38, 38]
	});

	// useLayoutEffect(() => {
	// 	(async () => {
	// 		await getGestores();
	// 		const data = await getData();
	// 		if (data.length > 0) {
	// 			if (mapDiv.current) {
	// 				const map = new Map({
	// 					container: mapDiv.current, // container ID
	// 					style: 'mapbox://styles/mapbox/streets-v12', // style URL
	// 					center: [data[0].longitude, data[0].latitude], // starting position [lng, lat]
	// 					zoom: 17 // starting zsoom
	// 				});

	// 				let coords = [];
	// 				let track = [];
	// 				let url = '';

	// 				data.map(async (item) => {
	// 					const coord = [item.longitude, item.latitude, item.fecha];
	// 					coords.push(coord);
	// 					if (coords.length >= 2) {
	// 						let position1 = coords[0];
	// 						let position2 = coords[1];

	// 						let dist = getDistanceBetweenPoints(
	// 							position1[1],
	// 							position1[0],
	// 							position2[1],
	// 							position2[0],
	// 							'meters'
	// 						);

	// 						if (dist >= 50) {
	// 							const popUp = new Popup({
	// 								className: 'text-success'
	// 							}).setHTML(
	// 								`<h6>${moment(item.fecha).format('HH:mm A')}</h6>
	// 						<p>${getTiempo(position1[2], position2[2])} minutos</p>
	// 						`
	// 							);

	// 							new Marker({
	// 								color: '#00a152'
	// 							})
	// 								.setLngLat([item.longitude, item.latitude])
	// 								.setPopup(popUp)
	// 								.addTo(map);
	// 							track.push(item);

	// 							url += `${item.longitude},${item.latitude};`;
	// 						}
	// 						coords.shift();
	// 					}
	// 				});
	// 				url = url.substring(0, url.length - 1);
	// 				await getRoute(url, map);
	// 			}
	// 		}
	// 	})();
	// }, []);

	// const getRoute = async (url, map) => {
	// 	const response = await fetch(
	// 		`https://api.mapbox.com/directions/v5/mapbox/cycling/${url}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
	// 	);
	// 	const data = await response.json();
	// 	const coordsResponse = data.routes[0].geometry.coordinates;

	// 	map.on('load', () => {
	// 		map.addSource(`sourceId`, {
	// 			type: 'geojson',
	// 			data: {
	// 				type: 'FeatureCollection',
	// 				features: [
	// 					{
	// 						type: 'Feature',
	// 						properties: {},
	// 						geometry: {
	// 							type: 'LineString',
	// 							coordinates: coordsResponse
	// 						}
	// 					}
	// 				]
	// 			}
	// 		});

	// 		map.addLayer({
	// 			id: `sourceId`,
	// 			type: 'line',
	// 			source: `sourceId`,
	// 			layout: { 'line-join': 'round', 'line-cap': 'round' },
	// 			paint: {
	// 				'line-color': 'blue',
	// 				'line-width': 4,
	// 				'line-opacity': 0.6
	// 			}
	// 		});
	// 	});
	// 	// return coordsResponse;
	// };

	// function getDistanceBetweenPoints(
	// 	latitude1,
	// 	longitude1,
	// 	latitude2,
	// 	longitude2,
	// 	unit = 'meters'
	// ) {
	// 	let theta = longitude1 - longitude2;
	// 	let distance =
	// 		60 *
	// 		1.1515 *
	// 		(180 / Math.PI) *
	// 		Math.acos(
	// 			Math.sin(latitude1 * (Math.PI / 180)) * Math.sin(latitude2 * (Math.PI / 180)) +
	// 				Math.cos(latitude1 * (Math.PI / 180)) *
	// 					Math.cos(latitude2 * (Math.PI / 180)) *
	// 					Math.cos(theta * (Math.PI / 180))
	// 		);
	// 	if (unit == 'miles') {
	// 		return Math.round(distance, 2);
	// 	} else if (unit == 'meters') {
	// 		return Math.round(distance * 1609.344, 2);
	// 	}
	// }

	// function getTiempo(fecha1, fecha2) {
	// 	var fecha1 = moment(fecha1, 'YYYY-MM-DD HH:mm:ss');
	// 	var fecha2 = moment(fecha2, 'YYYY-MM-DD HH:mm:ss');

	// 	// var diff = fecha2.diff(fecha1, 'd'); // Diff in days
	// 	// console.log(diff);

	// 	var diff = fecha2.diff(fecha1, 'h'); // Diff in hours
	// 	var min = diff * 60;
	// 	return min;
	// }

	// const getGestores = async () => {
	// 	setIsLoading(true);
	// 	const result = await getGestoresBySucursalAccessAsync(token);

	// 	if (!result.statusResponse) {
	// 		setIsLoading(false);
	// 		if (result.error === 'Unauthorized') {
	// 			setIsUnautorized(true);
	// 			return;
	// 		}
	// 		simpleMessage(result.error, 'error');
	// 		return;
	// 	}

	// 	setIsLoading(false);
	// 	let gestores = result.data.result;
	// 	let name = gestores.filter((item) => item.id.toString() === selectedGestor);

	// 	setGestorName(name[0].fullName);
	// };

	return (
		<Dialog fullScreen open={true}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					<Typography
						sx={{ ml: 2, flex: 1, textAlign: 'center' }}
						variant="h5"
						component="div"
					>
						{`${title} `}
					</Typography>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
				</Toolbar>
			</AppBar>
			<Stack display="flex" justifyContent="center">
				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
				>
					{slogan}
				</Typography>

				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
					variant="h6"
					component="div"
				>
					Reporte de Restreo de usuarios
				</Typography>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parámetros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Gestor Seleccionado: ${gestorName}`}
							</Typography>

							<Stack direction={'row'} spacing={1}>
								<Typography fontSize={11} textAlign="left">
									{/* {`Fecha Seleccionada: ${moment(selectedFecha).format(
										'L'
									)} `} */}
								</Typography>
							</Stack>
						</Stack>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>

							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>
					</Stack>
				</Container>
			</Stack>
			<Container maxWidth={false} sx={{ textAlign: 'center' }}>
				<hr />

				{isEmpty(data) ? (
					<NoData />
				) : (
					<MapContainer
						center={center}
						zoom={16}
						style={{
							height: '100vh'
						}}
					>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						<Polyline positions={recorrido} color="blue" />
						{data.map((item, index) => {
							if (index === 0) {
								return (
									<Marker
										key={item.id}
										position={[item.latitude, item.longitude]}
										icon={customIcon}
									>
										<Popup>
											<Typography
												fontWeight={'bold'}
												textAlign={'center'}
												component={'h6'}
											>
												{moment(item.fecha).format('HH:mm A')}
											</Typography>
											<p>Punto Inicial</p>
										</Popup>
									</Marker>
								);
							} else {
								if (item.tiempo >= 1) {
									return (
										<Marker
											key={item.id}
											position={[item.latitude, item.longitude]}
											icon={customIcon}
										>
											<Popup>
												<Stack>
													<Typography
														fontWeight={'bold'}
														textAlign={'center'}
														component={'h6'}
													>
														{moment(item.fecha).format('HH:mm A')}
													</Typography>
													<Typography
														textAlign={'center'}
														component="span"
													>{`Tiempo: ${Math.round(
														item.tiempo
													)} minutos`}</Typography>
													<Typography
														textAlign={'center'}
														component={'span'}
													>{`Precicion: ${item.position.coords.accuracy.toFixed(
														2
													)} metros`}</Typography>
												</Stack>
											</Popup>
										</Marker>
									);
								}
							}
						})}
					</MapContainer>
				)}
			</Container>
		</Dialog>
	);
};

export default ReporteUserTracking;
