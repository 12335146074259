import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getGarantiasAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";
const Garantias = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, tipoCredito, estado } = useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        tipoCredito: tipoCredito === "t" ? 0 : tipoCredito,
        estado: estado === "t" ? 0 : estado,
      };

      setIsLoading(true);
      const result = await getGarantiasAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
      // console.log(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

  const exportExcel = () => {
    let exp = [];

    data.map((item) => {
      // console.log(item);
      let obj = {
        client: item.client,
        id: item.id,
        articulo: item.articulo,
        marca: item.marca,
        modelo: item.modelo,
        serie: item.serie,
        color: item.color,
        valorEstimado: item.valorEstimado,
        creditId: item.creditId,
        creditoMontoSolicitado: item.montoSolicitado,
        sucursal: item.sucursal,
        isDolar: item.isDolar ? "SI" : "NO",
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "garantias");
    XLSX.writeFile(bk, "Garantias.xlsx");
  };

  let totalValorEstimado = 0;

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Garantias
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    // style={{ position: "fixed", right: 20, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Parametros:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Sucursal: ${
                  selectedSucursal === "t" ? "Todas" : sucursalName
                }`}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Tipo de Crédito: ${
                  tipoCredito === "t" ? "Todos" : "1" ? "EMPEÑO" : "CREDITO"
                }`}
              </Typography>

              <Typography fontSize={11} textAlign="left">
                {`Tipo de Crédito: ${
                  tipoCredito === "t"
                    ? "Todos"
                    : tipoCredito === "1"
                    ? "EMPEÑO"
                    : "CREDITO"
                }`}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Usuario:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {user}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {rol}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
              </Typography>
            </Stack>
          </Stack>
        </Container>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <Stack>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.sucursal === suc.description
                );

                let totalGarantias = 0;

                return isEmpty(sSucursal) ? (
                  <></>
                ) : (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>
                    <Divider />
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Cliente
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Articulo
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Marca
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Modelo
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Serie
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Color
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Valor
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #Credito
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto C.
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sSucursal.map((row) => {
                            totalGarantias += row.valorEstimado;
                            totalValorEstimado += row.valorEstimado;
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="left"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.client}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.articulo}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.marca}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.modelo}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.serie}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.color}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.valorEstimado)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.creditId}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat(
                                    row.isDolar ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency: row.isDolar ? "USD" : "NIO",
                                    }
                                  ).format(row.montoSolicitado)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <hr />
                    <Stack
                      direction="row"
                      flex="row"
                      justifyContent="space-around"
                    >
                      <Stack textAlign="center">
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#03a9f4",
                            fontSize: 11,
                          }}
                        >
                          Total Garantias
                        </Typography>
                        <Typography style={{ fontSize: 11 }}>
                          {sSucursal.length}
                        </Typography>
                      </Stack>

                      <Stack textAlign="center">
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#03a9f4",
                            fontSize: 11,
                          }}
                        >
                          Suma Valor Garantia
                        </Typography>
                        <Typography style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(totalGarantias)}
                        </Typography>
                      </Stack>
                    </Stack>

                    <hr />
                  </Stack>
                );
              })}
            </Stack>
          )}

          {sucursalList.length === 1 ? (
            <></>
          ) : (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>
              <hr />

              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total de Garantias
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {data.length}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Valor Garantia
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalValorEstimado)}
                  </Typography>
                </Stack>
              </Stack>

              <hr />
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Garantias"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container
            fixed
            maxWidth="xl"
            sx={{ textAlign: "center", marginTop: 1 }}
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Tipo de Crédito: ${
                    tipoCredito === "t" ? "Todos" : "1" ? "EMPEÑO" : "CREDITO"
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Tipo de Crédito: ${
                    tipoCredito === "t"
                      ? "Todos"
                      : tipoCredito === "1"
                      ? "EMPEÑO"
                      : "CREDITO"
                  }`}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth="xl" sx={{ textAlign: "center" }}>
            <hr />
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal === suc.description
                  );

                  let totalGarantias = 0;

                  return isEmpty(sSucursal) ? (
                    <></>
                  ) : (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>
                      <Divider />
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Cliente
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Articulo
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Marca
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Modelo
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Serie
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Color
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Valor
                              </TableCell>

                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                #Credito
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Monto C.
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sSucursal.map((row) => {
                              totalGarantias += row.valorEstimado;
                              totalValorEstimado += row.valorEstimado;
                              return (
                                <TableRow key={row.id}>
                                  <TableCell
                                    align="left"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.client}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.id}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.articulo}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.marca}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.modelo}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.serie}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.color}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.valorEstimado)}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.creditId}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.montoSolicitado)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <hr />
                      <Stack
                        direction="row"
                        flex="row"
                        justifyContent="space-around"
                      >
                        <Stack textAlign="center">
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#03a9f4",
                              fontSize: 11,
                            }}
                          >
                            Total Garantias
                          </Typography>
                          <Typography style={{ fontSize: 11 }}>
                            {sSucursal.length}
                          </Typography>
                        </Stack>

                        <Stack textAlign="center">
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#03a9f4",
                              fontSize: 11,
                            }}
                          >
                            Suma Valor Garantia
                          </Typography>
                          <Typography style={{ fontSize: 11 }}>
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalGarantias)}
                          </Typography>
                        </Stack>
                      </Stack>

                      <hr />
                    </Stack>
                  );
                })}
              </>
            )}

            {sucursalList.length === 1 ? (
              <></>
            ) : (
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "center",
                  }}
                >
                  Consolidado
                </Typography>
                <hr />

                <Stack direction="row" flex="row" justifyContent="space-around">
                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total de Garantias
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {data.length}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Valor Garantia
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalValorEstimado)}
                    </Typography>
                  </Stack>
                </Stack>

                <hr />
              </Stack>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default Garantias;
