import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getRuta, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { getDepartmentListAsync } from "../../../api/Locations";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

function MainLocations() {
  const [departmentList, setDepartmentList] = useState([]);

  const { reload, setIsLoading, setIsUnautorized } = useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getDepartmentListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setDepartmentList(result.data.result);
    })();
  }, [reload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = departmentList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
            Lista de Departamentos
          </Typography>
          <hr />
          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Departamento
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        # Municipios
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Ver
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="center">
                          {row.municipalities.length}
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            style={{ color: "#2979ff" }}
                            onClick={() => {
                              navigate(`${ruta}/department/${row.id}`);
                            }}
                          >
                            <FontAwesomeIcon icon={faExternalLink} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={departmentList}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default MainLocations;
