import React, { useContext } from 'react';
import { Typography, IconButton, Tooltip, Stack, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const MoraTotal = ({ valueCor, valueDol }) => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();

	return (
		<div>
			<Stack
				direction={'row'}
				style={{ padding: 10 }}
				justifyContent="space-between"
				alignItems={'center'}
			>
				<Tooltip title="Ver Créditos en Mora">
					<IconButton
						disabled={!isAccess(access, 'RCREDXGEST')}
						sx={{ border: 1, borderColor: 'rgba(255, 159, 64, 1)' }}
						style={{
							width: 100,
							height: 100,
							backgroundColor: 'rgba(255, 159, 64, 0.2)'
						}}
						onClick={() => navigate(`${ruta}/dash-mora`)}
					>
						<TrendingDownIcon
							style={{
								fontSize: 110,
								color: 'rgba(255, 159, 64, 1)',
								opacity: 0.3,
								padding: 10
							}}
						/>
					</IconButton>
				</Tooltip>
				<Stack>
					<Typography
						align="right"
						style={{
							color: 'rgba(255, 159, 64, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{new Intl.NumberFormat('es-NI', {
							style: 'currency',
							currency: 'NIO'
						}).format(valueCor)}
					</Typography>
					<Typography
						align="right"
						style={{
							color: 'rgba(255, 159, 64, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD'
						}).format(valueDol)}
					</Typography>
				</Stack>
			</Stack>
			<Divider />
			<Typography
				variant="subtitle1"
				align="center"
				style={{ color: 'rgba(255, 159, 64, 1)', fontSize: 20 }}
			>
				Proyeccion de Cobro Diario
			</Typography>
		</div>
	);
};

export default MoraTotal;
