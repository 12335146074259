import React, { useContext } from "react";
import { Typography, IconButton, Tooltip, Stack, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRuta, isAccess } from "../../../helpers/Helpers";
import { DataContext } from "../../../context/DataContext";

import MoneyOffIcon from "@mui/icons-material/MoneyOff";

const VecidoCredits = ({ value }) => {
  const { access } = useContext(DataContext);
  let ruta = getRuta();
  let navigate = useNavigate();

  return (
    <div>
      <Stack
        direction={"row"}
        style={{ padding: 10 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Tooltip title="Ver Créditos Vencidos">
          <IconButton
            disabled={!isAccess(access, "RCREDVENC")}
            sx={{ border: 1, borderColor: "rgba(255, 99, 132, 1)" }}
            style={{
              width: 100,
              height: 100,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
            }}
            onClick={() => navigate(`${ruta}/dash-defeatedCredits`)}
          >
            <MoneyOffIcon
              style={{
                fontSize: 110,
                color: "rgba(255, 99, 132, 1)",
                opacity: 0.3,
                padding: 10,
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography
          align="right"
          style={{
            color: "rgba(255, 99, 132, 1)",
            fontWeight: 800,
            fontSize: 30,
          }}
          paragraph
        >
          {value}
        </Typography>
      </Stack>
      <Divider />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: "rgba(255, 99, 132, 1)", fontSize: 20 }}
      >
        Créditos Vencidos
      </Typography>
    </div>
  );
};

export default VecidoCredits;
