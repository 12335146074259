import React, { useContext } from "react";

import { DataContext } from "../../../../context/DataContext";

import { getToken } from "../../../../api/Account";

import Swal from "sweetalert2";
import AbonoNominal from "./AbonoNominal";
import AbonoCredito from "./AbonoCredito";

const AddAbono = ({
  setShowModal,
  id,
  localReload,
  setLocalReload,
  isNominal,
}) => {
  const { setShowPrintBillModal, setDataAbono } = useContext(DataContext);

  const printBill = (billData) => {
    Swal.fire({
      title: "Cuota Aplicada...!",
      text: "¿Deseas imprimir el recibo?",
      icon: "question",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Imprimir!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setDataAbono(billData);
        setShowPrintBillModal(true);
      }
    });
  };

  return isNominal ? (
    <AbonoNominal
      setShowModal={setShowModal}
      id={id}
      localReload={localReload}
      setLocalReload={setLocalReload}
      printBill={printBill}
    />
  ) : (
    <AbonoCredito
      setShowModal={setShowModal}
      id={id}
      localReload={localReload}
      setLocalReload={setLocalReload}
      printBill={printBill}
    />
  );
};

export default AddAbono;
