import React, { useContext, useRef } from "react";

import { Typography, IconButton, Stack, Paper } from "@mui/material";
import moment from "moment";

import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import { DataContext } from "../../../../context/DataContext";
import { conv2Letras } from "../../../../helpers/n2Letras";
import PrintBill from "./PrintBill";
const DetalleAbono = ({ setShowModal, isDolar }) => {
  const { title, slogan, icon, dataAbono, setDataAbono } =
    useContext(DataContext);
  const {
    id,
    client,
    concepto,
    cuotaDia,
    diasMora,
    fechaPago,
    montoAtrasado,
    montoCancelacion,
    nuevoSaldo,
    numeroAbono,
    realizadoPor,
    sucursal,
    totalAbonado,
    quote,
  } = dataAbono;

  const compRef = useRef();

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { margin: 10mm; }
`;

  return (
    <div>
      <Paper
        style={{
          padding: 20,
          textAlign: "center",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"start"}
        >
          <img src={icon} style={{ width: 50, height: 50 }} />
          <Stack>
            <Typography variant="h5" textAlign={"center"}>
              {title}
            </Typography>
            <Typography textAlign={"center"}>
              {"Aceptamos joyas, electrodomésticos"}
            </Typography>
            <Typography textAlign={"center"}>
              {"Vehículos y electronicós"}
            </Typography>
            <Typography variant="h6" textAlign={"center"}>
              {`SUCURSAL - ${sucursal.description}`}
            </Typography>
          </Stack>
          <Stack>
            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    //   style={{ position: "absolute", right: 50, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 50, color: "#2979ff", width: 50 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
            <Typography
              variant="h6"
              color="#f50057"
              textAlign="left"
              marginTop={2}
            >
              {`N° ${quote.lastNumRoc}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography textAlign={"right"}>
            FECHA:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {`${moment(fechaPago).format("dddd,")} ${moment(fechaPago).format(
                "D"
              )} de ${moment(fechaPago).format("MMMM")} de ${moment(
                fechaPago
              ).format("YYYY")}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            RECIBIMOS DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${client}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(
                totalAbonado,
                isDolar
              )}  -  (${new Intl.NumberFormat(isDolar ? "en-US" : "es-NI", {
                style: "currency",
                currency: isDolar ? "USD" : "NIO",
              }).format(totalAbonado)})`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${concepto}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" marginTop={5} spacing={3}>
          <Typography textAlign={"center"}>
            Firma:_______________________
          </Typography>
          <Typography textAlign={"left"}>
            Contrato:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${quote.credit.numeroCredito}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={10}>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>ENTREGUE CONFORME</Typography>
          </Stack>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>RECIBI CONFORME</Typography>
          </Stack>
        </Stack>
      </Paper>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintBill ref={compRef} isDolar={isDolar} />
      </div>
    </div>
  );
};

export default DetalleAbono;
