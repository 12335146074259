import axios from 'axios';
import { errorResponse } from '../helpers/Helpers';
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = '';

if (process.env.NODE_ENV === 'production') {
	Api = `${REACT_APP_PRODURL}cashflow`;
} else {
	Api = `${REACT_APP_URL}cashflow`;
}

export const getCashFlowsAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/GetCasFlows`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCierreCajaAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCierreCaja/${id}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(service).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getLastCierreAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/GetLastCierre/${id}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(service).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

