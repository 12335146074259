import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isUndefined } from "lodash";
import moment from "moment";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { getSucursalByUserAsync } from "../../../api/Users";
import { getGastosAsync } from "../../../api/Contabilidad";

import MediumModal from "../../../components/modals/MediumModal";
import DetalleGasto from "./DetalleGasto";
import AddTrasnferencia from "./AddTrasnferencia";
import AddGasto from "./AddGasto";

const GastosList = () => {
  var date = new Date();
  const [fechaDesde] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [fechaHasta] = useState(date);

  const [gastoList, setgastoList] = useState([]);
  const [selectedGasto, setSelectedGasto] = useState([]);
  const [localReload, setLocalReload] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);

  const [sucursalList, setSucursalList] = useState([]);

  const [sumGastos, setSumGastos] = useState(0);
  const [isTrasnf, setIsTrasnsf] = useState(false);

  const {
    setIsLoading,
    setIsUnautorized,
    access,
    selectedSucursal,
    setSelectedSucursal,
  } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      const suc = await getSucursals();
      await getGastos(suc);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = gastoList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
      setSelectedSucursal(result.data.result[0].id);
      return result.data.result[0].id;
    }
    return selectedSucursal;
  };

  const getGastos = async (sucursal) => {
    setIsLoading(true);
    const data = {
      desde: moment(fechaDesde).format("YYYY-MM-DD").toString(),
      hasta: moment(fechaHasta).format("YYYY-MM-DD").toString(),
      sucursalId: sucursal,
    };
    const result = await getGastosAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setgastoList(result.data.result);

    let gastos = 0;
    result.data.result.map((item) => (gastos += item.entradas));
    setSumGastos(gastos);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
    getGastos(value);
  };

  const addMovment = (value) => {
    setIsTrasnsf(value);
    setShowModal(true);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              color={"#00a152"}
              variant="h4"
              fontWeight={"bold"}
              className="d-none d-sm-block"
            >
              Gastos del Mes
            </Typography>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              spacing={2}
              alignItems={"center"}
            >
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                justifyContent="space-between"
                spacing={1}
              >
                <FormControl style={{ textAlign: "left", width: 200 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => onChangeSucursal(e.target.value)}
                    value={selectedSucursal}
                    label="Sucursal"
                  >
                    {sucursalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              {isAccess(access, "CAJA CREATE") ? (
                <Stack spacing={1} direction="column">
                  <Button
                    onClick={() => {
                      addMovment(false);
                    }}
                    startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                    variant="outlined"
                    style={{
                      color: "#f50057",
                      borderColor: "#f50057",
                      padding: 15,
                    }}
                  >
                    Agregar Gasto
                  </Button>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        N°Recibo
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Entrada
                      </TableCell>

                      <TableCell align="left" className="text-primary fw-bold">
                        Cuenta Salida
                      </TableCell>

                      <TableCell align="left" className="text-primary fw-bold">
                        Realizado Por
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Ver
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {moment(row.fecha).format("L")}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.numeroRecibo}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>

                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(row.entradas)}
                          </TableCell>

                          <TableCell align="left">{row.payWith}</TableCell>

                          <TableCell align="left">
                            {`${row.realizadoPor.split(" ")[0]}  ${
                              row.realizadoPor.split(" ")[1]
                            }`}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              style={{
                                color: "#ffc400",
                              }}
                              onClick={() => {
                                setSelectedGasto(row);
                                setPrintModal(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faPrint} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={gastoList}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                spacing={1}
              >
                <Typography
                  textAlign={"left"}
                  color={"#ffc400"}
                  variant="h6"
                  fontWeight={"bold"}
                >
                  Total de Gastos:
                </Typography>
                <Typography
                  variant="h6"
                  textAlign={"left"}
                  color={"#ffc400"}
                  fontWeight={"bold"}
                >
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(sumGastos)}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Paper>
      </Container>

      <MediumModal
        titulo={
          isTrasnf ? "Trasnferir Efectivo Entre Cuentas" : "Salida de Efectivo"
        }
        isVisible={showModal}
        setVisible={setShowModal}
      >
        {isTrasnf ? (
          <AddTrasnferencia
            setShowModal={setShowModal}
            localReload={localReload}
            setLocalReload={setLocalReload}
            selectedSucursal={selectedSucursal}
          />
        ) : (
          <AddGasto
            setShowModal={setShowModal}
            localReload={localReload}
            setLocalReload={setLocalReload}
            selectedSucursal={selectedSucursal}
          />
        )}
      </MediumModal>

      <MediumModal
        titulo={"Detalle Recibo"}
        isVisible={printModal}
        setVisible={setPrintModal}
      >
        <DetalleGasto setShowModal={setShowModal} gasto={selectedGasto} />
      </MediumModal>
    </div>
  );
};

export default GastosList;
