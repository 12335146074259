import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Stack,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";

import { isEmpty, isUndefined } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import {
  anularCierreAsync,
  cerrarMestAsync,
  getCierreMesListAsync,
} from "../../../api/Contabilidad";
import moment from "moment/moment";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

import { getSucursalByUserAsync } from "../../../api/Users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

const CierreMesList = () => {
  const [cierreList, setCierreList] = useState([]);
  const [sucursalList, setSucursalList] = useState([]);
  const [localReload, setLocalReload] = useState(false);

  const {
    setIsLoading,
    setIsUnautorized,
    selectedSucursal,
    setSelectedSucursal,
    access,
  } = useContext(DataContext);

  const token = getToken();

  const withSearch = cierreList.filter((val) => {
    return val;
  });

  useEffect(() => {
    (async () => {
      const selected = await getSucursals();

      await getCierres(selected);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
      setSelectedSucursal(result.data.result[0].id);
      return result.data.result[0].id;
    }

    return selectedSucursal;
  };

  const getCierres = async (id) => {
    setIsLoading(true);
    const result = await getCierreMesListAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setCierreList(result.data.result);
    setIsLoading(false);
  };

  const onChangeSucursal = async (value) => {
    setSelectedSucursal(value);
    await getCierres(value);
  };

  const cerrarMes = async () => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "Verifica que no haya diferencia los saldos...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Continuar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await cerrarMestAsync(token, selectedSucursal);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Listo!", "Mes Cerrado.", "success");
        })();
      }
    });
  };

  const eliminarCierreMes = async (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await anularCierreAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Cierre Anulado.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={"center"}
        spacing={2}
      >
        <Typography
          textAlign={"left"}
          color={"#00a152"}
          variant="h6"
          fontWeight={"bold"}
        >
          Lista de Cierres
        </Typography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={"center"}
          spacing={2}
        >
          <FormControl style={{ textAlign: "left", width: 200 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Sucursal
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => onChangeSucursal(e.target.value)}
              value={selectedSucursal}
              label="Sucursal"
            >
              {sucursalList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            onClick={() => {
              cerrarMes();
            }}
            startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            variant="outlined"
            style={{
              color: "#00a152",
              borderColor: "#00a152",
              padding: 15,
            }}
          >
            Agregar Cierre
          </Button>
        </Stack>
      </Stack>

      <hr />

      {isEmpty(cierreList) ? (
        <NoData />
      ) : (
        <Stack spacing={2}>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 13 }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 13 }}
                  >
                    Fecha Cierre
                  </TableCell>

                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 13 }}
                  >
                    Mes Cerrado
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-primary fw-bold"
                    style={{ fontSize: 13 }}
                  >
                    Realizado Por
                  </TableCell>

                  {isAccess(access, "REP BALC") ? (
                    <TableCell align="center" className="text-primary fw-bold">
                      Eliminar
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItem.map((row) => {
                  const { fullName, fechaCierre, id, monthName, year } = row;
                  return (
                    <TableRow key={row.id} style={{ alignItems: "center" }}>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontSize: 12 }}
                      >
                        {id}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 12 }}>
                        {moment(fechaCierre).format("L")}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 12 }}>
                        {`${monthName} - ${year}`}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: 12 }}>
                        {fullName}
                      </TableCell>
                      {isAccess(access, "REP BALC") ? (
                        <TableCell align="center">
                          <IconButton
                            style={{
                              color: "#f50057",
                              width: 40,
                            }}
                            onClick={() => {
                              eliminarCierreMes(id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationComponent
            data={withSearch}
            paginate={paginate}
            itemsperPage={itemsperPage}
          />
        </Stack>
      )}
    </div>
  );
};

export default CierreMesList;
