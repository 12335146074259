import React, { useEffect, useContext } from 'react';
import { DataContext } from './context/DataContext';
import './App.css';
import { getController, simpleMessage } from './helpers/Helpers';
import Login from './pages/Login';
import Loading from './components/Loading';
import { getToken, getUser, getUserByUsername } from './api/Account';
import DrawerApp from './components/DrawerApp';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ServerOff from './components/ServerOff';
import { checkRolAccessAsync } from './api/Rols';
import * as signalR from '@microsoft/signalr';

const controller = getController();

function App() {
	const {
		setIsLoading,
		isLogged,
		setIsLogged,
		setAccess,
		setIsDarkMode,
		docTitle,
		favicon,
		isServerOff,
		setIsServerOff,
		access
	} = useContext(DataContext);

	const user = getUser();
	const token = getToken();

	useEffect(() => {
		document.title = docTitle;
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = favicon;
		if (token === null || user === null) {
			setIsLoading(false);
			setIsLogged(false);
			return;
		} else {
			getData();
			setIsLogged(true);
		}
	}, []);

	const getData = async () => {
		setIsLoading(true);
		const result = await getUserByUsername(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			simpleMessage(result.error, 'error');
			return;
		}
		setIsServerOff(result.data.userRol.isOff);
		setIsDarkMode(result.data.isDarkMode);
		setAccess(result.data.userRol.permissions);
		setIsLoading(false);
	};

	const checkAccess = async () => {
		const result = await checkRolAccessAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			simpleMessage(result.error, 'error');
			return;
		}
		setIsServerOff(result.data.result);
	};

	const connection = new signalR.HubConnectionBuilder()
		.withUrl(`${controller}serverhub`)
		// .configureLogging(signalR.LogLevel.Debug)
		.build();

	if (token) {
		connection.on('CheckAccess', () => {
			checkAccess();
		});

		connection
			.start()
			.then(() => {
				// console.log("Conectado");
			})
			.catch((err) => {
				console.log(err);
			});
	}

	if (isLogged === null) {
		return <Loading />;
	}

	return isLogged ? (
		isServerOff ? (
			<ServerOff />
		) : (
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DrawerApp />
				{/* <ClientsByBarrio /> */}
			</LocalizationProvider>
		)
	) : (
		<Login />
	);
}

export default App;
