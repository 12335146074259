import React from "react";
import { Grid } from "@mui/material";
import MyAccount from "./pages/MyAccount";
import UpdateUserData from "./pages/UpdateUserData";

const AccountContainer = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={5}>
        <MyAccount />
      </Grid>

      <Grid item sm={7}>
        <UpdateUserData />
      </Grid>
    </Grid>
  );
};

export default AccountContainer;
