import React, { useContext, useState, useEffect } from 'react';

import {
	Paper,
	Typography,
	Container,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faPrint } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getCreditsCancelledAsync } from '../../../api/Report';
import NoData from '../../../components/NoData';
import SmallModal from '../../../components/modals/SmallModal';
import moment from 'moment';
import SelectorCreditsCancelled from '../../reports/selectores/selectoresCartera/SelectorCreditsCancelled';

const DashCancelados = () => {
	const [data, setData] = useState([]);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [moneda, setMoneda] = useState(1);
	const [tipoCredito, setTipoCredito] = useState(0);

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0) {
			setSelectedSucursal(result.data.result[0].id);
		}
		getData(result.data.result[0].id, moneda, tipoCredito);
	};

	const getData = async (sucursal, moneda, tc) => {
		// setIsLoading(true);
		// let hoy = new Date();
		var desde = new Date();
		var hasta = new Date();
		if (desde.getDay() === 6) {
			desde = new Date(desde.setDate(desde.getDate() - 7));
		} else {
			let dia = desde.getDay();
			while (dia !== 6) {
				desde = new Date(desde.setDate(desde.getDate() - 1));
				dia = desde.getDay();
			}
		}

		const data = {
			sucursalId: sucursal,
			desde: moment(desde).format('YYYY-MM-DD'),
			hasta: moment(hasta).format('YYYY-MM-DD'),
			gestorId: 0,
			moneda,
			tipoCredito: tc
		};
		const result = await getCreditsCancelledAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setData(result.data.result);
	};

	const oncChangeSucursal = async (value) => {
		setSelectedSucursal(value);
		getData(value, moneda, tipoCredito);
	};

	const oncChangeMoneda = async (value) => {
		setMoneda(value);
		getData(selectedSucursal, value, tipoCredito);
	};

	const oncChangeTipoCredito = async (value) => {
		setTipoCredito(value);
		getData(selectedSucursal, moneda, value);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent={'space-between'}
						spacing={1}
					>
						<Stack spacing={2}>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
							>
								<Button
									onClick={() => {
										navigate(`${ruta}/`);
									}}
									style={{
										color: '#2979ff',
										borderColor: '#2979ff'
									}}
									variant="outlined"
								>
									<FontAwesomeIcon
										style={{ marginRight: 10, fontSize: 20, padding: 8 }}
										icon={faChevronCircleLeft}
									/>
									Regresar
								</Button>

								<Typography
									color={'#00a152'}
									variant="h6"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Créditos Cancelados
								</Typography>
							</Stack>
							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
									<MenuItem key={0} value={0}>
										TODAS...
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>

						<Stack spacing={2}>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={2}
							>
								<FormControl fullWidth style={{ textAlign: 'left' }}>
									<InputLabel id="demo-simple-select-standard-label">
										Moneda
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										onChange={(e) => oncChangeMoneda(e.target.value)}
										value={moneda}
										label="Moneda"
										style={{ textAlign: 'left' }}
									>
										<MenuItem value="">
											<em>Seleccione una Opción</em>
										</MenuItem>

										<MenuItem key={1} value={1}>
											CORDOBAS C$
										</MenuItem>
										<MenuItem key={2} value={2}>
											DOLARES $
										</MenuItem>
									</Select>
								</FormControl>

								<FormControl fullWidth style={{ textAlign: 'left' }}>
									<InputLabel id="demo-simple-select-standard-label">
										Tipo de Cédito
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										onChange={(e) => oncChangeTipoCredito(e.target.value)}
										value={tipoCredito}
										label="tipo de crédito"
										style={{ textAlign: 'left' }}
									>
										<MenuItem value="">
											<em>Seleccione una Opción</em>
										</MenuItem>

										<MenuItem key={0} value={1}>
											EMPEÑO
										</MenuItem>
										<MenuItem key={1} value={2}>
											CREDITO
										</MenuItem>
										<MenuItem key={0} value={0}>
											TODOS...
										</MenuItem>
									</Select>
								</FormControl>
							</Stack>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={2}
							>
								{isAccess(access, 'RCREDCANC') ? (
									<Button
										onClick={() => {
											setShowModal(true);
										}}
										style={{
											color: '#2979ff',
											borderColor: '#2979ff',
											padding: 10
										}}
										variant="outlined"
										fullWidth
									>
										<FontAwesomeIcon
											style={{ marginRight: 10, fontSize: 20 }}
											icon={faPrint}
										/>
										Reporte
									</Button>
								) : (
									<></>
								)}
							</Stack>
						</Stack>
					</Stack>

					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Cliente
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Teléfono
										</TableCell>

										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Dirección
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											M.Entregado
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											I.C.
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Perioc.
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Plazo
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											F.Canc.
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Renovo
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{data.map((row) => {
										const {
											address,
											fechaCancelacion,
											fullName,
											interesCorriente,
											isRenovado,
											montoEntregado,
											periocidad,
											phone,
											plazo,
											isDolar
										} = row.result;
										return (
											<TableRow key={row.id}>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{fullName}
												</TableCell>
												<TableCell align="center" style={{ fontSize: 11 }}>
													{phone}
												</TableCell>

												<TableCell align="left" style={{ fontSize: 11 }}>
													{address}
												</TableCell>
												<TableCell align="center" style={{ fontSize: 11 }}>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(montoEntregado)}
												</TableCell>
												<TableCell align="center" style={{ fontSize: 11 }}>
													{`${interesCorriente.toFixed(2)} %`}
												</TableCell>

												<TableCell align="center" style={{ fontSize: 11 }}>
													{periocidad}
												</TableCell>

												<TableCell align="center" style={{ fontSize: 11 }}>
													{plazo}
												</TableCell>
												<TableCell align="center" style={{ fontSize: 11 }}>
													{moment(fechaCancelacion).format('L')}
												</TableCell>
												<TableCell align="center" style={{ fontSize: 11 }}>
													{isRenovado ? 'SI' : 'NO'}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}
					<hr />
					<Stack direction="row" flex="row" justifyContent="space-around">
						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Créditos
							</Typography>
							<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
						</Stack>
					</Stack>
					<hr />
				</Paper>
			</Container>

			<SmallModal
				titulo={'Créditos Cancelados'}
				isVisible={showModal}
				setVisible={setShowModal}
			>
				<SelectorCreditsCancelled />
			</SmallModal>
		</div>
	);
};

export default DashCancelados;
