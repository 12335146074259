import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Divider,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../../api/Account";
import { DataContext } from "../../../../context/DataContext";
import {
  getGrupoCuentasListAsync,
  updateCuentaContableAsync,
} from "../../../../api/Contabilidad";
import { simpleMessage } from "../../../../helpers/Helpers";

const UpdateCuentaContable = ({
  selected,
  setShowModal,
  localReload,
  setLocalReload,
}) => {
  const [description, setDescription] = useState(selected.descripcion);
  const [countGroupList, setCountGroupList] = useState([]);
  const [selectedCountGroup, setSelectedCountGroup] = useState(
    selected.tipoCuenta.id
  );
  const [countNumber, setCountNumber] = useState(selected.countNumber);
  const [nivel, setNivel] = useState(selected.nivel);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getGroupList();
      setIsLoading(false);
    })();
  }, [localReload]);

  const saveChangesAsync = async () => {
    if (description.length === 0) {
      simpleMessage("Agrege una descripción...", "error");
      return;
    }
    if (countNumber.length === 0) {
      simpleMessage("Agrege un numero de cuenta...", "error");
      return;
    }

    if (selectedCountGroup === "" || selectedCountGroup === null) {
      simpleMessage("Seleccione una grupo de cuenta...", "error");
      return;
    }
    const data = {
      id: selected.id,
      descripcion: description,
      countGroupId: selectedCountGroup,
      countNumber,
      nivel,
    };

    setIsLoading(true);
    const result = await updateCuentaContableAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Cuenta Contable Actualizada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  const getGroupList = async () => {
    const result = await getGrupoCuentasListAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setCountGroupList(result.data.result);
  };

  const countNumb = (value) => {
    if (/^[0-9,.]+$/.test(value.toString()) || value === "") {
      setCountNumber(value);
      return;
    }
  };
  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          onChange={(e) => setDescription(e.target.value.toUpperCase())}
          label={"Descripcion"}
          value={description}
        />

        <TextField
          fullWidth
          style={{ marginTop: 20 }}
          required
          onChange={(e) => countNumb(e.target.value)}
          label={"Numero de Cuenta"}
          value={countNumber}
        />

        <FormControl fullWidth style={{ marginTop: 20 }} required>
          <InputLabel id="demo-simple-select-standard-label">
            Tipo de Cuenta
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedCountGroup}
            onChange={(e) => setSelectedCountGroup(e.target.value)}
            label="Grupo Cuenta"
            style={{ textAlign: "left" }}
          >
            <MenuItem value="">
              <em>Seleccione tipo de cuenta</em>
            </MenuItem>
            {countGroupList.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel id="demo-simple-select-standard-label">Nivel</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => setNivel(e.target.value)}
            value={nivel}
            label="Nivel"
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            marginTop: 30,
            borderRadius: 20,
            color: "#00a152",
            borderColor: "#00a152",
          }}
          size="large"
        >
          Actualizar Cuenta
        </Button>
      </Container>
    </div>
  );
};

export default UpdateCuentaContable;
