import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faPenToSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import {
  deleteIntCorrienteAsync,
  getInteresListAsync,
} from "../../../api/InteresCorriente";
import SmallModal from "../../../components/modals/SmallModal";
import AddTasaInteres from "./AddTasaInteres";
import { EditTasaInteres } from "./EditTasaInteres";
import Swal from "sweetalert2";

const MainInteresCorriente = () => {
  const [interesCorrienteList, setInteresCorrienteList] = useState([]);
  const [selectedIC, setSelectedIC] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [localReload, setLocalReload] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const withSearch = interesCorrienteList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.name.toString().includes(searchTerm)) {
      return val;
    }
  });

  const { access, setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getInteresListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setInteresCorrienteList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = interesCorrienteList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteIC = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteIntCorrienteAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);

            if (result.error === "eX01") {
              deleteUserData();
              deleteToken();
              setIsLogged(false);
              return;
            }

            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);

          Swal.fire("Eliminado!", "Tasa de Interes Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Tasas de Interes
            </Typography>
            {isAccess(access, "CONFIG CREATE") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Tasa de Interes
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Tasa de Interes
                      </TableCell>
                      {isAccess(access, "CONFIG UPDATE") ||
                      isAccess(access, "CONFIG DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const { id, intCorriente } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {`${intCorriente.toFixed(2)} %`}
                          </TableCell>
                          {isAccess(access, "CONFIG UPDATE") ||
                          isAccess(access, "CONFIG DELETE") ? (
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent="center"
                              >
                                {isAccess(access, "CONFIG UPDATE") ? (
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      setSelectedIC(row);
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {isAccess(access, "CONFIG DELETE") ? (
                                  <IconButton
                                    style={{
                                      color: "#f50057",
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => deleteIC(row)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Tasa de Interes"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddTasaInteres
          setShowModal={setShowModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={`Editar Interes Corriente`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <EditTasaInteres
          selected={selectedIC}
          setShowModal={setShowEditModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default MainInteresCorriente;
