import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faExternalLink,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { isEmpty } from "lodash";
import { getToken } from "../../../../api/Account";
import { DataContext } from "../../../../context/DataContext";
import NoData from "../../../../components/NoData";
import { getCatalogoCuentasListAsync } from "../../../../api/Contabilidad";
import { isAccess, simpleMessage } from "../../../../helpers/Helpers";
import SmallModal from "../../../../components/modals/SmallModal";
import AddCuentaContable from "./AddCuentaContable";
import { PaginationComponent } from "../../../../components/PaginationComponent";
import UpdateCuentaContable from "./UpdateCuentaContable";

const CatalogoCuentas = () => {
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
  const [countList, setCountList] = useState([]);
  const [selectedCount, setSelectedCount] = useState("");
  const [localReload, setLocalReload] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = countList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.descripcion.toString().includes(searchTerm) ||
      val.countNumber.toString().includes(searchTerm)
    ) {
      return val;
    }
  });

  const token = getToken();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getCatalogoCuentasListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setCountList(result.data.result);
      setIsLoading(false);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  return (
    <div>
      <Paper
        style={{
          padding: 20,
          textAlign: "center",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Typography
            textAlign={"left"}
            color={"#00a152"}
            variant="h6"
            fontWeight={"bold"}
          >
            Catalogo de Cuentas
          </Typography>
          {isAccess(access, "ADMON CREATE") ? (
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
              variant="outlined"
              style={{
                color: "#00a152",
                borderColor: "#00a152",
              }}
            >
              Agregar Cuenta
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <hr />

        <TextField
          style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
          fullWidth
          onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
          value={searchTerm}
          label={"Buscar Cuenta"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "#2979ff" }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isEmpty(currentItem) ? (
          <NoData />
        ) : (
          <Stack spacing={2}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className="text-primary fw-bold"
                      style={{ fontSize: 13 }}
                    >
                      #.Cuenta
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-primary fw-bold"
                      style={{ fontSize: 13 }}
                    >
                      Descripción0
                    </TableCell>

                    <TableCell
                      align="center"
                      className="text-primary fw-bold"
                      style={{ fontSize: 13 }}
                    >
                      Tipo Cuenta
                    </TableCell>

                    <TableCell
                      align="center"
                      className="text-primary fw-bold"
                      style={{ fontSize: 13 }}
                    >
                      Nivel
                    </TableCell>

                    <TableCell align="center" className="text-primary fw-bold">
                      Editar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItem.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 12 }}
                        >
                          {row.countNumber}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 12 }}>
                          {row.descripcion}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 12 }}>
                          {row.tipoCuenta.description}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 12 }}>
                          {row.nivel}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            style={{
                              color: "#ffc400",
                              width: 40,
                              height: 40,
                            }}
                            onClick={() => {
                              setShowEditModal(true);
                              setSelectedCount(row);
                            }}
                          >
                            <FontAwesomeIcon icon={faExternalLink} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationComponent
              data={withSearch}
              paginate={paginate}
              itemsperPage={itemsperPage}
            />
          </Stack>
        )}
      </Paper>

      <SmallModal
        titulo={"Agregar Cuenta"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddCuentaContable
          setShowModal={setShowModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={`Editar: ${selectedCount.descripcion}`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <UpdateCuentaContable
          selected={selectedCount}
          setShowModal={setShowEditModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default CatalogoCuentas;
