import React, { useState, useContext } from "react";
import {
  TextField,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { denegarCreditAsync } from "../../../api/Credits";

const DenegarCredit = ({ setShowModal, id }) => {
  const [notaDenegado, setNotaDenegado] = useState("");
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const denegarCredito = async () => {
    if (notaDenegado.length === 0) {
      simpleMessage("Debe ingresar el motivo", "error");
      return;
    }

    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Denegar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const data = {
            id,
            notaAnulacion: notaDenegado,
          };
          const result = await denegarCreditAsync(token, data);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          Swal.fire("Listo!", "", "success");
          setIsLoading(false);
          setShowModal(false);
        })();
      }
    });
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Stack direction="column" spacing={1}>
          <Paper
            style={{
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <Stack spacing={1} direction="row" justifyContent={"center"}>
                <Typography
                  fontWeight={"bold"}
                  style={{
                    color: "#2979ff",
                  }}
                >
                  DENEGAR CREDITO # :
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {id}
                </Typography>
              </Stack>

              <TextField
                required
                fullWidth
                multiline
                onChange={(e) => setNotaDenegado(e.target.value.toUpperCase())}
                value={notaDenegado}
                label={"Nota"}
              />

              <Button
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => denegarCredito()}
                style={{
                  color: "#ffc400",
                  borderColor: "#ffc400",
                }}
                size="large"
              >
                denegar credito
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </div>
  );
};

export default DenegarCredit;
