import React from "react";
import { Typography, IconButton, Tooltip, Stack, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getRuta } from "../../../helpers/Helpers";

const PendieteCredits = ({ value }) => {
  let ruta = getRuta();

  let navigate = useNavigate();

  return (
    <div>
      <Stack
        direction={"row"}
        style={{ padding: 10 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Tooltip title="Ver Créditos no Desembolsados">
          <IconButton
            sx={{ border: 1, borderColor: "rgba(153, 102, 255, 1)" }}
            style={{
              width: 100,
              height: 100,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
            }}
            onClick={() => navigate(`${ruta}/dash-creditsNoDesembolsados`)}
          >
            <FontAwesomeIcon
              icon={faUsersSlash}
              style={{
                fontSize: 60,
                color: "rgba(153, 102, 255, 1))",
                opacity: 0.3,
                padding: 10,
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography
          align="right"
          style={{
            color: "rgba(153, 102, 255, 1)",
            fontWeight: 800,
            fontSize: 30,
          }}
          paragraph
        >
          {value}
        </Typography>
      </Stack>
      <Divider />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: "rgba(153, 102, 255, 1)", fontSize: 20 }}
      >
        No Desembolsados
      </Typography>
    </div>
  );
};

export default PendieteCredits;
