import React, { useState, useEffect, useContext } from 'react';
import {
	Container,
	Paper,
	Typography,
	Stack,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendarWeek,
	faChartColumn,
	faLocationDot,
	faMoneyBillTrendUp,
	faMoneyBillWheat,
	faSackDollar
} from '@fortawesome/free-solid-svg-icons';
import HomeInfo from './HomeInfo';
import ChartDia from './homeInfoComponents/ChartDia';

import MoraByGestor from './homeInfoComponents/MoraByGestor';
import ClientsByLocation from './clientComponents/ClientsByLocation';
import CreditsByPeriodicidad from './clientComponents/CreditsByPeriodicidad';
import ColocacionSemanal from './homeInfoComponents/ColocacionSemanal';
import { getSucursalByUserAsync } from '../../api/Users';
import { DataContext } from '../../context/DataContext';
import { getToken } from '../../api/Account';
import { simpleMessage } from '../../helpers/Helpers';
import CreditsByBarrio from './homeInfoComponents/CreditsByBarrio';

const HomeContainer = () => {
	const [sucursalList, setSucursalList] = useState([]);

	const { setIsLoading, setIsUnautorized, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);

	const token = getToken();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setSucursalList(result.data.result);

		if (selectedSucursal === 0) {
			setSelectedSucursal(result.data.result[0].id);
		}
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
	};

	if (selectedSucursal === 0) {
		return <></>;
	}

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						spacing={2}
						justifyContent="space-between"
					>
						<Stack direction={'row'}>
							<Typography
								color={'#00a152'}
								textAlign="left"
								variant="h4"
								fontWeight={'bold'}
							>
								<FontAwesomeIcon
									icon={faChartColumn}
									style={{ marginRight: 20 }}
									className="fa-beat"
								/>
							</Typography>
							<Typography
								color={'#00a152'}
								textAlign="left"
								variant="h4"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							>
								Resumen General
							</Typography>
						</Stack>

						<FormControl style={{ textAlign: 'left', width: 200 }}>
							<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => onChangeSucursal(e.target.value)}
								value={selectedSucursal}
								label="Sucursal"
							>
								{sucursalList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Stack>

					<hr />
					<HomeInfo selectedSucursal={selectedSucursal} />

					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faCalendarWeek}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Grupo Periodicidad
								</Typography>
							</Stack>

							<hr />
							<Paper style={{ padding: 20 }}>
								<CreditsByPeriodicidad selectedSucursal={selectedSucursal} />
							</Paper>
						</Grid>

						<Grid item xs={12} md={6}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faLocationDot}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Grupo Municipios
								</Typography>
							</Stack>

							<hr />
							<Paper style={{ padding: 20 }}>
								<ClientsByLocation selectedSucursal={selectedSucursal} />
							</Paper>
						</Grid>

						{/* <Grid item xs={12} md={12}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faLocationDot}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h5"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Grupo Barrios
								</Typography>
							</Stack>

							<hr />
							<Paper style={{ padding: 20 }}>
								<CreditsByBarrio selectedSucursal={selectedSucursal} />
							</Paper>
						</Grid> */}

						<Grid item xs={12}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faSackDollar}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Colocación Semanal
								</Typography>
							</Stack>

							<hr />
							<ColocacionSemanal selectedSucursal={selectedSucursal} />
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faMoneyBillTrendUp}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Recupeación Diaria
								</Typography>
							</Stack>

							<hr />
							<ChartDia selectedSucursal={selectedSucursal} />
						</Grid>

						<Grid item xs={12}>
							<Stack direction={'row'} sx={{ mt: 2 }}>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
								>
									<FontAwesomeIcon
										icon={faMoneyBillWheat}
										style={{ marginRight: 20 }}
										className="fa-beat"
									/>
								</Typography>
								<Typography
									color={'#00a152'}
									textAlign="left"
									variant="h4"
									fontWeight={'bold'}
									className="d-none d-sm-block"
								>
									Proyeccion de Cobro por Gestor
								</Typography>
							</Stack>

							<hr />
							<MoraByGestor selectedSucursal={selectedSucursal} />
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</div>
	);
};

export default HomeContainer;
