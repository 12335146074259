import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";
import AddPuesto from "./AddPuesto";
import { getPuestosAsync } from "../../../api/Employees";
import EditPuesto from "./EditPuesto";


const CargoList = () => {
  const [cargoList, setCargoList] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [localReload, setLocalReload] = useState(false);

  const [searchTerm] = useState("");

  const withSearch = cargoList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.description.toString().includes(searchTerm)) {
      return val;
    }
  });

  const { access, setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getPuestosAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);

      setCargoList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = cargoList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Cargos
            </Typography>
            {isAccess(access, "NOMINA CREAR") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Cargo
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Puesto
                      </TableCell>
                      {isAccess(access, "NOMINA UPDATE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Editar
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      const { id, description } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="left">{description}</TableCell>
                          {isAccess(access, "NOMINA UPDATE") ? (
                            <TableCell align="center">
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  setSelectedCargo(row);
                                  setShowEditModal(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Puesto"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddPuesto
          setShowModal={setShowModal}
          puestoReload={localReload}
          setPuestoReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={`Editar Puesto`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <EditPuesto
          selected={selectedCargo}
          setShowModal={setShowEditModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default CargoList;
