import React, { useState, useContext } from "react";
import {
  TextField,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { Save } from "@mui/icons-material";
import { addPuestosAsync } from "../../../api/Employees";

const AddPuesto = ({ setShowModal, puestoReload, setPuestoReload }) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const [description, setDescription] = useState("");

  const saveChangesAsync = async () => {
    if (description.length === 0) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }

    setIsLoading(true);
    const result = await addPuestosAsync(token, description);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Puesto Agregado...!", "success");
    setPuestoReload(!puestoReload);
    setShowModal(false);
  };

  return (
    <div>
      <hr />
      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => setDescription(e.target.value.toUpperCase())}
          label={"Puesto"}
          value={description}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => saveChangesAsync()}>
                  <Save style={{ color: "#2979ff" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};

export default AddPuesto;
