import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Switch,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";

import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronCircleLeft,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import {
  getRolByIdAsync,
  getRolListAsync,
  updateRolAsync,
} from "../../../api/Rols";

const EditRol = () => {
  const token = getToken();
  let ruta = getRuta();
  let navigate = useNavigate();
  const { id } = useParams();

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const [rolName, setRolName] = useState("");
  const [isFullAccess, setIsFullAccess] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [selectedRol, setSelectedRol] = useState([]);

  const [nivelJerarquico, setNivelJerarquico] = useState(0);

  //Client module
  const [clientVer, setClientVer] = useState(false);
  const [clientCreate, setClientCreate] = useState(false);
  const [clientUpdate, setClientUpdate] = useState(false);
  const [clientDelete, setClientDelete] = useState(false);

  //Credit Module
  const [creditVer, setCreditVer] = useState(false);
  const [creditCreate, setCreditCreate] = useState(false);
  const [creditUpdate, setCreditUpdate] = useState(false);
  const [creditRegen, setCreditRegen] = useState(false);
  const [creditDelete, setCreditDelete] = useState(false);
  const [creditLiquidate, setCreditLiquidate] = useState(false);
  const [paymentUpdate, setPaymentUpdate] = useState(false);
  const [printPagare, setPrintPagare] = useState(false);
  const [creditNote, setCreditNote] = useState(false);

  //Admon Module
  const [cashMovVer, setCashMovVer] = useState(false);
  const [cashMovCreate, setCashMovCreate] = useState(false);
  const [cashMovDelete, setCashMovDelete] = useState(false);
  const [admonVer, setAdmonVer] = useState(false);
  const [admonCreate, setAdmonCreate] = useState(false);
  const [cajaVer, setCajaVer] = useState(false);
  const [cajaCreate, setCajaCreate] = useState(false);

  //Report Module
  const [reportsVer, setReportsVer] = useState(false);
  const [rClients, setRClients] = useState(false);
  const [rCredXGest, setRCredCgest] = useState(false);
  const [rCobDiario, setRCobDiario] = useState(false);
  const [recuperac, setRecuperac] = useState(false);
  const [rDesembolso, setRDesembolso] = useState(false);
  const [rSalCart, setRSalCart] = useState(false);
  const [rCredVenc, setRCredVenc] = useState(false);
  const [rVencProy, setRVencProy] = useState(false);
  const [rCredCanc, setRCredCanc] = useState(false);
  const [rAccountSts, setRAccountSts] = useState(false);
  const [rBoxClose, setRBoxClose] = useState(false);
  const [rResultSts, setRResultSts] = useState(false);
  const [rCierre, setRCierre] = useState(false);

  const [repBalC, setRepBalC] = useState(false);
  const [cashFlow, setCashFlow] = useState(false);
  const [repEResult, setRepEresult] = useState(false);
  const [repHGasto, setRepHGasto] = useState(false);
  const [repTCash, setRepTCash] = useState(false);
  const [repHLDiario, setRepHLDiario] = useState(false);
  const [repBalGral, setRepBalGral] = useState(false);

  //Securiry Module
  const [securityVer, setSecurityVer] = useState(false);
  const [securityCreate, setSecurityCreate] = useState(false);
  const [securityUpdate, setSecurityUpdate] = useState(false);
  const [securityDelete, setSecurityDelete] = useState(false);
  const [verSchedule, setVerSchedule] = useState(false);
  const [editSchedule, setEditSchedule] = useState(false);
  const [verSucursal, setVerSucursal] = useState(false);
  const [createSucursal, setCreateSucursal] = useState(false);
  const [editSucursal, setEditSucursal] = useState(false);
  const [migrarCartera, setMigrarCartera] = useState(false);

  //Configuration Module
  const [confVer, setConfVer] = useState(false);
  const [confCreate, setConfCreate] = useState(false);
  const [confUpdate, setConfUpdate] = useState(false);
  const [confDelete, setConfDelete] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const handleChangeFullAccess = () => {
    setIsFullAccess(!isFullAccess);

    //Clients
    setClientVer(!isFullAccess);
    setClientCreate(!isFullAccess);
    setClientUpdate(!isFullAccess);
    setClientDelete(!isFullAccess);

    //Credits
    setCreditVer(!isFullAccess);
    setCreditCreate(!isFullAccess);
    setCreditUpdate(!isFullAccess);
    setCreditRegen(!isFullAccess);
    setCreditDelete(!isFullAccess);
    setCreditLiquidate(!isFullAccess);
    setPaymentUpdate(!isFullAccess);
    setPrintPagare(!isFullAccess);
    setCreditNote(!isFullAccess);

    //Admon
    setCashMovVer(!isFullAccess);
    setCashMovCreate(!isFullAccess);
    setCashMovDelete(!isFullAccess);
    setAdmonVer(!isFullAccess);
    setAdmonCreate(!isFullAccess);
    setCajaVer(!isFullAccess);
    setCajaCreate(!isFullAccess);

    //Reports
    setReportsVer(!isFullAccess);
    setRClients(!isFullAccess);
    setRCredCgest(!isFullAccess);
    setRCobDiario(!isFullAccess);
    setRecuperac(!isFullAccess);
    setRDesembolso(!isFullAccess);
    setRSalCart(!isFullAccess);
    setRCredVenc(!isFullAccess);
    setRVencProy(!isFullAccess);
    setRCredCanc(!isFullAccess);
    setRAccountSts(!isFullAccess);
    setRBoxClose(!isFullAccess);
    setRResultSts(!isFullAccess);

    setRepBalC(!isFullAccess);
    setCashFlow(!isFullAccess);
    setRepEresult(!isFullAccess);
    setRepHGasto(!isFullAccess);
    setRepTCash(!isFullAccess);
    setRepHLDiario(!isFullAccess);
    setRepBalGral(!isFullAccess);

    //Security
    setSecurityVer(!isFullAccess);
    setSecurityCreate(!isFullAccess);
    setSecurityUpdate(!isFullAccess);
    setSecurityDelete(!isFullAccess);
    setVerSchedule(!isFullAccess);
    setEditSchedule(!isFullAccess);
    setVerSucursal(!isFullAccess);
    setCreateSucursal(!isFullAccess);
    setEditSucursal(!isFullAccess);
    setMigrarCartera(!isFullAccess);

    //Settings
    setConfVer(!isFullAccess);
    setConfCreate(!isFullAccess);
    setConfUpdate(!isFullAccess);
    setConfDelete(!isFullAccess);
  };

  const saveRol = async () => {
    if (rolName === "") {
      simpleMessage("Ingrese una nombre al nuevo rol", "error");
      return;
    }

    selectedRol.rolName = rolName;
    selectedRol.isFullAccess = isFullAccess;
    selectedRol.idRolSuperior = nivelJerarquico;
    selectedRol.permissions.map((item) => {
      switch (item.description) {
        //Clients
        case "CLIENTS VER":
          item.isEnable = clientVer;
          break;
        case "CLIENTS CREATE":
          item.isEnable = clientCreate;
          break;
        case "CLIENTS UPDATE":
          item.isEnable = clientUpdate;
          break;
        case "CLIENTS DELETE":
          item.isEnable = clientDelete;
          break;

        //Admon
        case "CASHMOV VER":
          item.isEnable = cashMovVer;
          break;
        case "CASHMOV CREATE":
          item.isEnable = cashMovCreate;
          break;
        case "CASHMOV DELETE":
          item.isEnable = cashMovDelete;
          break;

        case "ADMON VER":
          item.isEnable = admonVer;
          break;
        case "ADMON CREATE":
          item.isEnable = admonCreate;
          break;
        case "CAJA VER":
          item.isEnable = cajaVer;
          break;
        case "CAJA CREATE":
          item.isEnable = cajaCreate;
          break;

        //Credits
        case "CREDITS VER":
          item.isEnable = creditVer;
          break;
        case "CREDITS CREATE":
          item.isEnable = creditCreate;
          break;
        case "CREDITS REGEN":
          item.isEnable = creditRegen;
          break;
        case "CREDITS UPDATE":
          item.isEnable = creditUpdate;
          break;
        case "CREDITS DELETE":
          item.isEnable = creditDelete;
          break;
        case "CREDIT LIQUIDATE":
          item.isEnable = creditLiquidate;
          break;
        case "PAYMENT UPDATE":
          item.isEnable = paymentUpdate;
          break;
        case "PRINT PAGARE":
          item.isEnable = printPagare;
          break;
        case "CREDITNOTE":
          item.isEnable = creditNote;
          break;

        //Reports
        case "REPORTS VER":
          item.isEnable = reportsVer;
          break;
        case "RCLIENTS":
          item.isEnable = rClients;
          break;
        case "RCREDXGEST":
          item.isEnable = rCredXGest;
          break;
        case "RCOBDIARIO":
          item.isEnable = rCobDiario;
          break;
        case "RECUPERAC":
          item.isEnable = recuperac;
          break;
        case "RDESEMBOLSO":
          item.isEnable = rDesembolso;
          break;
        case "RSALCART":
          item.isEnable = rSalCart;
          break;
        case "RCREDVENC":
          item.isEnable = rCredVenc;
          break;
        case "RVENCPROY":
          item.isEnable = rVencProy;
          break;
        case "RCREDCANC":
          item.isEnable = rCredCanc;
          break;
        case "RACCOUNTSTS":
          item.isEnable = rAccountSts;
          break;
        case "RBOXCLOSE":
          item.isEnable = rBoxClose;
          break;
        case "RRESULTSTS":
          item.isEnable = rResultSts;
          break;
        case "RCIERRE":
          item.isEnable = rCierre;
          break;

        case "REP BALC":
          item.isEnable = repBalC;
          break;
        case "CASHFLOW":
          item.isEnable = cashFlow;
          break;
        case "REP ERESULT":
          item.isEnable = repEResult;
          break;
        case "REP HGASTO":
          item.isEnable = repHGasto;
          break;
        case "REP TCASH":
          item.isEnable = repTCash;
          break;
        case "REP HLDIADIO":
          item.isEnable = repHLDiario;
          break;
        case "REP BALGRAL":
          item.isEnable = repBalGral;
          break;

        //Security
        case "SECURITY VER":
          item.isEnable = securityVer;
          break;
        case "SECURITY CREATE":
          item.isEnable = securityCreate;
          break;
        case "SECURITY UPDATE":
          item.isEnable = securityUpdate;
          break;
        case "SECURITY DELETE":
          item.isEnable = securityDelete;
          break;
        case "VER HORARIOS":
          item.isEnable = verSchedule;
          break;
        case "EDITAR HORARIOS":
          item.isEnable = editSchedule;
          break;

        case "VER SUCURSAL":
          item.isEnable = verSucursal;
          break;
        case "CREAR SUCURSAL":
          item.isEnable = createSucursal;
          break;
        case "EDITAR SUCURSAL":
          item.isEnable = editSucursal;
          break;
        case "MIGRAR CARTERA":
          item.isEnable = migrarCartera;
          break;

        //Configurations
        case "CONFIG VER":
          item.isEnable = confVer;
          break;
        case "CONFIG CREATE":
          item.isEnable = confCreate;
          break;
        case "CONFIG UPDATE":
          item.isEnable = confUpdate;
          break;
        case "CONFIG DELETE":
          item.isEnable = confDelete;
          break;
      }
    });

    setIsLoading(true);
    const result = await updateRolAsync(token, selectedRol);
    if (!result.statusResponse) {
      setIsLoading(false);

      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }

      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Rol actualizado...!", "success");
    setIsEdit(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getRolListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setRolesList(result.data.result);

      getRolData();

      setIsLoading(false);
    })();
  }, []);

  const getRolData = async () => {
    setIsLoading(true);
    const result = await getRolByIdAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setSelectedRol(result.data.result);
    setIsLoading(false);
    setRolName(result.data.result.rolName);
    setNivelJerarquico(result.data.result.idRolSuperior);
    setIsFullAccess(result.data.result.isFullAccess);

    result.data.result.permissions.map((item) => {
      switch (item.description) {
        //Clients
        case "CLIENTS VER":
          setClientVer(item.isEnable);
          break;
        case "CLIENTS CREATE":
          setClientCreate(item.isEnable);
          break;
        case "CLIENTS UPDATE":
          setClientUpdate(item.isEnable);
          break;
        case "CLIENTS DELETE":
          setClientDelete(item.isEnable);
          break;

        //Credits
        case "CREDITS VER":
          setCreditVer(item.isEnable);
          break;
        case "CREDITS CREATE":
          setCreditCreate(item.isEnable);
          break;
        case "CREDITS UPDATE":
          setCreditUpdate(item.isEnable);
          break;
        case "CREDITS REGEN":
          setCreditRegen(item.isEnable);
          break;
        case "CREDITS DELETE":
          setCreditDelete(item.isEnable);
          break;
        case "CREDIT LIQUIDATE":
          setCreditLiquidate(item.isEnable);
          break;
        case "PAYMENT UPDATE":
          setPaymentUpdate(item.isEnable);
          break;
        case "PRINT PAGARE":
          setPrintPagare(item.isEnable);
          break;
        case "CREDITNOTE":
          setCreditNote(item.isEnable);
          break;

        //Admon
        case "CASHMOV VER":
          setCashMovVer(item.isEnable);
          break;
        case "CASHMOV CREATE":
          setCashMovCreate(item.isEnable);
          break;
        case "CASHMOV DELETE":
          setCashMovDelete(item.isEnable);
          break;
        case "ADMON VER":
          setAdmonVer(item.isEnable);
          break;
        case "ADMON CREATE":
          setAdmonCreate(item.isEnable);
          break;
        case "CAJA VER":
          setCajaVer(item.isEnable);
          break;
        case "CAJA CREATE":
          setCajaCreate(item.isEnable);
          break;

        //Reports
        case "REPORTS VER":
          setReportsVer(item.isEnable);
          break;
        case "RCLIENTS":
          setRClients(item.isEnable);
          break;
        case "RCREDXGEST":
          setRCredCgest(item.isEnable);
          break;
        case "RCOBDIARIO":
          setRCobDiario(item.isEnable);
          break;
        case "RECUPERAC":
          setRecuperac(item.isEnable);
          break;
        case "RDESEMBOLSO":
          setRDesembolso(item.isEnable);
          break;
        case "RSALCART":
          setRSalCart(item.isEnable);
          break;
        case "RCREDVENC":
          setRCredVenc(item.isEnable);
          break;
        case "RVENCPROY":
          setRVencProy(item.isEnable);
          break;
        case "RCREDCANC":
          setRCredCanc(item.isEnable);
          break;
        case "RACCOUNTSTS":
          setRAccountSts(item.isEnable);
          break;
        case "RBOXCLOSE":
          setRBoxClose(item.isEnable);
          break;
        case "RRESULTSTS":
          setRResultSts(item.isEnable);
          break;
        case "RCIERRE":
          setRCierre(item.isEnable);
          break;

        case "REP BALC":
          setRepBalC(item.isEnable);
          break;
        case "CASHFLOW":
          setCashFlow(item.isEnable);
          break;
        case "REP ERESULT":
          setRepEresult(item.isEnable);
          break;
        case "REP HGASTO":
          setRepHGasto(item.isEnable);
          break;
        case "REP TCASH":
          setRepTCash(item.isEnable);
          break;
        case "REP HLDIADIO":
          setRepHLDiario(item.isEnable);
          break;
        case "REP BALGRAL":
          setRepBalGral(item.isEnable);
          break;

        //Security
        case "SECURITY VER":
          setSecurityVer(item.isEnable);
          break;
        case "SECURITY CREATE":
          setSecurityCreate(item.isEnable);
          break;
        case "SECURITY UPDATE":
          setSecurityUpdate(item.isEnable);
          break;
        case "SECURITY DELETE":
          setSecurityDelete(item.isEnable);
          break;
        case "VER HORARIOS":
          setVerSchedule(item.isEnable);
          break;
        case "EDITAR HORARIOS":
          setEditSchedule(item.isEnable);
          break;

        case "VER SUCURSAL":
          setVerSucursal(item.isEnable);
          break;
        case "CREAR SUCURSAL":
          setCreateSucursal(item.isEnable);
          break;
        case "EDITAR SUCURSAL":
          setEditSucursal(item.isEnable);
          break;
        case "MIGRAR CARTERA":
          setMigrarCartera(item.isEnable);
          break;

        //Settings
        case "CONFIG VER":
          setConfVer(item.isEnable);
          break;
        case "CONFIG CREATE":
          setConfCreate(item.isEnable);
          break;
        case "CONFIG UPDATE":
          setConfUpdate(item.isEnable);
          break;
        case "CONFIG DELETE":
          setConfDelete(item.isEnable);
          break;
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={
              isAccess(access, "SECURITY UPDATE") ? "space-between" : ""
            }
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/rols`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Editar Rol
            </Typography>

            {isAccess(access, "SECURITY UPDATE") ? (
              <Button
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
                style={{
                  color: "#ffc400",
                  borderColor: "#ffc400",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faPenToSquare}
                />
                Editar
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          <Typography
            variant="h6"
            color={"#1c54b2"}
            style={{ textAlign: "left" }}
          >
            Configuraciones del Rol
          </Typography>

          <Paper
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Stack
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <TextField
                fullWidth
                style={{ maxWidth: 600 }}
                onChange={(e) => setRolName(e.target.value.toUpperCase())}
                label={"Nombre rol"}
                value={rolName}
                disabled={!isEdit}
              />

              <FormControl
                disabled={!isEdit}
                fullWidth
                required
                style={{ maxWidth: 600 }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Seleccione un rol superior
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={nivelJerarquico}
                  onChange={(e) => setNivelJerarquico(e.target.value)}
                  label="Seleccione un rol superior"
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="">
                    <em>Seleccione un rol superior</em>
                  </MenuItem>
                  {rolesList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.rolName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>

            <Typography
              variant="h6"
              fontWeight={"bold"}
              style={{
                color: "#2979ff",
              }}
            >
              Acceso Total al Sistema
            </Typography>

            <Switch
              disabled={!isEdit}
              style={{
                color: isFullAccess ? "#4caf50" : "#f50057",
              }}
              checked={isFullAccess}
              onChange={handleChangeFullAccess}
            />

            <Stack spacing={2}>
              {/* Clientes */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Clientes
                </Typography>
                <Divider />
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  display="flex"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={clientVer}
                        onChange={() => setClientVer(!clientVer)}
                      />
                    }
                    label="Ver Clientes"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={clientCreate}
                        onChange={() => setClientCreate(!clientCreate)}
                      />
                    }
                    label="Crear Clientes"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    style={{
                      textAlign: "center",
                    }}
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={clientUpdate}
                        onChange={() => setClientUpdate(!clientUpdate)}
                      />
                    }
                    label="Actualizar Cliente"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    style={{
                      textAlign: "center",
                    }}
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={clientDelete}
                        onChange={() => setClientDelete(!clientDelete)}
                      />
                    }
                    label="Eliminar Cliente"
                  />
                </Stack>
              </Paper>

              {/* Credits */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Creditos
                </Typography>

                <Divider />

                <Stack
                  display="flex"
                  justifyContent="space-around"
                  marginTop={1}
                  spacing={1}
                >
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Permisos de Crédito
                    </Typography>

                    <Divider />

                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditVer}
                            onChange={() => setCreditVer(!creditVer)}
                          />
                        }
                        label="Ver Créditos"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditCreate}
                            onChange={() => setCreditCreate(!creditCreate)}
                          />
                        }
                        label="Crear Créditos"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditUpdate}
                            onChange={() => setCreditUpdate(!creditUpdate)}
                          />
                        }
                        label="Actualizar Crédito"
                      />
                    </Stack>
                  </Paper>

                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Permisos Especiales de Crédito
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditRegen}
                            onChange={() => setCreditRegen(!creditRegen)}
                          />
                        }
                        label="Regenerar Crédito"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditLiquidate}
                            onChange={() =>
                              setCreditLiquidate(!creditLiquidate)
                            }
                          />
                        }
                        label="Liquidar Crédito"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditDelete}
                            onChange={() => setCreditDelete(!creditDelete)}
                          />
                        }
                        label="Eliminar Crédito"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={paymentUpdate}
                            onChange={() => setPaymentUpdate(!paymentUpdate)}
                          />
                        }
                        label="Cambiar F. Pago"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={printPagare}
                            onChange={() => setPrintPagare(!printPagare)}
                          />
                        }
                        label="Imprimir Contrato"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={creditNote}
                            onChange={() => setCreditNote(!creditNote)}
                          />
                        }
                        label="Nota de Cédito"
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Paper>

              {/* Admon */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Administración
                </Typography>
                <Divider />
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  display="flex"
                  justifyContent="space-around"
                  marginTop={1}
                  spacing={2}
                >
                  {/* Permisos Caja general */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Caja
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={cajaVer}
                            onChange={() => setCajaVer(!cajaVer)}
                          />
                        }
                        label="Ver Caja"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={cajaCreate}
                            onChange={() => setCajaCreate(!cajaCreate)}
                          />
                        }
                        label="Crear Movimiento"
                      />
                    </Stack>
                  </Paper>

                  {/* Permisos Caja Chica */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Caja Chica
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={cashMovVer}
                            onChange={() => setCashMovVer(!cashMovVer)}
                          />
                        }
                        label="Ver Caja"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={cashMovCreate}
                            onChange={() => setCashMovCreate(!cashMovCreate)}
                          />
                        }
                        label="Crear Movimiento"
                      />
                    </Stack>
                  </Paper>

                  {/* Permisos Contabilidad */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Gastos
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={admonVer}
                            onChange={() => setAdmonVer(!admonVer)}
                          />
                        }
                        label="Ver Gastos"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={admonCreate}
                            onChange={() => setAdmonCreate(!admonCreate)}
                          />
                        }
                        label="Crear Gastos"
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Paper>

              {/* Reports */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Reportes
                </Typography>
                <Divider />

                <Stack
                  display="flex"
                  justifyContent="space-around"
                  marginTop={1}
                  spacing={1}
                >
                  {/* Reportes de cartera */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Reportes de Cartera
                    </Typography>

                    <Divider />

                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rClients}
                            onChange={() => setRClients(!rClients)}
                          />
                        }
                        label="Clientes por Gestor"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rCredXGest}
                            onChange={() => setRCredCgest(!rCredXGest)}
                          />
                        }
                        label="Creditos por Gestor"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rCobDiario}
                            onChange={() => setRCobDiario(!rCobDiario)}
                          />
                        }
                        label="Cobro Diario"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={recuperac}
                            onChange={() => setRecuperac(!recuperac)}
                          />
                        }
                        label="Recuperación"
                      />
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rDesembolso}
                            onChange={() => setRDesembolso(!rDesembolso)}
                          />
                        }
                        label="Desembolso"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rSalCart}
                            onChange={() => setRSalCart(!rSalCart)}
                          />
                        }
                        label="Saldos Cartera"
                      />
                    </Stack>

                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rCredVenc}
                            onChange={() => setRCredVenc(!rCredVenc)}
                          />
                        }
                        label="Créditos Vencidos"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rVencProy}
                            onChange={() => setRVencProy(!rVencProy)}
                          />
                        }
                        label="Vencimientos Proyectados"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rCredCanc}
                            onChange={() => setRCredCanc(!rCredCanc)}
                          />
                        }
                        label="Créditos Cancelados"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rAccountSts}
                            onChange={() => setRAccountSts(!rAccountSts)}
                          />
                        }
                        label="Estado de Cuenta"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rCierre}
                            onChange={() => setRCierre(!rCierre)}
                          />
                        }
                        label="Reporte de Cierre"
                      />

                      {/* <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rBoxClose}
                            onChange={() => setRBoxClose(!rBoxClose)}
                          />
                        }
                        label="Cierre de Caja"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={rResultSts}
                            onChange={() => setRResultSts(!rResultSts)}
                          />
                        }
                        label="Estado de Resultado"
                      /> */}
                    </Stack>
                  </Paper>

                  {/* Reportes Administrativos| */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Reportes Administrativos
                    </Typography>
                    <Divider />

                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={repHGasto}
                            onChange={() => setRepHGasto(!repHGasto)}
                          />
                        }
                        label="Reporte de Gastos"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={cashFlow}
                            onChange={() => setCashFlow(!cashFlow)}
                          />
                        }
                        label="Flujo de Caja"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={repHLDiario}
                            onChange={() => setRepHLDiario(!repHLDiario)}
                          />
                        }
                        label="Libro Diario"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={repBalC}
                            onChange={() => setRepBalC(!repBalC)}
                          />
                        }
                        label="Balanza de Comprobación"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={repEResult}
                            onChange={() => setRepEresult(!repEResult)}
                          />
                        }
                        label="Estado de Resultado"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={repBalGral}
                            onChange={() => setRepBalGral(!repBalGral)}
                          />
                        }
                        label="Balance General"
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Paper>

              {/* Security */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Seguridad
                </Typography>
                <Divider />
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  display="flex"
                  justifyContent="space-around"
                  marginTop={1}
                  spacing={1}
                >
                  {/* Usuarios y Roles */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Usuarios y Roles
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={securityVer}
                            onChange={() => setSecurityVer(!securityVer)}
                          />
                        }
                        label="Ver"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={securityCreate}
                            onChange={() => setSecurityCreate(!securityCreate)}
                          />
                        }
                        label="Crear"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={securityUpdate}
                            onChange={() => setSecurityUpdate(!securityUpdate)}
                          />
                        }
                        label="Actualizar"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        style={{
                          textAlign: "center",
                        }}
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={securityDelete}
                            onChange={() => setSecurityDelete(!securityDelete)}
                          />
                        }
                        label="Eliminar"
                      />
                    </Stack>
                  </Paper>

                  {/* Accesos al Servidor */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Accesos al Servidor
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={verSchedule}
                            onChange={() => setVerSchedule(!verSchedule)}
                          />
                        }
                        label="Ver Horarios"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={editSchedule}
                            onChange={() => setEditSchedule(!editSchedule)}
                          />
                        }
                        label="Editar Horarios"
                      />
                    </Stack>
                  </Paper>

                  {/* Permisos Especiales */}
                  <Paper
                    style={{
                      padding: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 17,
                        color: "#2196f3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Permisos Especiales
                    </Typography>
                    <Divider />
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={verSucursal}
                            onChange={() => setVerSucursal(!verSucursal)}
                          />
                        }
                        label="Ver Sucursal"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={createSucursal}
                            onChange={() => setCreateSucursal(!createSucursal)}
                          />
                        }
                        label="Crear Sucursal"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={editSucursal}
                            onChange={() => setEditSucursal(!editSucursal)}
                          />
                        }
                        label="Editar Sucursal"
                      />

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            disabled={!isEdit}
                            checked={migrarCartera}
                            onChange={() => setMigrarCartera(!migrarCartera)}
                          />
                        }
                        label="Migrar Cartera"
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Paper>

              {/* Configurations */}
              <Paper
                style={{
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#2196f3",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Modulo Configuracion
                </Typography>
                <Divider />
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  display="flex"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={confVer}
                        onChange={() => setConfVer(!confVer)}
                      />
                    }
                    label="Ver Configuraciones"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={confCreate}
                        onChange={() => setConfCreate(!confCreate)}
                      />
                    }
                    label="Crear Configuraciones"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    style={{
                      textAlign: "center",
                    }}
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={confUpdate}
                        onChange={() => setConfUpdate(!confUpdate)}
                      />
                    }
                    label="Actualizar Configuraciones"
                  />

                  <FormControlLabel
                    labelPlacement="top"
                    style={{
                      textAlign: "center",
                    }}
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={confDelete}
                        onChange={() => setConfDelete(!confDelete)}
                      />
                    }
                    label="Eliminar Configuraciones"
                  />
                </Stack>
              </Paper>

              <Button
                disabled={!isEdit}
                fullWidth
                variant="outlined"
                style={{ color: "#2979ff", borderColor: "#2979ff" }}
                startIcon={<FontAwesomeIcon icon={faSave} />}
                onClick={() => saveRol()}
              >
                Actualizar Rol
              </Button>
            </Stack>
          </Paper>
        </Paper>
      </Container>
    </div>
  );
};

export default EditRol;
