import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}roles`;
} else {
  Api = `${REACT_APP_URL}roles`;
}

export const getRolListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetRoles";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const addRolAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/AddRol`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getRolByIdAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetRolById/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service + id).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const updateRolAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/UpdateRol`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const deleteRolByIdAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/DeleteRol/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service + id).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const updateRolScheduleAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/UpdateRolShedule`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const checkRolAccessAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/CheckRolAccess";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
