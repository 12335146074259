import React, { useContext, useState, useEffect } from 'react';

import {
	Paper,
	Typography,
	Container,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import { getRuta, simpleMessage } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getGastosAsync } from '../../../api/Report';
import NoData from '../../../components/NoData';
import moment from 'moment';

const DashGastos = () => {
	const [data, setData] = useState([]);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState(0);

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0) {
			setSelectedSucursal(result.data.result[0].id);
		}
		getData(result.data.result[0].id);
	};

	const getData = async (sucursal) => {
		setIsLoading(true);
		const result = await getGastosAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setData(result.data.result);
		setIsLoading(false);
	};

	const oncChangeSucursal = async (value) => {
		setSelectedSucursal(value);
		getData(value);
	};

	let monto = 0;
	data.map((c) => {
		monto += c.debito;
	});

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent={'space-between'}
						spacing={1}
					>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<Button
								onClick={() => {
									navigate(`${ruta}/`);
								}}
								style={{
									color: '#2979ff',
									borderColor: '#2979ff'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faChevronCircleLeft}
								/>
								Regresar
							</Button>

							<Typography
								color={'#00a152'}
								variant="h6"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							>
								Gastos
							</Typography>
						</Stack>

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={2}
						>
							<FormControl fullWidth style={{ textAlign: 'left', width: 200 }}>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
									<MenuItem key={0} value={0}>
										TODAS...
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>

					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Referencia
										</TableCell>

										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Cuenta
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Monto
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Fecha
										</TableCell>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Realizado Por
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row) => {
										return (
											<TableRow key={row.id}>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.referencia}
												</TableCell>
												<TableCell align="left" style={{ fontSize: 11 }}>
													{`${row.countNumber} - ${row.descripcion}`}
												</TableCell>

												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(row.debito)}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{moment(row.fech).format('L')}
												</TableCell>

												<TableCell
													align="left"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.fullName}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}

					<hr />
					<Stack direction="row" flex="row" justifyContent="space-around">
						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Registros
							</Typography>
							<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Principal
							</Typography>
							<Typography style={{ fontSize: 11 }}>
								{new Intl.NumberFormat('es-NI', {
									style: 'currency',
									currency: 'NIO'
								}).format(monto)}
							</Typography>
						</Stack>
					</Stack>
					<hr />
				</Paper>
			</Container>
		</div>
	);
};

export default DashGastos;
