import React, { useContext } from 'react';
import {
	faBriefcase,
	faCalculator,
	faHandHoldingDollar,
	faMoneyBillTransfer,
  faCheckToSlot,
  faCircleDollarToSlot
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Collapse,
	Typography,
	Divider
} from '@mui/material';
import { Stack } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { getRuta, isAccess } from '../../helpers/Helpers';
import { DataContext } from '../../context/DataContext';

const Administracion = ({ drawerIsOpen }) => {
	const [open, setOpen] = React.useState(false);
	let rout = getRuta();
	const { access } = useContext(DataContext);
	return (
		<div>
			<ListItemButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
				<ListItemIcon>
					<FontAwesomeIcon
						icon={faBriefcase}
						style={{
							fontSize: 22
						}}
					/>
				</ListItemIcon>
				<ListItemText primary="Administracion" />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>

			<Collapse in={open} timeout="auto" unmountOnExit>
				<Divider />
				<Stack marginTop={1} paddingLeft={drawerIsOpen ? 4 : 0.5} spacing={1}>
					{isAccess(access, 'CASHFLOW') ? (
						<NavLink
							to={`${rout}/cashflow`}
							className={(navData) => (navData.isActive ? 'active' : 'inactive')}
						>
							<Stack spacing={3} direction="row" alignItems={'center'}>
								<FontAwesomeIcon
									icon={faMoneyBillTransfer}
									style={{
										fontSize: 22
									}}
								/>
								<Typography>Flujo de Efectivo</Typography>
							</Stack>
						</NavLink>
					) : (
						<></>
					)}

					{isAccess(access, 'ACAJA VER') ? (
						<>
							{/* <NavLink
								to={`${rout}/cashregister`}
								className={(navData) => (navData.isActive ? 'active' : 'inactive')}
							>
								<Stack spacing={3} direction="row" alignItems={'center'}>
									<FontAwesomeIcon
										icon={faCheckToSlot}
										style={{
											fontSize: 22
										}}
									/>
									<Typography>Arqueo Caja</Typography>
								</Stack>
							</NavLink> */}
							<NavLink
								to={`${rout}/arqueouser`}
								className={(navData) => (navData.isActive ? 'active' : 'inactive')}
							>
								<Stack spacing={3} direction="row" alignItems={'center'}>
									<FontAwesomeIcon
										icon={faCircleDollarToSlot}
										style={{
											fontSize: 22
										}}
									/>
									<Typography>Arqueo Caja</Typography>
								</Stack>
							</NavLink>
						</>
					) : (
						<></>
					)}

					{isAccess(access, 'ADMON VER') ? (
						<>
							<NavLink
								to={`${rout}/gastos`}
								className={(navData) => (navData.isActive ? 'active' : 'inactive')}
							>
								<Stack spacing={3} direction="row" alignItems={'center'}>
									<FontAwesomeIcon
										icon={faHandHoldingDollar}
										style={{
											fontSize: 22
										}}
									/>
									<Typography>Gastos</Typography>
								</Stack>
							</NavLink>

							<NavLink
								to={`${rout}/contabilidad`}
								className={(navData) => (navData.isActive ? 'active' : 'inactive')}
							>
								<Stack spacing={3} direction="row" alignItems={'center'}>
									<FontAwesomeIcon
										icon={faCalculator}
										style={{
											fontSize: 25
										}}
									/>
									<Typography>Contabilidad</Typography>
								</Stack>
							</NavLink>
						</>
					) : (
						<></>
					)}
				</Stack>
			</Collapse>
		</div>
	);
};

export default Administracion;
