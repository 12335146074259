import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}account`;
} else {
  Api = `${REACT_APP_URL}account`;
}

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getUser = () => {
  return localStorage.getItem("user");
};

export const getRol = () => {
  return localStorage.getItem("rol");
};

export const deleteToken = () => {
  return localStorage.removeItem("token");
};

export const deleteUserData = () => {
  return localStorage.removeItem("user");
};

export const deleteRol = () => {
  return localStorage.removeItem("rol");
};

export const apiLogin = async (data) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api + "/Login";
  try {
    await axios.post(url, data).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
        resul.error = resp.data.message;
        return resul;
      } else {
        localStorage.setItem("rol", resp.data.result.user.userRol.rolName);
        localStorage.setItem("token", resp.data.result.token);
        localStorage.setItem(
          "user",
          `${resp.data.result.user.firstName} ${resp.data.result.user.lastName}`
        );

        resul.data = resp.data.result.user;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
    return resul;
  }
  return resul;
};

export const apiLogOut = () => {
  const resul = { statusResponse: true, error: null };
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("rol");
  } catch (error) {
    resul.statusResponse = false;
    resul.error = error;
  }
  return resul;
};

export const changeThemeAsync = async (token) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api + "/ChangeTheme";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    await authAxios.post(url).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
      } else {
        resul.data = resp.data.result;
        resul.statusResponse = true;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
  }
  return resul;
};

export const updatePasswordAsync = async (token, data) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api + "/UpdatePassword";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(url, data).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
      } else {
        resul.data = resp.data.result;
        resul.statusResponse = true;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
  }
  return resul;
};

export const updateMyAccountAsync = async (token, data) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api + "/UpdateMyAccoun";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(url, data).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
      } else {
        deleteUserData();
        localStorage.setItem(
          "user",
          `${resp.data.result.firstName} ${resp.data.result.lastName}`
        );
        resul.data = resp.data.result;
        resul.statusResponse = true;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
  }
  return resul;
};

export const getUserByUsername = async (token) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetUser";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
        resul.error = resp.data.message;
      } else {
        resul.data = resp.data.result;
        resul.statusResponse = true;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
  }
  return resul;
};
