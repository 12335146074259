import React from 'react';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Stack } from '@mui/material';

const NoData = () => {
	return (
		<Stack spacing={2}>
			<FontAwesomeIcon
				style={{ color: '#ffc400', fontSize: 100 }}
				icon={faCloudArrowDown}
				className="fa-shake"
			/>
			<Typography
				style={{ color: '#ff3d00', marginBottom: 20 }}
				textAlign="center"
				variant="h4"
				className="fa-fade"
			>
				No hay datos
			</Typography>
		</Stack>
	);
};

export default NoData;
