import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Paper,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCirclePlus,
  faTrashAlt,
  faCommentDollar,
} from "@fortawesome/free-solid-svg-icons";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";

import { isAccess, simpleMessage } from "../../../helpers/Helpers";

import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";

import Swal from "sweetalert2";
import {
  deleteGarantiaAsync,
  getCreditGarantiasAsync,
} from "../../../api/Credits";
import AddGarantia from "./garantiaComponents/AddGarantia";
import EditGarantia from "./garantiaComponents/EditGarantia";
import SaleGarantia from "./garantiaComponents/SaleGarantia";

const CreditGarantias = ({ id }) => {
  const token = getToken();
  const [garantiaList, setGarantiaList] = useState([]);
  const [selectedGarantia, setSelectedGarantia] = useState([]);

  const [localReload, setLocalReload] = useState(false);

  const { setIsLoading, setIsLogged, setIsUnautorized, access } =
    useContext(DataContext);

  const [searchTerm, setSearchTerm] = useState("");

  const [addGarantiaModal, setAddGarantiaModal] = useState(false);
  const [editGarantiaModal, setEditGarantiaModal] = useState(false);
  const [saleModal, setSaleModal] = useState(false);

  const withSearch = garantiaList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.fullName.toString().includes(searchTerm)) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getCreditGarantiasAsync(token, id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setGarantiaList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteGarantia = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteGarantiaAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "eX01") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error.response.data.message, "error");
            return;
          }
          if (result.error === "Unauthorized") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminada!", "Garantia Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Paper
        style={{
          padding: 20,
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography color={"#00a152"} variant="h6" fontWeight={"bold"}>
            Garantias del Crédito
          </Typography>
          {isAccess(access, "CREDITS CREATE") ? (
            <Button
              onClick={() => {
                setAddGarantiaModal(true);
              }}
              style={{
                color: "#00a152",
                borderColor: "#00a152",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faCirclePlus}
              />
              Agregar
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <hr />

        {isEmpty(currentItem) ? (
          <NoData />
        ) : (
          <Stack spacing={2}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="text-primary fw-bold">
                      #
                    </TableCell>
                    <TableCell align="left" className="text-primary fw-bold">
                      Artículo
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      Color
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      V.Estimado
                    </TableCell>
                    {isAccess(access, "CREDITS UPDATE") ||
                    isAccess(access, "CREDITS DELETE") ? (
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Acciones
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItem.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell align="center" component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.articulo}</TableCell>
                        <TableCell align="center">{row.color}</TableCell>
                        <TableCell align="center">
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(row.valorEstimado)}
                        </TableCell>
                        {isAccess(access, "CREDITS UPDATE") ||
                        isAccess(access, "CREDITS DELETE") ? (
                          <TableCell align="center">
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent="center"
                            >
                              {isAccess(access, "CREDITS UPDATE") ? (
                                <>
                                  <Tooltip title="Vender">
                                    <IconButton
                                      style={{
                                        color: "#00a152",
                                      }}
                                      onClick={() => {
                                        setSelectedGarantia(row);
                                        setSaleModal(true);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faCommentDollar} />
                                    </IconButton>
                                  </Tooltip>
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      setSelectedGarantia(row);
                                      setEditGarantiaModal(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                  </IconButton>
                                </>
                              ) : (
                                <></>
                              )}
                              {isAccess(access, "CREDITS DELETE") ? (
                                <IconButton
                                  style={{
                                    color: "#f50057",
                                    width: 40,
                                    height: 40,
                                  }}
                                  onClick={() => deleteGarantia(row)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationComponent
              data={withSearch}
              paginate={paginate}
              itemsperPage={itemsperPage}
            />
          </Stack>
        )}
      </Paper>

      <SmallModal
        titulo={"Agregar Garantia"}
        isVisible={addGarantiaModal}
        setVisible={setAddGarantiaModal}
      >
        <AddGarantia
          setShowModal={setAddGarantiaModal}
          id={id}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={"Editar Garantia"}
        isVisible={editGarantiaModal}
        setVisible={setEditGarantiaModal}
      >
        <EditGarantia
          setShowModal={setEditGarantiaModal}
          selectedGarantia={selectedGarantia}
          setSelectedGarantia={setSelectedGarantia}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={"Vender Garantia"}
        isVisible={saleModal}
        setVisible={setSaleModal}
      >
        <SaleGarantia
          id={id}
          selectedGarantia={selectedGarantia}
          localReload={localReload}
          setLocalReload={setLocalReload}
          setShowModal={setSaleModal}
        />
      </SmallModal>
    </div>
  );
};

export default CreditGarantias;
