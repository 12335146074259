import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	TextField,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DataContext } from '../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../helpers/Helpers';
import { getToken } from '../../../api/Account';

import { getSucursalByUserAsync } from '../../../api/Users';
import moment from 'moment';
import { getLastCierreAsync } from '../../../api/CashFlows';
import { addCierreCajaAsync, getDataArqueoAsync } from '../../../api/ArqueoCaja';
const ArqueoUserAdd = () => {
	const [sucursalList, setSucursalList] = useState([]);
	const [dataArqueo, setDataArqueo] = useState([]);
	const [lastCierre, setLastCierre] = useState([]);
	const [nuevoSaldoDolar, setNuevoSaldoDolar] = useState(0);
	const [nuevoSaldoCordoba, setNuevoSaldoCordoba] = useState(0);

	const [fecha, setFecha] = useState(new Date());
	const [hasta, setHasta] = useState(new Date());
	const [comentario, setComentario] = useState();

	const { setIsLoading, setIsUnautorized, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		setSelectedSucursal(result.data.result[0].id);
		let lastC = await getLastCierre(result.data.result[0].id);
		let dataArk = await getDataArqueo(fecha, hasta, result.data.result[0].id);
		setNewScores(lastC, dataArk);
	};

	const getLastCierre = async (id) => {
		setIsLoading(true);
		const result = await getLastCierreAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setLastCierre(result.data.result);
		return result.data.result;
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getDataArqueo(fecha, hasta, value);
		getLastCierre(value);
	};

	const getDataArqueo = async (fecha, hasta, sucursalId) => {
		setIsLoading(true);
		const data = {
			sucursalId,
			fecha: moment(fecha).format('YYYY-MM-DD'),
			hasta: moment(hasta).format('YYYY-MM-DD')
		};
		const result = await getDataArqueoAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setDataArqueo(result.data.result);
		return result.data.result;
	};

	const saveArqueo = async () => {
		const data = {
			totalEntradaCordoba: dataArqueo.totalEntradaCordoba,
			totalSalidasCordoba: dataArqueo.totalSalidaCordoba,
			totalEntradaDolar: dataArqueo.totalEntradaDolar,
			totalSalidaDolar: dataArqueo.totalSalidaDolar,
			saldoCordoba: nuevoSaldoCordoba,
			saldoDolar: nuevoSaldoDolar,
			sucursalId: selectedSucursal,
			lastCierreId: lastCierre.id,
			observaciones: comentario,
			fecha: moment(hasta).format('YYYY-MM-DD'),
			saldoAnteriorCordoba: lastCierre.saldoCordoba,
			saldoAnteriorDolar: lastCierre.saldoDolar
		};

		setIsLoading(true);
		const result = await addCierreCajaAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		simpleMessage('Arqueo Guardado', 'success');
		setComentario('');
		setIsLoading(false);
	};

	const setNewScores = (lastC, dataArk) => {
		let lastScoreDolar = lastC.saldoDolar;
		let lastScoreCordoba = lastC.saldoCordoba;

		let entradaCordoba = dataArk.totalEntradaCordoba;
		let entradaDolar = dataArk.totalEntradaDolar;

		let salidaCordoba = dataArk.totalSalidaCordoba;
		let salidaDolar = dataArk.totalSalidaDolar;

		setNuevoSaldoCordoba(lastScoreCordoba + entradaCordoba - salidaCordoba);
		setNuevoSaldoDolar(lastScoreDolar + entradaDolar - salidaDolar);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						spacing={1}
						justifyContent="space-between"
					>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<Button
								onClick={() => {
									navigate(`${ruta}/arqueouser`);
								}}
								style={{
									color: '#2979ff',
									borderColor: '#2979ff'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faChevronCircleLeft}
								/>
								Regresar
							</Button>

							<Typography color={'#00a152'} variant="h5" fontWeight={'bold'}>
								Agregar Arqueo de Caja
							</Typography>
						</Stack>

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<DesktopDatePicker
								closeOnSelect
								label="Desde"
								inputFormat="DD/MM/YYYY"
								value={fecha}
								onChange={async (newValue) => {
									if (moment(newValue, 'DD/MM/YYYY').isAfter(hasta)) {
										simpleMessage('Seleccione una fecha valida', 'error');
										return;
									}
									setFecha(newValue);
									let dataArk = await getDataArqueo(
										newValue,
										hasta,
										selectedSucursal
									);
									setNewScores(lastCierre, dataArk);
								}}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>
							<DesktopDatePicker
								closeOnSelect
								label="Hasta"
								inputFormat="DD/MM/YYYY"
								value={hasta}
								onChange={async (newValue) => {
									if (moment(fecha, 'DD/MM/YYYY').isAfter(newValue)) {
										simpleMessage('Seleccione una fecha valida', 'error');
										return;
									}
									setHasta(newValue);
									let dataArk = await getDataArqueo(
										fecha,
										newValue,
										selectedSucursal
									);
									setNewScores(lastCierre, dataArk);
								}}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>

							<FormControl style={{ textAlign: 'left' }} fullWidth>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>
					</Stack>

					<hr />

					<Grid container spacing={2}>
						<Grid item sm={12} md={4}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Stack spacing={2}>
									<Typography color={'#ffc400'} fontWeight={'bold'}>
										{`Ultimo Cierre: ${moment(lastCierre.fechaCreacion).format(
											'L'
										)}`}
									</Typography>
									<hr />
									<Paper
										style={{
											padding: 20
										}}
									>
										<Stack spacing={2}>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Entrada Cordoba:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(
														lastCierre
															? lastCierre.totalEntradaCordoba
															: 0
													)}
												</Typography>
											</Stack>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Salida Cordoba:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(
														lastCierre
															? lastCierre.totalSalidasCordoba
															: 0
													)}
												</Typography>
											</Stack>
											<hr />
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Saldo Cordoba:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(
														lastCierre ? lastCierre.saldoCordoba : 0
													)}
												</Typography>
											</Stack>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 20
										}}
									>
										<Stack spacing={2}>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Entrada Dolar:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(
														lastCierre
															? lastCierre.totalEntradaDolar
															: 0
													)}
												</Typography>
											</Stack>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Salida Dolar:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(
														lastCierre ? lastCierre.totalSalidaDolar : 0
													)}
												</Typography>
											</Stack>
											<hr />
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Saldo Dolar:
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(
														lastCierre ? lastCierre.saldoDolar : 0
													)}
												</Typography>
											</Stack>
										</Stack>
									</Paper>
								</Stack>
							</Paper>
						</Grid>

						<Grid item sm={12} md={8}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Typography color={'#00a152'} fontWeight={'bold'}>
									Nuevo Cierre
								</Typography>

								<hr />
								<Stack spacing={2}>
									<Paper
										style={{
											padding: 20
										}}
									>
										<Stack spacing={2}>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Entrada Cordoba:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(
														dataArqueo
															? dataArqueo.totalEntradaCordoba
															: 0
													)}
												</Typography>
											</Stack>

											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Salida Cordoba:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(
														dataArqueo
															? dataArqueo.totalSalidaCordoba
															: 0
													)}
												</Typography>
											</Stack>
											<hr />
											<Stack direction="row" justifyContent="space-between">
												<Typography color={'#00a152'} fontWeight={'bold'}>
													Nuevo Saldo Cordoba:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(nuevoSaldoCordoba)}
												</Typography>
											</Stack>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 20
										}}
									>
										<Stack spacing={2}>
											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Entrada Dolar:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(
														dataArqueo
															? dataArqueo.totalEntradaDolar
															: 0
													)}
												</Typography>
											</Stack>

											<Stack direction="row" justifyContent="space-between">
												<Typography fontWeight={'bold'}>
													Salida Dolar:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(
														dataArqueo ? dataArqueo.totalSalidaDolar : 0
													)}
												</Typography>
											</Stack>
											<hr />
											<Stack direction="row" justifyContent="space-between">
												<Typography color={'#00a152'} fontWeight={'bold'}>
													Nuevo Saldo Dolar:
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('en-US', {
														style: 'currency',
														currency: 'USD'
													}).format(nuevoSaldoDolar)}
												</Typography>
											</Stack>
										</Stack>
									</Paper>
								</Stack>
							</Paper>
						</Grid>

						<Grid item sm={12}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Stack spacing={2}>
									<TextField
										label="Comentario"
										multiline
										value={comentario}
										fullWidth
										onChange={(e) =>
											setComentario(e.target.value.toUpperCase())
										}
									/>

									<Button
										onClick={() => {
											saveArqueo();
										}}
										style={{
											color: '#2979ff',
											borderColor: '#2979ff',
											padding: 13,
											borderRadius: 30
										}}
										variant="outlined"
										fullWidth
									>
										<FontAwesomeIcon
											style={{ marginRight: 10, fontSize: 20 }}
											icon={faSave}
										/>
										Guardar Arqueo
									</Button>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</div>
	);
};

export default ArqueoUserAdd;
