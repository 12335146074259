import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
import { getToken } from "./Account";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";
const token = getToken();

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}task`;
} else {
  Api = `${REACT_APP_URL}task`;
}

export const getAllNotificationsAsync = async (tkn) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetAllNotificationsAsync";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${tkn}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getNotificationsByCreditAsync = async (id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetNotificationsByCredit/" + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const addNotificationAsync = async (data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/AddTask`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const deleteNotificationAsync = async (id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/DeleteTask/" + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const atendNotificationAsync = async (id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/AtendTask/" + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
