import React, { useState, useContext } from "react";
import {
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";
import SellIcon from "@mui/icons-material/Sell";
import { soldGarantiaAsync } from "../../../../api/Credits";

const SaleGarantia = ({
  id,
  selectedGarantia,
  localReload,
  setLocalReload,
  setShowModal,
}) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const [montoVenta, setMontoVenta] = useState("");

  //Devuelve un numero decimal
  const funcMontoVenta = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      setMontoVenta(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (montoVenta.length === 0) {
      simpleMessage("Ingrese monto de venta...", "error");
      return;
    }
    const data = {
      creditId: id,
      totalRecibido: montoVenta,
      garantiaId: selectedGarantia.id,
    };
    // console.log("🚀  data:", data)
    setIsLoading(true);
    const result = await soldGarantiaAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Garantia Vendida...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <hr />

      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => funcMontoVenta(e.target.value)}
          label={"Monto Venta"}
          value={montoVenta}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => saveChangesAsync()}>
                  <Tooltip title="Vender">
                    <SellIcon style={{ color: "#2979ff" }} />
                  </Tooltip>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};

export default SaleGarantia;
