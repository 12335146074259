import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Grid,
	Divider
} from '@mui/material';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../helpers/Helpers';
import { getToken } from '../../../api/Account';
import { PrintReport } from '../../../components/PrintReport';
import { getArqueoByIdAsync } from '../../../api/ArqueoCaja';
import moment from 'moment';

const ArqueoUserDetails = () => {
	const [arqueo, setArqueo] = useState([]);

	const [detalleArqueoCordoba, setDetalleArqueoCordoba] = useState([]);
	const [detalleArqueoDolar, setDetalleArqueoDolar] = useState([]);

	const { id } = useParams();

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getArqueoById();
	}, []);

	const getArqueoById = async () => {
		setIsLoading(true);
		const result = await getArqueoByIdAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setArqueo(result.data.result);
		objectDetalle(result.data.result.aqueoUserDetails);
	};

	const objectDetalle = (data) => {
		let expCordoba = [];
		let expDolar = [];
		let daCordoba = data.filter((item) => !item.isDolar);
		let daDolar = data.filter((item) => item.isDolar);
		daCordoba.map((item) => {
			let obj = {
				id: item.id,
				description: item.demonimacion.description,

				cantidad: item.cantidad,
				total: item.total
			};
			expCordoba.push(obj);
		});
		setDetalleArqueoCordoba(expCordoba);

		daDolar.map((item) => {
			let obj = {
				id: item.id,
				description: item.demonimacion.description,

				cantidad: item.cantidad,
				total: item.total
			};
			expDolar.push(obj);
		});
		setDetalleArqueoDolar(expDolar);
	};

	const compRef = useRef();

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 8mm; }
`;

	return arqueo.length === 0 ? (
		<></>
	) : (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/arqueouser`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h6" fontWeight={'bold'}>
							{`Detalle Arqueo: ${arqueo.gestor.fullName}`}
						</Typography>
					</Stack>

					<hr />

					<Grid container spacing={2}>
						<Grid item sm={12} md={4}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Typography color={'#2979ff'} fontWeight={'bold'}>
									{` Arqueo #: ${arqueo.id} `}
								</Typography>

								<hr />
								<Stack spacing={2}>
									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Fecha Arqueo:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{moment(arqueo.fechaCreacion).format('L')}
										</Typography>
									</Stack>

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Sucursal:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{arqueo.sucursal.description}
										</Typography>
									</Stack>

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Usuario:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{arqueo.gestor.fullName}
										</Typography>
									</Stack>

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Recibido Por:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{arqueo.recibidoPor.fullName}
										</Typography>
									</Stack>

									<Divider />

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Total Recuperacion C$:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(arqueo.recuperacionCordoba)}
										</Typography>
									</Stack>
									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Total Recuperacion $:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('en-US', {
												style: 'currency',
												currency: 'USD'
											}).format(arqueo.recuperacionDolar)}
										</Typography>
									</Stack>

									<Divider />

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Billetaje C$
										</Typography>
										<Typography
											color={'#ffc400'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(arqueo.efectivoCordoba)}
										</Typography>
									</Stack>
									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Billetaje $
										</Typography>
										<Typography
											color={'#ffc400'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('en-US', {
												style: 'currency',
												currency: 'USD'
											}).format(arqueo.efectivoDolar)}
										</Typography>
									</Stack>

									<Divider />

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Diferencia C$:
										</Typography>
										<Typography
											color={'#f50057'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(arqueo.diferenciaCordoba)}
										</Typography>
									</Stack>

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Diferencia $:
										</Typography>
										<Typography
											color={'#f50057'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{new Intl.NumberFormat('en-Us', {
												style: 'currency',
												currency: 'USD'
											}).format(arqueo.diferenciaDolar)}
										</Typography>
									</Stack>

									<Stack spacing={1} direction="row">
										<Typography
											color={'#2979ff'}
											fontWeight={'bold'}
											fontSize={14}
										>
											Comentario:
										</Typography>
										<Typography
											color={'#00a152'}
											fontWeight={'bold'}
											fontSize={14}
										>
											{arqueo.comentario}
										</Typography>
									</Stack>

									<hr />

									<ReactToPrint
										trigger={() => {
											return (
												<Button
													style={{
														color: '#2979ff',
														borderColor: '#2979ff',
														padding: 13,
														borderRadius: 30
													}}
													variant="outlined"
													fullWidth
												>
													<FontAwesomeIcon
														style={{ marginRight: 10, fontSize: 14 }}
														icon={faPrint}
													/>
													Imprimir Arqueo
												</Button>
											);
										}}
										content={() => compRef.current}
										pageStyle={pageStyle}
									/>
								</Stack>
							</Paper>
						</Grid>

						<Grid item sm={12} md={4}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Typography color={'#00a152'} fontWeight={'bold'}>
									Detalle Billetaje C$
								</Typography>

								<hr />

								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="left"
													className="text-primary fw-bold"
												>
													Denominacion
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													Billetaje
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													C$
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{detalleArqueoCordoba.map((row) => {
												const { cantidad, description, id, total } = row;

												return (
													<TableRow key={id}>
														<TableCell align="left">
															{description}
														</TableCell>
														<TableCell align="center">
															{cantidad}
														</TableCell>
														<TableCell align="center">
															{total}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Grid>

						<Grid item sm={12} md={4}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Typography color={'#00a152'} fontWeight={'bold'}>
									Detalle Billetaje $
								</Typography>

								<hr />

								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="left"
													className="text-primary fw-bold"
												>
													Denominacion
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													Billetaje
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													$
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{detalleArqueoDolar.map((row) => {
												const { cantidad, description, id, total } = row;

												return (
													<TableRow key={id}>
														<TableCell align="left">
															{description}
														</TableCell>
														<TableCell align="center">
															{cantidad}
														</TableCell>
														<TableCell align="center">
															{total}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</Container>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Arqueo de Usuario'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Stack>
						<Typography color={'#2979ff'} fontWeight={'bold'} align="center">
							{` Arqueo #: ${arqueo.id} `}
						</Typography>
						<Typography color={'#2979ff'} fontWeight={'bold'} align="center">
							{` Usuario #: ${arqueo.gestor.fullName} `}
						</Typography>
						<Typography color={'#2979ff'} align="center">
							{` Fecha: ${moment(arqueo.fechaCreacion).format('L')} `}
						</Typography>

						<hr />
						<Stack spacing={2} direction="row" justifyContent="space-between">
							<Stack spacing={1} direction="row">
								<Typography fontWeight={'bold'}>Sucursal:</Typography>
								<Typography>{arqueo.sucursal.description}</Typography>
							</Stack>

							<Stack spacing={1} direction="row">
								<Typography fontWeight={'bold'}>Recuperacion:</Typography>
								<Typography fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(arqueo.recuperacion)}
								</Typography>
							</Stack>

							<Stack spacing={1} direction="row">
								<Typography fontWeight={'bold'}>Billetaje</Typography>
								<Typography fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(arqueo.efectivo)}
								</Typography>
							</Stack>

							<Stack spacing={1} direction="row">
								<Typography fontWeight={'bold'}>Diferencia:</Typography>
								<Typography fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(arqueo.diferencia)}
								</Typography>
							</Stack>
						</Stack>
					</Stack>

					<hr />

					<Stack
						style={{
							padding: 20
						}}
					>
						<Typography color={'#00a152'} fontWeight={'bold'} align="center">
							Detalle Billetaje
						</Typography>

						<hr />

						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell align="left" className="text-primary fw-bold">
											Denominacion
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Billetaje
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											C$
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{arqueo.aqueoUserDetails.map((row) => {
										const { cantidad, demonimacion, id, total } = row;

										return (
											<TableRow key={id}>
												<TableCell align="left" className="text-dark">
													{demonimacion.description}
												</TableCell>
												<TableCell align="center" className="text-dark">
													{cantidad}
												</TableCell>
												<TableCell align="center" className="text-dark">
													{total}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>

						<Stack marginTop={20} direction="row" justifyContent="space-between">
							<Typography
								color={'#2979ff'}
								fontWeight={'bold'}
								align="center"
								style={{ textDecoration: 'overline' }}
							>
								{` Entrega #: ${arqueo.gestor.fullName} `}
							</Typography>
							<Typography
								color={'#2979ff'}
								fontWeight={'bold'}
								align="center"
								style={{ textDecoration: 'overline' }}
							>
								{` Recibe #: ${arqueo.recibidoPor.fullName} `}
							</Typography>
						</Stack>
					</Stack>
				</PrintReport>
			</div>
		</div>
	);
};

export default ArqueoUserDetails;
