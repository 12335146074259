import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import {
  getRuta,
  simpleMessage,
  validateCedula,
} from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";

import { listarSucursalesAsync } from "../../../api/Sucursal";
import { addEmployeeAsync, getPuestosAsync } from "../../../api/Employees";

const AddEmployee = () => {
  const token = getToken();
  let ruta = getRuta();
  let navigate = useNavigate();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondLastName, setSecondLastName] = useState("");
  const [identification, setIdentification] = useState("");

  const [puestoList, setPuestoList] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState("");

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");

  const [inssNumber, setInssNumber] = useState();
  const [salario, setSalario] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    getSucursals();
    getPuestos();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await listarSucursalesAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setSucursalList(result.data.result);
    setIsLoading(false);
  };

  const getPuestos = async () => {
    setIsLoading(true);
    const result = await getPuestosAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setPuestoList(result.data.result);
    setIsLoading(false);
  };

  const addEmployee = async () => {
    if (validate()) {
      const datos = {
        firstName,
        secondName,
        lastName,
        secondLastName,
        address,
        puestoId: selectedPuesto,
        inss: inssNumber,
        identification,
        salario,
        address,
        sucursalId: selectedSucursal,
      };
      setIsLoading(true);
      const result = await addEmployeeAsync(token, datos);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      clearData();
      simpleMessage("Empleado Agregado...!", "success");
    }
  };

  const validate = () => {
    let isValid = true;
    if (firstName.length === 0) {
      simpleMessage("Debe ingresar un nombre", "error");
      return (isValid = false);
    }

    if (lastName.length === 0) {
      simpleMessage("Debe ingresar un apellido", "error");
      return (isValid = false);
    }

    if (identification.length === 0) {
      simpleMessage("Debe ingresar una cédula", "error");
      return (isValid = false);
    }

    if (!validateCedula(identification)) {
      simpleMessage("Debe ingresar una cédula valida", "error");
      return (isValid = false);
    }

    if (selectedPuesto.length === 0) {
      simpleMessage("Debe seleccionar un puesto", "error");
      return (isValid = false);
    }

    if (salario.length === 0) {
      simpleMessage("Debe ingresar un salario", "error");
      return (isValid = false);
    }

    if (address.length === 0) {
      simpleMessage("Debe ingresar una direccion", "error");
      return (isValid = false);
    }

    if (selectedSucursal.length === 0) {
      simpleMessage("Debe seleccionar una sucursal", "error");
      return (isValid = false);
    }

    return isValid;
  };

  const clearData = () => {
    setFirstName("");
    setSecondName("");
    setLastName("");
    setSecondLastName("");
    setSelectedPuesto("");
    setSelectedSucursal("");
    setAddress("");
    setInssNumber("");
    setIdentification("");
    setSalario("");
  };

  //Devuelve un entero positivo
  const funtionSetINSS = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setInssNumber(value);
      return;
    }
  };

  const funtionSetSalario = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setSalario(value);
      return;
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/employees`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Agregar Empleado
            </Typography>
          </Stack>

          <hr />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                  value={firstName}
                  label={"Primer Nombre"}
                />

                <TextField
                  fullWidth
                  onChange={(e) => setSecondName(e.target.value.toUpperCase())}
                  value={secondName}
                  label={"Segundo Nombre"}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setLastName(e.target.value.toUpperCase())}
                  value={lastName}
                  label={"Primer apellido"}
                />
                <TextField
                  fullWidth
                  onChange={(e) =>
                    setSecondLastName(e.target.value.toUpperCase())
                  }
                  value={secondLastName}
                  label={"Segundo Apellido"}
                />

                <TextField
                  required
                  fullWidth
                  onChange={(e) =>
                    setIdentification(e.target.value.toUpperCase())
                  }
                  style={{ marginTop: 20 }}
                  value={identification}
                  label={"Cedula: 000-000000-0000X"}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-standard-label">
                    Seleccione un puesto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedPuesto}
                    onChange={(e) => setSelectedPuesto(e.target.value)}
                    label="Seleccione un puesto"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un puesto</em>
                    </MenuItem>
                    {puestoList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  required
                  fullWidth
                  onChange={(e) => funtionSetINSS(e.target.value)}
                  value={inssNumber}
                  label={"Numero INSS"}
                />

                <TextField
                  fullWidth
                  onChange={(e) => funtionSetSalario(e.target.value)}
                  value={salario}
                  label={"Salario"}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) =>
                    setAddress(e.target.value.toLocaleUpperCase())
                  }
                  value={address}
                  label={"Direccion"}
                />

                <FormControl
                  fullWidth
                  style={{ textAlign: "left", marginTop: 20 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSelectedSucursal(e.target.value)}
                    value={selectedSucursal}
                    label="Sucursal"
                  >
                    {sucursalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
            }
            fullWidth
            onClick={() => addEmployee()}
            style={{
              marginTop: 30,
              borderRadius: 20,
              color: "#00a152",
              borderColor: "#00a152",
            }}
            size="large"
          >
            Agregar Empleado
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default AddEmployee;
