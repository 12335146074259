import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Container,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { retirarCapitalAsync } from "../../../api/Cash";
import { getTipoFlujoAsync } from "../../../api/Contabilidad";
import { getSucursalByUserAsync } from "../../../api/Users";

const RetirarCapital = ({
  setShowModal,
  localReload,
  setLocalReload,
  selectedSucursal,
  selectedTipoFlujo,
}) => {
  const [sucursalList, setSucursalList] = useState([]);
  const [sucursal, setSucursal] = useState(selectedSucursal);
  const [tipoFlujoList, setTiposFlujoList] = useState([]);
  const [tf, setTF] = useState(selectedTipoFlujo);
  const [monto, setMonto] = useState("");
  const [description, setDescription] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      await getSucursals();
      await getTiposFlujo();
    })();
  }, [localReload]);

  const getTiposFlujo = async () => {
    setIsLoading(true);
    const result = await getTipoFlujoAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setTiposFlujoList(result.data.result);
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  const montoMov = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMonto(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (monto.length === 0) {
      simpleMessage("Ingrese un monto válido...", "error");
      return;
    }

    if (description.length === 0) {
      simpleMessage("Ingrese una descripcion válida...", "error");
      return;
    }

    const data = {
      sucursalId: sucursal,
      idCuenta: tf,
      monto,
      description,
    };
    setIsLoading(true);
    const result = await retirarCapitalAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Retiro de capital realizado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 500 }}>
        <hr />

        <Stack spacing={2}>
          <FormControl style={{ textAlign: "left" }} fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Sucursal
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setSucursal(e.target.value)}
              value={selectedSucursal}
              label="Sucursal"
            >
              {sucursalList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ textAlign: "left" }} fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Cuenta
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setTF(e.target.value)}
              value={tf}
              label="Cuenta"
            >
              {tipoFlujoList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            required
            onChange={(e) => montoMov(e.target.value)}
            label={"Monto a debitar"}
            value={monto}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">C$</InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            required
            onChange={(e) => setDescription(e.target.value.toUpperCase())}
            label={"Descripcion del movimiento"}
            value={description}
          />

          <Button
            fullWidth
            variant="outlined"
            style={{
              color: "#f50057",
              borderColor: "#f50057",
            }}
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={() => saveChangesAsync()}
          >
            Retirar Capital
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default RetirarCapital;
