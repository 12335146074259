import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	IconButton,
	Container,
	Paper,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	InputAdornment
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faCirclePlus,
	faExternalLink,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { DataContext } from '../../../context/DataContext';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { getToken } from '../../../api/Account';
import NoData from '../../../components/NoData';
import { PaginationComponent } from '../../../components/PaginationComponent';
import { getSucursalByUserAsync } from '../../../api/Users';
import {
	deleteCierreCajaAsync,
	getArqueoUserBySucursalAsync,
	getCierreCajaAsync
} from '../../../api/ArqueoCaja';
import moment from 'moment';
import Swal from 'sweetalert2';

const ArqueoUser = () => {
	const [arqueoList, setArquoList] = useState([]);
	const [sucursalList, setSucursalList] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	const [localReload, setLocalReload] = useState(false);

	const { setIsLoading, setIsUnautorized, access, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);

	let ruta = getRuta();
	const token = getToken();
	let navigate = useNavigate();

	const withSearch = arqueoList.filter((val) => {
		if (searchTerm === '') {
			return val;
		} else if (val.gestor.includes(searchTerm)) {
			return val;
		}
	});

	const onChangeSearch = (val) => {
		setCurrentPage(1);
		setSearchTerm(val);
		paginate(1);
	};

	useEffect(() => {
		getSucursals();
	}, [localReload]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = withSearch.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		setSelectedSucursal(result.data.result[0].id);
		await getArqueoUser(result.data.result[0].id);
	};

	const getArqueoUser = async (sucursal) => {
		setIsLoading(true);
		const result = await getCierreCajaAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setArquoList(result.data.result);
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getArqueoUser(value);
	};

	const deleteArqueo = async (item) => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'No podras revertir esto...!',
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				(async () => {
					setIsLoading(true);
					const result = await deleteCierreCajaAsync(token, item.id);
					if (!result.statusResponse) {
						setIsLoading(false);
						if (result.error === 'Unauthorized') {
							setIsUnautorized(true);
							return;
						}
						simpleMessage(result.error, 'error');
						return;
					}
					setIsLoading(false);
					setLocalReload(!localReload);
					Swal.fire('Eliminado!', 'Arqueo Eliminado.', 'success');
				})();
			}
		});
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
						<Typography color={'#00a152'} variant="h4" fontWeight={'bold'}>
							Arqueos de Caja
						</Typography>
						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
							<FormControl style={{ textAlign: 'left', minWidth: 100 }} fullWidth>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							{isAccess(access, 'ACAJA CREATE') ? (
								<Button
									onClick={() => {
										navigate(`${ruta}/arqueouser/add/${selectedSucursal}`);
									}}
									startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
									variant="outlined"
									style={{
										color: '#00a152',
										borderColor: '#00a152'
									}}
								>
									Agregar Arqueo
								</Button>
							) : (
								<></>
							)}
						</Stack>
					</Stack>
					<hr />

					<TextField
						style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
						fullWidth
						onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
						value={searchTerm}
						label={'Buscar Arqueo'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<FontAwesomeIcon
											icon={faSearch}
											style={{ color: '#1769aa' }}
										/>
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="a dense table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												#
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Fecha Arqueo
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Realizado por
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Saldo Anterior
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Total Entradas
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Total Gastos
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Nuevo Saldo
											</TableCell>

											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Comentario
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Eliminar
											</TableCell>
											{/* {isAccess(access, 'ACAJA VER') ? (
											) : (
												<></>
											)} */}
										</TableRow>
									</TableHead>

									<TableBody>
										{currentItem.map((row) => {
											console.log('🚀  row:', row);
											const {
												saldoAnteriorCordoba,
												saldoAnteriorDolar,
												saldoCordoba,
												saldoDolar,
												totalEntradaCordoba,
												totalEntradaDolar,
												fechaCreacion,
												fullName,
												totalSalidaDolar,
												totalSalidasCordoba,
												id,
												observaciones
											} = row;

											return (
												<TableRow key={id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{id}
													</TableCell>
													<TableCell align="center">
														{moment(fechaCreacion).format('L')}
													</TableCell>
													<TableCell align="left">{fullName}</TableCell>
													<TableCell align="center">
														<Stack>
															<span>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(saldoAnteriorCordoba)}
															</span>
															<span>
																{new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD'
																}).format(saldoAnteriorDolar)}
															</span>
														</Stack>
													</TableCell>
													<TableCell align="center">
														<Stack>
															<span>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalEntradaCordoba)}
															</span>
															<span>
																{new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD'
																}).format(totalEntradaDolar)}
															</span>
														</Stack>
													</TableCell>
													<TableCell align="center">
														<Stack>
															<span>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalSalidasCordoba)}
															</span>
															<span>
																{new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD'
																}).format(totalSalidaDolar)}
															</span>
														</Stack>
													</TableCell>
													<TableCell align="center">
														<Stack>
															<span>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(saldoCordoba)}
															</span>
															<span>
																{new Intl.NumberFormat('en-US', {
																	style: 'currency',
																	currency: 'USD'
																}).format(saldoDolar)}
															</span>
														</Stack>
													</TableCell>

													{/* <TableCell align="left">{recibidoPor}</TableCell> */}
													<TableCell align="left">
														{observaciones}
													</TableCell>
													{/* <TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(recuperacionCordoba)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(diferenciaCordoba)}
													</TableCell> */}
													<TableCell
														align="center"
														className="text-primary fw-bold"
													>
														<IconButton
															style={{
																color: '#f50057'
															}}
															onClick={() => {
																deleteArqueo(row);
															}}
														>
															<FontAwesomeIcon icon={faTrashAlt} />
														</IconButton>
													</TableCell>

													{/* {isAccess(access, 'ACAJA VER') ? (
														<TableCell
															align="center"
															className="text-primary fw-bold"
														>
															<IconButton
																style={{
																	color: '#ffc400'
																}}
																onClick={() => {
																	navigate(
																		`${ruta}/arqueouser/details/${row.id}`
																	);
																}}
															>
																<FontAwesomeIcon
																	icon={faExternalLink}
																/>
															</IconButton>
														</TableCell>
													) : (
														<></>
													)} */}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<PaginationComponent
								data={withSearch}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>
		</div>
	);
};

export default ArqueoUser;
