import React, { useContext } from "react";
import {
  faAddressBook,
  faAddressCard,
  faBuildingUser,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";
import { getRuta, isAccess } from "../../helpers/Helpers";
import { DataContext } from "../../context/DataContext";

const Nomina = ({ drawerIsOpen }) => {
  const [open, setOpen] = React.useState(false);
  let rout = getRuta();
  const { access } = useContext(DataContext);

  return (
    <div>
      <ListItemButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
        <ListItemIcon>
          {/* <ApartmentRoundedIcon style={{ fontSize: 30 }} /> */}
          <FontAwesomeIcon
            icon={faBuildingUser}
            style={{
              fontSize: 22,
            }}
          />
        </ListItemIcon>
        <ListItemText primary="RRHH" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Stack marginTop={1} paddingLeft={drawerIsOpen ? 4 : 0.5} spacing={1}>
          {isAccess(access, "NOMINA VER") ? (
            <>
              <NavLink
                to={`${rout}/nomina`}
                className={(navData) =>
                  navData.isActive ? "active" : "inactive"
                }
              >
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    style={{
                      fontSize: 22,
                    }}
                  />
                  <Typography>Nomina</Typography>
                </Stack>
              </NavLink>

              <NavLink
                to={`${rout}/employees`}
                className={(navData) =>
                  navData.isActive ? "active" : "inactive"
                }
              >
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    style={{
                      fontSize: 22,
                    }}
                  />
                  <Typography>Empleados</Typography>
                </Stack>
              </NavLink>

              <NavLink
                to={`${rout}/puesto`}
                className={(navData) =>
                  navData.isActive ? "active" : "inactive"
                }
              >
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faIdBadge}
                    style={{
                      fontSize: 22,
                    }}
                  />
                  <Typography>Puestos</Typography>
                </Stack>
              </NavLink>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Collapse>
    </div>
  );
};

export default Nomina;
