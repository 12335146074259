import React, { useState, useContext } from "react";

import {
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { Save } from "@mui/icons-material";
import { updateSucursalAsync } from "../../../api/Sucursal";

const EditSucursal = ({
  selected,
  setShowModal,
  localReload,
  setLocalReload,
}) => {
  const { id, description } = selected;
  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const [newDescription, setNewDescription] = useState(description);

  const saveChangesAsync = async () => {
    if (newDescription.length === 0) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }
    if (newDescription === description) {
      simpleMessage("Ingrese una descripción diferente...", "error");
      return;
    }
    const data = {
      id,
      description: newDescription,
    };

    setIsLoading(true);
    const result = await updateSucursalAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Sucursal Actualizada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <hr />
      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          style={{ marginBottom: 20, marginTop: 20 }}
          onChange={(e) => setNewDescription(e.target.value.toUpperCase())}
          label={"Descripción"}
          value={newDescription}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={"editar"}>
                  <IconButton
                    onClick={() => {
                      saveChangesAsync();
                    }}
                  >
                    <Save style={{ color: "#ff5722" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};

export default EditSucursal;
