import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}tipocambio`;
} else {
  Api = `${REACT_APP_URL}tipocambio`;
}

export const getListTipoCambioAsync = async (token) => {
  const resul = { statusResponse: true, data: [], error: null };
  let url = Api;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (!resp.data.isSuccess) {
        resul.statusResponse = false;
        resul.error = resp.data.message;
        return resul;
      } else {
        resul.data = resp.data.result;
      }
    });
  } catch (error) {
    resul.statusResponse = false;
    resul.error = errorResponse(error.response);
    return resul;
  }
  return resul;
};

export const updateTCAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/SetTipoCambioDia/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
