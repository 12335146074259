import React, { useContext } from 'react';
import { Typography, IconButton, Tooltip, Stack, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';

const ActiveCredits = ({ value, valueNominal }) => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();

	return (
		<div>
			<Stack
				direction={'row'}
				style={{ padding: 10 }}
				justifyContent="space-between"
				alignItems={'center'}
			>
				<Stack direction={'row'}>
					<Tooltip title="Ver Créditos y Empeños Activos">
						<IconButton
							disabled={!isAccess(access, 'RCREDXGEST')}
							sx={{ border: 1, borderColor: 'rgba(54, 162, 235, 1)' }}
							style={{
								width: 100,
								height: 100,
								backgroundColor: 'rgba(54, 162, 235, 0.2)'
							}}
							onClick={() => navigate(`${ruta}/dash-activeCredits`)}
						>
							<FontAwesomeIcon
								icon={faHandHoldingDollar}
								style={{
									fontSize: 60,
									color: 'rgba(54, 162, 235, 1)',
									opacity: 0.3,
									padding: 10
								}}
							/>
						</IconButton>
					</Tooltip>
					<Stack marginLeft={2}>
						<Typography
							align="right"
							style={{
								color: 'rgba(54, 162, 235, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Créditos
						</Typography>
						<Typography
							align="right"
							style={{
								color: 'rgba(54, 162, 235, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Empeños
						</Typography>
					</Stack>
				</Stack>

				<Stack>
					<Tooltip title="Créditos">
						<Typography
							align="right"
							style={{
								color: 'rgba(54, 162, 235, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							{value}
						</Typography>
					</Tooltip>

					<Tooltip title="Empeños">
						<Typography
							align="right"
							style={{
								color: 'rgba(54, 162, 235, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							{valueNominal}
						</Typography>
					</Tooltip>
				</Stack>
			</Stack>
			<Divider />
			<Typography
				variant="subtitle1"
				align="center"
				style={{ color: 'rgba(54, 162, 235, 1)', fontSize: 20 }}
			>
				Créditos y Empeños Activos
			</Typography>
		</div>
	);
};

export default ActiveCredits;
