import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Select,
  InputAdornment,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage, validateCedula } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import { listarSucursalesAsync } from "../../../../api/Sucursal";
import {
  getEmployeeByIdAsync,
  getPuestosAsync,
  updateEmployeeAsync,
} from "../../../../api/Employees";

const EmployeeDetails = ({ id, setEmployeeName }) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondLastName, setSecondLastName] = useState("");
  const [identification, setIdentification] = useState("");

  const [puestoList, setPuestoList] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState("");

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");

  const [inssNumber, setInssNumber] = useState();
  const [salario, setSalario] = useState("");
  const [address, setAddress] = useState("");
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [isActive, setIsActive] = useState(true);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getSucursals();
    getPuestos();
    getEmployee();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await listarSucursalesAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setSucursalList(result.data.result);
    setIsLoading(false);
  };

  const getPuestos = async () => {
    setIsLoading(true);
    const result = await getPuestosAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setPuestoList(result.data.result);
    setIsLoading(false);
  };

  const getEmployee = async () => {
    setIsLoading(true);
    const result = await getEmployeeByIdAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
   
    setEmployeeName(result.data.result.fullName);
    setFirstName(result.data.result.firstName);
    setSecondName(result.data.result.secondName);
    setLastName(result.data.result.lastName);
    setSecondLastName(result.data.result.secondLastName);
    setSalario(result.data.result.salario);
    setSelectedPuesto(result.data.result.puesto.id);
    setIdentification(result.data.result.identification);
    setInssNumber(result.data.result.inssNumber);
    setAddress(result.data.result.address);
    setSelectedSucursal(result.data.result.sucursal.id);
    setFechaInicio(new Date(result.data.result.fechaInicioContrato));
    setIsActive(result.data.result.isActive);

    setIsLoading(false);
  };

  //Devuelve un entero positivo
  const funtionSetINSS = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setInssNumber(value);
      return;
    }
  };

  const funtionSetSalario = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setSalario(value);
      return;
    }
  };

  const saveChanges = async () => {
    if (validate()) {
      const datos = {
        id,
        firstName,
        secondName,
        lastName,
        secondLastName,
        address,
        puestoId: selectedPuesto,
        inss: inssNumber,
        identification,
        salario,
        sucursalId: selectedSucursal,
        fechaInicioContrato: fechaInicio,
      };
      setIsLoading(true);
      const result = await updateEmployeeAsync(token, datos);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      simpleMessage("Empleado Actualizado...!", "success");
    }
  };

  const validate = () => {
    let isValid = true;
    if (firstName.length === 0) {
      simpleMessage("Debe ingresar un nombre", "error");
      return (isValid = false);
    }

    if (lastName.length === 0) {
      simpleMessage("Debe ingresar un apellido", "error");
      return (isValid = false);
    }

    if (identification.length === 0) {
      simpleMessage("Debe ingresar una cédula", "error");
      return (isValid = false);
    }

    if (!validateCedula(identification)) {
      simpleMessage("Debe ingresar una cédula valida", "error");
      return (isValid = false);
    }

    if (selectedPuesto.length === 0) {
      simpleMessage("Debe seleccionar un puesto", "error");
      return (isValid = false);
    }

    if (salario.length === 0) {
      simpleMessage("Debe ingresar un salario", "error");
      return (isValid = false);
    }

    if (address.length === 0) {
      simpleMessage("Debe ingresar una direccion", "error");
      return (isValid = false);
    }

    if (selectedSucursal.length === 0) {
      simpleMessage("Debe seleccionar una sucursal", "error");
      return (isValid = false);
    }

    return isValid;
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Detalle Empleado
            </Typography>

            {isActive ? (
              <Button
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
                style={{
                  color: "#ffc400",
                  borderColor: "#ffc400",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faPenToSquare}
                />
                Editar
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                  value={firstName}
                  label={"Primer Nombre"}
                  disabled={!isEdit}
                />

                <TextField
                  fullWidth
                  onChange={(e) => setSecondName(e.target.value.toUpperCase())}
                  value={secondName}
                  label={"Segundo Nombre"}
                  disabled={!isEdit}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setLastName(e.target.value.toUpperCase())}
                  value={lastName}
                  label={"Primer apellido"}
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  onChange={(e) =>
                    setSecondLastName(e.target.value.toUpperCase())
                  }
                  value={secondLastName}
                  label={"Segundo Apellido"}
                  disabled={!isEdit}
                />

                <TextField
                  required
                  fullWidth
                  onChange={(e) =>
                    setIdentification(e.target.value.toUpperCase())
                  }
                  style={{ marginTop: 20 }}
                  value={identification}
                  label={"Cedula: 000-000000-0000X"}
                  disabled={!isEdit}
                />

                <FormControl fullWidth required disabled={!isEdit}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Seleccione un puesto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedPuesto}
                    onChange={(e) => setSelectedPuesto(e.target.value)}
                    label="Seleccione un puesto"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un puesto</em>
                    </MenuItem>
                    {puestoList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => funtionSetINSS(e.target.value)}
                  value={inssNumber}
                  label={"Numero INSS"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!isEdit}
                />

                <TextField
                  fullWidth
                  onChange={(e) => funtionSetSalario(e.target.value)}
                  value={salario}
                  label={"Salario"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C$</InputAdornment>
                    ),
                  }}
                  disabled={!isEdit}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) =>
                    setAddress(e.target.value.toLocaleUpperCase())
                  }
                  value={address}
                  label={"Direccion"}
                  disabled={!isEdit}
                />

                <FormControl
                  fullWidth
                  style={{ textAlign: "left", marginTop: 20 }}
                  disabled={!isEdit}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSelectedSucursal(e.target.value)}
                    value={selectedSucursal}
                    label="Sucursal"
                  >
                    {sucursalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <DesktopDatePicker
                  closeOnSelect
                  label="Fecha Ingreso"
                  inputFormat="DD/MM/YYYY"
                  value={fechaInicio}
                  onChange={(newValue) => setFechaInicio(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!isEdit}
                />

                <Button
                  variant="outlined"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ marginRight: 20 }}
                    />
                  }
                  fullWidth
                  onClick={() => saveChanges()}
                  style={{
                    // marginTop: 30,
                    borderRadius: 25,
                    color: "#00a152",
                    borderColor: "#00a152",
                    padding: 13,
                  }}
                  size="large"
                  disabled={!isEdit}
                >
                  Guardar Cambios
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default EmployeeDetails;
