import React, { useState, useEffect, useContext } from 'react';
import {
	FormControl,
	FormControlLabel,
	Paper,
	Container,
	Button,
	Stack,
	Autocomplete,
	TextField,
	Radio,
	RadioGroup,
	FormLabel,
	Typography
} from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import { getRuta, simpleMessage } from '../../../../helpers/Helpers';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClientsBySucursalAccessAsync, getCreditsByClientAsync } from '../../../../api/Clients';
import { isEmpty } from 'lodash';

const SelectorAccountStatus = () => {
	const [clientList, setClientList] = useState([]);
	const [selectedClient, setSelectedClient] = useState(null);

	const [creditList, setCreditList] = useState([]);
	const [selectedCredit, setSelectedCredit] = useState(0);

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getClientsBySucursalAccessAsync(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setClientList(result.data.result);
		})();
	}, []);

	const verReport = () => {
		if (selectedCredit === 0) {
			simpleMessage('Seleccione un Crédito', 'error');
			return;
		}

		var params = `${selectedCredit}`;
		window.open(`${ruta}/r-accountstatus/${params}`);
	};

	const defaultProps = {
		options: clientList,
		getOptionLabel: (option) => `${option.fullName} - ${option.identification}`
	};

	const getCreditsByClient = async (client) => {
		if (client === null) {
			setSelectedClient(client);
			setCreditList([]);
			setSelectedCredit(0);
			return;
		}
		setSelectedClient(client);
		setIsLoading(true);
		const result = await getCreditsByClientAsync(token, client.id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setCreditList(result.data.result);
	};

	const handleChange = (event) => {
		setSelectedCredit(0);
		setSelectedCredit(event.target.value);
	};

	return (
		<div>
			<Container style={{ width: 550, padding: 10 }}>
				{/* <Paper
					elevation={10}
					style={{
						padding: 5
					}}
				> */}
				<Stack spacing={2}>
					<Autocomplete
						{...defaultProps}
						id="combo-box-demo"
						value={selectedClient}
						onChange={(event, newValue) => {
							getCreditsByClient(newValue);
						}}
						noOptionsText="No existe cliente con ese nombre"
						renderInput={(params) => (
							<TextField fullWidth {...params} label="Seleccione un Cliente" />
						)}
					/>

					{isEmpty(selectedClient) ? (
						<></>
					) : isEmpty(creditList) ? (
						<Typography color={'#00a152'} textAlign="center" fontWeight={'bold'}>
							Este cliente no tiene créditos activos
						</Typography>
					) : (
						<FormControl>
							<FormLabel id="demo-controlled-radio-buttons-group">
								Lista de Creditos
							</FormLabel>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={selectedCredit}
								onChange={handleChange}
							>
								{creditList.map((item) => {
									return (
										<FormControlLabel
											value={item.id}
											control={<Radio />}
											label={`Crédito#: ${item.numeroCredito}  -  Estado: ${
												item.isCanceled ? 'Cancelado' : 'Pendiente'
											}`}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					)}

					<Button
						variant="outlined"
						startIcon={<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />}
						fullWidth
						onClick={() => verReport()}
						style={{
							color: '#4caf50',
							borderColor: '#4caf50'
						}}
						size="large"
					>
						Generar Reporte
					</Button>
				</Stack>
				{/* </Paper> */}
			</Container>
		</div>
	);
};

export default SelectorAccountStatus;
