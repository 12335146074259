import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Chip,
  Container,
  Button,
  Autocomplete,
  Typography,
  Switch,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import moment from "moment/moment";
import {
  addHolidayAsync,
  addHolidayWithSucursalAsync,
} from "../../../api/Holiday";
import { listarSucursalesAsync } from "../../../api/Sucursal";
import { Stack } from "@mui/system";

const AddHoliday = ({ setShowModal, localReload, setLocalReload }) => {
  const [fecha, setFecha] = useState(new Date());
  const [description, setDescription] = useState("");

  const [allSucursals, setAllSucursals] = useState(true);

  const [sucursalList, setSucursalList] = useState([]);
  const fixedOptions = [sucursalList];
  const [value, setValue] = useState([...fixedOptions, sucursalList]);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    getSucursals();
  }, []);

  const saveChangesAsync = () => {
    if (!moment(fecha).isValid()) {
      simpleMessage("Seleccione una fecha válida...", "error");
      return;
    }
    if (description.length === 0) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }

    allSucursals ? saveToAllSucursals() : saveWithSucursals();
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await listarSucursalesAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setSucursalList(result.data.result);
    setIsLoading(false);
  };

  const saveToAllSucursals = async () => {
    const data = {
      fecha,
      description,
    };

    setIsLoading(true);
    const result = await addHolidayAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Día Festivo Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  const saveWithSucursals = async () => {
    const filtered = value.filter((s) => s.id !== undefined);
    const data = {
      fecha,
      description,
      sucursales: filtered,
    };

    setIsLoading(true);
    const result = await addHolidayWithSucursalAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Día Festivo Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <hr />
        <Stack spacing={2}>
          <DesktopDatePicker
            closeOnSelect
            label="Fecha dia festivo"
            inputFormat="DD/MM/YYYY"
            value={fecha}
            onChange={(newValue) => setFecha(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />

          <TextField
            fullWidth
            required
            onChange={(e) => setDescription(e.target.value.toUpperCase())}
            label={"Descripcion"}
            value={description}
          />

          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              style={{
                color: "#2979ff",
              }}
            >
              Todas las Sucursales
            </Typography>

            <Switch
              style={{
                color: allSucursals ? "#4caf50" : "#f50057",
              }}
              checked={allSucursals}
              onChange={() => setAllSucursals(!allSucursals)}
            />
          </Stack>

          {allSucursals ? (
            <></>
          ) : (
            <Autocomplete
              multiple
              id="fixed-tags-demo"
              value={value.description}
              onChange={(event, newValue) => {
                setValue([
                  ...fixedOptions,
                  ...newValue.filter(
                    (option) => fixedOptions.indexOf(option) === -1
                  ),
                ]);
              }}
              options={sucursalList}
              getOptionLabel={(option) => option.description}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.description}
                    {...getTagProps({ index })}
                    disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sucursales"
                  placeholder="Sucursales con este día feriado"
                />
              )}
            />
          )}

          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
            }
            fullWidth
            onClick={() => saveChangesAsync()}
            style={{
              color: "#00a152",
              borderColor: "#00a152",
            }}
            size="large"
          >
            Agregar Dia Feriado
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default AddHoliday;
