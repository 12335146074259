import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Stack,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  TextField,
} from "@mui/material";

import { difference, isEmpty, isUndefined } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { getAsientosContableAsync } from "../../../api/Contabilidad";
import moment from "moment/moment";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

import { getSucursalByUserAsync } from "../../../api/Users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import MediumModal from "../../../components/modals/MediumModal";
import AddAsientoContable from "./AddAsientoContable";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EditAsientoContable from "./EditAsientoContable";

const LibroDiario = () => {
  const [aContableList, setAContableList] = useState([]);
  var date = new Date();
  // const [date, setDate] = useState(new Date());
  const [fechaDesde, setFechaDesde] = useState(date);
  const [fechaHasta, setFechaHasta] = useState(date);
  const [searchTerm] = useState("");

  const [sumDebito, setSumDebito] = useState(0);
  const [sumCredito, setSumCredito] = useState(0);

  const [sucursalList, setSucursalList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [localReload, setLocalReload] = useState(false);
  const [selectedAsientoC, setSelectedAsientoC] = useState([]);

  const {
    setIsLoading,
    setIsUnautorized,
    selectedSucursal,
    setSelectedSucursal,
    access,
  } = useContext(DataContext);
  const token = getToken();

  const withSearch = aContableList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.referencia.toString().includes(searchTerm)) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      const selected = await getSucursals();

      setIsLoading(true);
      const data = {
        desde: moment(fechaDesde).format("YYYY-MM-DD").toString(),
        hasta: moment(fechaHasta).format("YYYY-MM-DD").toString(),
        sucursalId: selected,
      };

      const result = await getAsientosContableAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setAContableList(result.data.result);
      sumatorias(result.data.result);
      setIsLoading(false);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const sumatorias = (data) => {
    let sDebito = 0;
    let sCredito = 0;
    if (data.length > 0) {
      data.map((asiento) => {
        asiento.asientoContableDetails.map((detalle) => {
          sDebito += detalle.debito;
          sCredito += detalle.credito;
        });
      });
      setSumDebito(sDebito);
      setSumCredito(sCredito);
    }
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
      setSelectedSucursal(result.data.result[0].id);
      return result.data.result[0].id;
    }

    return selectedSucursal;
  };

  const onChangeSucursal = async (value) => {
    setSelectedSucursal(value);
    setIsLoading(true);
    const data = {
      desde: moment(fechaDesde).format("YYYY-MM-DD").toString(),
      hasta: moment(fechaHasta).format("YYYY-MM-DD").toString(),
      sucursalId: value,
    };
    const result = await getAsientosContableAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setAContableList(result.data.result);
    sumatorias(result.data.result);
    setIsLoading(false);
  };

  const onChangeFechaAsiento = async (val) => {
    setFechaDesde(val);
    setFechaHasta(val);
    setLocalReload(!localReload);
  };

  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={"center"}
        spacing={2}
      >
        <Stack alignItems={"left"}>
          <Typography
            textAlign={"left"}
            color={"#00a152"}
            variant="h6"
            fontWeight={"bold"}
          >
            Asientos Contable del Día
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={"center"}
            spacing={2}
          >
            <FormControl style={{ textAlign: "left", width: 200 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => onChangeSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <DesktopDatePicker
              closeOnSelect
              label="Fecha Asiento"
              inputFormat="DD/MM/YYYY"
              value={fechaDesde}
              onChange={(newValue) => onChangeFechaAsiento(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Stack>

        <Stack direction="column" spacing={1} divider={<Divider />}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            spacing={1}
          >
            <Typography color={"#2979ff"} fontWeight={"bold"} fontSize={12}>
              Diferencia
            </Typography>
            <Typography color={"#f50057"} fontWeight={"bold"} fontSize={12}>
              {new Intl.NumberFormat("es-NI", {
                style: "currency",
                currency: "NIO",
              }).format(sumDebito - sumCredito)}
            </Typography>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <Typography textAlign={"left"} fontWeight={"bold"} fontSize={12}>
                ∑ Débito:
              </Typography>
              <Typography
                textAlign={"left"}
                color={"#00a152"}
                fontWeight={"bold"}
                fontSize={12}
              >
                {new Intl.NumberFormat("es-NI", {
                  style: "currency",
                  currency: "NIO",
                }).format(sumDebito)}
              </Typography>
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <Typography textAlign={"left"} fontWeight={"bold"} fontSize={12}>
                ∑ Crédito:
              </Typography>
              <Typography
                textAlign={"left"}
                color={"#00a152"}
                fontWeight={"bold"}
                fontSize={12}
              >
                {new Intl.NumberFormat("es-NI", {
                  style: "currency",
                  currency: "NIO",
                }).format(sumCredito)}
              </Typography>
            </Stack>
          </Stack>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            variant="outlined"
            style={{
              color: "#00a152",
              borderColor: "#00a152",
              // padding: 15,
            }}
          >
            Agregar asiento
          </Button>
        </Stack>
      </Stack>

      <hr />
      {isEmpty(aContableList) ? (
        <NoData />
      ) : (
        <Stack spacing={2}>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Asiento
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Referencia
                  </TableCell>

                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Cuenta
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Descripción
                  </TableCell>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Débito
                  </TableCell>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Crédito
                  </TableCell>
                  <TableCell
                    align="center"
                    className="text-primary fw-bold"
                    style={{ fontSize: 12 }}
                  >
                    Diferencia
                  </TableCell>

                  {isAccess(access, "LIBDIARIO UPDATE") ? (
                    <TableCell align="center" className="text-primary fw-bold">
                      Editar
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItem.map((row) => {
                  const { asientoContableDetails, fecha, id, referencia } = row;
                  let dif = 0;
                  let sumDebe = 0;
                  let sumCred = 0;

                  asientoContableDetails.map((i) => {
                    sumDebe += i.debito;
                    sumCred += i.credito;
                  });

                  dif = sumDebe - sumCred;

                  return (
                    <TableRow key={row.id} style={{ alignItems: "center" }}>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontSize: 11 }}
                      >
                        <Stack>{`N°: ${id}`}</Stack>
                        {moment(fecha).format("L")}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: 11 }}>
                        {referencia}
                      </TableCell>

                      <TableCell align="center">
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableBody>
                              {asientoContableDetails.map((i) => {
                                return (
                                  <TableRow key={i.id}>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                      style={{
                                        fontSize: 11,
                                        borderBottomWidth: 2,
                                        borderBottomColor: "#2979ff",
                                      }}
                                    >
                                      {i.cuenta.countNumber}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>

                      <TableCell align="left">
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableBody>
                              {asientoContableDetails.map((i) => {
                                return (
                                  <TableRow key={i.id}>
                                    <TableCell
                                      align="left"
                                      component="th"
                                      scope="row"
                                      style={{
                                        fontSize: 11,
                                        borderBottomWidth: 2,
                                        borderBottomColor: "#2979ff",
                                      }}
                                    >
                                      {i.cuenta.descripcion}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>

                      <TableCell align="center">
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableBody>
                              {asientoContableDetails.map((i) => {
                                return (
                                  <TableRow key={i.id}>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                      style={{
                                        fontSize: 11,
                                        borderBottomWidth: 2,
                                        borderBottomColor: "#2979ff",
                                      }}
                                    >
                                      {new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(i.debito)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>

                      <TableCell align="center">
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableBody>
                              {asientoContableDetails.map((i) => {
                                return (
                                  <TableRow key={i.id}>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                      style={{
                                        fontSize: 11,
                                        borderBottomWidth: 2,
                                        borderBottomColor: "#2979ff",
                                      }}
                                    >
                                      {new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(i.credito)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ color: dif !== 0 ? "#ffc400" : "inherit" }}
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(dif)}
                      </TableCell>

                      {isAccess(access, "LIBDIARIO UPDATE") ? (
                        <TableCell align="center">
                          <IconButton
                            style={{
                              color: "#ffc400",
                            }}
                            onClick={() => {
                              setShowEditModal(true);
                              setSelectedAsientoC(row);
                            }}
                          >
                            <FontAwesomeIcon icon={faExternalLink} />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationComponent
            data={withSearch}
            paginate={paginate}
            itemsperPage={itemsperPage}
          />
        </Stack>
      )}

      <MediumModal
        titulo={"Agregar Asiento Contable"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddAsientoContable
          setShowModal={setShowModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </MediumModal>

      <MediumModal
        titulo={"Editar Asiento Contable"}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <EditAsientoContable
          setShowModal={setShowEditModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
          selectedAsientoC={selectedAsientoC}
        />
      </MediumModal>
    </div>
  );
};

export default LibroDiario;
