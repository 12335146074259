import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Container,
	Button,
	Paper,
	Typography,
	MenuItem,
	InputLabel,
	Grid,
	FormControl,
	Select,
	InputAdornment,
	Autocomplete,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../api/Account';
import { DataContext } from '../../context/DataContext';
import { getRuta, simpleMessage } from '../../helpers/Helpers';
import { getClientsBySucursalAccessAsync } from '../../api/Clients';
import { useNavigate, useParams } from 'react-router-dom';
import { getPeriodicidadListAsync } from '../../api/Periodicidad';
import { getInteresListAsync } from '../../api/InteresCorriente';
import { getGestoresBySucursalAccessAsync } from '../../api/Users';
import { getPlazoByPeriodicidadAsync } from '../../api/Plazo';
import { addCreditAsync, addCreditNominalAsync } from '../../api/Credits';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment/moment';
const AddCredit = () => {
	const token = getToken();
	const { id } = useParams();
	let ruta = getRuta();
	let navigate = useNavigate();
	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const [clientList, setClientList] = useState([]);
	const [selectedClient, setSelectedClient] = useState(null);

	const [periodicidadList, setPeriodicidadList] = useState([]);
	const [selectedPeriodicidad, setSelectedPeriodicidad] = useState('');

	const [plazoList, setPlazoList] = useState([]);
	const [selectedPlazo, setSelectedPlazo] = useState('');

	const [interesCorrienteList, setInteresCorrienteList] = useState([]);
	const [selectedInteresCorriente, setSelectedInteresCorriente] = useState('');

	const [gestorList, setGestorList] = useState([]);
	const [selectedGestor, setSelectedGestor] = useState('');
	const [montoPrincipal, setMontoPrincipal] = useState('');
	const [destino, setDestino] = useState('');

	const [fechaPrimeraCuota, setFechaPrimeraCuota] = useState(new Date());
	const [isDolar, setIsDolar] = useState(false);
	const [isNominal, setIsNominal] = useState(true);
	const [cobrarSeguro, setCobrarSeguro] = useState(false);
	// const [numCredito, setNumCredito] = useState('');

	const [fechaCreacion, setFechaCreacion] = useState(new Date());

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getClientsBySucursalAccessAsync(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setClientList(result.data.result);

			if (id != '0') {
				let filtered = result.data.result.filter((item) => item.id == id);
				setSelectedClient(filtered[0]);
			}
			await getGestoresBySucursal();
			const resultPer = await getPeriodicidadListAsync(token);
			if (!resultPer.statusResponse) {
				setIsLoading(false);
				if (resultPer.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultPer.error, 'error');
				return;
			}
			setPeriodicidadList(resultPer.data.result);
			const resultIC = await getInteresListAsync(token);
			if (!resultIC.statusResponse) {
				setIsLoading(false);
				if (resultIC.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultIC.error, 'error');
				return;
			}
			setInteresCorrienteList(resultIC.data.result);

			if (isNominal) {
				var newDate = new Date();
				setSelectedInteresCorriente(1027);

				setFechaPrimeraCuota(newDate.setMonth(newDate.getMonth() + 1));
				setSelectedPeriodicidad(1);
				const result = await getPlazoByPeriodicidadAsync(token, 1);
				if (!result.statusResponse) {
					setIsLoading(false);
					if (result.error === 'Unauthorized') {
						setIsUnautorized(true);
						return;
					}
					simpleMessage(result.error, 'error');
					return;
				}
				setPlazoList(result.data.result);

				setSelectedPlazo(519);
			}

			setIsLoading(false);
		})();
	}, []);

	//Devuelve un entero positivo
	const funtionSetMontoSolicitado = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setMontoPrincipal(value);
			return;
		}
	};

	const defaultProps = {
		options: clientList,
		getOptionLabel: (option) => option.fullName
	};

	const handleChangePeriodicidad = async (event) => {
		const selected = periodicidadList.filter((item) => item.id == event.target.value);
		let dias = selected[0].cuotaDias;

		var newDate = new Date();
		newDate.setDate(newDate.getDate() + dias);

		setFechaPrimeraCuota(newDate);
		setPlazoList([]);
		setSelectedPeriodicidad(event.target.value);

		if (event.target.value !== '') {
			setIsLoading(true);
			const result = await getPlazoByPeriodicidadAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setPlazoList(result.data.result);
			setIsLoading(false);
		} else {
			setPlazoList([]);
		}
	};

	const addNewCredit = async () => {
		if (validate()) {
			const data = {
				clientId: selectedClient.id,
				montoPrincipal: parseInt(montoPrincipal),
				fechaPrimeraCuota: moment(fechaPrimeraCuota).format('YYYY-MM-DD').toString(),
				destino: destino,
				periocidadId: selectedPeriodicidad,
				plazoId: selectedPlazo,
				interesCorrienteId: selectedInteresCorriente,
				gestor: selectedGestor,
				isDolar,
				cobrarSeguro,
				// numeroCredito: numCredito,
				fechaCreacion: moment(fechaCreacion).format('YYYY-MM-DD').toString()
			};
			setIsLoading(true);
			const result = isNominal
				? await addCreditNominalAsync(token, data)
				: await addCreditAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			clearData();
			simpleMessage('Crédito Agregado...!', 'success');
		}
	};

	//Validando campos ingresados
	const validate = () => {
		let isValid = true;
		if (isEmpty(selectedClient)) {
			simpleMessage('Debe seleccionar un cliente', 'error');
			return (isValid = false);
		}

		if (montoPrincipal === '') {
			simpleMessage('Debe ingresar un monto', 'error');
			return (isValid = false);
		}

		if (fechaPrimeraCuota === '') {
			simpleMessage('Debe ingresar una fecha', 'error');
			return (isValid = false);
		}

		if (selectedGestor === '') {
			simpleMessage('Debe seleccionar un gestor', 'error');
			return (isValid = false);
		}

		if (selectedPlazo === '') {
			simpleMessage('Debe seleccionar un plazo', 'error');
			return (isValid = false);
		}

		if (selectedInteresCorriente === '') {
			simpleMessage('Debe seleccionar una tasa de interes', 'error');
			return (isValid = false);
		}

		if (isEmpty(destino)) {
			simpleMessage('Debe seleccionar un destino de credito', 'error');
			return (isValid = false);
		}

		return isValid;
	};

	const clearData = () => {
		setSelectedClient(null);
		setMontoPrincipal('');
		setSelectedGestor('');
		setDestino('');
		setFechaCreacion(new Date());
		// setNumCredito('');
		if (!isNominal) {
			setSelectedPeriodicidad('');
			setSelectedPlazo('');
			setSelectedInteresCorriente('');
		}
	};

	const onChangeNominal = async () => {
		let val = !isNominal;
		setIsNominal(val);
		if (val) {
			const selected = periodicidadList.filter((item) => item.id == 1);
			let dias = selected[0].cuotaDias;

			var newDate = new Date();
			newDate.setDate(newDate.getDate() + dias);

			setFechaPrimeraCuota(newDate);

			setSelectedPeriodicidad(1);
			const result = await getPlazoByPeriodicidadAsync(token, 1);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setPlazoList(result.data.result);
		} else {
			clearData();
		}
	};

	const getGestoresBySucursal = async () => {
		const result = await getGestoresBySucursalAccessAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setGestorList(result.data.result);
	};

	const onChangeFechaCreacion = async (val) => {
		setFechaCreacion(val);

		const selected = periodicidadList.filter((item) => item.id == selectedPeriodicidad);

		let dias = selected[0].cuotaDias;

		var newDate = new Date(val);
		newDate.setDate(newDate.getDate() + dias);

		setFechaPrimeraCuota(newDate);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<Button
								onClick={() => {
									navigate(`${ruta}/credits`);
								}}
								style={{
									color: '#2979ff',
									borderColor: '#2979ff'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faChevronCircleLeft}
								/>
								Regresar
							</Button>

							<Typography
								className="d-none d-sm-block"
								color={'#00a152'}
								variant="h4"
								fontWeight={'bold'}
							>
								Agregar Crédito
							</Typography>
						</Stack>

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={2}
						>
							<FormControlLabel
								labelPlacement="start"
								style={{ color: '#2979ff', fontWeight: 'bold' }}
								control={
									<Checkbox
										checked={isNominal}
										onChange={() => onChangeNominal()}
										sx={{
											color: '#2979ff',
											'&.Mui-checked': {
												color: '#2979ff'
											}
										}}
									/>
								}
								label="Empeño"
							/>
						</Stack>
					</Stack>

					<hr />

					<Stack spacing={3}>
						<Paper style={{ textAlign: 'center', padding: 20 }}>
							<Stack
								direction={{ xs: 'column', sm: 'column', md: 'row' }}
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography
									variant="h6"
									color={'#2979ff'}
									style={{ textAlign: 'left' }}
								>
									Datos del Crédito
								</Typography>

								<FormControlLabel
									labelPlacement="start"
									style={{ color: '#00a152', fontWeight: 'bold' }}
									control={
										<Checkbox
											checked={isDolar}
											onChange={() => setIsDolar(!isDolar)}
											sx={{
												color: '#00a152',
												'&.Mui-checked': {
													color: '#00a152'
												}
											}}
										/>
									}
									label="Moneda Dolar"
								/>
							</Stack>

							<hr />

							{selectedClient ? (
								<Paper
									style={{
										padding: 20
									}}
								>
									<Stack
										spacing={1}
										direction={{ xs: 'column', sm: 'column', md: 'row' }}
										justifyContent="space-between"
									>
										<Stack spacing={1} direction="row">
											<Typography
												color={'#ffc400'}
												style={{ textAlign: 'left' }}
											>
												Id Cliente:
											</Typography>

											<Typography style={{ textAlign: 'left' }}>
												{selectedClient.id}
											</Typography>
										</Stack>

										<Stack spacing={1} direction="row">
											<Typography
												color={'#ffc400'}
												style={{ textAlign: 'left' }}
											>
												Cédula:
											</Typography>
											<Typography style={{ textAlign: 'left' }}>
												{selectedClient.identification}
											</Typography>
										</Stack>

										<Stack spacing={1} direction="row">
											<Typography
												color={'#ffc400'}
												style={{ textAlign: 'left' }}
											>
												Teléfono:
											</Typography>
											<Typography style={{ textAlign: 'left' }}>
												{selectedClient.phone}
											</Typography>
										</Stack>

										<Stack spacing={1} direction="row">
											<Typography
												color={'#ffc400'}
												style={{ textAlign: 'left' }}
											>
												Promedio:
											</Typography>
											<Typography style={{ textAlign: 'left' }}>
												{selectedClient.promedio}
											</Typography>
										</Stack>
									</Stack>
								</Paper>
							) : (
								<></>
							)}

							<Grid container spacing={2} marginTop={1}>
								<Grid item xs={12} sm={12} md={6}>
									<Stack spacing={2}>
										<Autocomplete
											{...defaultProps}
											id="combo-box-demo"
											value={selectedClient}
											onChange={(event, newValue) => {
												setSelectedClient(newValue);
											}}
											noOptionsText="No existe cliente con ese nombre"
											renderInput={(params) => (
												<TextField
													fullWidth
													{...params}
													label="Seleccione un Cliente"
												/>
											)}
										/>

										<TextField
											required
											fullWidth
											onChange={(e) =>
												funtionSetMontoSolicitado(e.target.value)
											}
											value={montoPrincipal}
											label={'Monto Solicitado'}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{isDolar ? '$' : 'C$'}
													</InputAdornment>
												)
											}}
										/>

										<FormControl fullWidth style={{ textAlign: 'left' }}>
											<InputLabel id="demo-simple-select-standard-label">
												Gestor
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={(e) => setSelectedGestor(e.target.value)}
												value={selectedGestor}
												label="Gestor"
												style={{ textAlign: 'left' }}
											>
												<MenuItem value="">
													<em>Seleccione un Gestor</em>
												</MenuItem>
												{gestorList.map((item) => {
													return (
														<MenuItem key={item.id} value={item.id}>
															{item.fullName}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<DesktopDatePicker
											closeOnSelect
											label="Fecha Creacion"
											inputFormat="DD/MM/YYYY"
											value={fechaCreacion}
											onChange={(newValue) => onChangeFechaCreacion(newValue)}
											renderInput={(params) => <TextField {...params} />}
										/>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<Stack spacing={2}>
										<FormControl fullWidth style={{ textAlign: 'left' }}>
											<InputLabel id="demo-simple-select-standard-label">
												Periodicidad
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={handleChangePeriodicidad}
												value={selectedPeriodicidad}
												label="Periodicidad"
												style={{ textAlign: 'left' }}
												disabled={isNominal}
											>
												<MenuItem value="">
													<em>Seleccione un Periodo</em>
												</MenuItem>
												{periodicidadList.map((item) => {
													return (
														<MenuItem key={item.id} value={item.id}>
															{item.description}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<FormControl fullWidth style={{ textAlign: 'left' }}>
											<InputLabel id="demo-simple-select-standard-label">
												Plazo
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={(e) => setSelectedPlazo(e.target.value)}
												value={selectedPlazo}
												label="Plazo"
												style={{ textAlign: 'left' }}
												disabled={isNominal}
											>
												<MenuItem value="">
													<em>Seleccione un Plazo</em>
												</MenuItem>
												{plazoList.map((item) => {
													return (
														<MenuItem key={item.id} value={item.id}>
															{item.description}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<FormControl fullWidth style={{ textAlign: 'left' }}>
											<InputLabel id="demo-simple-select-standard-label">
												Tasa de interes
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={(e) =>
													setSelectedInteresCorriente(e.target.value)
												}
												value={selectedInteresCorriente}
												label="Tasa de interes"
												style={{ textAlign: 'left' }}
												disabled={isNominal}
											>
												<MenuItem value="">
													<em>Seleccione una Tasa de Interes</em>
												</MenuItem>
												{interesCorrienteList.map((item) => {
													return (
														<MenuItem key={item.id} value={item.id}>
															{`${item.intCorriente.toFixed(2)} %`}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<DesktopDatePicker
											disabled={isNominal}
											closeOnSelect
											label={
												isNominal ? 'Fecha Abono' : 'Fecha Primera Cuota'
											}
											inputFormat="DD/MM/YYYY"
											value={fechaPrimeraCuota}
											onChange={(newValue) => setFechaPrimeraCuota(newValue)}
											renderInput={(params) => <TextField {...params} />}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										onChange={(e) => setDestino(e.target.value.toUpperCase())}
										value={destino}
										label={'Destino'}
									/>
								</Grid>
							</Grid>

							<Button
								variant="outlined"
								startIcon={
									<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
								}
								fullWidth
								onClick={() => addNewCredit()}
								style={{
									marginTop: 30,
									borderRadius: 20,
									color: '#00a152',
									borderColor: '#00a152'
								}}
								size="large"
							>
								Agregar Credito
							</Button>
						</Paper>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default AddCredit;
