import React, { useState, useContext } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { updateMunicipalityAsync } from "../../../api/Locations";

const AddEditAbMun = ({ selectedMun, setShowModal }) => {
  const { setIsLoading, reload, setReload, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const token = getToken();

  const { abreviatura, id, name } = selectedMun;
  const [newAbrev, setNewAbrev] = useState(abreviatura);

  const saveChangesAsync = async () => {
    if (abreviatura === newAbrev) {
      simpleMessage("Ingrese una abreviatura diferente...", "error");
      return;
    }

    const data = {
      id,
      Abreviatura: newAbrev,
    };

    const result = await updateMunicipalityAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    if (result.data === null) {
      setIsLoading(false);
      simpleMessage("Municipio no encontrado", "error");
      return;
    }
    setReload(!reload);
    simpleMessage("Cambio realizado...!", "success");
    setShowModal(false);
  };

  return (
    <div
      style={{
        width: 400,
        textAlign: "center",
      }}
    >
      <hr />
      <Stack spacing={1} direction="row" justifyContent={"center"}>
        <Typography
          style={{ color: "#2979ff", fontWeight: "bold" }}
          variant="h6"
        >
          Municipio:
        </Typography>
        <Typography variant="h6">{name}</Typography>
      </Stack>

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        onChange={(e) => setNewAbrev(e.target.value.toUpperCase())}
        value={newAbrev}
        label={"Abreviatura"}
        placeholder={"Ingrese Abreviatura"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => saveChangesAsync()}
              >
                <Send style={{ color: "#ff5722" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default AddEditAbMun;
