import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isUndefined } from "lodash";

import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { getNominaAsync } from "../../../api/Employees";

const NominaList = () => {
  const [nominaList, setNominaList] = useState([]);
  const [localReload, setLocalReload] = useState(false);

  const {
    setIsLoading,
    setIsUnautorized,
    access,
    selectedSucursal,
    setSelectedSucursal,
  } = useContext(DataContext);

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [sucursalList, setSucursalList] = useState([]);
  const withSearch = nominaList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.id.toString().includes(searchTerm) ||
      val.description.toString().includes(searchTerm)
    ) {
      return val;
    }
  });

  useEffect(() => {
    getSucursals();
  }, [localReload]);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setSucursalList(result.data.result);

    if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
      setSelectedSucursal(result.data.result[0].id);
      await getNominaList(result.data.result[0].id);
      setIsLoading(false);
      return;
    }
    await getNominaList(selectedSucursal);
    setIsLoading(false);
  };

  const getNominaList = async (sucursalId) => {
    const result = await getNominaAsync(token, sucursalId);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setNominaList(result.data.result);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const onChangeSucursal = async (value) => {
    setSelectedSucursal(value);
    await getNominaList(value);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Nominas
            </Typography>

            <FormControl style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => onChangeSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar nomina"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#2979ff" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Desde
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Hasta
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Estado
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha Pago
                      </TableCell>
                      {isAccess(access, "NOMINA UPDATE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const {
                        description,
                        desde,
                        estado,
                        fechaPago,
                        hasta,
                        id,
                      } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="center">
                            {moment(desde).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {moment(hasta).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {estado.description}
                          </TableCell>
                          <TableCell align="center">
                            {estado.id === 2
                              ? moment(fechaPago).format("L")
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {isAccess(access, "NOMINA UPDATE") ? (
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  navigate(`${ruta}/nominadetails/${id}`);
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLink} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default NominaList;
