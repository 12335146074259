import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";

import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { getRolListAsync } from "../../../api/Rols";
import SmallModal from "../../../components/modals/SmallModal";
import EditServerRolAccess from "./EditServerRolAccess";
import moment from "moment/moment";

const ServerRollist = () => {
  const [rolList, setRolList] = useState([]);
  const [selectedRol, setSelectedRol] = useState([]);

  const [localReload, setLocalReload] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const token = getToken();

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = rolList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.id.toString().includes(searchTerm) ||
      val.rolName.toString().includes(searchTerm)
    ) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const result = await getRolListAsync(token);

      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setRolList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const setHora = (horaString) => {
    if (horaString !== null) {
      let dateString = moment().format("YYYY-MM-DD").toString();
      let datehoraString = `${dateString}T${horaString}`;
      return new Date(datehoraString);
    }
    return new Date();
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Horarios de Acceso
            </Typography>
          </Stack>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar Rol"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Nombre Rol
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Desde
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Hasta
                      </TableCell>

                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Activo Sabado
                      </TableCell>

                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Activo Domingo
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 13 }}
                      >
                        Activo 24/7
                      </TableCell>

                      {isAccess(access, "SECURITY UPDATE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 13 }}
                        >
                          Editar Horario
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id} style={{ fontSize: 12 }}>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ fontSize: 12 }}
                          >
                            {row.id}
                          </TableCell>

                          <TableCell align="left" style={{ fontSize: 12 }}>
                            {row.rolName}
                          </TableCell>

                          <TableCell align="center" style={{ fontSize: 12 }}>
                            {row.activoHasta === null
                              ? ""
                              : moment(setHora(row.activoDesde)).format(
                                  "hh:mm A"
                                )}
                          </TableCell>

                          <TableCell align="center" style={{ fontSize: 12 }}>
                            {row.activoHasta === null
                              ? ""
                              : moment(setHora(row.activoHasta)).format(
                                  "hh:mm A"
                                )}
                          </TableCell>

                          <TableCell align="center" style={{ fontSize: 12 }}>
                            {row.trabajarSabado ? "SI" : "NO"}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: 12 }}>
                            {row.trabajarDomingo ? "SI" : "NO"}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: 12 }}>
                            {row.isAccess247 ? "SI" : "NO"}
                          </TableCell>

                          {isAccess(access, "SECURITY UPDATE") ? (
                            <TableCell align="center">
                              {isAccess(access, "SECURITY UPDATE") ? (
                                <IconButton
                                  style={{
                                    color: "#ffc400",
                                    width: 30,
                                  }}
                                  onClick={() => {
                                    setSelectedRol(row);
                                    setEditModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faUserClock}
                                    style={{ fontSize: 15 }}
                                  />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={`Editar Horario Rol: ${selectedRol.rolName}`}
        isVisible={editModal}
        setVisible={setEditModal}
      >
        <EditServerRolAccess
          selectedRol={selectedRol}
          setShowModal={setEditModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default ServerRollist;
