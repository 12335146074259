import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField, Autocomplete, InputAdornment, Stack, Container } from '@mui/material';
import { DataContext } from '../../../context/DataContext';

import { getToken } from '../../../api/Account';
import { simpleMessage } from '../../../helpers/Helpers';
import { getCatalogoCuentasListAsync } from '../../../api/Contabilidad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const AdddetalleAsientoContable = ({
	setShowModal,
	selectedCuenta,
	setSelectedCuenta,
	detalleAsiento,
	setDetalleAsiento
}) => {
	const [catalogoCuentas, setCatalogoCuentas] = useState([]);
	const [credito, setCredito] = useState('');
	const [debito, setDebito] = useState('');

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	const token = getToken();
	useEffect(() => {
		(async () => {
			await getCatalogoCuentas();
		})();
	}, []);

	const defaultProps = {
		options: catalogoCuentas,
		getOptionLabel: (option) => `${option.countNumber} - ${option.descripcion}`
	};

	const getCatalogoCuentas = async () => {
		setIsLoading(true);
		const result = await getCatalogoCuentasListAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setCatalogoCuentas(result.data.result);
		setIsLoading(false);
		return;
	};

	const funtionSetDebito = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setDebito(value);
			return;
		}
	};

	const funtionSetCredito = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setCredito(value);
			return;
		}
	};

	const editor = () => {
		const data = {
			num: detalleAsiento.num,
			cuenta: selectedCuenta.countNumber,
			descripcion: selectedCuenta.descripcion,
			debito: debito === '' ? 0 : debito,
			credito: credito === '' ? 0 : credito,
			cuentaContableId: selectedCuenta.id
		};
		let detalle = detalleAsiento;
		detalle.push(data);
		setDetalleAsiento(detalle);
		setSelectedCuenta([]);
		setShowModal(false);
	};

	return (
		<div>
			<Container style={{ width: 400 }}>
				<hr />

				<Stack spacing={2}>
					<Autocomplete
						fullWidth
						{...defaultProps}
						id="combo-box-demo"
						value={selectedCuenta}
						onChange={(event, newValue) => {
							setSelectedCuenta(newValue);
						}}
						noOptionsText="No existe cuenta con ese nombre"
						renderInput={(params) => (
							<TextField fullWidth {...params} label="Seleccione una cuenta" />
						)}
					/>

					<TextField
						required
						fullWidth
						onChange={(e) => funtionSetDebito(e.target.value)}
						value={debito}
						label={'Debito'}
						InputProps={{
							startAdornment: <InputAdornment position="start">C$</InputAdornment>
						}}
					/>

					<TextField
						required
						fullWidth
						onChange={(e) => funtionSetCredito(e.target.value)}
						value={credito}
						label={'Credito'}
						InputProps={{
							startAdornment: <InputAdornment position="start">C$</InputAdornment>
						}}
					/>

					<Button
						variant="outlined"
						startIcon={
							<FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: 20 }} />
						}
						fullWidth
						onClick={() => editor()}
						style={{
							borderRadius: 20,
							color: '#00a152',
							borderColor: '#00a152'
						}}
						size="large"
					>
						Agregar Asiento
					</Button>
				</Stack>
			</Container>
		</div>
	);
};

export default AdddetalleAsientoContable;
