import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Paper,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
  Stack,
} from "@mui/material";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { deleteToken, deleteUserData } from "../../../../api/Account";
import { DataContext } from "../../../../context/DataContext";

import { isAccess, simpleMessage } from "../../../../helpers/Helpers";

import NoData from "../../../../components/NoData";

import Swal from "sweetalert2";

import moment from "moment/moment";
import {
  deleteNotificationAsync,
  getNotificationsByCreditAsync,
} from "../../../../api/Notifications";
import SmallModal from "../../../../components/modals/SmallModal";
import AddTask from "./AddTask";

const TasksList = ({ id }) => {
  const [taskList, setTaskList] = useState([]);
  const { setIsLoading, setIsLogged, setIsUnautorized, access } =
    useContext(DataContext);

  const [localReload, setLocalReload] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getNotificationsByCreditAsync(id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setTaskList(result.data.result);
    })();
  }, [localReload]);

  const [addTaskModal, setAddTaskModal] = useState(false);

  const deleteTask = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteNotificationAsync(item);

          if (!result.statusResponse) {
            setIsLoading(false);
            simpleMessage(result.error, "error");
            return;
          }
          if (result.error === "Unauthorized") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminada!", "Tarea Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Paper
        style={{
          padding: 10,
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography
            textAlign={"left"}
            color={"#00a152"}
            variant="h6"
            fontWeight={"bold"}
          >
            Tareas
          </Typography>

          {isAccess(access, "CREDITS CREATE") ? (
            <Button
              onClick={() => {
                setAddTaskModal(true);
              }}
              style={{
                color: "#00a152",
                borderColor: "#00a152",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faCirclePlus}
              />
              Agregar
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <hr />

        {isEmpty(taskList) ? (
          <NoData />
        ) : (
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="text-primary fw-bold">
                    #
                  </TableCell>
                  <TableCell align="center" className="text-primary fw-bold">
                    Fecha Creacion
                  </TableCell>
                  <TableCell align="left" className="text-primary fw-bold">
                    Descripción
                  </TableCell>
                  <TableCell align="center" className="text-primary fw-bold">
                    Fecha Lanzamiento
                  </TableCell>
                  <TableCell align="center" className="text-primary fw-bold">
                    Completada
                  </TableCell>
                  {isAccess(access, "CREDITS DELETE") ? (
                    <TableCell align="center" className="text-primary fw-bold">
                      Eliminar
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {taskList.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center" component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {moment(row.fechaCreacion).format("L")}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.description}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {moment(row.fechaLaunch).format("L")}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {row.isLaunched ? "SI" : "NO"}
                      </TableCell>
                      {isAccess(access, "CREDITS DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          <IconButton
                            style={{
                              color: "#f50057",
                              width: 40,
                              height: 40,
                            }}
                            onClick={() => deleteTask(row.id)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <SmallModal
        titulo={"Agregar Tarea"}
        isVisible={addTaskModal}
        setVisible={setAddTaskModal}
      >
        <AddTask
          setShowModal={setAddTaskModal}
          id={id}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default TasksList;
