import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../context/DataContext";
import { getRuta, simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import {
  addArqueoSucursalAsync,
  getDenominationsAsync,
  getRecuperacionBySucursalAsync,
} from "../../../../api/ArqueoCaja";

import {
  getSucursalByUserAsync,
  getusersBySucursalAsync,
} from "../../../../api/Users";
const AddArqueoSucursal = () => {
  const [sucursalList, setSucursalList] = useState([]);

  const [recuperacionSucursal, setRecuperacionSucursal] = useState(0);

  const [denominationList, setDenominationList] = useState([]);
  const [detalleArqueo, setDetalleArqueo] = useState([]);
  const [totalBilletaje, setTotalBilletaje] = useState(0);
  const [diferencia, setDiferencia] = useState(0);

  const [reloadArqueo, setReloadArqueo] = useState(false);
  const {
    setIsLoading,
    setIsUnautorized,
    selectedSucursal,
    setSelectedSucursal,
  } = useContext(DataContext);

  const token = getToken();
  let ruta = getRuta();
  let navigate = useNavigate();

  useEffect(() => {
    getSucursals();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    setSelectedSucursal(result.data.result[0].id);

    await getDenominaciones(result.data.result[0].id);
  };

  useEffect(() => {}, [reloadArqueo]);

  const getDenominaciones = async () => {
    setIsLoading(true);
    const result = await getDenominationsAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setDenominationList(result.data.result);
    objectDetalle(result.data.result);
  };

  const objectDetalle = (data) => {
    let exp = [];
    data.map((item) => {
      let obj = {
        id: item.id,
        description: item.description,
        valor: item.valor,
        cantidad: "",
        total: 0,
      };
      exp.push(obj);
    });
    setDetalleArqueo(exp);
  };

  const onEditDetalle = (id, valor, cantidad) => {
    let total = 0;
    let data = detalleArqueo;
    data.map((item) => {
      if (item.id === id) {
        item.cantidad = cantidad;
        item.total = valor * cantidad;
      }
      total += item.total;
    });
    setTotalBilletaje(total);
    setDiferencia(recuperacionSucursal - total);
    setReloadArqueo(!reloadArqueo);
  };

  const onChangeSucursal = async (value) => {
    setSelectedSucursal(value);
    getDenominaciones(value);
    setIsLoading(true);
    const result = await getRecuperacionBySucursalAsync(token, value);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setRecuperacionSucursal(result.data.result);
  };

  const saveArqueo = async () => {
    let modelDetalleArqueo = [];
    detalleArqueo.map((item) => {
      let model = {
        demonimacionId: item.id,
        cantidad: item.cantidad === "" ? 0 : item.cantidad,
        total: item.total,
      };
      modelDetalleArqueo.push(model);
    });

    const data = {
      aqueoUserDetails: modelDetalleArqueo,
      efectivo: totalBilletaje,
      recuperacion: recuperacionSucursal,
      diferencia,
      sucursalId: selectedSucursal,
    };

    setIsLoading(true);
    const result = await addArqueoSucursalAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setTotalBilletaje(0);
    setDiferencia(0);
    objectDetalle(denominationList);
    setIsLoading(false);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/cashregister`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              {`Arqueo Sucursal`}
            </Typography>
          </Stack>

          <hr />
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography color={"#00a152"} fontWeight={"bold"}>
                  Usuario
                </Typography>

                <hr />
                <Stack spacing={2}>
                  <FormControl style={{ textAlign: "left" }} fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">
                      Sucursal
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={(e) => onChangeSucursal(e.target.value)}
                      value={selectedSucursal}
                      label="Sucursal"
                    >
                      {sucursalList.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <Typography
                    color={"#00a152"}
                    variant="h6"
                    fontWeight={"bold"}
                  >
                    {`Total Recuperado:  ${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(recuperacionSucursal)} `}
                  </Typography>

                  <Typography
                    color={"#ffc400"}
                    variant="h6"
                    fontWeight={"bold"}
                  >
                    {`Billetaje:  ${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalBilletaje)} `}
                  </Typography>

                  <Typography
                    color={"#f50057"}
                    variant="h6"
                    fontWeight={"bold"}
                  >
                    {`Diferencia:  ${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(diferencia)} `}
                  </Typography>

                  <hr />

                  <Button
                    onClick={() => {
                      saveArqueo();
                    }}
                    style={{
                      color: "#2979ff",
                      borderColor: "#2979ff",
                      padding: 13,
                      borderRadius: 30,
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10, fontSize: 20 }}
                      icon={faSave}
                    />
                    Guardar Arqueo
                  </Button>
                </Stack>
              </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography color={"#00a152"} fontWeight={"bold"}>
                  Billetaje
                </Typography>

                <hr />

                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className="text-primary fw-bold"
                          width={150}
                        >
                          Denominacion
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          width={150}
                        >
                          Billetaje
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          C$
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detalleArqueo.map((row) => {
                        const { id, description, valor, cantidad, total } = row;
                        return (
                          <TableRow key={id}>
                            <TableCell align="left" width={150}>
                              {description}
                            </TableCell>
                            <TableCell align="center" width={150}>
                              <TextField
                                size="small"
                                value={cantidad}
                                fullWidth
                                onChange={(e) =>
                                  onEditDetalle(id, valor, e.target.value)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{total}</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AddArqueoSucursal;