import React, { useState, useContext, useEffect, useRef } from 'react';
import {
	Paper,
	Typography,
	Divider,
	Stack,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Button
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPrint, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { isAccess, simpleMessage } from '../../../helpers/Helpers';

import moment from 'moment';
import { DataContext } from '../../../context/DataContext';
import { isEmpty } from 'lodash';
import NoData from '../../../components/NoData';
import { getCreditPaymentAsync } from '../../../api/Credits';
import { getToken } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintPayment from './PrintPayment';
import SmallModal from '../../../components/modals/SmallModal';
import UpdateFechaPago from './paymentComponents/UpdateFechaPago';
import PrintPagare from './paymentComponents/PrintPagare';

const CreditPayment = ({ id, clientName, isNominal, dataCredit, isDolar }) => {
	const token = getToken();
	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	const [paymentList, setPaymentList] = useState([]);
	const [credit, setCredit] = useState([]);
	const [quoteList, setQuoteList] = useState([]);

	const [montoPagar, setMontoPagar] = useState();

	const [showModal, setShowModal] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState([]);
	const [localReload, setLocalReload] = useState(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getCreditPaymentAsync(token, id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setPaymentList(result.data.result.payments);
			setCredit(result.data.result);
			setQuoteList(result.data.result.quotes);

			setMontoPagar(result.data.result.montoPagar);

			setIsLoading(false);
		})();
	}, [localReload]);

	const totalAbonado = () => {
		let result = 0;
		if (quoteList.length > 0) {
			quoteList.map((item) => (result += item.totalRecibido));
		}
		return result;
	};

	const compRefPagare = useRef();
	const compRef = useRef();

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 8mm; }
`;

	const pageStylePagare = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  // @page { size: landscape;  margin: 8mm; }
`;

	const editpayment = async (payment) => {
		setSelectedPayment(payment);
		setShowModal(true);
	};

	return (
		<div>
			<Stack direction="column" spacing={2}>
				<Paper
					style={{
						padding: 10
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						justifyContent={'space-between'}
					>
						<Typography
							textAlign={'left'}
							color={'#00a152'}
							variant="h6"
							fontWeight={'bold'}
						>
							Plan de Pagos
						</Typography>

						<Stack direction="row" spacing={2}>
							{isAccess(access, 'PRINT PAGARE') ? (
								<ReactToPrint
									trigger={() => {
										return (
											<Button
												style={{
													color: '#00a152',
													borderColor: '#00a152'
												}}
												variant="outlined"
											>
												<FontAwesomeIcon
													style={{ marginRight: 10, fontSize: 20 }}
													icon={faPrint}
												/>
												Contrato
											</Button>
										);
									}}
									content={() => compRefPagare.current}
									pageStyle={pageStylePagare}
								/>
							) : (
								<></>
							)}

							<ReactToPrint
								trigger={() => {
									return (
										<Button
											style={{
												color: '#2979ff',
												borderColor: '#2979ff'
											}}
											variant="outlined"
										>
											<FontAwesomeIcon
												style={{ marginRight: 10, fontSize: 20 }}
												icon={faPrint}
											/>
											Plan de Pagos
										</Button>
									);
								}}
								content={() => compRef.current}
								pageStyle={pageStyle}
							/>
						</Stack>
					</Stack>

					<hr />
					{isEmpty(paymentList) ? (
						<NoData />
					) : (
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											#.Cuota
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											Días
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											F.Pago
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											S.Anterior
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											C.Principal
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											C.Interes C.
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											C.Pagar.
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											Nuevo S.
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 13 }}
										>
											Cancelado
										</TableCell>
										{/* {isAccess(access, "PAYMENT UPDATE") && !isNominal ? (
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Acciones
                      </TableCell>
                    ) : (
                      <></>
                    )} */}
									</TableRow>
								</TableHead>
								<TableBody>
									{paymentList.map((row) => {
										return (
											<TableRow key={row.id}>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{row.numeroCuota}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{row.periodicidad}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{moment(row.fechaPago).format('L')}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.saldoAnterior)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.montoPrincipal)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.montoInteresCorriente)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.cuotaPrincipal)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.nuevoSaldo)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 12 }}
												>
													<FontAwesomeIcon
														style={{
															color: row.isCanceled
																? '#00a152'
																: '#ff9800',
															fontSize: 20
														}}
														icon={
															row.isCanceled
																? faCircleCheck
																: faTimesCircle
														}
													/>
												</TableCell>

												{/* {isAccess(access, "PAYMENT UPDATE") && !isNominal ? (
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 12 }}
                          >
                            <IconButton
                              disabled={row.isCanceled}
                              style={{
                                color: "#ff9100",
                                width: 40,
                                height: 40,
                              }}
                              onClick={() => editpayment(row)}
                            >
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <></>
                        )} */}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Paper>

				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						justifyContent={'space-between'}
						divider={<Divider orientation="vertical" flexItem />}
					>
						<Stack direction="column" spacing={1}>
							<Typography>Monto a Pagar</Typography>
							<Typography variant="h6" style={{ color: '#2979ff' }}>
								{`${new Intl.NumberFormat(isDolar ? 'en-US' : 'es-NI', {
									style: 'currency',
									currency: isDolar ? 'USD' : 'NIO'
								}).format(montoPagar)}`}
							</Typography>
						</Stack>

						{isNominal ? (
							<></>
						) : (
							<Stack direction="column" spacing={1}>
								<Typography>Total Abonado</Typography>
								<Typography variant="h6" style={{ color: '#00a152' }}>
									{`${new Intl.NumberFormat(isDolar ? 'en-US' : 'es-NI', {
										style: 'currency',
										currency: isDolar ? 'USD' : 'NIO'
									}).format(totalAbonado())}`}
								</Typography>
							</Stack>
						)}

						{isNominal ? (
							<Stack direction="column" spacing={1}>
								<Typography>Saldo Pendiente</Typography>
								<Typography variant="h6" style={{ color: '#f50057' }}>
									{`${new Intl.NumberFormat(isDolar ? 'en-US' : 'es-NI', {
										style: 'currency',
										currency: isDolar ? 'USD' : 'NIO'
									}).format(montoPagar)}`}
								</Typography>
							</Stack>
						) : (
							<Stack direction="column" spacing={1}>
								<Typography>Saldo Pendiente</Typography>
								<Typography variant="h6" style={{ color: '#f50057' }}>
									{`${new Intl.NumberFormat(isDolar ? 'en-US' : 'es-NI', {
										style: 'currency',
										currency: isDolar ? 'USD' : 'NIO'
									}).format(montoPagar - totalAbonado())}`}
								</Typography>
							</Stack>
						)}
					</Stack>
				</Paper>
			</Stack>

			<div style={{ display: 'none' }}>
				<PrintPagare
					clientName={clientName}
					dataCredit={dataCredit}
					paymentList={paymentList}
					ref={compRefPagare}
				/>
			</div>

			<div style={{ display: 'none' }}>
				<PrintPayment
					clientName={clientName}
					credit={credit}
					paymentList={paymentList}
					ref={compRef}
				/>
			</div>

			<SmallModal
				titulo={`Editar Fecha de Pago`}
				isVisible={showModal}
				setVisible={setShowModal}
			>
				<UpdateFechaPago
					data={selectedPayment}
					setShowModal={setShowModal}
					localReload={localReload}
					setLocalReload={setLocalReload}
				/>
			</SmallModal>
		</div>
	);
};

export default CreditPayment;
