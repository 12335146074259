import React, { useContext, useState } from "react";
import {
  Paper,
  Box,
  Tabs,
  Tab,
  Divider,
  Typography,
  Container,
  Stack,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faChevronCircleLeft,
  faClipboardUser,
  faCreditCard,
  faHandHoldingDollar,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { DataContext } from "../../../../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { getRuta } from "../../../../helpers/Helpers";
import EmployeeDetails from "./EmployeeDetails";
import Vacaciones from "./Vacaciones";
import Adelantos from "./Adelantos";
import Prestamos from "./Prestamos";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const EmployeDetailsContainer = () => {
  const { access } = useContext(DataContext);
  let ruta = getRuta();
  let navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [employeeName, setEmployeeName] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/employees`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Stack direction={"row"} spacing={1}>
              <Typography
                color={"#00a152"}
                variant="h6"
                fontWeight={"bold"}
                className="d-none d-sm-block"
              >
                Cliente:
              </Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                {employeeName}
              </Typography>
            </Stack>
          </Stack>

          <Divider style={{ marginTop: 10 }} />

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
            centered
          >
            <Tab
              icon={
                <FontAwesomeIcon
                  icon={faClipboardUser}
                  style={{ fontSize: 20 }}
                />
              }
              label="Empleado"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />

            <Tab
              icon={
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{ fontSize: 20 }}
                />
              }
              label="Vacaciones"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />

            <Tab
              icon={
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{ fontSize: 20 }}
                />
              }
              label="Adelanto"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />

            <Tab
              icon={
                <FontAwesomeIcon
                  icon={faHandHoldingDollar}
                  style={{ fontSize: 20 }}
                />
              }
              label="Prestamo"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />
          </Tabs>

          <Divider style={{ marginTop: 10 }} />

          <TabPanel value={value} index={0}>
            <EmployeeDetails id={id} setEmployeeName={setEmployeeName} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Vacaciones id={id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Adelantos id={id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Prestamos id={id} />
          </TabPanel>
        </Paper>
      </Container>
    </div>
  );
};
