import React, { useContext, useState, useEffect } from "react";

import {
    Paper,
    Typography,
    Container,
    Stack,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
} from "@mui/material";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronCircleLeft, faPrint
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { getSucursalByUserAsync } from "../../../api/Users";
import { getClientsReportAsync } from "../../../api/Report";
import NoData from "../../../components/NoData";
import SelectorClientsGestor from "../../reports/selectores/selectoresCartera/SelectorClientsGestor";
import SmallModal from "../../../components/modals/SmallModal";

const DashClients = () => {
    const [data, setData] = useState([]);
    const [sucursalList, setSucursalList] = useState([]);
    const [selectedSucursal, setSelectedSucursal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { setIsLoading, setIsUnautorized, access } =
        useContext(DataContext);
    const token = getToken();
    let ruta = getRuta();
    let navigate = useNavigate();

    useEffect(() => {
        getSucursals();
    }, []);


    const getSucursals = async () => {
        setIsLoading(true);
        const result = await getSucursalByUserAsync(token);
        if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
                setIsUnautorized(true);
                return;
            }
            simpleMessage(result.error, "error");
            return;
        }
        setIsLoading(false);
        setSucursalList(result.data.result);
        if (selectedSucursal === 0) {
            setSelectedSucursal(result.data.result[0].id);
        }
        getData(result.data.result[0].id)
    };

    const getData = async (sucursal) => {
        setIsLoading(true);
        const data = {
            sucursalId: sucursal,
            selectedSortBy: 0,
            selectedStatus: 1,
        };
        setIsLoading(true);
        const result = await getClientsReportAsync(token, data);
        if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
                setIsUnautorized(true);
                return;
            }
            simpleMessage(result.error, "error");
            return;
        }
        setIsLoading(false);
        setData(result.data.result);
    }

    const oncChangeSucursal = async (value) => {
        setSelectedSucursal(value)
        getData(value)
    }

    return (
        <div>
            <Container maxWidth="xl">
                <Paper
                    style={{
                        padding: 20,
                        textAlign: "center",
                    }}
                >
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={"space-between"}
                        spacing={1}
                    >
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            alignItems="center"
                            spacing={1}
                        >
                            <Button
                                onClick={() => {
                                    navigate(`${ruta}/`);
                                }}
                                style={{
                                    color: "#2979ff",
                                    borderColor: "#2979ff",
                                }}
                                variant="outlined"
                            >
                                <FontAwesomeIcon
                                    style={{ marginRight: 10, fontSize: 20 }}
                                    icon={faChevronCircleLeft}
                                />
                                Regresar
                            </Button>


                            <Typography
                                color={"#00a152"}
                                variant="h6"
                                fontWeight={"bold"}
                                className="d-none d-sm-block"
                            >
                                Clientes Activos
                            </Typography>
                        </Stack>

                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            alignItems="center"
                            spacing={2}
                        >

                            <FormControl fullWidth style={{ textAlign: "left", width: 200 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                    Sucursal
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={(e) => oncChangeSucursal(e.target.value)}
                                    value={selectedSucursal}
                                    label="Sucursal"
                                >
                                    {sucursalList.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.description}
                                            </MenuItem>
                                        );
                                    })}
                                    <MenuItem key={0} value={0}>
                                        TODAS...
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            {isAccess(access, "RCLIENTS") ? (
                                <Button
                                    onClick={() => {
                                        setShowModal(true)
                                    }}
                                    style={{
                                        color: "#2979ff",
                                        borderColor: "#2979ff",
                                        padding: 10
                                    }}
                                    variant="outlined"
                                >
                                    <FontAwesomeIcon
                                        style={{ marginRight: 10, fontSize: 20 }}
                                        icon={faPrint}
                                    />
                                    Reporte
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Stack>

                    <hr />

                    {isEmpty(data) ? (
                        <NoData />
                    ) : (
                        <TableContainer>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Nombre Completo
                                        </TableCell>

                                        <TableCell align="center" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Teléfono
                                        </TableCell>
                                        <TableCell align="left" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Dirección
                                        </TableCell>
                                        <TableCell align="left" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Actividad Económica
                                        </TableCell>
                                        <TableCell align="center" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Ciclos
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className="text-primary fw-bold" style={{ fontSize: 11 }}
                                        >
                                            Sucursal
                                        </TableCell>
                                        <TableCell align="center" className="text-primary fw-bold" style={{ fontSize: 11 }}>
                                            Promedio
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell align="left" component="th" scope="row" style={{ fontSize: 10 }}>
                                                    {row.fullName}
                                                </TableCell>
                                                <TableCell align="center" scope="row" style={{ fontSize: 10 }}>
                                                    {row.phone}
                                                </TableCell>
                                                <TableCell align="left" scope="row" style={{ fontSize: 10 }}>
                                                    {row.address}
                                                </TableCell>
                                                <TableCell align="left" scope="row" style={{ fontSize: 10 }}>
                                                    {row.actividadEconomica}
                                                </TableCell>

                                                <TableCell align="center" scope="row" style={{ fontSize: 10 }}>
                                                    {row.creditos}
                                                </TableCell>

                                                <TableCell align="center" scope="row" style={{ fontSize: 10 }}>
                                                    {row.sucursal}
                                                </TableCell>

                                                <TableCell align="center" scope="row" style={{ fontSize: 10 }}>
                                                    {row.promedio}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    <hr />

                    <Stack direction="row" flex="row" justifyContent="space-around">
                        <Stack textAlign="center">
                            <span style={{ fontWeight: "bold", color: "#03a9f4" }}>
                                Total Clientes
                            </span>
                            <span>{data.length}</span>
                        </Stack>
                    </Stack>

                    <hr />
                </Paper>
            </Container>

            <SmallModal titulo={"Reporte de Clientes"} isVisible={showModal} setVisible={setShowModal}>
                <SelectorClientsGestor />
            </SmallModal>
        </div>


    )
}

export default DashClients