import React, { useContext } from 'react';
import { Typography, IconButton, Tooltip, Stack, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CancecionSemanal = ({ valueC, valueE }) => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();

	return (
		<div>
			<Stack
				direction={'row'}
				style={{ padding: 10 }}
				justifyContent="space-between"
				alignItems={'center'}
			>
				<Stack direction={'row'}>
					<Tooltip title="Ver Créditos Cancelados">
						<IconButton
							disabled={!isAccess(access, 'RCREDCANC')}
							sx={{ border: 1, borderColor: 'rgba(144, 164, 174, 1)' }}
							style={{
								width: 100,
								height: 100,
								backgroundColor: 'rgba(144, 164, 174, 0.2)'
							}}
							onClick={() => navigate(`${ruta}/dash-cancelados`)}
						>
							<CalendarMonthIcon
								style={{
									fontSize: 110,
									color: 'rgba(144, 164, 174, 1)',
									opacity: 0.3,
									padding: 10
								}}
							/>
						</IconButton>
					</Tooltip>
					<Stack marginLeft={2}>
						<Typography
							align="right"
							style={{
								color: 'rgba(144, 164, 174, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Créditos
						</Typography>
						<Typography
							align="right"
							style={{
								color: 'rgba(144, 164, 174, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Empeños
						</Typography>
					</Stack>
				</Stack>
				<Stack>
					<Typography
						align="right"
						style={{
							color: 'rgba(144, 164, 174, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{valueC}
					</Typography>
					<Typography
						align="right"
						style={{
							color: 'rgba(144, 164, 174, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{valueE}
					</Typography>
				</Stack>
			</Stack>
			<Divider />
			<Typography
				variant="subtitle1"
				align="center"
				style={{ color: 'rgba(144, 164, 174, 1)', fontSize: 20 }}
			>
				Cancelacion Semanal
			</Typography>
		</div>
	);
};

export default CancecionSemanal;
