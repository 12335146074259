import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import {
  denegarCreditAsync,
  getCreditDeniedByIdAsync,
} from "../../../api/Credits";
import moment from "moment";

const DeniedDetails = ({ id }) => {
  const [data, setData] = useState([]);
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const token = getToken();

  useEffect(() => {
    getdataDenied();
  }, []);

  const getdataDenied = async () => {
    setIsLoading(true);
    const result = await getCreditDeniedByIdAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setData(result.data.result);
    setIsLoading(false);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={1} direction="row" justifyContent={"center"}>
              <Typography
                fontWeight={"bold"}
                style={{
                  color: "#2979ff",
                }}
              >
                CREDITO DENEGADO # :
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {id}
              </Typography>
            </Stack>

            <TextField
              required
              disabled
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={data.notaDenegado}
              label={"Nota"}
            />
            <TextField
              required
              disabled
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={moment(data.fechaDenegado).format("L")}
              label={"Nota"}
            />
            <TextField
              required
              disabled
              fullWidth
              value={data.denegadoPor}
              label={"Denegado por"}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => denegarCredito()}
              style={{
                color: "#ffc400",
                borderColor: "#ffc400",
              }}
              size="large"
            >
              denegar credito
            </Button> */}
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default DeniedDetails;
