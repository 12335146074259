import React, { useState, useEffect, useContext } from 'react';
import {
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Grid,
	Autocomplete,
	InputAdornment,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import { DataContext } from '../../../context/DataContext';
import { isEmpty, isUndefined } from 'lodash';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getToken } from '../../../api/Account';
import { simpleMessage } from '../../../helpers/Helpers';
import { addAsientoContableAsync, getCatalogoCuentasListAsync } from '../../../api/Contabilidad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const AddAsientoContable = ({ setLocalReload, localReload, setShowModal }) => {
	const [referencia, setReferencia] = useState('');
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSuc, setSelectedSuc] = useState();

	const [catalogoCuentas, setCatalogoCuentas] = useState([]);
	const [selectedCuenta, setSelectedCuenta] = useState(null);
	const [monto, setMonto] = useState('');
	const [isAsientoCierre, setIsAsientoCierre] = useState(false);

	const [detalleAsiento, setDetalleAsiento] = useState([]);
	const [fechaAsiento, setFechaAsiento] = useState(new Date());

	const { setIsLoading, setIsUnautorized, access, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);

	const token = getToken();

	useEffect(() => {
		(async () => {
			setSelectedSuc(await getSucursals());
			await getCatalogoCuentas();
		})();
	}, [localReload]);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
			setSelectedSucursal(result.data.result[0].id);
			return result.data.result[0].id;
		}
		return selectedSucursal;
	};

	const getCatalogoCuentas = async () => {
		setIsLoading(true);
		const result = await getCatalogoCuentasListAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setCatalogoCuentas(result.data.result);
		return;
	};

	const defaultProps = {
		options: catalogoCuentas,
		getOptionLabel: (option) => `${option.countNumber} - ${option.descripcion}`
	};

	const funtionSetMonto = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setMonto(value);
			return;
		}
	};

	const addDetalleAsiento = (mov) => {
		if (isEmpty(selectedCuenta)) {
			simpleMessage('Seleccione una cuenta contable...', 'error');
			return;
		}

		if (monto.length === 0) {
			simpleMessage('Debe ingresar un monto', 'error');
			return;
		}

		const data = {
			num: detalleAsiento.length + 1,
			cuenta: selectedCuenta.countNumber,
			descripcion: selectedCuenta.descripcion,
			debito: mov === 1 ? monto : 0,
			credito: mov === -1 ? monto : 0,
			cuentaContableId: selectedCuenta.id
		};

		const list = detalleAsiento;
		list.push(data);
		setDetalleAsiento(list);
		setSelectedCuenta(null);
	};

	const saveChangesAsync = async () => {
		if (referencia.length === 0) {
			simpleMessage('Agrege una referencia...', 'error');
			return;
		}

		if (selectedSuc.length === 0) {
			simpleMessage('Seleccione una sucursal...', 'error');
			return;
		}
		if (isEmpty(detalleAsiento)) {
			simpleMessage('Agrege el detalle del asiento...', 'error');
			return;
		}
		const data = {
			referencia,
			sucursalId: selectedSuc,
			fechaAsiento,
			asientoContableDetails: detalleAsiento,
			isAsientoCierre
		};

		setIsLoading(true);
		const result = await addAsientoContableAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		simpleMessage('Asiento agregado...!', 'success');
		setLocalReload(!localReload);
		setShowModal(false);
	};

	return (
		<div>
			<Paper
				style={{
					padding: 20,
					textAlign: 'center'
				}}
			>
				<Stack direction="row" justifyContent="space-between" spacing={1}>
					<FormControl fullWidth style={{ textAlign: 'left' }}>
						<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							onChange={(e) => setSelectedSuc(e.target.value)}
							value={selectedSucursal}
							label="Sucursal"
						>
							{sucursalList.map((item) => {
								return (
									<MenuItem key={item.id} value={item.id}>
										{item.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<DesktopDatePicker
						closeOnSelect
						label="Fecha Asiento"
						inputFormat="DD/MM/YYYY"
						value={fechaAsiento}
						onChange={(newValue) => setFechaAsiento(newValue)}
						renderInput={(params) => <TextField fullWidth {...params} />}
					/>
					<FormControlLabel
						labelPlacement="start"
						control={
							<Checkbox
								checked={isAsientoCierre}
								onChange={() => setIsAsientoCierre(!isAsientoCierre)}
							/>
						}
						label="Es de cierre"
					/>
				</Stack>

				<TextField
					fullWidth
					style={{ marginTop: 20 }}
					required
					onChange={(e) => setReferencia(e.target.value.toUpperCase())}
					label={'Referencia'}
					value={referencia}
				/>

				<Typography textAlign={'center'} color={'#00a152'} variant="h6" fontWeight={'bold'}>
					Detalle
				</Typography>

				<hr />

				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Autocomplete
							fullWidth
							{...defaultProps}
							id="combo-box-demo"
							value={selectedCuenta}
							onChange={(event, newValue) => {
								if (newValue.nivel < 5) {
									simpleMessage(
										'Debe seleccionar una cuenta de detalle',
										'error'
									);
									return;
								}
								setSelectedCuenta(newValue);
							}}
							noOptionsText="No existe cuenta con ese nombre"
							renderInput={(params) => (
								<TextField fullWidth {...params} label="Seleccione una cuenta" />
							)}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							required
							fullWidth
							onChange={(e) => funtionSetMonto(e.target.value)}
							value={monto}
							label={'Monto'}
							InputProps={{
								startAdornment: <InputAdornment position="start">C$</InputAdornment>
							}}
						/>
					</Grid>

					<Grid item xs={6}>
						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => addDetalleAsiento(1)}
							style={{
								borderRadius: 20,
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							size="large"
						>
							Agregar a debe
						</Button>
					</Grid>

					<Grid item xs={6}>
						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => addDetalleAsiento(-1)}
							style={{
								borderRadius: 20,
								color: '#ffc400',
								borderColor: '#ffc400'
							}}
							size="large"
						>
							Agregar a haber
						</Button>
					</Grid>
				</Grid>

				<hr />

				<TableContainer>
					<Table aria-label="simple table" size="small">
						<TableHead>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Cuenta
							</TableCell>
							<TableCell
								align="left"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Descripción
							</TableCell>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Débito
							</TableCell>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Crédito
							</TableCell>
						</TableHead>

						<TableBody>
							{detalleAsiento.map((row) => {
								return (
									<TableRow key={row.num}>
										<TableCell
											align="center"
											component="th"
											scope="row"
											style={{
												fontSize: 12
											}}
										>
											{row.cuenta}
										</TableCell>
										<TableCell
											align="left"
											style={{
												fontSize: 12
											}}
										>
											{row.descripcion}
										</TableCell>
										<TableCell
											align="center"
											style={{
												fontSize: 12
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(row.debito)}
										</TableCell>
										<TableCell
											align="center"
											style={{
												fontSize: 12
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(row.credito)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<Button
					variant="outlined"
					startIcon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />}
					fullWidth
					onClick={() => saveChangesAsync()}
					style={{
						marginTop: 30,
						borderRadius: 20,
						color: '#00a152',
						borderColor: '#00a152'
					}}
					size="large"
				>
					Agregar asiento
				</Button>
			</Paper>
		</div>
	);
};

export default AddAsientoContable;
