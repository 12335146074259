import React, { useContext, useState, useEffect } from 'react';

import {
	Paper,
	Typography,
	Container,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getCreditsMorasAsync, getDefeatedCreditsAsync } from '../../../api/Report';
import NoData from '../../../components/NoData';
import SmallModal from '../../../components/modals/SmallModal';
import moment from 'moment';
import SelectorCreditosVencidos from '../../reports/selectores/selectoresCartera/SelectorCreditosVencidos';

const DashMora = () => {
	const [data, setData] = useState([]);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [moneda, setMoneda] = useState(1);

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0) {
			setSelectedSucursal(result.data.result[0].id);
		}
		getData(result.data.result[0].id, moneda);
	};

	const getData = async (sucursal, moneda) => {
		setIsLoading(true);
		const data = {
			sucursalId: sucursal,
			moneda
		};

		const result = await getCreditsMorasAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setData(result.data.result);
	};

	const oncChangeSucursal = async (value) => {
		setSelectedSucursal(value);
		getData(value);
	};

	const oncChangeMoneda = async (value) => {
		setMoneda(value);
		getData(selectedSucursal, value);
	};

	let totalCuota = 0;
	let totalAtraso = 0;
	let totalVencido = 0;
	let totalSaldo = 0;

	let contadorMora = 0;
	let contadorVenc = 0;

	data.map((c) => {
		if (c.tipo === 'M') {
			contadorMora += 1;
			totalAtraso += c.atraso;
		} else {
			contadorVenc += 1;
			totalVencido += c.saldoTotal;
		}
		totalCuota += c.cuota;
		totalSaldo += c.saldoTotal;
	});

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent={'space-between'}
						spacing={1}
					>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<Button
								onClick={() => {
									navigate(`${ruta}/`);
								}}
								style={{
									color: '#2979ff',
									borderColor: '#2979ff'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faChevronCircleLeft}
								/>
								Regresar
							</Button>

							<Typography
								color={'#00a152'}
								variant="h6"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							>
								Créditos en Mora
							</Typography>
						</Stack>

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={2}
						>
							<FormControl fullWidth style={{ textAlign: 'left', width: 200 }}>
								<InputLabel id="demo-simple-select-standard-label">
									Moneda
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeMoneda(e.target.value)}
									value={moneda}
									label="Moneda"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										CORDOBAS C$
									</MenuItem>
									<MenuItem key={2} value={2}>
										DOLARES $
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left', width: 200 }}>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
									<MenuItem key={0} value={0}>
										TODAS...
									</MenuItem>
								</Select>
							</FormControl>

							{/* {isAccess(access, 'RCREDVENC') ? (
								<Button
									onClick={() => {
										setShowModal(true);
									}}
									style={{
										color: '#2979ff',
										borderColor: '#2979ff',
										padding: 10
									}}
									variant="outlined"
								>
									<FontAwesomeIcon
										style={{ marginRight: 10, fontSize: 20 }}
										icon={faPrint}
									/>
									Reporte
								</Button>
							) : (
								<></>
							)} */}
						</Stack>
					</Stack>

					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Cliente
										</TableCell>

										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Dirección
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Teléfono
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											F.Vencimiento
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Tipo
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Atraso
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											A.Dias
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											C + A
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Saldo Total
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row) => {
										const { isDolar } = row;

										return (
											<TableRow key={row.id}>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.fullName}
												</TableCell>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.address}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.phone}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{moment(row.fechaVencimiento).format('L')}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.tipo}
												</TableCell>

												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.atraso)}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{row.atrasoDias}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.atraso + row.cuota)}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(row.saldoTotal)}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}

					<hr />
					<Stack direction="row" flex="row" justifyContent="space-around">
						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Créditos en Atraso
							</Typography>
							<Typography style={{ fontSize: 11 }}>{contadorMora}</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Atraso
							</Typography>
							<Typography style={{ fontSize: 11 }}>
								{`${new Intl.NumberFormat(
									parseInt(moneda) === 2 ? 'en-US' : 'es-NI',
									{
										style: 'currency',
										currency: parseInt(moneda) === 2 ? 'USD' : 'NIO'
									}
								).format(totalAtraso)} = ${(
									(totalAtraso / totalSaldo) *
									100
								).toFixed(2)}%`}
							</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Créditos Vencidos
							</Typography>
							<Typography style={{ fontSize: 11 }}>{contadorVenc}</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Vencido
							</Typography>
							<Typography style={{ fontSize: 11 }}>
								{`${new Intl.NumberFormat(
									parseInt(moneda) === 2 ? 'en-US' : 'es-NI',
									{
										style: 'currency',
										currency: parseInt(moneda) === 2 ? 'USD' : 'NIO'
									}
								).format(totalVencido)} = ${(
									(totalVencido / totalSaldo) *
									100
								).toFixed(2)}%`}
							</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Saldo
							</Typography>
							<Typography style={{ fontSize: 11 }}>
								{new Intl.NumberFormat(parseInt(moneda) === 2 ? 'en-US' : 'es-NI', {
									style: 'currency',
									currency: parseInt(moneda) === 2 ? 'USD' : 'NIO'
								}).format(totalSaldo)}
							</Typography>
						</Stack>
					</Stack>
					<hr />
				</Paper>
			</Container>
		</div>
	);
};

export default DashMora;
