import React, { useContext } from 'react';
import { Typography, Stack } from '@mui/material';
import { DataContext } from '../context/DataContext';

export const PrintReportGerencial = React.forwardRef((props, ref) => {
	const { title, slogan, icon, representanteLegal } = useContext(DataContext);
	const getPageMargins = () => {
		return `body {
                color:black;
              }`;
	};

	return (
		<div ref={ref}>
			<style>{getPageMargins()}</style>
			<Stack justifyContent={'space-between'} direction="row">
				<img loading="lazy" src={icon} alt="logo" style={{ height: 50 }} />
				<Stack display="flex" justifyContent="center">
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: 'bold',
							fontSize: 15
						}}
					>{`${title}`}</Typography>
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: 'bold',
							fontSize: 12
						}}
					>{`${slogan}`}</Typography>
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontSize: 11,
							fontWeight: 'bold'
						}}
					>
						{props.titulo}
					</Typography>
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontSize: 11,
							fontWeight: 'bold'
						}}
					>
						{representanteLegal}
					</Typography>
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontSize: 11,
							fontWeight: 'bold'
						}}
					>
						{props.rango}
					</Typography>
				</Stack>
				<img loading="lazy" src={icon} alt="logo" style={{ height: 50 }} />
			</Stack>
			<div style={{ fontSize: 8 }}>{props.children}</div>

			{props.isLandscape ? (
				<Stack
					direction={'row'}
					spacing={2}
					marginTop={10}
					justifyContent="space-around"
					alignItems="flex-start"
				>
					<Stack justifyContent="center">
						<span>----------------------------------------------------</span>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Elaborado por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Contador
						</Typography>
					</Stack>

					<Stack justifyContent="center">
						<span>----------------------------------------------------</span>

						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Revisado Por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Auditor
						</Typography>
					</Stack>

					<Stack justifyContent="center">
						<span>----------------------------------------------------</span>

						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Autorizado Por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Propietario
						</Typography>
					</Stack>
				</Stack>
			) : (
				<Stack
					direction={'row'}
					spacing={2}
					marginTop={10}
					justifyContent="space-around"
					alignItems="flex-start"
				>
					<Stack justifyContent="center">
						<span>----------------------------------</span>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Elaborado por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Contador
						</Typography>
					</Stack>

					<Stack justifyContent="center">
						<span>----------------------------------</span>

						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Revisado Por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Auditor
						</Typography>
					</Stack>

					<Stack justifyContent="center">
						<span>----------------------------------</span>

						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Autorizado Por
						</Typography>
						<Typography textAlign={'center'} color="#212121" fontSize={13}>
							Propietario
						</Typography>
					</Stack>
				</Stack>
			)}
		</div>
	);
});
