import React, { useState, useContext, useEffect } from "react";
import {
  Paper,
  Container,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";

import { simpleMessage } from "../../../../helpers/Helpers";
import { getPlazoByPeriodicidadAsync } from "../../../../api/Plazo";
import { addPrestamoAsync } from "../../../../api/Employees";

const AddPrestamo = ({ id, setShowModal, reload, setReload }) => {
  const [description, setDescription] = useState("");
  const [monto, setMonto] = useState("");
  const [cuota, setCuota] = useState(0);

  const [plazoList, setPlazoList] = useState([]);
  const [selectedPlazo, setSelectedPlazo] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const token = getToken();

  const saveChanges = async () => {
    if (monto.length === 0) {
      simpleMessage("Ingrese una descripcion", "error");
      return;
    }

    const data = {
      employeeId: id,
      plazoId: selectedPlazo,
      monto,
      cuota,
      description,
    };

    setIsLoading(true);
    const result = await addPrestamoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Prestamo Agregado...!", "success");
    setReload(!reload);
    setShowModal(false);
  };

  const getPlazoByPeriodicidad = async () => {
    setIsLoading(true);
    const result = await getPlazoByPeriodicidadAsync(token, 2);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setPlazoList(result.data.result);
    setIsLoading(false);
  };

  useEffect(() => {
    getPlazoByPeriodicidad();
  }, []);

  //Devuelve un entero positivo
  const setMontoCuota = (montoCuota, plazoId) => {
    let val;
    if (/^\d*\.?\d*$/.test(montoCuota.toString()) || montoCuota === "") {
      setMonto(montoCuota);
      val = parseFloat(montoCuota);
    }

    if (val > 0 && plazoId > 0) {
      setSelectedPlazo(plazoId);
      const selected = plazoList.filter((item) => item.id == plazoId);
      let result = (val / selected[0].cuotas).toFixed(2);
      setCuota(result);
    } else {
      setCuota(0);
    }
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
                Cuota:
              </Typography>

              <Typography color={"#ffc400"} style={{ textAlign: "center" }}>
                {new Intl.NumberFormat("es-NI", {
                  style: "currency",
                  currency: "NIO",
                }).format(cuota)}
              </Typography>
            </Stack>

            <hr />

            <TextField
              required
              fullWidth
              onChange={(e) => setMontoCuota(e.target.value, selectedPlazo)}
              value={monto}
              label={"Monto Prestamo"}
            />

            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Plazo
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setMontoCuota(monto, e.target.value)}
                value={selectedPlazo}
                label="Plazo"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione un Plazo</em>
                </MenuItem>
                {plazoList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              required
              fullWidth
              multiline
              onChange={(e) =>
                setDescription(e.target.value.toLocaleUpperCase())
              }
              value={description}
              label={"Description"}
            />

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{ marginRight: 20 }}
                />
              }
              fullWidth
              onClick={() => saveChanges()}
              style={{
                borderRadius: 30,
                color: "#4caf50",
                borderColor: "#4caf50",
                padding: 12,
              }}
              size="large"
            >
              Agregar Adelanto
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default AddPrestamo;
