import React, { useState, useContext } from "react";
import { TextField, Divider, Container, Stack, Button } from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { addClientReferenceAsync } from "../../../../api/Clients";

const AddReference = ({
  clientId,
  setShowModal,
  localReload,
  setLocalReload,
}) => {
  const [fullName, setFullName] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [parentesco, setParentesco] = useState("");

  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const saveChangesAsync = async () => {
    if (validate()) {
      const data = {
        clientId,
        fullName,
        telefono,
        direccion,
        parentesco,
      };
      setIsLoading(true);
      const result = await addClientReferenceAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      simpleMessage("Referencia Agregada...!", "success");
      setLocalReload(!localReload);
      setShowModal(false);
    }
  };

  //Devuelve un entero positivo
  const funtionSetPhone = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setTelefono(value);
      return;
    }
  };

  const validate = () => {
    let isValid = true;
    if (fullName === "") {
      simpleMessage("Debe ingresar los nombres y apellidos", "error");
      return (isValid = false);
    }

    if (telefono === "") {
      simpleMessage("Debe ingresar un numero telefónico", "error");
      return (isValid = false);
    }

    if (direccion === "") {
      simpleMessage("Debe ingresar una dirección", "error");
      return (isValid = false);
    }

    if (parentesco === "") {
      simpleMessage("Debe especificar un parentesco con el cliente", "error");
      return (isValid = false);
    }

    return isValid;
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <Stack spacing={2}>
          <Divider />

          <TextField
            required
            fullWidth
            onChange={(e) => setFullName(e.target.value.toUpperCase())}
            value={fullName}
            label={"Nombres y Apellidos"}
          />

          <TextField
            required
            fullWidth
            onChange={(e) => funtionSetPhone(e.target.value)}
            value={telefono}
            label={"Telefono"}
          />

          <TextField
            required
            multiline
            fullWidth
            onChange={(e) => setDireccion(e.target.value.toUpperCase())}
            value={direccion}
            label={"Direccion"}
          />

          <TextField
            required
            fullWidth
            onChange={(e) => setParentesco(e.target.value.toUpperCase())}
            value={parentesco}
            label={"Parentesco"}
          />

          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
            }
            fullWidth
            onClick={() => saveChangesAsync()}
            style={{
              color: "#4caf50",
              borderColor: "#4caf50",
            }}
          >
            Agregar Referencia
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default AddReference;
