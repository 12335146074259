import React, { useState, useContext } from "react";
import { TextField, Divider, Container, Button } from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addPlazoAsync } from "../../../../api/Plazo";

const AddPlazo = ({
  setShowModal,
  periodicidadId,
  localReload,
  setLocalReload,
}) => {
  const token = getToken();

  const [description, setDescription] = useState("");
  const [cuotas, setCuotas] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const saveChangesAsync = async () => {
    const data = {
      periodicidadId,
      description,
      cuotas,
    };

    if (description.length === 0) {
      simpleMessage("Ingrese una descripcion...", "error");
      return;
    }
    if (cuotas.length === 0) {
      simpleMessage("Ingrese cantidad de cuotas...", "error");
      return;
    }
    setIsLoading(true);
    const result = await addPlazoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Plazo Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  //Devuelve un entero positivo
  const funtionSetCuotas = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setCuotas(value);
      return;
    }
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <Divider />
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => setDescription(e.target.value.toUpperCase())}
          label={"Descripción"}
          value={description}
        />
        <TextField
          fullWidth
          required
          style={{ marginBottom: 20, marginTop: 20 }}
          onChange={(e) => funtionSetCuotas(e.target.value)}
          label={"Cuotas"}
          value={cuotas}
        />

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            color: "#00a152",
            borderColor: "#00a152",
          }}
          size="large"
        >
          Agregar Plazo
        </Button>
      </Container>
    </div>
  );
};

export default AddPlazo;
