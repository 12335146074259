import React, { useContext, useState, useEffect } from 'react';

import {
	Paper,
	Typography,
	Container,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faPrint } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getCreditsCancelledAsync, getNewCreditsAsync } from '../../../api/Report';
import NoData from '../../../components/NoData';
import SmallModal from '../../../components/modals/SmallModal';
import moment from 'moment';
import SelectorCreditsCancelled from '../../reports/selectores/selectoresCartera/SelectorCreditsCancelled';

const DashCreditosNuevos = () => {
	const [data, setData] = useState([]);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState(0);

	const [moneda, setMoneda] = useState(1);
	const [tipoCredito, setTipoCredito] = useState(0);

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getSucursals();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0) {
			setSelectedSucursal(result.data.result[0].id);
		}
		getData(result.data.result[0].id, moneda, tipoCredito);
	};

	const getData = async (sucursal, moneda, tc) => {
		setIsLoading(true);
		const data = {
			sucursalId: sucursal,
			moneda,
			tipoCredito: tc
		};
		const result = await getNewCreditsAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setData(result.data.result);
		setIsLoading(false);
	};

	const oncChangeMoneda = async (value) => {
		setMoneda(value);
		getData(selectedSucursal, value, tipoCredito);
	};

	const oncChangeSucursal = async (value) => {
		setSelectedSucursal(value);
		getData(value);
	};

	let monto = 0;
	data.map((c) => {
		monto += c.montoEntregado;
	});

	const oncChangeTipoCredito = async (value) => {
		setTipoCredito(value);
		getData(selectedSucursal, moneda, value);
	};
	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent={'space-between'}
						spacing={1}
					>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={1}
						>
							<Button
								onClick={() => {
									navigate(`${ruta}/`);
								}}
								style={{
									color: '#2979ff',
									borderColor: '#2979ff'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faChevronCircleLeft}
								/>
								Regresar
							</Button>

							<Typography
								color={'#00a152'}
								variant="h6"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							></Typography>
						</Stack>

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							alignItems="center"
							spacing={2}
						>
							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
									<MenuItem key={0} value={0}>
										TODAS...
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Moneda
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeMoneda(e.target.value)}
									value={moneda}
									label="Moneda"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										CORDOBAS C$
									</MenuItem>
									<MenuItem key={2} value={2}>
										DOLARES $
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Tipo de Cédito
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => oncChangeTipoCredito(e.target.value)}
									value={tipoCredito}
									label="tipo de crédito"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={2} value={1}>
										EMPEÑO
									</MenuItem>
									<MenuItem key={1} value={2}>
										CREDITO
									</MenuItem>
									<MenuItem key={0} value={0}>
										TODOS...
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>

					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											#
										</TableCell>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Cliente
										</TableCell>

										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Periocidad
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Plazo
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Desembolso
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Cuota
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											F.Entrega
										</TableCell>
										<TableCell
											align="center"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Tipo
										</TableCell>
										<TableCell
											align="left"
											className="text-primary fw-bold"
											style={{ fontSize: 12 }}
										>
											Creado Por
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row) => {
										const {
											numeroCredito,
											client,
											plazo,
											montoEntregado,
											cuotaPagar,
											isDolar,
											fechaEntrega,
											isNominal,
											creadoPor,
											periocidad
										} = row;

										return (
											<TableRow key={row.id}>
												<TableCell
													align="center"
													component="th"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{numeroCredito}
												</TableCell>
												<TableCell align="left" style={{ fontSize: 11 }}>
													{client.fullName}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{periocidad.description}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{plazo.description}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(montoEntregado)}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{new Intl.NumberFormat(
														isDolar ? 'en-US' : 'es-NI',
														{
															style: 'currency',
															currency: isDolar ? 'USD' : 'NIO'
														}
													).format(cuotaPagar)}
												</TableCell>
												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{moment(fechaEntrega).format('L')}
												</TableCell>

												<TableCell
													align="center"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{isNominal ? 'EMPEÑO' : 'CREDITO'}
												</TableCell>

												<TableCell
													align="left"
													scope="row"
													style={{ fontSize: 11 }}
												>
													{creadoPor.fullName}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}

					<hr />

					<Stack direction="row" flex="row" justifyContent="space-around">
						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Registros
							</Typography>
							<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
						</Stack>

						<Stack textAlign="center">
							<Typography
								style={{
									fontWeight: 'bold',
									color: '#03a9f4',
									fontSize: 11
								}}
							>
								Total Principal
							</Typography>
							<Typography style={{ fontSize: 11 }}>
								{new Intl.NumberFormat(moneda === 2 ? 'en-US' : 'es-NI', {
									style: 'currency',
									currency: moneda === 2 ? 'USD' : 'NIO'
								}).format(monto)}
							</Typography>
						</Stack>
					</Stack>
					<hr />
				</Paper>
			</Container>
		</div>
	);
};

export default DashCreditosNuevos;
