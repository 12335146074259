import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Typography,
	Container,
	Stack,
	Button,
	Paper,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	InputAdornment
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { DataContext } from '../../../../context/DataContext';
import { simpleMessage } from '../../../../helpers/Helpers';
import { getToken } from '../../../../api/Account';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import {
	addQuoteAsync,
	getDataCreditAsync,
	addQuoteInteresAsync,
	addAbonoCapitalAsync,
	addQuoteConSobranteAsync
} from '../../../../api/Credits';

const AbonoCredito = ({ setShowModal, id, localReload, setLocalReload, printBill }) => {
	const [montoAbono, setMontoAbono] = useState('');
	const [active, setActive] = useState(1);
	const [totalAbonado, setTotalAbonado] = useState(0);
	const [nuevoSaldo, setNuevoSaldo] = useState(0);
	const [sobrante, setSobrante] = useState(0);
	const [isDolar, setIsDolar] = useState(false);
	const [tipoAbono, setTipoAbono] = useState(1);
	const [isDisable, setIsDisable] = useState(false);
	const [dataAbono, setDataAbono] = useState([]);
	const [creditNumber, setCreditNumer] = useState('');

	const [compra, setCompra] = useState(0);
	const [venta, setVenta] = useState(0);
	const [alDia, setAlDia] = useState(0);
	const [saldoAnterior, setSaldoAnterior] = useState(0);

	// const [fecha, setFecha] = useState(new Date());

	const token = getToken();
	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getDataCreditAsync(token, id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setCreditNumer(result.data.result.numeroCredito);
			setIsDolar(result.data.result.isDolar);
			setDataAbono(result.data.result);

			setCompra(result.data.result.bcnCompra);
			setVenta(result.data.result.bcnVenta);
			setAlDia(result.data.result.quoteSugest);
			setActive(result.data.result.isDolar ? 2 : 1);

			setTotalAbonado(result.data.result.saldoTotal);
			setSaldoAnterior(result.data.result.saldoTotal);

			// await getSaldoAnterior();
		})();
	}, []);

	//Devuelve un entero positivo
	const funtionSetMontoCuota = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString())) {
			if (value > parseFloat(saldoAnterior) && tipoAbono !== 4) {
				simpleMessage('No puede abonar mas de lo que debe', 'error');
				setMontoAbono(saldoAnterior.toFixed(2));
				setSaldoAnterior(saldoAnterior);
				setNuevoSaldo(0);
				return;
			}
			setMontoAbono(value);
			if (tipoAbono !== 4) {
				setNuevoSaldo(parseFloat(saldoAnterior) - parseFloat(value === '' ? 0 : value));
			} else {
				let valor = parseFloat(saldoAnterior) - parseFloat(value === '' ? 0 : value);
				setNuevoSaldo(valor <= 0 ? 0 : valor);
				setSobrante(valor <= 0 ? Math.abs(valor) : 0);
			}
			return;
		}
	};

	const addCuota = async () => {
		if (montoAbono.length === 0) {
			simpleMessage('Debe ingresar un monto a abonar', 'error');
			return;
		}

		if (nuevoSaldo < 0 && tipoAbono !== 4) {
			simpleMessage('No puede ingresar una cuota mayor al saldo del credito', 'error');
			return;
		}

		if (sobrante <= 0 && tipoAbono === 4) {
			simpleMessage('El sobrante no puede ser cero', 'error');
			return;
		}

		let concepto = '';
		switch (tipoAbono) {
			case 2:
				concepto = 'ABONO DE INTERES';
				break;
			case 3:
				concepto = 'ABONO DE CAPITAL';
				break;

			case 4:
				concepto = 'ABONO CON SOBRANTE';
				break;

			default:
				concepto = 'ABONO DEL DIA';
				break;
		}

		setIsLoading(true);
		let result = [];
		const data = {
			creditId: id,
			totalRecibido: montoAbono,
			concepto: concepto,
			moneda: active,
			// fechaPago: moment(fecha).format('YYYY-MM-DD')
		};
		switch (tipoAbono) {
			case 2:
				result = await addQuoteInteresAsync(token, data);
				break;
			case 3:
				result = await addAbonoCapitalAsync(token, data);
				break;

			case 4:
				result = await addQuoteConSobranteAsync(token, data);
				break;

			default:
				result = await addQuoteAsync(token, data);
				break;
		}

		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		printBill(result.data.result);
		setIsLoading(false);
		setLocalReload(!localReload);
		setShowModal(false);
	};

	const onChangeData = (moneda, tipo) => {
		setTipoAbono(tipo);
		setActive(moneda);

		if (isDolar) {
			if (moneda === 2) {
				if (tipo === 1) {
					setIsDisable(false);
					setMontoAbono('');
					setNuevoSaldo(0);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setNuevoSaldo(dataAbono.saldoTotal);
				} else if (tipo === 2) {
					setIsDisable(true);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setMontoAbono(dataAbono.quoteInteres);
					setNuevoSaldo(totalAbonado);
				} else if (tipo === 3) {
					setIsDisable(false);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoCapital);
					setMontoAbono(dataAbono.saldoCapital);
					setTotalAbonado(dataAbono.saldoCapital);
					setNuevoSaldo(0);
				} else {
					setIsDisable(false);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setSobrante(0);
					setMontoAbono(0);
				}
			} else {
				if (tipo === 1) {
					setIsDisable(false);
					setMontoAbono('');
					setNuevoSaldo(0);
					setAlDia((dataAbono.quoteSugest * venta).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal * venta).toFixed(2));
					setNuevoSaldo((dataAbono.saldoTotal * venta).toFixed(2));
				} else if (tipo === 2) {
					setIsDisable(true);
					setAlDia((dataAbono.quoteSugest * venta).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal * venta).toFixed(2));
					setMontoAbono((dataAbono.quoteInteres * venta).toFixed(2));
					setNuevoSaldo((totalAbonado * venta).toFixed(2));
				} else if (tipo === 3) {
					setIsDisable(false);
					setAlDia((dataAbono.quoteSugest * venta).toFixed(2));
					setSaldoAnterior((dataAbono.saldoCapital * venta).toFixed(2));
					setMontoAbono((dataAbono.saldoCapital * venta).toFixed(2));
					setTotalAbonado((dataAbono.saldoCapital * venta).toFixed(2));
					setNuevoSaldo(0);
				} else {
					setIsDisable(false);
					setAlDia((dataAbono.quoteSugest * venta).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal * venta).toFixed(2));
					setSobrante(0);
					setMontoAbono(0);
				}
			}
		} else {
			if (moneda === 1) {
				if (tipo === 1) {
					setIsDisable(false);
					setMontoAbono('');
					setNuevoSaldo(0);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setNuevoSaldo(dataAbono.saldoTotal);
				} else if (tipo === 2) {
					setIsDisable(true);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setMontoAbono(dataAbono.quoteInteres);
					setNuevoSaldo(totalAbonado);
				} else if (tipo === 3) {
					setIsDisable(false);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoCapital);
					setMontoAbono(dataAbono.saldoCapital);
					setTotalAbonado(dataAbono.saldoCapital);
					setNuevoSaldo(0);
				} else {
					setIsDisable(false);
					setAlDia(dataAbono.quoteSugest);
					setSaldoAnterior(dataAbono.saldoTotal);
					setSobrante(0);
					setMontoAbono(0);
				}
			} else {
				if (tipo === 1) {
					setIsDisable(false);
					setMontoAbono('');
					setNuevoSaldo(0);
					setAlDia((dataAbono.quoteSugest / compra).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal / compra).toFixed(2));
					setNuevoSaldo((dataAbono.saldoTotal / compra).toFixed(2));
				} else if (tipo === 2) {
					setIsDisable(true);
					setAlDia((dataAbono.quoteSugest / compra).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal / compra).toFixed(2));
					setMontoAbono((dataAbono.quoteInteres / compra).toFixed(2));
					setNuevoSaldo((totalAbonado / compra).toFixed(2));
				} else if (tipo === 3) {
					setIsDisable(false);
					setAlDia((dataAbono.quoteSugest / compra).toFixed(2));
					setSaldoAnterior((dataAbono.saldoCapital / compra).toFixed(2));
					setMontoAbono((dataAbono.saldoCapital / compra).toFixed(2));
					setTotalAbonado((dataAbono.saldoCapital / compra).toFixed(2));
					setNuevoSaldo(0);
				} else {
					setIsDisable(false);
					setAlDia((dataAbono.quoteSugest / compra).toFixed(2));
					setSaldoAnterior((dataAbono.saldoTotal / compra).toFixed(2));
					setSobrante(0);
					setMontoAbono(0);
				}
			}
		}
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Stack direction="column" spacing={1}>
					<Paper
						style={{
							padding: 20
						}}
					>
						<Stack direction="column" spacing={2} justifyContent="center">
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Stack spacing={1} direction="row">
									<Typography
										fontWeight={'bold'}
										style={{
											color: '#2979ff'
										}}
									>
										Credito # :
									</Typography>
									<Typography
										style={{
											fontWeight: 'bold'
										}}
									>
										{creditNumber}
									</Typography>
								</Stack>

								<Stack spacing={1} direction="row">
									<Typography
										fontWeight={'bold'}
										style={{
											color: '#00a152'
										}}
									>
										Ponerse Al Día :
									</Typography>
									<Typography
										style={{
											fontWeight: 'bold'
										}}
									>
										{`${new Intl.NumberFormat(
											active === 2 ? 'en-US' : 'es-NI',
											{
												style: 'currency',
												currency: active === 2 ? 'USD' : 'NIO'
											}
										).format(alDia)}`}
									</Typography>
								</Stack>
							</Stack>

							<hr />

							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
							>
								{/* <DesktopDatePicker
									closeOnSelect
									label="Fecha Pago"
									inputFormat="DD/MM/YYYY"
									value={fecha}
									onChange={(newValue) => setFecha(newValue)}
									renderInput={(params) => <TextField fullWidth {...params} />}
								/> */}

								<FormControl fullWidth style={{ textAlign: 'left' }}>
									<InputLabel id="demo-simple-select-standard-label">
										Moneda
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										onChange={(e) => onChangeData(e.target.value, tipoAbono)}
										value={active}
										label="Moneda"
									>
										<MenuItem value={1}>
											{isDolar
												? `CORDOBA - ${new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
												  }).format(venta)}`
												: 'CORDOBA'}
										</MenuItem>
										<MenuItem value={2}>
											{isDolar
												? 'DOLAR'
												: `DOLAR - ${new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
												  }).format(compra)}`}
										</MenuItem>
									</Select>
								</FormControl>
							</Stack>

							{/* <FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Moneda
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeData(e.target.value, tipoAbono)}
									value={active}
									label="Moneda"
								>
									<MenuItem value={1}>
										{isDolar
											? `CORDOBA - ${new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
											  }).format(venta)}`
											: 'CORDOBA'}
									</MenuItem>
									<MenuItem value={2}>
										{isDolar
											? 'DOLAR'
											: `DOLAR - ${new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
											  }).format(compra)}`}
									</MenuItem>
								</Select>
							</FormControl> */}

							<TextField
								required
								fullWidth
								disabled={isDisable}
								onChange={(e) => funtionSetMontoCuota(e.target.value)}
								value={montoAbono}
								label={'Monto a abonar'}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											{active === 2 ? '$' : 'C$'}
										</InputAdornment>
									)
								}}
							/>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Tipo de abono
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeData(active, e.target.value)}
									value={tipoAbono}
									label="Tipo de Abono"
								>
									<MenuItem value={1}>ABONO DEL DIA</MenuItem>
									<MenuItem value={2}>ABONO INTERES</MenuItem>
									<MenuItem value={3}>ABONO CAPITAL</MenuItem>
									<MenuItem value={4}>ABONO CON SOBRANTE</MenuItem>
								</Select>
							</FormControl>

							<Button
								variant="outlined"
								startIcon={
									<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
								}
								fullWidth
								onClick={() => addCuota()}
								style={{
									color: '#00a152',
									borderColor: '#00a152'
								}}
								size="large"
							>
								Agregar Cuota
							</Button>
						</Stack>
					</Paper>

					<Paper
						style={{
							padding: 10
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-around"
							divider={<Divider orientation="vertical" flexItem />}
						>
							<Stack spacing={1} justifyContent={'center'}>
								<Typography
									fontWeight={'bold'}
									textAlign="center"
									style={{
										color: '#00a152'
									}}
								>
									Saldo Anterior:
								</Typography>
								<Typography
									textAlign="center"
									style={{
										fontWeight: 'bold'
									}}
								>
									{`${new Intl.NumberFormat(active === 2 ? 'en-US' : 'es-NI', {
										style: 'currency',
										currency: active === 2 ? 'USD' : 'NIO'
									}).format(saldoAnterior)}`}
								</Typography>
							</Stack>

							<Stack spacing={1} justifyContent={'center'}>
								<Typography
									textAlign="center"
									fontWeight={'bold'}
									style={{
										color: '#f50057'
									}}
								>
									Nuevo Saldo:
								</Typography>
								<Typography
									textAlign="center"
									style={{
										fontWeight: 'bold'
									}}
								>
									{`${new Intl.NumberFormat(active === 2 ? 'en-US' : 'es-NI', {
										style: 'currency',
										currency: active === 2 ? 'USD' : 'NIO'
									}).format(nuevoSaldo)}`}
								</Typography>
							</Stack>

							{tipoAbono === 4 ? (
								<Stack spacing={1} justifyContent={'center'}>
									<Typography
										textAlign="center"
										fontWeight={'bold'}
										style={{
											color: '#ffc400'
										}}
									>
										Sobrante:
									</Typography>
									<Typography
										textAlign="center"
										style={{
											fontWeight: 'bold'
										}}
									>
										{`${new Intl.NumberFormat(
											active === 2 ? 'en-US' : 'es-NI',
											{
												style: 'currency',
												currency: active === 2 ? 'USD' : 'NIO'
											}
										).format(sobrante)}`}
									</Typography>
								</Stack>
							) : (
								''
							)}
						</Stack>
					</Paper>
				</Stack>
			</Container>
		</div>
	);
};

export default AbonoCredito;
