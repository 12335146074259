import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Paper,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCirclePlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";

import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import {
  getClientReferencesAsync,
  deleteClientReferenceAsync,
} from "../../../api/Clients";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";
import AddReference from "./referenceComponents/AddReference";
import EditReference from "./referenceComponents/EditReference";
import Swal from "sweetalert2";

const ClientReferences = ({ id }) => {
  const token = getToken();
  const [referenceList, setReferenceList] = useState([]);
  const [selectedReference, setSelectedReference] = useState([]);

  const [localReload, setLocalReload] = useState(false);

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = referenceList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.fullName.toString().includes(searchTerm)) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getClientReferencesAsync(token, id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setReferenceList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [addReferenceModal, setAddReferenceModal] = useState(false);
  const [editReferenceModal, seteditReferenceModal] = useState(false);

  const deleteReference = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteClientReferenceAsync(token, item.id);
          if (!result.statusResponse) {
            simpleMessage(result.error.response.data.message, "error");
            return;
          }

          if (result.error === "Unauthorized") {
            setIsLoading(false);
            setIsUnautorized(true);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);

          Swal.fire("Eliminada!", "Referencia Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Paper
        style={{
          padding: 20,
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography color={"#00a152"} variant="h6" fontWeight={"bold"}>
            Referencias Personales
          </Typography>

          {isAccess(access, "CLIENTS CREATE") ? (
            <Button
              onClick={() => {
                setAddReferenceModal(true);
              }}
              style={{
                color: "#00a152",
                borderColor: "#00a152",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faCirclePlus}
              />
              Agregar
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <hr />

        {isEmpty(currentItem) ? (
          <NoData />
        ) : (
          <Stack spacing={2}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="text-primary fw-bold">
                      #
                    </TableCell>
                    <TableCell align="left" className="text-primary fw-bold">
                      Nombre Completo
                    </TableCell>
                    <TableCell align="left" className="text-primary fw-bold">
                      Dirección
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      Parentesco
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      Teléfono
                    </TableCell>
                    {isAccess(access, "CLIENTS UPDATE") ||
                    isAccess(access, "CLIENTS DELETE") ? (
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Acciones
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItem.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell align="center" component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.fullName}</TableCell>
                        <TableCell align="left">{row.direccion}</TableCell>
                        <TableCell align="center">{row.parentesco}</TableCell>
                        <TableCell align="center">{row.telefono}</TableCell>
                        {isAccess(access, "CLIENTS UPDATE") ||
                        isAccess(access, "CLIENTS DELETE") ? (
                          <TableCell align="center">
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent="center"
                            >
                              {isAccess(access, "CLIENTS UPDATE") ? (
                                <IconButton
                                  style={{
                                    color: "#ffc400",
                                  }}
                                  onClick={() => {
                                    setSelectedReference(row);
                                    seteditReferenceModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                              {isAccess(access, "CLIENTS DELETE") ? (
                                <IconButton
                                  style={{
                                    color: "#f50057",
                                    width: 40,
                                    height: 40,
                                  }}
                                  onClick={() => deleteReference(row)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationComponent
              data={withSearch}
              paginate={paginate}
              itemsperPage={itemsperPage}
            />
          </Stack>
        )}
      </Paper>

      <SmallModal
        titulo={"Agregar Referencia"}
        isVisible={addReferenceModal}
        setVisible={setAddReferenceModal}
      >
        <AddReference
          setShowModal={setAddReferenceModal}
          clientId={id}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={"Editar Referencia"}
        isVisible={editReferenceModal}
        setVisible={seteditReferenceModal}
      >
        <EditReference
          setShowModal={seteditReferenceModal}
          selectedReference={selectedReference}
          setSelectedReference={setSelectedReference}
          localReload={localReload}
          setLocalReload={localReload}
        />
      </SmallModal>
    </div>
  );
};

export default ClientReferences;
