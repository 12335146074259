import React, { useContext } from "react";
import { Typography, IconButton, Tooltip, Stack, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getRuta, isAccess } from "../../../helpers/Helpers";
import { DataContext } from "../../../context/DataContext";


const RecuperacionDiaria = ({ valueCor, valueDol }) => {
  const { access } = useContext(DataContext);
  let ruta = getRuta();
  let navigate = useNavigate();

  return (
    <div>
      <Stack
        direction={"row"}
        style={{ padding: 10 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Tooltip title="Ver Cobros del Dia">
          <IconButton
            disabled={!isAccess(access, "RECUPERAC")}
            sx={{ border: 1, borderColor: "rgba(75, 192, 192, 1)" }}
            style={{
              width: 100,
              height: 100,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
            }}
            onClick={() => navigate(`${ruta}/dash-recuperacionDiaria`)}
          >
            <FontAwesomeIcon
              icon={faCashRegister}
              style={{
                fontSize: 60,
                color: "rgba(75, 192, 192, 1)",
                opacity: 0.3,
                padding: 10,
              }}
            />
          </IconButton>
        </Tooltip>
        <Stack>
          <Typography
            align="right"
            style={{
              color: "rgba(75, 192, 192, 1)",
              fontWeight: 800,
              fontSize: 20,
            }}
            paragraph
          >
            {new Intl.NumberFormat("es-NI", {
              style: "currency",
              currency: "NIO",
            }).format(valueCor)}
          </Typography>
          <Typography
            align="right"
            style={{
              color: "rgba(75, 192, 192, 1)",
              fontWeight: 800,
              fontSize: 20,
            }}
            paragraph
          >
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(valueDol)}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: "rgba(75, 192, 192, 1)", fontSize: 20 }}
      >
        Recuperación Diaria
      </Typography>
    </div>
  );
};

export default RecuperacionDiaria;
