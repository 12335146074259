import React, { useContext } from 'react';
import { Typography, IconButton, Tooltip, Stack, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getRuta, isAccess } from '../../../helpers/Helpers';
import { DataContext } from '../../../context/DataContext';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';

const CreditosNuevos = ({ valueC, valueE }) => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();
	return (
		<div>
			<Stack
				direction={'row'}
				style={{ padding: 10 }}
				justifyContent="space-between"
				alignItems={'center'}
			>
				<Stack direction={'row'}>
					<Tooltip title="Ver Créditos Nuevos">
						<IconButton
							disabled={!isAccess(access, 'RCREDCANC')}
							sx={{ border: 1, borderColor: 'rgba(174, 213, 129, 1)' }}
							style={{
								width: 100,
								height: 100,
								backgroundColor: 'rgba(174, 213, 129, 0.2)'
							}}
							onClick={() => navigate(`${ruta}/dash-newCredits`)}
						>
							<FiberNewRoundedIcon
								style={{
									fontSize: 100,
									color: 'rgba(174, 213, 129, 1)',
									opacity: 0.3,
									padding: 10
								}}
							/>
						</IconButton>
					</Tooltip>
					<Stack marginLeft={2}>
						<Typography
							align="right"
							style={{
								color: 'rgba(174, 213, 129, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Créditos
						</Typography>
						<Typography
							align="right"
							style={{
								color: 'rgba(174, 213, 129, 1)',
								fontWeight: 800,
								fontSize: 20
							}}
							paragraph
						>
							Empeños
						</Typography>
					</Stack>
				</Stack>
				<Stack>
					<Typography
						align="right"
						style={{
							color: 'rgba(174, 213, 129, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{valueC}
					</Typography>
					<Typography
						align="right"
						style={{
							color: 'rgba(174, 213, 129, 1)',
							fontWeight: 800,
							fontSize: 20
						}}
						paragraph
					>
						{valueE}
					</Typography>
				</Stack>
			</Stack>
			<Divider />
			<Typography
				variant="subtitle1"
				align="center"
				style={{ color: 'rgba(174, 213, 129, 1)', fontSize: 20 }}
			>
				Desembolsos Nuevos de la Semana
			</Typography>
		</div>
	);
};

export default CreditosNuevos;
