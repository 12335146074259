import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';

import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getSucursalByUserAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { getLibroDiarioHistoryAsync } from '../../../api/AdmonReport';
import { PrintReportGerencial } from '../../../components/PrintReportGerencial';

const ReporteLibroDiario = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [sumDebito, setSumDebito] = useState(0);
	const [sumCredito, setSumCredito] = useState(0);
	const [sucursalList, setSucursalList] = useState([]);

	const compRef = useRef();
	const { selectedSucursal, fechaDesde, fechaHasta } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal,
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString()
			};

			setIsLoading(true);
			const result = await getLibroDiarioHistoryAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);

			setData(result.data.result);
			sumatorias(result.data.result);
		})();
	}, []);

	const sumatorias = (data) => {
		let sDebito = 0;
		let sCredito = 0;
		if (data.length > 0) {
			data.map((asiento) => {
				asiento.detalle.map((detalle) => {
					sDebito += detalle.debito;
					sCredito += detalle.credito;
				});
			});
			setSumDebito(sDebito);
			setSumCredito(sCredito);
		}
	};

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(
			selectedSucursal === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedSucursal)
		);

		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<Stack />

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte Libro Diario
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton variant="outlined">
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')}`}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Hasta:  ${moment(fechaHasta).format('L')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Asiento
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Referencia
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Cuenta
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Descripción
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Débito
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Crédito
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Diferencia
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Creacion
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 11 }}
											>
												Edicion
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map((row) => {
											const {
												detalle,
												fecha,
												id,
												realizadoPor,
												referencia,
												sucursal,
												editadoPor,
												fechaEdicion
											} = row;

											let dif = 0;
											let sumDebe = 0;
											let sumCred = 0;

											detalle.map((i) => {
												sumDebe += i.debito;
												sumCred += i.credito;
											});

											dif = sumDebe - sumCred;

											return (
												<TableRow key={row.id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
														style={{ fontSize: 10 }}
													>
														<Stack>{`N°: ${id}`}</Stack>
														{moment(fecha).format('L')}
													</TableCell>
													<TableCell
														align="left"
														style={{ fontSize: 10 }}
													>
														{referencia}
													</TableCell>

													<TableCell align="center">
														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableBody>
																	{detalle.map((i) => {
																		return (
																			<TableRow key={i.id}>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 10,
																						borderBottomWidth: 2,
																						borderBottomColor:
																							'#2979ff'
																					}}
																				>
																					{i.countNumber}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													</TableCell>

													<TableCell align="left">
														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableBody>
																	{detalle.map((i) => {
																		return (
																			<TableRow key={i.id}>
																				<TableCell
																					align="left"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 10,
																						borderBottomWidth: 2,
																						borderBottomColor:
																							'#2979ff'
																					}}
																				>
																					{i.cuenta}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													</TableCell>

													<TableCell align="center">
														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableBody>
																	{detalle.map((i) => {
																		return (
																			<TableRow key={i.id}>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 10,
																						borderBottomWidth: 2,
																						borderBottomColor:
																							'#2979ff'
																					}}
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						i.debito
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													</TableCell>

													<TableCell align="center">
														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableBody>
																	{detalle.map((i) => {
																		return (
																			<TableRow key={i.id}>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 10,
																						borderBottomWidth: 2,
																						borderBottomColor:
																							'#2979ff'
																					}}
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						i.credito
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													</TableCell>

													<TableCell
														align="center"
														style={{
															color:
																dif !== 0 ? '#ffc400' : 'inherit',
															fontSize: 10
														}}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(dif)}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 10 }}
													>
														<Typography fontSize={10}>
															{`${realizadoPor.split(' ')[0]}  ${
																realizadoPor.split(' ')[1]
															}`}
															<Typography
																fontSize={10}
																style={{ color: '#2979ff' }}
															>{`SUCURSAL ${sucursal}`}</Typography>
														</Typography>
													</TableCell>
													<TableCell
														align="left"
														style={{ fontSize: 10 }}
													>
														{editadoPor === 'NO EDITADO' ? (
															<Typography
																fontSize={10}
																style={{ color: '#2979ff' }}
															>{`${editadoPor}`}</Typography>
														) : (
															<Typography fontSize={10}>
																{`${editadoPor.split(' ')[0]}  ${
																	editadoPor.split(' ')[1]
																}`}
																<Typography
																	fontSize={10}
																	style={{ color: '#2979ff' }}
																>{`Fecha ${moment(
																	fechaEdicion
																).format('L')}`}</Typography>
															</Typography>
														)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<hr />
							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										∑ Débito:
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(sumDebito)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										∑ Crédito:
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(sumCredito)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Diferencia:
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(sumDebito - sumCredito)}
									</Typography>
								</Stack>
							</Stack>
							<hr />
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReportGerencial
					ref={compRef}
					titulo={'Reporte Libro Diario'}
					fecha={`Fecha: ${moment().format('L')}`}
					rango={`Del ${moment(fechaDesde).format('L')} al ${moment(fechaHasta).format(
						'L'
					)}`}
					isLandscape={true}
				>
					<Container maxWidth="xl" sx={{ textAlign: 'center', marginTop: 1 }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<hr />

					<Container maxWidth="xl" sx={{ textAlign: 'center' }}>
						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Asiento
												</TableCell>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Referencia
												</TableCell>

												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Cuenta
												</TableCell>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Descripción
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Débito
												</TableCell>
												<TableCell
													align="center"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Crédito
												</TableCell>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Realizado Por
												</TableCell>
												<TableCell
													align="left"
													className="text-primary fw-bold"
													style={{ fontSize: 11 }}
												>
													Sucursal
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((row) => {
												const {
													detalle,
													fecha,
													id,
													realizadoPor,
													referencia,
													sucursal
												} = row;
												return (
													<TableRow key={row.id}>
														<TableCell
															align="center"
															component="th"
															scope="row"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															<Stack>{`N°: ${id}`}</Stack>
															{moment(fecha).format('L')}
														</TableCell>
														<TableCell
															align="left"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{referencia}
														</TableCell>

														<TableCell align="center">
															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableBody>
																		{detalle.map((i) => {
																			return (
																				<TableRow
																					key={i.id}
																				>
																					<TableCell
																						align="center"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 9,
																							borderBottomWidth: 2,
																							borderBottomColor:
																								'#2979ff'
																						}}
																						className="text-dark"
																					>
																						{
																							i.countNumber
																						}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>
														</TableCell>

														<TableCell align="left">
															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableBody>
																		{detalle.map((i) => {
																			return (
																				<TableRow
																					key={i.id}
																				>
																					<TableCell
																						align="left"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 8,
																							borderBottomWidth: 2,
																							borderBottomColor:
																								'#2979ff'
																						}}
																						className="text-dark"
																					>
																						{i.cuenta}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>
														</TableCell>

														<TableCell align="center">
															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableBody>
																		{detalle.map((i) => {
																			return (
																				<TableRow
																					key={i.id}
																				>
																					<TableCell
																						align="center"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 8,
																							borderBottomWidth: 2,
																							borderBottomColor:
																								'#2979ff'
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							i.debito
																						)}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>
														</TableCell>

														<TableCell align="center">
															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableBody>
																		{detalle.map((i) => {
																			return (
																				<TableRow
																					key={i.id}
																				>
																					<TableCell
																						align="center"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 8,
																							borderBottomWidth: 2,
																							borderBottomColor:
																								'#2979ff'
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							i.credito
																						)}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>
														</TableCell>

														<TableCell
															align="left"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{`${realizadoPor.split(' ')[0]}  ${
																realizadoPor.split(' ')[1]
															}`}
														</TableCell>

														<TableCell
															align="left"
															style={{ fontSize: 8 }}
															className="text-dark"
														>
															{sucursal}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>

								<hr />
								<Stack direction="row" flex="row" justifyContent="space-around">
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											∑ Débito:
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(sumDebito)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											∑ Crédito:
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(sumCredito)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Diferencia:
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(sumDebito - sumCredito)}
										</Typography>
									</Stack>
								</Stack>
								<hr />
							</>
						)}
					</Container>
				</PrintReportGerencial>
			</div>
		</div>
	);
};

export default ReporteLibroDiario;
