import axios from 'axios';
import { errorResponse } from '../helpers/Helpers';
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = '';

if (process.env.NODE_ENV === 'production') {
	Api = `${REACT_APP_PRODURL}clients`;
} else {
	Api = `${REACT_APP_URL}clients`;
}

export const getClientListAsync = async (token, sucursal) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClients/' + sucursal;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getClientsBysSucursalAccessAsync = async (token) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClientsBysucursalAccess';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getClientsBySucursalAccessAsync = async (token) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClientsBySucursalAccessAsync';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getClientByIdAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClientById/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getClientFullAddressAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClientFullAddress/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addClientAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddClient/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const updateClientAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/UpdateClient`;

	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteClientAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/DeleteClient/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service + id).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

//References
export const getClientReferencesAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetClientReferences/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addClientReferenceAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddClientReference`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const updateClientReferenceAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/UpdateClientReference`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const deleteClientReferenceAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/DeleteClientReference/`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service + id).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

//Credits
export const getCreditsByClientAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCreditsByClient/';
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url + id).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};
