import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomeContainer from '../pages/home/HomeContainer';
import AccountContainer from '../pages/account/AccountContainer';

import DefeatedCredits from '../pages/reports/reportesCartera/DefeatedCredits';
import AccountStatus from '../pages/reports/reportesCartera/AccountStatus';
import ServerRollist from '../pages/security/server/ServerRollist';
import CreditsCancelled from '../pages/reports/reportesCartera/CreditsCancelled';
import SucursalList from '../pages/settings/sucursals/SucursalList';
import PlazoList from '../pages/settings/Periodicidad/plazos/PlazoList';
import Contabilidad from '../pages/admon/contabilidad/Contabilidad';
import VencimientosProyectados from '../pages/reports/reportesCartera/VencimientosProyectados';
import ReporteBalanzaComprobacion from '../pages/reports/reportesAdministracion/ReporteBalanzaComprobacion';
import CashFlow from '../pages/admon/cashFlow/CashFlow';
import GastosList from '../pages/admon/gastos/GastosList';
import ReporteEstadoResultado from '../pages/reports/reportesAdministracion/ReporteEstadoResultado';
import ReporteGastosHistory from '../pages/reports/reportesAdministracion/ReporteGastosHistory';

import MainInteresCorriente from '../pages/settings/interesCorriente/MainInteresCorriente';
import PeriodicidadList from '../pages/settings/Periodicidad/PeriodicidadList';
import TasaCambioList from '../pages/settings/tasaCambio/TasaCambioList';
import ClientList from '../pages/clients/ClientList';
import AddClient from '../pages/clients/AddClient';
import { DetalleClientContainer } from '../pages/clients/detalleCliente/DetalleClientContainer';
import CreditLIst from '../pages/credits/CreditLIst';
import AddCredit from '../pages/credits/AddCredit';
import CreditDetailsContainer from '../pages/credits/creditDetails/CreditDetailsContainer';
import Userlist from '../pages/security/users/Userlist';
import RolList from '../pages/security/rols/RolList';
import AddUser from '../pages/security/users/AddUser';
import EditUser from '../pages/security/users/EditUser';
import AddRol from '../pages/security/rols/AddRol';
import EditRol from '../pages/security/rols/EditRol';
import HolidayList from '../pages/settings/holidays/HolidayList';
import Reports from '../pages/reports/Reports';

import MainLocations from '../pages/settings/locations/MainLocations';
import Municipalities from '../pages/settings/locations/Municipalities';
import { Communities } from '../pages/settings/locations/Communities';

import ClientesGestor from '../pages/reports/reportesCartera/ClientesGestor';
import CreditsByGestor from '../pages/reports/reportesCartera/CreditsByGestor';
import CobroDiario from '../pages/reports/reportesCartera/CobroDiario';
import Recuperacion from '../pages/reports/reportesCartera/Recuperacion';
import Desembolsos from '../pages/reports/reportesCartera/Desembolsos';
import SaldosCartera from '../pages/reports/reportesCartera/SaldosCartera';
import { getRuta } from '../helpers/Helpers';
import ReporteCashFlowHistory from '../pages/reports/reportesAdministracion/ReporteCashFlowHistory';
import ReporteLibroDiario from '../pages/reports/reportesAdministracion/ReporteLibroDiario';
import ReporteBalanceGeneral from '../pages/reports/reportesAdministracion/ReporteBalanceGeneral';
import Garantias from '../pages/reports/reportesCartera/Garantias';
import IngresoFinanciero from '../pages/reports/reportesCartera/IngresoFinanciero';

import EmployeeList from '../pages/rrhh/employees/EmployeeList';
import AddEmployee from '../pages/rrhh/employees/AddEmployee';
import CargoList from '../pages/rrhh/cargos/CargoList';
import { EmployeDetailsContainer } from '../pages/rrhh/employees/detalleEmployee/EmployeDetailsContainer';
import Cierre from '../pages/reports/reportesCartera/Cierre';
import NominaList from '../pages/rrhh/nomina/NominaList';
import NominaDetails from '../pages/rrhh/nomina/nominaDetails/NominaDetails';
import NominaEmployee from '../pages/rrhh/nomina/nominaDetails/NominaEmployee';
import ReportBalanceGeneralResum from '../pages/reports/reportesAdministracion/ReportBalanceGeneralResum';
import DashClients from '../pages/home/dashMiniReports/DashClients';
import DashActiveCredits from '../pages/home/dashMiniReports/DashActiveCredits';
import DashDefeatedCredits from '../pages/home/dashMiniReports/DashDefeatedCredits';
import DashCreditsNoDesembolsado from '../pages/home/dashMiniReports/DashCreditsNoDesembolsado';
import DashRecuperacionDiaria from '../pages/home/dashMiniReports/DashRecuperacionDiaria';
import DashOtrosIngresos from '../pages/home/dashMiniReports/DashOtrosIngresos';
import DashGastos from '../pages/home/dashMiniReports/DashGastos';
import DashMora from '../pages/home/dashMiniReports/DashMora';
import DashCancelados from '../pages/home/dashMiniReports/DashCancelados';
import DashCreditosNuevos from '../pages/home/dashMiniReports/DashCreditosNuevos';
import CreditsByBarrio from '../pages/reports/reportesCartera/CreditsByBarrio';
import ArqueoUser from '../pages/admon/arqueoUser/ArqueoUser';
import ArqueoUserAdd from '../pages/admon/arqueoUser/ArqueoUserAdd';
import ArqueoUserDetails from '../pages/admon/arqueoUser/ArqueoUserDetails';
import CashRegisterSucursal from '../pages/admon/cashRegister/CashRegisterSucursal';
import AddArqueoSucursal from '../pages/admon/cashRegister/arqueoCajaComponents/AddArqueoSucursal';
import ArqueoSucursalDetails from '../pages/admon/cashRegister/arqueoCajaComponents/ArqueoSucursalDetails';
import ReporteUserTracking from '../pages/reports/reportesAdministracion/ReporteUserTracking';

export const Rutas = () => {
	let rout = getRuta();

	return (
		<Routes>
			{/* Rulas Dash */}
			<Route path={`${rout}/`} element={<HomeContainer />} />
			<Route path={`${rout}/dash-clients`} element={<DashClients />} />
			<Route path={`${rout}/dash-activeCredits`} element={<DashActiveCredits />} />
			<Route path={`${rout}/dash-defeatedCredits`} element={<DashDefeatedCredits />} />
			<Route
				path={`${rout}/dash-creditsNoDesembolsados`}
				element={<DashCreditsNoDesembolsado />}
			/>
			<Route path={`${rout}/dash-recuperacionDiaria`} element={<DashRecuperacionDiaria />} />
			<Route path={`${rout}/dash-recuperacionDiaria`} element={<DashRecuperacionDiaria />} />
			<Route path={`${rout}/dash-otrosIngresos`} element={<DashOtrosIngresos />} />
			<Route path={`${rout}/dash-gastos`} element={<DashGastos />} />
			<Route path={`${rout}/dash-mora`} element={<DashMora />} />
			<Route path={`${rout}/dash-cancelados`} element={<DashCancelados />} />
			<Route path={`${rout}/dash-newCredits`} element={<DashCreditosNuevos />} />

			{/* Rulas Clientes */}
			<Route path={`${rout}/clients`} element={<ClientList />} />
			<Route path={`${rout}/addclient`} element={<AddClient />} />
			<Route path={`${rout}/client/:id`} element={<DetalleClientContainer />} />

			{/* Rulas Creditos */}
			<Route path={`${rout}/credits`} element={<CreditLIst />} />
			<Route path={`${rout}/credit/add/:id`} element={<AddCredit />} />
			<Route path={`${rout}/credit/:id`} element={<CreditDetailsContainer />} />

			{/* Rulas Admon */}
			<Route path={`${rout}/cashflow`} element={<CashFlow />} />

			<Route path={`${rout}/cashregister`} element={<CashRegisterSucursal />} />
			<Route path={`${rout}/arqueocajaAdd`} element={<AddArqueoSucursal />} />
			<Route
				path={`${rout}/arqueosucursal/details/:id`}
				element={<ArqueoSucursalDetails />}
			/>
			<Route path={`${rout}/arqueouser`} element={<ArqueoUser />} />
			<Route path={`${rout}/arqueouser/add/:id`} element={<ArqueoUserAdd />} />
			<Route path={`${rout}/arqueouser/details/:id`} element={<ArqueoUserDetails />} />

			<Route path={`${rout}/gastos`} element={<GastosList />} />
			<Route path={`${rout}/contabilidad`} element={<Contabilidad />} />

			{/* Rulas Nomina */}
			<Route path={`${rout}/employees`} element={<EmployeeList />} />
			<Route path={`${rout}/add-employees`} element={<AddEmployee />} />
			<Route path={`${rout}/employee/:id`} element={<EmployeDetailsContainer />} />
			<Route path={`${rout}/nomina`} element={<NominaList />} />
			<Route path={`${rout}/nominadetails/:id`} element={<NominaDetails />} />
			<Route path={`${rout}/nominadetails/:id/:ideployee`} element={<NominaEmployee />} />
			<Route path={`${rout}/puesto`} element={<CargoList />} />
			{/* 
    
      {/* Rulas Reportes */}
			<Route path={`${rout}/reports`} element={<Reports />} />
			<Route
				path={`${rout}/r-clientsgestor/:selectedSucursal/:selectedStatus/:selectedSortBy`}
				element={<ClientesGestor />}
			/>
			<Route
				path={`${rout}/r-garantias/:selectedSucursal/:tipoCredito/:estado`}
				element={<Garantias />}
			/>
			<Route
				path={`${rout}/r-creditsbyGestor/:selectedSucursal/:selectedGestor/:moneda/:tipoCredito`}
				element={<CreditsByGestor />}
			/>

			<Route
				path={`${rout}/r-creditsbyBarrio/:selectedSucursal/:selectedBarrio/:moneda/:tipoCredito`}
				element={<CreditsByBarrio />}
			/>
			<Route
				path={`${rout}/r-cobrodiario/:selectedSucursal/:selectedGestor/:selectedFecha/:moneda/:tipoCredito`}
				element={<CobroDiario />}
			/>
			<Route
				path={`${rout}/r-recuperacion/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta/:moneda/:tipoCredito`}
				element={<Recuperacion />}
			/>
			<Route
				path={`${rout}/r-ingresofinanciero/:selectedSucursal/:fechaDesde/:fechaHasta/:moneda/:tipoCredito`}
				element={<IngresoFinanciero />}
			/>
			<Route
				path={`${rout}/r-desembolsos/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta/:moneda/:tipoCredito`}
				element={<Desembolsos />}
			/>
			<Route
				path={`${rout}/r-saldoscartera/:selectedSucursal/:selectedGestor/:moneda/:tipoCredito`}
				element={<SaldosCartera />}
			/>
			<Route
				path={`${rout}/r-defeatedcredits/:selectedSucursal/:selectedGestor/:moneda/:tipoCredito`}
				element={<DefeatedCredits />}
			/>
			<Route
				path={`${rout}/r-proyecteddefeatedcredits/:selectedSucursal/:selectedGestor/:fechaHasta/:moneda/:tipoCredito`}
				element={<VencimientosProyectados />}
			/>
			<Route path={`${rout}/r-accountstatus/:selectedCredit`} element={<AccountStatus />} />

			<Route
				path={`${rout}/r-creditscancelled/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta/:moneda/:tipoCredito/:renovados`}
				element={<CreditsCancelled />}
			/>
			<Route
				path={`${rout}/r-cierre/:selectedSucursal/:selectedPeriodicidad/:moneda/:fechaDesde/:fechaHasta`}
				element={<Cierre />}
			/>

			{/* Reportes Administrativos */}
			<Route
				path={`${rout}/r-balanzacomprobacion/:selectedSucursal/:fechaDesde/:fechaHasta/:presentacion/:cuentas0`}
				element={<ReporteBalanzaComprobacion />}
			/>

			<Route
				path={`${rout}/r-estadoresultado/:selectedSucursal/:fechaDesde/:fechaHasta/:cuentas0`}
				element={<ReporteEstadoResultado />}
			/>

			<Route
				path={`${rout}/r-gastoshistory/:selectedSucursal/:fechaDesde/:fechaHasta`}
				element={<ReporteGastosHistory />}
			/>

			<Route
				path={`${rout}/r-cashflowhistory/:selectedSucursal/:selectedUser/:fechaDesde/:fechaHasta`}
				element={<ReporteCashFlowHistory />}
			/>
			<Route
				path={`${rout}/r-librodiariohistory/:selectedSucursal/:fechaDesde/:fechaHasta`}
				element={<ReporteLibroDiario />}
			/>
			<Route
				path={`${rout}/r-balancegral/:selectedSucursal/:selectedYear/:selectedMonth/:presentacion/:cuentas0`}
				element={<ReporteBalanceGeneral />}
			/>
			<Route
				path={`${rout}/r-usertracking/:selectedSucursal/:selectedGestor/:selectedFecha`}
				element={<ReporteUserTracking />}
			/>

			{/* Rulas Security-users */}
			<Route path={`${rout}/users`} element={<Userlist />} />
			<Route path={`${rout}/adduser`} element={<AddUser />} />
			<Route path={`${rout}/user/:id`} element={<EditUser />} />

			{/* Rulas Security-rols */}
			<Route path={`${rout}/rols`} element={<RolList />} />
			<Route path={`${rout}/addrol`} element={<AddRol />} />
			<Route path={`${rout}/rol/:id`} element={<EditRol />} />

			{/* Rulas Security-server */}
			<Route path={`${rout}/server`} element={<ServerRollist />} />
			<Route path={`${rout}/account`} element={<AccountContainer />} />

			{/* Rulas Ubicaciones */}
			<Route path={`${rout}/sucursales`} element={<SucursalList />} />
			<Route path={`${rout}/locations`} element={<MainLocations />} />
			<Route path={`${rout}/department/:id`} element={<Municipalities />} />
			<Route path={`${rout}/locations/municipalities/:id`} element={<Communities />} />
			<Route path={`${rout}/locations/municipalities/:id`} element={<Communities />} />

			<Route path={`${rout}/tasa-interes`} element={<MainInteresCorriente />} />
			<Route path={`${rout}/periodicidad`} element={<PeriodicidadList />} />
			<Route path={`${rout}/plazosbyperiodicidadid/:id`} element={<PlazoList />} />
			<Route path={`${rout}/holidays`} element={<HolidayList />} />
			<Route path={`${rout}/tasa-cambio`} element={<TasaCambioList />} />
		</Routes>
	);
};
