import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from 'mapbox-gl';

// mapboxgl.accessToken =
// 	'pk.eyJ1IjoibWFlc3Bpbm96YSIsImEiOiJjbHZ2c25zY3kwNW1pMmpxc3RveDJ0bzFxIn0.BgOLD5R8vpKVBQ9zX6rskg';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<DataProvider>
				<App />
			</DataProvider>
		</BrowserRouter>
	</React.StrictMode>
);
