import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Container,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";
import {
  getGestoresAsync,
  getGestoresBySucursalAccessAsync,
  getSucursalByUserAsync,
} from "../../../../api/Users";
import { getRuta, simpleMessage } from "../../../../helpers/Helpers";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";

const SelectorDesembolsos = () => {
  const [gestorList, setGestorList] = useState([]);
  const [selectedGestor, setSelectedGestor] = useState("t");

  var date = new Date();
  const [fechaDesde, setFechaDesde] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [fechaHasta, setFechaHasta] = useState(date);

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("t");

  const [moneda, setMoneda] = useState(1);
  const [tipoCredito, setTipoCredito] = useState("t");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();

  useEffect(() => {
    getGestores(selectedSucursal);
    getSucursals();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
    getGestores(value);
  };

  const getGestores = async (sucursal) => {
    setIsLoading(true);
    const result =
      sucursal === "t"
        ? await getGestoresBySucursalAccessAsync(token)
        : await getGestoresAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setGestorList(result.data.result);
  };

  const verReport = () => {
    if (selectedGestor.length === 0) {
      simpleMessage("Seleccione un gestor", "error");
      return;
    }
    if (!moment(fechaDesde).isValid()) {
      simpleMessage("Seleccione una fecha inicial válida", "error");
      return;
    }
    if (!moment(fechaHasta).isValid()) {
      simpleMessage("Seleccione una fecha final válida", "error");
      return;
    }

    var params = `${selectedSucursal}/${selectedGestor}/${moment(
      fechaDesde
    ).format("YYYY-MM-DD")}/${moment(fechaHasta).format("YYYY-MM-DD")}`;
    window.open(`${ruta}/r-desembolsos/${params}/${moneda}/${tipoCredito}`);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => onChangeSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
                <MenuItem key={"t"} value={"t"}>
                  TODAS...
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ textAlign: "left", marginTop: 20 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Gestores
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setSelectedGestor(e.target.value)}
                value={selectedGestor}
                label="Gestores"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione un Gestor</em>
                </MenuItem>
                {gestorList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fullName}
                    </MenuItem>
                  );
                })}
                <MenuItem value={"t"}>TODOS...</MenuItem>
              </Select>
            </FormControl>

            <Stack spacing={2} direction={"row"}>
              <DesktopDatePicker
                closeOnSelect
                label="Desde"
                inputFormat="DD/MM/YYYY"
                value={fechaDesde}
                onChange={(newValue) => setFechaDesde(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                closeOnSelect
                label="Hasta"
                inputFormat="DD/MM/YYYY"
                value={fechaHasta}
                onChange={(newValue) => setFechaHasta(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>

            <Stack spacing={2} direction="row">
              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Moneda
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => setMoneda(e.target.value)}
                  value={moneda}
                  label="Moneda"
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="">
                    <em>Seleccione una Opción</em>
                  </MenuItem>

                  <MenuItem key={1} value={1}>
                    CORDOBAS C$
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    DOLARES $
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de Cédito
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => setTipoCredito(e.target.value)}
                  value={tipoCredito}
                  label="tipo de crédito"
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="">
                    <em>Seleccione una Opción</em>
                  </MenuItem>

                  <MenuItem key={0} value={1}>
                    EMPEÑO
                  </MenuItem>
                  <MenuItem key={1} value={2}>
                    CREDITO
                  </MenuItem>
                  <MenuItem key={"t"} value={"t"}>
                    TODOS...
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => verReport()}
              style={{
                color: "#4caf50",
                borderColor: "#4caf50",
              }}
              size="large"
            >
              Generar Reporte
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default SelectorDesembolsos;
