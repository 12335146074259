import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  Divider,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getCierreAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { getSucursalByUserAsync } from "../../../api/Users";

import * as XLSX from "xlsx/xlsx.mjs";
import { getPeriodicidadListAsync } from "../../../api/Periodicidad";
const Cierre = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const [periodicidadName, setPeriodicidadName] = useState("");
  const [periodicidadList, setPeriodicidadList] = useState([]);

  const compRef = useRef();
  const {
    selectedSucursal,
    selectedPeriodicidad,
    moneda,
    fechaDesde,
    fechaHasta,
  } = useParams();
  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let sucursals = await getSucursals();
      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        periodicidadId: selectedPeriodicidad === "t" ? 0 : selectedPeriodicidad,
        moneda,
        desde: fechaDesde,
        hasta: fechaHasta,
      };

      const result = await getCierreAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    let periodicidad = await getPeriodicidad(selectedSucursal);

    if (selectedPeriodicidad === "t") {
      setPeriodicidadName("Todas");
    } else {
      let name = periodicidad.filter((item) => {
        return item.id.toString() === selectedPeriodicidad;
      });

      setPeriodicidadName(name[0].fullName);
    }
    return result.data.result;
  };

  const getPeriodicidad = async () => {
    setIsLoading(true);
    const result = await getPeriodicidadListAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setPeriodicidadList(result.data.result);
    return result.data.result;
  };

  const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  
  @page { size: landscape;  margin: 3mm; }
`;

  const exportExcel = () => {
    let exp = [];
    data.map((item) => {
      const {
        aColectar,
        atrasoDias,
        capital,
        cuotaPagar,
        isDolar,
        cuotasAtrasadas,
        cuotasPagada,
        cuotasPendiente,
        fullName,
        id,
        interes,
        montoEntregado,
        numeroCredito,
        saldoCapital,
        saldoCapitalCierre,
        periocidad,
        sucursal,
        tipo,
      } = item;
      let obj = {
        numeroCredito,
        cliente: fullName,
        montoEntregado,
        saldoPrincipal: saldoCapital,
        cuotaPagar,
        cuotasPagada,
        cuotasPendiente,
        cuotasAtrasadas,
        estado: tipo,
        diasAtraso: atrasoDias,
        interes,
        capital,
        aRecuperar: aColectar,
        saldoCapitalCierre,
        isDolar,
        periocidad: periocidad.description,
        sucursal: sucursal.description,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "cierre");
    XLSX.writeFile(bk, "ReporteCierre.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Cierre
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton variant="outlined">
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Parametros:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Sucursal: ${
                  selectedSucursal === "t" ? "Todas" : sucursalName
                }`}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Periodicidad Seleccionada: ${
                  selectedPeriodicidad === "t" ? "Todos" : periodicidadName
                }`}
              </Typography>

              <Typography fontSize={11} textAlign="left">
                {`Moneda: ${parseInt(moneda) === 1 ? "Cordoba" : "Dolar"} `}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Usuario:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {user}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {rol}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
              </Typography>
            </Stack>
          </Stack>
        </Container>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <Stack>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter((c) => c.sucursal.id === suc.id);

                return (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>

                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>

                    {periodicidadList.map((per) => {
                      const cPeriodicidad = sSucursal.filter(
                        (c) => c.periocidad.id === per.id
                      );

                      let entregado = 0;
                      let saldoPrincipal = 0;
                      let sumInteres = 0;
                      let sumCapital = 0;
                      let sumArecuperar = 0;
                      let saldoCierre = 0;

                      cPeriodicidad.map((c) => {
                        const {
                          montoEntregado,
                          saldoCapital,
                          saldoCapitalCierre,
                          interes,
                          capital,
                          aColectar,
                        } = c;
                        entregado += montoEntregado;
                        saldoPrincipal += saldoCapital;
                        sumInteres += interes;
                        sumCapital += capital;
                        sumArecuperar += aColectar;
                        saldoCierre += saldoCapitalCierre;
                      });

                      return isEmpty(cPeriodicidad) ? (
                        <></>
                      ) : (
                        <Stack padding={1} key={per.id}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              sx={{
                                color: "#2196f3",
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              Periodicidad:
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              {`${per.description}`}
                            </Typography>
                          </Stack>

                          <Divider />

                          <TableContainer>
                            <Table aria-label="simple table" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Cliente
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Monto Entregado
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Saldo Principal
                                  </TableCell>
                                  <Tooltip title="Cuota a Pagar">
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 12 }}
                                    >
                                      C. Pagar
                                    </TableCell>
                                  </Tooltip>

                                  <Tooltip title="Cuotas Pagadas">
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 12 }}
                                    >
                                      C. Pagadas
                                    </TableCell>
                                  </Tooltip>

                                  <Tooltip title="Cuotas Pendientes">
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 12 }}
                                    >
                                      C. Pendientes
                                    </TableCell>
                                  </Tooltip>

                                  <Tooltip title="Cuotas Atrasadas">
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 12 }}
                                    >
                                      C. Atrasadas
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Estado
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Días Atraso
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Interes
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Capital
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    A Recuperar
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 12 }}
                                  >
                                    Saldo Cierre
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {cPeriodicidad.map((row) => {
                                  const {
                                    aColectar,
                                    atrasoDias,
                                    capital,
                                    cuotaPagar,
                                    isDolar,
                                    cuotasAtrasadas,
                                    cuotasPagada,
                                    cuotasPendiente,
                                    fullName,
                                    id,
                                    interes,
                                    montoEntregado,
                                    numeroCredito,
                                    saldoCapital,
                                    saldoCapitalCierre,
                                    tipo,
                                  } = row;
                                  return (
                                    <TableRow key={id}>
                                      <TableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 11 }}
                                      >
                                        {numeroCredito}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        style={{ fontSize: 11 }}
                                      >
                                        {fullName}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(montoEntregado)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(saldoCapital)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(cuotaPagar)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {cuotasPagada}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {cuotasPendiente}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {cuotasAtrasadas}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {tipo}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {atrasoDias}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(interes)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(capital)}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(aColectar)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 11 }}
                                      >
                                        {new Intl.NumberFormat(
                                          isDolar ? "en-US" : "es-NI",
                                          {
                                            style: "currency",
                                            currency: isDolar ? "USD" : "NIO",
                                          }
                                        ).format(saldoCapitalCierre)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <hr />

                          <Stack
                            direction="row"
                            flex="row"
                            justifyContent="space-around"
                          >
                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Creditos
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {cPeriodicidad.length}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Entregado
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(entregado)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Saldo Principal
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(saldoPrincipal)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Interes
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(sumInteres)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Capital
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(sumCapital)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total A Recuperar
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(sumArecuperar)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Saldo a Cierre
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat(
                                  parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                  {
                                    style: "currency",
                                    currency:
                                      parseInt(moneda) === 2 ? "USD" : "NIO",
                                  }
                                ).format(saldoCierre)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <hr />
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Cierre"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Periodicidad Seleccionada: ${
                    selectedPeriodicidad === "t" ? "Todos" : periodicidadName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Moneda: ${parseInt(moneda) === 1 ? "Cordoba" : "Dolar"} `}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth="xl" sx={{ textAlign: "center" }}>
            <hr />
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal.id === suc.id
                  );

                  return (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>

                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>

                      {periodicidadList.map((per) => {
                        const cPeriodicidad = sSucursal.filter(
                          (c) => c.periocidad.id === per.id
                        );

                        let entregado = 0;
                        let saldoPrincipal = 0;
                        let sumInteres = 0;
                        let sumCapital = 0;
                        let sumArecuperar = 0;
                        let saldoCierre = 0;

                        cPeriodicidad.map((c) => {
                          const {
                            montoEntregado,
                            saldoCapital,
                            saldoCapitalCierre,
                            interes,
                            capital,
                            aColectar,
                          } = c;
                          entregado += montoEntregado;
                          saldoPrincipal += saldoCapital;
                          sumInteres += interes;
                          sumCapital += capital;
                          sumArecuperar += aColectar;
                          saldoCierre += saldoCapitalCierre;
                        });

                        return isEmpty(cPeriodicidad) ? (
                          <></>
                        ) : (
                          <Stack padding={1} key={per.id}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                sx={{
                                  color: "#2196f3",
                                  textAlign: "left",
                                }}
                                fontSize={11}
                              >
                                Periodicidad:
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={11}
                              >
                                {`${per.description}`}
                              </Typography>
                            </Stack>

                            <Divider />

                            <TableContainer>
                              <Table aria-label="simple table" size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      #
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Cliente
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Monto Entregado
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Saldo Principal
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Cuota Pagar
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Cuotas Pagadas
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Cuotas Pendientes
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Estado
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Interes
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Capital
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      A Recuperar
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Saldo Cierre
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {cPeriodicidad.map((row) => {
                                    const {
                                      aColectar,
                                      atrasoDias,
                                      capital,
                                      cuotaPagar,
                                      isDolar,
                                      cuotasAtrasadas,
                                      cuotasPagada,
                                      cuotasPendiente,
                                      fullName,
                                      id,
                                      interes,
                                      montoEntregado,
                                      numeroCredito,
                                      saldoCapital,
                                      saldoCapitalCierre,
                                      tipo,
                                    } = row;
                                    return (
                                      <TableRow key={id}>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {numeroCredito}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {fullName}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(montoEntregado)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(saldoCapital)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(cuotaPagar)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {cuotasPagada}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {cuotasPendiente}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          <div>{`${cuotasAtrasadas}C ${tipo}`}</div>
                                          <div>{`${atrasoDias} Días`}</div>
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(interes)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(capital)}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(aColectar)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-dark"
                                          style={{ fontSize: 9 }}
                                        >
                                          {new Intl.NumberFormat(
                                            isDolar ? "en-US" : "es-NI",
                                            {
                                              style: "currency",
                                              currency: isDolar ? "USD" : "NIO",
                                            }
                                          ).format(saldoCapitalCierre)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <hr />

                            <Stack
                              direction="row"
                              flex="row"
                              justifyContent="space-around"
                            >
                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Creditos
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {cPeriodicidad.length}
                                </Typography>
                              </Stack>
                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Entregado
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(entregado)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Saldo Principal
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(saldoPrincipal)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Interes
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(sumInteres)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Capital
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(sumCapital)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total A Recuperar
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(sumArecuperar)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Saldo a Cierre
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat(
                                    parseInt(moneda) === 2 ? "en-US" : "es-NI",
                                    {
                                      style: "currency",
                                      currency:
                                        parseInt(moneda) === 2 ? "USD" : "NIO",
                                    }
                                  ).format(saldoCierre)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <hr />
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default Cierre;
