import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

import SmallModal from "../../../components/modals/SmallModal";
import Swal from "sweetalert2";
import {
  deleteHolidayAsync,
  getHolidaysByYearAsync,
} from "../../../api/Holiday";
import AddHoliday from "./AddHoliday";
import moment from "moment/moment";

const HolidayList = () => {
  const [holidayList, setHolidayList] = useState([]);
  const [localReload, setLocalReload] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [searchTerm] = useState("");
  const withSearch = holidayList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.description.toString().includes(searchTerm)) {
      return val;
    }
  });

  const { access, setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getHolidaysByYearAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setHolidayList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = holidayList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deleteHoliday = async (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteHolidayAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "eX01") {
              deleteUserData();
              deleteToken();
              setIsLogged(false);
              return;
            }
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Tasa de Interes Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Días Festivos
            </Typography>
            {isAccess(access, "CONFIG CREATE") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar dia Festivo
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />
          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>

                      <TableCell align="left" className="text-primary fw-bold">
                        Sucursales
                      </TableCell>
                      {isAccess(access, "CONFIG DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Eliminar
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const { description, festivo, id, sucursals } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="center" component="th">
                            {moment(festivo).format("L")}
                          </TableCell>
                          <TableCell align="left" component="th">
                            {description}
                          </TableCell>
                          <TableCell align="left" component="th">
                            <Stack spacing={1} direction="row">
                              {sucursals.map((i) => {
                                return <Chip label={i.description} />;
                              })}
                            </Stack>
                          </TableCell>
                          {isAccess(access, "CONFIG DELETE") ? (
                            <TableCell align="center">
                              <IconButton
                                style={{
                                  color: "#f50057",
                                  width: 40,
                                  height: 40,
                                }}
                                onClick={() => deleteHoliday(id)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Feriado"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddHoliday
          setShowModal={setShowModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default HolidayList;
