import React, { useState, useContext } from "react";
import { Paper, Container, Button, Stack, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";

import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";

import { simpleMessage } from "../../../../helpers/Helpers";
import { updateAdelantoAsync } from "../../../../api/Employees";

const UpdateAdelanto = ({ selected, setShowModal, reload, setReload }) => {
  const [monto, setMonto] = useState(selected.montoAdelanto);
  const [fecha, setFecha] = useState(new Date(selected.fechaCreacion));

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const token = getToken();

  const saveChanges = async () => {
    if (monto.length === 0) {
      simpleMessage("Seleccione un monto", "error");
      return;
    }
    if (!moment(fecha).isValid()) {
      simpleMessage("Seleccione una fecha válida", "error");
      return;
    }

    const data = {
      id: selected.id,
      monto,
      fechaCreacion: moment(fecha).format("YYYY-MM-DD"),
    };

    setIsLoading(true);
    const result = await updateAdelantoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Adelanto Editado...!", "success");
    setReload(!reload);
    setShowModal(false);
  };

  //Devuelve un entero positivo
  const funtionSetMonto = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMonto(value);
      return;
    }
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <DesktopDatePicker
              closeOnSelect
              label="FechaAdelanto"
              inputFormat="DD/MM/YYYY"
              value={fecha}
              onChange={(newValue) => setFecha(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <TextField
              required
              fullWidth
              onChange={(e) => funtionSetMonto(e.target.value)}
              value={monto}
              label={"Monto"}
            />

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => saveChanges()}
              style={{
                borderRadius: 30,
                color: "#4caf50",
                borderColor: "#4caf50",
                padding: 12,
              }}
              size="large"
            >
              Actualizar Adelanto
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default UpdateAdelanto;
