import React, { useState, useContext } from "react";
import { TextField, Divider, Container, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

import { simpleMessage } from "../../../../helpers/Helpers";
import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";
import { updateFechaPagoAsync } from "../../../../api/Credits";

const UpdateFechaPago = ({
  data,
  setShowModal,
  localReload,
  setLocalReload,
}) => {
  const { fechaPago, id } = data;

  const [fecha, setFecha] = useState(fechaPago);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const saveChangesAsync = async () => {
    if (!moment(fecha).isValid()) {
      simpleMessage("Seleccione una fecha válida...", "error");
      return;
    }
    if (moment(fecha).format("L") === moment(fechaPago).format("L")) {
      simpleMessage("Seleccione una fecha diferente...", "error");
      return;
    }

    const data = {
      id,
      newfechaPago: moment(fecha).format("YYYY-MM-DD").toString(),
    };

    setIsLoading(true);
    const result = await updateFechaPagoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Fecha de pago actualizada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <DesktopDatePicker
          closeOnSelect
          label="Fecha de Cuota"
          inputFormat="DD/MM/YYYY"
          value={fecha}
          onChange={(newValue) => setFecha(newValue)}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            marginTop: 30,
            borderRadius: 20,
            color: "#ff9100",
            borderColor: "#ff9100",
          }}
          size="large"
        >
          Actualizar plan de pagos
        </Button>
      </Container>
    </div>
  );
};

export default UpdateFechaPago;
