import React, { useState, useEffect, useContext } from 'react';
import {
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	IconButton,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import { DataContext } from '../../../context/DataContext';
import { isEmpty } from 'lodash';
import { getSucursalByUserAsync } from '../../../api/Users';
import { getToken } from '../../../api/Account';
import { simpleMessage } from '../../../helpers/Helpers';
import { updateAsientoContableAsync } from '../../../api/Contabilidad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExternalLink,
	faPlusCircle,
	faSave,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SmallModal from '../../../components/modals/SmallModal';
import EditDetalleAsientoContable from './EditDetalleAsientoContable';
import moment from 'moment';
import AdddetalleAsientoContable from './AdddetalleAsientoContable';
import Swal from 'sweetalert2';

const EditAsientoContable = ({ setLocalReload, localReload, setShowModal, selectedAsientoC }) => {
	const [referencia, setReferencia] = useState(selectedAsientoC.referencia);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSuc, setSelectedSuc] = useState(selectedAsientoC.sucursal.id);
	const [selectedCuenta, setSelectedCuenta] = useState(null);
	const [detalleAsiento, setDetalleAsiento] = useState([]);
	const [selectedDetalle, setSelectedDetalle] = useState('');
	const [fechaAsiento, setFechaAsiento] = useState(new Date(selectedAsientoC.fecha));
	const [isAsientoCierre, setIsAsientoCierre] = useState(selectedAsientoC.isCierre);

	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	const token = getToken();

	useEffect(() => {
		(async () => {
			setSelectedSuc(await getSucursals());
			//   await getCatalogoCuentas();
			asientoConverter(selectedAsientoC.asientoContableDetails);
		})();
	}, [localReload]);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);

		return selectedSuc;
	};

	const saveChangesAsync = async () => {
		if (referencia.length === 0) {
			simpleMessage('Agrege una referencia...', 'error');
			return;
		}

		if (selectedSuc.length === 0) {
			simpleMessage('Seleccione una sucursal...', 'error');
			return;
		}
		if (isEmpty(detalleAsiento)) {
			simpleMessage('Agrege el detalle del asiento...', 'error');
			return;
		}
		const data = {
			id: selectedAsientoC.id,
			referencia,
			fechaAsiento: moment(fechaAsiento).format('YYYY-MM-DD'),
			sucursalId: selectedSuc,
			isAsientoCierre,
			asientoContableDetails: detalleAsiento
		};

		setIsLoading(true);
		const result = await updateAsientoContableAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		simpleMessage('Asiento actualizado...!', 'success');
		setLocalReload(!localReload);
		setShowModal(false);
	};

	const asientoConverter = (detalle) => {
		let source = [];
		detalle.map((item) => {
			const data = {
				num: source.length + 1,
				cuenta: item.cuenta.countNumber,
				descripcion: item.cuenta.descripcion,
				debito: item.debito,
				credito: item.credito,
				cuentaContableId: item.cuenta.id
			};

			source.push(data);
		});

		const list = source;

		setDetalleAsiento(list);
	};

	const deleteDetail = async (id) => {
		Swal.fire({
			title: '¿Confirmar Eliminar?',
			// text: "...!",
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				let detalle = detalleAsiento.filter((item) => item.num !== id);
				setDetalleAsiento(detalle);
			}
		});
	};

	return (
		<div>
			<Paper
				style={{
					padding: 20,
					textAlign: 'center'
				}}
			>
				<Stack direction="row" justifyContent="space-between" spacing={1}>
					<FormControl fullWidth style={{ textAlign: 'left' }}>
						<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							onChange={(event, newValue) => {
								if (newValue.nivel < 5) {
									simpleMessage(
										'Debe seleccionar una cuenta de detalle',
										'error'
									);
									return;
								}
								setSelectedCuenta(newValue);
							}}
							value={selectedSuc}
							label="Sucursal"
						>
							{sucursalList.map((item) => {
								return (
									<MenuItem key={item.id} value={item.id}>
										{item.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<DesktopDatePicker
						closeOnSelect
						label="Fecha Asiento"
						inputFormat="DD/MM/YYYY"
						value={fechaAsiento}
						onChange={(newValue) => setFechaAsiento(newValue)}
						renderInput={(params) => <TextField fullWidth {...params} />}
					/>

					<FormControlLabel
						labelPlacement="start"
						control={
							<Checkbox
								checked={isAsientoCierre}
								onChange={() => setIsAsientoCierre(!isAsientoCierre)}
							/>
						}
						label="Es de cierre"
					/>
				</Stack>

				<TextField
					fullWidth
					style={{ marginTop: 20 }}
					required
					onChange={(e) => setReferencia(e.target.value.toUpperCase())}
					label={'Referencia'}
					value={referencia}
				/>

				<Stack direction="row" justifyContent="space-between" spacing={1} marginTop={1}>
					<Typography
						textAlign={'center'}
						color={'#00a152'}
						variant="h6"
						fontWeight={'bold'}
					>
						Detalle
					</Typography>

					<Button
						variant="outlined"
						startIcon={
							<FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 20 }} />
						}
						fullWidth
						onClick={() => setShowAddModal(true)}
						style={{
							borderRadius: 20,
							color: '#ffc400',
							borderColor: '#ffc400',
							width: 300
						}}
						size="large"
					>
						Agregar detalle
					</Button>
				</Stack>

				<hr />

				<TableContainer>
					<Table aria-label="simple table" size="small">
						<TableHead>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Cuenta
							</TableCell>
							<TableCell
								align="left"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Descripción
							</TableCell>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Débito
							</TableCell>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Crédito
							</TableCell>
							<TableCell
								align="center"
								className="text-primary fw-bold"
								style={{ fontSize: 12 }}
							>
								Acciones
							</TableCell>
						</TableHead>

						<TableBody>
							{detalleAsiento.map((row) => {
								return (
									<TableRow key={row.num}>
										<TableCell
											align="center"
											component="th"
											scope="row"
											style={{
												fontSize: 12
											}}
										>
											{row.cuenta}
										</TableCell>

										<TableCell
											align="left"
											style={{
												fontSize: 12
											}}
										>
											{row.descripcion}
										</TableCell>
										<TableCell
											align="center"
											style={{
												fontSize: 12
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(row.debito)}
										</TableCell>
										<TableCell
											align="center"
											style={{
												fontSize: 12
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(row.credito)}
										</TableCell>

										<TableCell align="center">
											<IconButton
												style={{
													color: '#ffc400'
												}}
												onClick={() => {
													setShowEditModal(true);
													setSelectedDetalle(row);
												}}
											>
												<FontAwesomeIcon icon={faExternalLink} />
											</IconButton>
											<IconButton
												style={{
													color: '#f50057'
												}}
												onClick={() => {
													deleteDetail(row.num);
												}}
											>
												<FontAwesomeIcon icon={faTrashAlt} />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<Button
					variant="outlined"
					startIcon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />}
					fullWidth
					onClick={() => saveChangesAsync()}
					style={{
						marginTop: 30,
						borderRadius: 20,
						color: '#ffc400',
						borderColor: '#ffc400'
					}}
					size="large"
				>
					Editar asiento
				</Button>
			</Paper>

			<SmallModal
				titulo={'Agregar Detalle'}
				isVisible={showAddModal}
				setVisible={setShowAddModal}
			>
				<AdddetalleAsientoContable
					setShowModal={setShowAddModal}
					selectedCuenta={selectedCuenta}
					setSelectedCuenta={setSelectedCuenta}
					detalleAsiento={detalleAsiento}
					setDetalleAsiento={setDetalleAsiento}
				/>
			</SmallModal>

			<SmallModal
				titulo={'Editar Detalle'}
				isVisible={showEditModal}
				setVisible={setShowEditModal}
			>
				<EditDetalleAsientoContable
					setShowModal={setShowEditModal}
					selectedDetalle={selectedDetalle}
					selectedCuenta={selectedCuenta}
					setSelectedCuenta={setSelectedCuenta}
					detalleAsiento={detalleAsiento}
					setDetalleAsiento={setDetalleAsiento}
				/>
			</SmallModal>
		</div>
	);
};

export default EditAsientoContable;
