import React, { useState } from "react";
import { IconButton, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallModal from "./modals/SmallModal";

const CallerSelector = ({ icon, text, modalTitle, children }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Paper
        elevation={10}
        style={{
          textAlign: "center",
          padding: 15,
        }}
      >
        <IconButton
          onClick={() => setShowModal(true)}
          sx={{
            border: 1,
            borderColor: "rgba(54, 162, 235, 1)",
            p: 2,
            mb: 1,
          }}
          style={{
            backgroundColor: "rgba(54, 162, 235, 0.2)",
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{ color: "rgba(54, 162, 235, 1)", width: 50, height: 50 }}
          />
        </IconButton>
        <hr />
        <Typography
          fontSize={18}
          fontWeight={"bold"}
          style={{ color: "rgba(54, 162, 235, 1)" }}
        >
          {text}
        </Typography>
        <SmallModal
          titulo={modalTitle}
          isVisible={showModal}
          setVisible={setShowModal}
        >
          {children}
        </SmallModal>
      </Paper>
    </div>
  );
};

export default CallerSelector;
