import React, { useState, useContext } from "react";
import {
  TextField,
  Divider,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import { simpleMessage } from "../../../../helpers/Helpers";
import { DataContext } from "../../../../context/DataContext";
import { deleteToken, deleteUserData, getToken } from "../../../../api/Account";
import { Save } from "@mui/icons-material";
import { updateCommunityAsync } from "../../../../api/Locations";

export const CommunityEdit = ({ selectedCommunity, setShowModal }) => {
  const token = getToken();

  const { setIsLoading, reload, setReload, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const { id, name } = selectedCommunity;

  const [newName, setNewName] = useState(name);

  const saveChangesAsync = async () => {
    const data = {
      id,
      name: newName,
    };
    if (name === newName) {
      simpleMessage("Ingrese un nombre diferente...", "error");
      return;
    }
    const result = await updateCommunityAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    if (result.data === null) {
      setIsLoading(false);
      simpleMessage("Comunidad no encontrada", "error");
      return;
    }

    setReload(!reload);
    setIsLoading(false);
    simpleMessage("Cambio realizado...!", "success");
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <Divider />
        <TextField
          fullWidth
          style={{ marginTop: 20, marginBottom: 20 }}
          variant="standard"
          onChange={(e) => setNewName(e.target.value.toUpperCase())}
          value={newName}
          label={"Nombre Comunidad"}
          placeholder={"Ingrese Nuevo Nombre"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={"editar"}>
                  <IconButton
                    onClick={() => {
                      saveChangesAsync();
                    }}
                  >
                    <Save style={{ color: "#ff5722" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};
