import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import moment from 'moment';
import { getSucursalByUserAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';
import { getBalanceGralAsync } from '../../../api/AdmonReport';
import { PrintReportGerencial } from '../../../components/PrintReportGerencial';

const ReporteBalanceGeneral = () => {
	const [data, setData] = useState([]);
	const [sucursalName, setSucursalName] = useState('');

	const compRef = useRef();
	const { selectedSucursal, selectedYear, selectedMonth, presentacion, cuentas0 } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				year: selectedYear,
				month: selectedMonth,
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal,
				isResumido: presentacion === '2',
				incluideCuentas0: cuentas0 === '1'
			};

			setIsLoading(true);
			const result = await getBalanceGralAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		return result.data.result;
	};

	const getPC = () => {
		let totalPasivos = 0;
		let totalCapital = 0;
		data.map((item) => {
			if (item.cuenta.countNumber === '2') {
				totalPasivos = item.total;
			}
			if (item.cuenta.countNumber === '3') {
				totalCapital = item.total;
			}
		});
		return totalPasivos + totalCapital;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 8mm; }
`;

	let fechaDesde = new Date(selectedYear, selectedMonth - 1, 1);
	let fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontWeight: 'bold'
						}}
					>
						{slogan}
					</Typography>

					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontWeight: 'bold'
						}}
						variant="h6"
						component="div"
					>
						Balance General
					</Typography>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parámetros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Stack direction={'row'} spacing={1}>
									<Typography fontSize={11} textAlign="left">
										{`Desde: ${moment(fechaDesde).format('L')} Hasta: ${moment(
											fechaHasta
										).format('L')} `}
									</Typography>
								</Stack>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<ReactToPrint
						trigger={() => {
							return (
								<IconButton
									variant="outlined"
									style={{ position: 'fixed', right: 20, top: 75 }}
								>
									<PrintRoundedIcon
										style={{ fontSize: 35, color: '#2979ff', width: 35 }}
									/>
								</IconButton>
							);
						}}
						content={() => compRef.current}
						pageStyle={pageStyle}
					/>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />

					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												No de Cuenta
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Descripción de Cuenta
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Sub - Total
											</TableCell>
											<TableCell
												align="center"
												style={{ fontSize: 14 }}
												className="text-primary fw-bold"
											>
												Total
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map((row) => {
											const { cuenta, subtotal, total } = row;

											return (
												<TableRow key={cuenta.id}>
													<TableCell
														align="left"
														component="th"
														scope="row"
														style={{ fontSize: 12 }}
													>
														{cuenta.countNumber}
													</TableCell>
													<TableCell align="left">
														<Typography
															style={{
																fontSize: 12,
																marginLeft: cuenta.nivel * 10
															}}
														>
															{cuenta.descripcion}
														</Typography>
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(subtotal)}
													</TableCell>
													<TableCell
														align="center"
														style={{ fontSize: 12 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(total)}
													</TableCell>
												</TableRow>
											);
										})}

										<TableRow key={-1}>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{ fontSize: 15 }}
											>
												PASIVO + CAPITAL
											</TableCell>
											<TableCell />
											<TableCell />
											<TableCell align="center" style={{ fontSize: 15 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(getPC())}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReportGerencial
					ref={compRef}
					titulo={' Balance General'}
					fecha={`Fecha: ${moment().format('L')}`}
					rango={`Del ${moment(fechaDesde).format('L')} al ${moment(fechaHasta).format(
						'L'
					)}`}
					isLandscape={false}
				>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parámetros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Sucursal: ${selectedSucursal === 't' ? 'Todas' : sucursalName}`}
							</Typography>

							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{rol}
							</Typography>
						</Stack>
					</Stack>
					<Container maxWidth="lg" sx={{ textAlign: 'center' }}>
						<hr />

						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								<TableContainer>
									<Table aria-label="simple table" size="small">
										<TableHead>
											<TableRow>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													No de Cuenta
												</TableCell>
												<TableCell
													align="left"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Descripción de Cuenta
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Sub - Total
												</TableCell>
												<TableCell
													align="center"
													style={{ fontSize: 14 }}
													className="text-primary fw-bold"
												>
													Total
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((row) => {
												const { cuenta, subtotal, total } = row;
												return (
													<TableRow key={cuenta.id}>
														<TableCell
															align="left"
															component="th"
															scope="row"
															style={{ fontSize: 12 }}
															className="text-dark"
														>
															{cuenta.countNumber}
														</TableCell>
														<TableCell align="left">
															<Typography
																style={{
																	fontSize: 12
																}}
																className="text-dark"
															>
																{cuenta.descripcion}
															</Typography>
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 12 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(subtotal)}
														</TableCell>
														<TableCell
															align="center"
															style={{ fontSize: 12 }}
															className="text-dark"
														>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(total)}
														</TableCell>
													</TableRow>
												);
											})}
											<TableRow key={-1}>
												<TableCell
													align="left"
													component="th"
													scope="row"
													style={{ fontSize: 15 }}
													className="text-dark"
													colSpan={2}
												>
													PASIVO + CAPITAL
												</TableCell>

												<TableCell />
												<TableCell
													align="center"
													style={{ fontSize: 15 }}
													className="text-dark"
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(getPC())}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</>
						)}
					</Container>
				</PrintReportGerencial>
			</div>
		</div>
	);
};

export default ReporteBalanceGeneral;
