import React, { useState, useContext } from "react";
import { TextField, Container, Button } from "@mui/material";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { Save } from "@mui/icons-material";
import { updateTCAsync } from "../../../api/TipoCambio";

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddValorCompra = ({
  setShowModal,
  localReload,
  setLocalReload,
  selectedTC,
}) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const [montoCompra, setMontoCompra] = useState("");
  const [montoVenta, setMontoVenta] = useState("");

  //Devuelve un entero positivo
  const funtionSetMontoCompra = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      setMontoCompra(value);
      return;
    }
  };
  const funtionSetMontoVenta = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      setMontoVenta(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (montoCompra.length === 0) {
      simpleMessage("Ingrese una valor de compra...", "error");
      return;
    }
    if (montoVenta.length === 0) {
      simpleMessage("Ingrese una valor de venta...", "error");
      return;
    }

    const data = { id: selectedTC.id, compra: montoCompra, venta: montoVenta };

    setIsLoading(true);
    const result = await updateTCAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Tipo de cambio actualizado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <hr />
      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => funtionSetMontoCompra(e.target.value)}
          label={"Monto Compra"}
          value={montoCompra}
        />
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => funtionSetMontoVenta(e.target.value)}
          label={"Monto venta"}
          value={montoVenta}
        />

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            color: "#00a152",
            borderColor: "#00a152",
            marginTop: 20,
          }}
          size="large"
        >
          Guardar cambios
        </Button>
      </Container>
    </div>
  );
};

export default AddValorCompra;
