import axios from 'axios';
import { errorResponse } from '../helpers/Helpers';
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = '';

if (process.env.NODE_ENV === 'production') {
	Api = `${REACT_APP_PRODURL}cashmovments`;
} else {
	Api = `${REACT_APP_URL}cashmovments`;
}

export const getGetCajaChicaMovAsync = async (token, sucursal) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCajaChicaMogment/' + sucursal;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getCajaMovAsync = async (token, sucursal) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = Api + '/GetCajaMovment/' + sucursal;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCajaChicaMovAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCajaChicaMov/`;

	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCajaInAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCajaIn`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCajaOutAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCajaOut`;

	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});

	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addCashTransfAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddCashTranf`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const retirarCapitalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/RertiraCapital`;

	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const inyectarCapitalAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/InyeccionCapital`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};
