import React from "react";
import { Typography, IconButton, Tooltip, Stack, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRuta } from "../../../helpers/Helpers";

import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";

const Gastos = ({ value }) => {
  let ruta = getRuta();

  let navigate = useNavigate();
  return (
    <div>
      <Stack
        direction={"row"}
        style={{ padding: 10 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Tooltip title="Ver Gastos">
          <IconButton
            sx={{ border: 1, borderColor: "rgba(255, 0, 255, 1)" }}
            style={{
              width: 100,
              height: 100,
              backgroundColor: "rgba(255, 0, 255, 0.2)",
            }}
            onClick={() => navigate(`${ruta}/dash-gastos`)}
          >
            <ShoppingCartCheckoutRoundedIcon
              style={{
                fontSize: 100,
                color: "rgba(255, 0, 255, 1)",
                opacity: 0.3,
                padding: 10,
              }}
            />
          </IconButton>
        </Tooltip>
        <Stack>
          <Typography
            align="right"
            style={{
              color: "rgba(255, 0, 255, 1)",
              fontWeight: 800,
              fontSize: 20,
            }}
            paragraph
          >
            {new Intl.NumberFormat("es-NI", {
              style: "currency",
              currency: "NIO",
            }).format(value)}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: "rgba(255, 0, 255, 1)", fontSize: 20 }}
      >
        Gastos del Dia
      </Typography>
    </div>
  );
};

export default Gastos;
