import React, { useState, useEffect, useContext } from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
	Container,
	Button,
	Stack,
	Chip
} from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import { getSucursalByUserAsync } from '../../../../api/Users';
import { getRuta, simpleMessage } from '../../../../helpers/Helpers';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getComsBySucursalAccessAsync } from '../../../../api/Locations';

const SelectorCreditsByBarrio = () => {
	const [barrioList, setBarrioList] = useState([]);
	const [selectedBarrio, setSelectedBarrio] = useState('t');

	const [moneda, setMoneda] = useState(1);

	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState('t');
	const [tipoCredito, setTipoCredito] = useState('t');

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();

	useEffect(() => {
		getSucursals();
		getBarrios(selectedSucursal);
	}, []);

	const verReport = () => {
		var params = `${selectedSucursal}/${selectedBarrio}/${moneda}/${tipoCredito}`;
		window.open(`${ruta}/r-creditsbyBarrio/${params}`);
	};

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
	};

	const getBarrios = async (sucursal) => {
		setIsLoading(true);
		const result = await getComsBySucursalAccessAsync(token);

		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setBarrioList(result.data.result);
		setIsLoading(false);
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					elevation={10}
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => onChangeSucursal(e.target.value)}
								value={selectedSucursal}
								label="Sucursal"
							>
								{sucursalList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
								<MenuItem key={'t'} value={'t'}>
									TODAS...
								</MenuItem>
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ textAlign: 'left', marginTop: 20 }}>
							<InputLabel id="demo-simple-select-standard-label">Barrios</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => setSelectedBarrio(e.target.value)}
								value={selectedBarrio}
								label="Barrios"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Barrio</em>
								</MenuItem>
								{barrioList.map((item) => {
									return (
										<MenuItem
											key={item.id}
											value={item.id}
											style={{ justifyContent: 'space-between' }}
										>
											<span className="text-uppercase">{item.name}</span>
											<Stack spacing={1} direction={'row'} marginLeft={2}>
												<Chip
													variant="outlined"
													label={
														moneda === 1
															? `CREDITOS C$: ${item.creditsNIO}`
															: `CREDITOS $: ${item.creditsUSD}`
													}
												/>
												<Chip
													variant="outlined"
													label={
														moneda === 1
															? `EMPEÑOS C$: ${item.nominalesNIO}`
															: `EMPEÑOS $: ${item.nominalesUSD}`
													}
												/>
											</Stack>
										</MenuItem>
									);
								})}
								<MenuItem value={'t'}>TODOS...</MenuItem>
							</Select>
						</FormControl>

						<Stack spacing={2} direction="row">
							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Moneda
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setMoneda(e.target.value)}
									value={moneda}
									label="Moneda"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										CORDOBAS C$
									</MenuItem>
									<MenuItem key={2} value={2}>
										DOLARES $
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Tipo de Cédito
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setTipoCredito(e.target.value)}
									value={tipoCredito}
									label="tipo de crédito"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={0} value={1}>
										EMPEÑO
									</MenuItem>
									<MenuItem key={1} value={2}>
										CREDITO
									</MenuItem>
									<MenuItem key={'t'} value={'t'}>
										TODOS...
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>

						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => verReport()}
							style={{
								color: '#4caf50',
								borderColor: '#4caf50'
							}}
							size="large"
						>
							Generar Reporte
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default SelectorCreditsByBarrio;
