import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faExternalLinkAlt,
  faPenToSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";

import Swal from "sweetalert2";
import {
  deletePeriodicidadAsync,
  getPeriodicidadListAsync,
} from "../../../api/Periodicidad";
import AddPeriodicidad from "./AddPeriodicidad";
import EditPeriodicidad from "./EditPeriodicidad";
import { useNavigate } from "react-router-dom";

const PeriodicidadList = () => {
  const [periodicidadList, setPeriodicidadList] = useState([]);
  const [selectedPeriodicidad, setSelectedPeriodicidad] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const withSearch = periodicidadList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.name.toString().includes(searchTerm)) {
      return val;
    }
  });

  const {
    reload,
    setReload,
    access,
    setIsLoading,
    setIsLogged,
    setIsUnautorized,
  } = useContext(DataContext);

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getPeriodicidadListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setPeriodicidadList(result.data.result);
    })();
  }, [reload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = periodicidadList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deletePeriodicidad = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deletePeriodicidadAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);

            if (result.error === "eX01") {
              deleteUserData();
              deleteToken();
              setIsLogged(false);
              return;
            }

            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }

          setIsLoading(false);
          setReload(!reload);
          Swal.fire("Eliminado!", "Tasa de Interes Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Periodicidades
            </Typography>
            {isAccess(access, "CONFIG CREATE") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Periodicidad
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Cantidad Días
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Cuota Días
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Plazos
                      </TableCell>
                      {isAccess(access, "CONFIG UPDATE") ||
                      isAccess(access, "CONFIG DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const {
                        id,
                        description,
                        cuotaDias,
                        cantidadDias,
                        countPlazos,
                      } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {description}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {cantidadDias}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {cuotaDias}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent="center"
                              alignItems={"center"}
                            >
                              <Stack>{countPlazos}</Stack>
                              <IconButton
                                style={{
                                  color: "#2979ff",
                                }}
                                onClick={() => {
                                  navigate(
                                    `${ruta}/plazosbyperiodicidadid/${id}`
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                          {isAccess(access, "CONFIG UPDATE") ||
                          isAccess(access, "CONFIG DELETE") ? (
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent="center"
                              >
                                {isAccess(access, "CONFIG UPDATE") ? (
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      setSelectedPeriodicidad(row);
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                {isAccess(access, "CONFIG DELETE") ? (
                                  <IconButton
                                    style={{
                                      color: "#f50057",
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => deletePeriodicidad(row)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Periodicidad"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddPeriodicidad setShowModal={setShowModal} />
      </SmallModal>

      <SmallModal
        titulo={`Editar: ${selectedPeriodicidad.description}`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <EditPeriodicidad
          selected={selectedPeriodicidad}
          setShowModal={setShowEditModal}
        />
      </SmallModal>
    </div>
  );
};

export default PeriodicidadList;
