import React, { useState, useContext } from "react";
import {
  TextField,
  Container,
  Button,
  Typography,
  Switch,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import { DataContext } from "../../../context/DataContext";
import { TimePicker } from "@mui/x-date-pickers";
import { simpleMessage } from "../../../helpers/Helpers";
import moment from "moment/moment";
import { updateRolScheduleAsync } from "../../../api/Rols";
import { getToken } from "../../../api/Account";

const EditServerRolAccess = ({
  selectedRol,
  setShowModal,
  localReload,
  setLocalReload,
}) => {
  const {
    id,
    activoDesde,
    activoHasta,
    trabajarSabado,
    trabajarDomingo,
    isAccess247,
  } = selectedRol;


  const setHora = (horaString) => {
    if (horaString !== null) {
      let dateString = moment().format("YYYY-MM-DD").toString();
      let datehoraString = `${dateString}T${horaString}`;
      return new Date(datehoraString);
    }
    return new Date();
  };

  const [horaDesde, setHoraDesde] = useState(setHora(activoDesde));
  const [horaHasta, setHoraHasta] = useState(setHora(activoHasta));
  const [isFullAccess, setIsFullAccess] = useState(isAccess247);
  const [isSabado, setIsSabado] = useState(trabajarSabado);
  const [isDomingo, setIsDomingo] = useState(trabajarDomingo);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const saveChangesAsync = async () => {
    if (!moment(horaDesde).isValid()) {
      simpleMessage("Seleccione una hora de encendido válida...", "error");
      return;
    }
    if (!moment(horaHasta).isValid()) {
      simpleMessage("Seleccione una hora de encendido válida...", "error");
      return;
    }

    const data = {
      idRol: id,
      isAccess247: isFullAccess,
      activoSabado: isSabado,
      activoDomingo: isDomingo,
      activoDesde: moment(horaDesde).format("HH:mm").toString(),
      activoHasta: moment(horaHasta).format("HH:mm").toString(),
    };
    setIsLoading(true);
    const result = await updateRolScheduleAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Horario Definido...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <hr />
      <Container style={{ width: 450 }}>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-around"
        >
          <Typography
            variant="h5"
            fontWeight={"bold"}
            style={{
              color: "#2979ff",
            }}
          >
            Acceso al Sistema 24/7
          </Typography>

          <Switch
            style={{
              color: isFullAccess ? "#4caf50" : "#f50057",
            }}
            checked={isFullAccess}
            onChange={() => setIsFullAccess(!isFullAccess)}
          />
        </Stack>

        <TimePicker
          label="Hora de Inicio"
          ampm
          // showToolbar
          value={horaDesde}
          onChange={(value) => setHoraDesde(value)}
          renderInput={(params) => (
            <TextField style={{ marginTop: 20 }} fullWidth {...params} />
          )}
        />

        <TimePicker
          label="Hora de Cierre"
          ampm
          // showToolbar
          value={horaHasta}
          onChange={(value) => setHoraHasta(value)}
          renderInput={(params) => (
            <TextField style={{ marginTop: 20 }} fullWidth {...params} />
          )}
        />

        <Stack
          spacing={2}
          direction="row"
          marginTop={2}
          justifyContent="space-between"
        >
          <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
            <Typography
              fontWeight={"bold"}
              style={{
                color: "#2979ff",
                fontSize: 15,
              }}
            >
              Trabajar Sabado
            </Typography>

            <Switch
              style={{
                color: isFullAccess ? "#4caf50" : "#f50057",
              }}
              checked={isSabado}
              size="small"
              onChange={() => setIsSabado(!isSabado)}
            />
          </Stack>

          <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
            <Typography
              fontWeight={"bold"}
              style={{
                color: "#2979ff",
                fontSize: 15,
              }}
            >
              Trabajar Domingo
            </Typography>

            <Switch
              style={{
                color: isFullAccess ? "#4caf50" : "#f50057",
              }}
              checked={isDomingo}
              size="small"
              onChange={() => setIsDomingo(!isDomingo)}
            />
          </Stack>
        </Stack>

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            marginTop: 30,
            borderRadius: 20,
            color: "#00a152",
            borderColor: "#00a152",
          }}
          size="large"
        >
          Cambiar Horario
        </Button>
      </Container>
    </div>
  );
};

export default EditServerRolAccess;
