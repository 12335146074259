import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Container,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { addCashTransfAsync } from "../../../api/Cash";
import { getTipoFlujoAsync } from "../../../api/Contabilidad";
import { getSucursalByUserAsync } from "../../../api/Users";

const AddTrasnferencia = ({
  setShowModal,
  localReload,
  setLocalReload,
  selectedSucursal,
}) => {
  const [sucursalList, setSucursalList] = useState([]);
  const [sucursal, setSucursal] = useState(selectedSucursal);
  const [tipoFlujoList, setTiposFlujoList] = useState([]);
  const [tf, setTF] = useState("");
  const [monto, setMonto] = useState("");

  const [tfDestino, setTFDestino] = useState("");
  const [description, setDescription] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const getTiposFlujo = async () => {
    setIsLoading(true);
    const result = await getTipoFlujoAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setTiposFlujoList(result.data.result);
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  useEffect(() => {
    (async () => {
      await getSucursals();
      await getTiposFlujo();
    })();
  }, [localReload]);

  const montoMov = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMonto(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (monto.length === 0) {
      simpleMessage("Ingrese un monto válido...", "error");
      return;
    }

    if (tf === tfDestino) {
      simpleMessage("Seleccione otra cuenta destino...", "error");
      return;
    }

    if (description.length === 0) {
      simpleMessage("Ingrese una descripcion válida...", "error");
      return;
    }

    const data = {
      sucursalId: sucursal,
      idCuentaFuente: tf,
      monto,
      idCuentaDestino: tfDestino,
      description,
    };
    setIsLoading(true);
    const result = await addCashTransfAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Transferencia Realizada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Container>
        <hr />
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Typography
              color={"#f50057"}
              variant="h6"
              fontWeight={"bold"}
              textAlign="center"
            >
              Origen de Fondos
            </Typography>

            <Paper style={{ padding: 20, marginTop: 10 }}>
              <Stack spacing={2}>
                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSucursal(e.target.value)}
                    value={selectedSucursal}
                    label="Sucursal"
                  >
                    {sucursalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Cuenta
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setTF(e.target.value)}
                    value={tf}
                    label="Cuenta"
                  >
                    {tipoFlujoList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  required
                  onChange={(e) => montoMov(e.target.value)}
                  label={"Monto a debitar"}
                  value={monto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C$</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Paper>
          </Grid>

          <Grid item sm={12} md={6}>
            <Typography
              color={"#00a152"}
              variant="h6"
              fontWeight={"bold"}
              textAlign="center"
            >
              Destino de Fondos
            </Typography>

            <Paper style={{ padding: 20, marginTop: 10 }}>
              <Stack spacing={2}>
                <FormControl style={{ textAlign: "left" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Cuenta
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setTFDestino(e.target.value)}
                    value={tfDestino}
                    label="Cuenta"
                  >
                    {tipoFlujoList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  required
                  onChange={(e) => setDescription(e.target.value.toUpperCase())}
                  label={"Descripcion del movimiento"}
                  value={description}
                />

                <Button
                  fullWidth
                  variant="outlined"
                  style={{
                    padding: 14,
                    color: "#00a152",
                    borderColor: "#00a152",
                  }}
                  startIcon={<FontAwesomeIcon icon={faSave} />}
                  onClick={() => saveChangesAsync()}
                >
                  Trasnferir efectivo
                </Button>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddTrasnferencia;
