import React, { useContext } from "react";
import { Dialog, Container, Paper, Button, Stack } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

import { DataContext } from "../context/DataContext";
import Lottie from "lottie-react";
import unauthorized from "./media/unauthorized.json";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// import logo from "./media/Icon.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Unautorized = () => {
  const { title, isUnautorized, isDarkMode, setIsUnautorized, icon } =
    useContext(DataContext);

  const tema = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#0A1929" : "#fff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderRadius: 30 },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 20,
        },
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? "#0A1929" : "#fff",
            borderRadius: 30,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? "rgba(0, 20, 50, 0.8)" : "#0A1929C3",
            backdropFilter: "blur(3px)",
            borderRadius: 0,
          },
        },
      },
    },
  });

  let navigate = useNavigate();

  return (
    <div>
      <ThemeProvider theme={tema}>
        <Dialog
          fullScreen
          open={isUnautorized}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <img
                loading="lazy"
                src={icon}
                alt="logo"
                style={{ height: 40 }}
              />
              <Typography
                sx={{ ml: 2, flex: 1, textAlign: "center" }}
                variant="h4"
                component="div"
              >
                {`${title}`}
              </Typography>
            </Toolbar>
          </AppBar>

          <Container maxWidth="xl">
            <Paper
              style={{
                padding: 20,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#f50057",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 1,
                }}
                variant="h5"
                c
              >
                Usuario no autorizado
              </Typography>

              <hr />

              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <div
                  style={{
                    width: 600,
                  }}
                >
                  <Lottie
                    animationData={unauthorized}
                    loop={true}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <Stack>
                  <Paper
                    style={{
                      padding: 20,
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h5" style={{ color: "#0A1929" }}>
                      No estas autorizado para estar aquí!
                    </Typography>
                    <hr />
                    <p style={{ color: "#0A1929" }}>Comunicate con Sistemas</p>

                    <Button
                      fullWidth
                      onClick={() => {
                        navigate("/");
                        setIsUnautorized(false);
                      }}
                      style={{ marginRight: 20, borderRadius: 20 }}
                      variant="outlined"
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10, fontSize: 20 }}
                        icon={faChevronCircleLeft}
                      />
                      Volver al Inicio
                    </Button>
                  </Paper>
                </Stack>
              </Stack>
            </Paper>
          </Container>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default Unautorized;
