import React, { useState, useContext } from "react";
import { TextField, Container, Stack, Button, Paper } from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import moment from "moment";
import { addNotificationAsync } from "../../../../api/Notifications";

const AddTask = ({ setShowModal, id, localReload, setLocalReload }) => {
  const [dateLunch, setDateLunch] = useState(new Date());
  const [description, setDescription] = useState("");

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const addNotification = async () => {
    if (!moment(dateLunch).isValid()) {
      simpleMessage("Seleccione una fecha válida", "error");
      return;
    }

    if (description.length === 0) {
      simpleMessage("Ingrese una descripción de notificación", "error");
      return;
    }
    setIsLoading(true);
    const data = {
      creditId: id,
      dateLunch: moment(dateLunch).format("YYYY-MM-DD"),
      Descripcion: description,
    };

    setIsLoading(true);
    const result = await addNotificationAsync(data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);

    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack direction="column" spacing={2}>
            <DesktopDatePicker
              closeOnSelect
              label="Fecha de lanzamiento"
              inputFormat="DD/MM/YYYY"
              value={dateLunch}
              onChange={(newValue) => setDateLunch(newValue)}
              renderInput={(params) => (
                <TextField required fullWidth {...params} />
              )}
            />

            <TextField
              required
              fullWidth
              multiline
              onChange={(e) =>
                setDescription(e.target.value.toLocaleUpperCase())
              }
              value={description}
              label={"Descripción"}
            />

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => addNotification()}
              style={{
                color: "#00a152",
                borderColor: "#00a152",
              }}
              size="large"
            >
              Agregar Tarea
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default AddTask;
