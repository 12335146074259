import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Typography,
	Container,
	Stack,
	Button,
	Paper,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	InputAdornment
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { DataContext } from '../../../../context/DataContext';
import { simpleMessage } from '../../../../helpers/Helpers';
import { getToken } from '../../../../api/Account';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { addQuoteNominalAsync, getDataCreditNominalAsync } from '../../../../api/Credits';

import moment from 'moment';

const AbonoNominal = ({ setShowModal, id, localReload, setLocalReload, printBill }) => {
	const [active, setActive] = useState(1);
	const [fechaAbono, setFechaAbono] = useState('');

	const [diasAtraso, setDiasAtraso] = useState(0);
	const [montoAtraso, setMontoAtraso] = useState(0);
	const [saldoInteres, setSaldoInteres] = useState(0);
	const [saldoCapital, setSaldoCapital] = useState(0);
	const [nuevoSaldo, setNuevoSaldo] = useState(0);
	const [isDolar, setIsDolar] = useState(false);
	const [tipoAbono, setTipoAbono] = useState(1);
	const [montoPagar, setMontoPagar] = useState('');
	const [creditNumber, setCreditNumer] = useState('');

	const [compra, setCompra] = useState(0);
	const [venta, setVenta] = useState(0);

	const [montoVenta, setMontoVenta] = useState(0);

	const [sobrante, setSobrante] = useState(0);
	const [dataAbono, setDataAbono] = useState([]);

	// const [fecha, setFecha] = useState(new Date());

	const token = getToken();
	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	useEffect(() => {
		getDataCredit(id);
	}, []);

	const addCuota = async () => {
		if (tipoAbono === 3) {
			if (saldoCapital > parseFloat(montoVenta)) {
				simpleMessage('No puede vender el articulo en ese precio', 'error');
				return;
			}
		}
		setIsLoading(true);
		const data = {
			creditId: id,
			totalRecibido: tipoAbono === 3 ? montoVenta : montoPagar,
			concepto: tipoAbono === 1 ? 'ABONO DE INTERES' : 'PAGO TOTAL DE EMPEÑO',
			moneda: active,
			sobrante,
			tipoAbono
			// fechaPago: moment(fecha).format('YYYY-MM-DD')
		};
		const result = await addQuoteNominalAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}

			simpleMessage(result.error, 'error');
			return;
		}
		printBill(result.data.result);

		setIsLoading(false);
		setLocalReload(!localReload);
		setShowModal(false);
	};

	const getDataCredit = async (creditId, selectedFecha) => {
		setIsLoading(true);
		const data = { creditId: creditId };
		const result = await getDataCreditNominalAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setDataAbono(result.data.result);
		setCreditNumer(result.data.result.numeroCredito);
		setDiasAtraso(result.data.result.diasAtraso);
		setFechaAbono(result.data.result.fechapago);
		setSaldoInteres(result.data.result.saldoInteres);
		setSaldoCapital(result.data.result.saldoCapital);
		setMontoAtraso(result.data.result.montoAtraso);
		setIsDolar(result.data.result.isDolar);
		setMontoPagar(
			(result.data.result.saldoInteres + result.data.result.montoAtraso).toFixed(2)
		);

		setActive(result.data.result.isDolar ? 2 : 1);
		setCompra(result.data.result.bcnCompra);
		setVenta(result.data.result.bcnVenta);
	};

	const onChangeData = (moneda, tipo) => {
		setActive(moneda);
		setTipoAbono(tipo);
		setMontoVenta(0);

		if (isDolar) {
			if (moneda === 1) {
				if (tipo === 1) {
					setSaldoCapital(dataAbono.saldoCapital * venta);
					setSaldoInteres(dataAbono.saldoInteres * venta);
					setMontoAtraso(dataAbono.montoAtraso * venta);
					setNuevoSaldo((dataAbono.saldoCapital * venta).toFixed(2));
					setMontoPagar(
						((dataAbono.saldoInteres + dataAbono.montoAtraso) * venta).toFixed(2)
					);
				} else {
					setSaldoCapital(dataAbono.saldoCapital * venta);
					setSaldoInteres(dataAbono.saldoInteres * venta);
					setMontoAtraso(dataAbono.montoAtraso * venta);
					setNuevoSaldo(0);
					setMontoPagar(
						(
							(dataAbono.saldoCapital +
								dataAbono.saldoInteres +
								dataAbono.montoAtraso) *
							venta
						).toFixed(2)
					);
				}
			} else {
				if (tipo === 1) {
					setSaldoCapital(dataAbono.saldoCapital);
					setSaldoInteres(dataAbono.saldoInteres);
					setNuevoSaldo(dataAbono.saldoCapital);
					setMontoAtraso(dataAbono.montoAtraso);

					setMontoPagar((dataAbono.saldoInteres + dataAbono.montoAtraso).toFixed(2));
				} else {
					setMontoAtraso(dataAbono.montoAtraso);
					setSaldoCapital(dataAbono.saldoCapital);
					setSaldoInteres(dataAbono.saldoInteres);
					setNuevoSaldo(0);
					setMontoPagar(
						(
							dataAbono.saldoCapital +
							dataAbono.saldoInteres +
							dataAbono.montoAtraso
						).toFixed(2)
					);
				}
			}
		} else {
			if (moneda === 2) {
				if (tipo === 1) {
					setSaldoCapital(dataAbono.saldoCapital / compra);
					setSaldoInteres(dataAbono.saldoInteres / compra);
					setMontoAtraso(dataAbono.montoAtraso / compra);
					setNuevoSaldo((dataAbono.saldoCapital / compra).toFixed(2));
					setMontoPagar(
						((dataAbono.saldoInteres + dataAbono.montoAtraso) / compra).toFixed(2)
					);
				} else {
					setSaldoCapital(dataAbono.saldoCapital / compra);
					setSaldoInteres(dataAbono.saldoInteres / compra);
					setMontoAtraso(dataAbono.montoAtraso / compra);
					setNuevoSaldo(0);
					setMontoPagar(
						(
							(dataAbono.saldoCapital +
								dataAbono.saldoInteres +
								dataAbono.montoAtraso) /
							compra
						).toFixed(2)
					);
				}
			} else {
				if (tipo === 1) {
					setSaldoCapital(dataAbono.saldoCapital);
					setSaldoInteres(dataAbono.saldoInteres);
					setNuevoSaldo(dataAbono.saldoCapital);
					setMontoAtraso(dataAbono.montoAtraso);

					setMontoPagar((dataAbono.saldoInteres + dataAbono.montoAtraso).toFixed(2));
				} else {
					setMontoAtraso(dataAbono.montoAtraso);
					setSaldoCapital(dataAbono.saldoCapital);
					setSaldoInteres(dataAbono.saldoInteres);
					setNuevoSaldo(0);
					setMontoPagar(
						(
							dataAbono.saldoCapital +
							dataAbono.saldoInteres +
							dataAbono.montoAtraso
						).toFixed(2)
					);
				}
			}
		}
	};

	// const onChangeFecha = (newValue) => {
	// 	setFecha(newValue);
	// 	getDataCredit(id, newValue);
	// };

	//Devuelve un entero positivo
	const funtionSetSobrante = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString())) {
			setSobrante(value);
			return;
		}
	};

	//Devuelve un entero positivo
	const fnSetMontoVenta = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString())) {
			setMontoVenta(value);
			return;
		}
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Stack direction="column" spacing={1}>
					<Stack direction="column" spacing={1}>
						<Paper
							style={{
								padding: 20
							}}
						>
							<Stack direction="column" spacing={2} justifyContent={'center'}>
								<Stack>
									<Container style={{ width: 400 }}>
										<Stack direction="row" justifyContent="space-between">
											<Stack
												spacing={1}
												direction="row"
												justifyContent={'center'}
											>
												<Typography
													fontWeight={'bold'}
													style={{
														color: '#2979ff'
													}}
												>
													CREDITO # :
												</Typography>
												<Typography
													style={{
														fontWeight: 'bold'
													}}
												>
													{creditNumber}
												</Typography>
											</Stack>

											<Stack
												direction="row"
												justifyContent={'space-around'}
												spacing={1}
											>
												<Typography
													fontWeight={'bold'}
													style={{
														color: '#ffc400'
													}}
												>
													Dias de atraso :
												</Typography>
												<Typography
													style={{
														fontWeight: 'bold'
													}}
												>
													{diasAtraso}
												</Typography>
											</Stack>
										</Stack>

										<Stack
											spacing={1}
											direction="row"
											justifyContent={'space-between'}
										>
											<Typography
												textAlign={'left'}
												fontWeight={'bold'}
												style={{
													color: '#2979ff'
												}}
											>
												Fecha de Pago:
											</Typography>
											<Typography
												style={{
													fontWeight: 'bold'
												}}
												textAlign={'right'}
											>
												{moment(fechaAbono).format('L')}
											</Typography>
										</Stack>

										<Stack
											spacing={1}
											direction="row"
											justifyContent={'space-between'}
										>
											<Typography
												textAlign={'left'}
												fontWeight={'bold'}
												style={{
													color: '#00a152'
												}}
											>
												Cuota Interes:
											</Typography>
											<Typography
												style={{
													fontWeight: 'bold'
												}}
												textAlign={'right'}
											>
												{`${new Intl.NumberFormat(
													active === 2 ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: active === 2 ? 'USD' : 'NIO'
													}
												).format(saldoInteres)}`}
											</Typography>
										</Stack>

										<Stack direction="row" justifyContent={'space-between'}>
											<Typography
												textAlign={'left'}
												fontWeight={'bold'}
												style={{
													color: '#ffc400'
												}}
											>
												Monto Atraso
											</Typography>
											<Typography
												style={{
													fontWeight: 'bold'
												}}
												sx={{
													marginLeft: 1,
													textDecoration: 'underline',
													textUnderlineOffset: 8
												}}
												textAlign={'right'}
											>
												{`${new Intl.NumberFormat(
													active === 2 ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: active === 2 ? 'USD' : 'NIO'
													}
												).format(montoAtraso)}`}
											</Typography>
										</Stack>

										<Stack
											direction="row"
											justifyContent="space-between"
											marginTop={1}
										>
											<Typography
												fontWeight={'bold'}
												textAlign={'left'}
												style={{
													color: '#ffc400'
												}}
											>
												Cuota interes + atraso
											</Typography>
											<Typography
												textAlign="right"
												style={{
													fontWeight: 'bold'
												}}
											>
												{`${new Intl.NumberFormat(
													active === 2 ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: active === 2 ? 'USD' : 'NIO'
													}
												).format(montoAtraso + saldoInteres)}`}
											</Typography>
										</Stack>
									</Container>
								</Stack>
							</Stack>

							<hr />
							<Stack direction="column" spacing={2}>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems="center"
									spacing={1}
								>
									{/* <DesktopDatePicker
										closeOnSelect
										label="Fecha Pago"
										inputFormat="DD/MM/YYYY"
										value={fecha}
										onChange={(newValue) => onChangeFecha(newValue)}
										renderInput={(params) => (
											<TextField fullWidth {...params} />
										)}
									/> */}

									<FormControl fullWidth style={{ textAlign: 'left' }}>
										<InputLabel id="demo-simple-select-standard-label">
											Moneda
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											onChange={(e) =>
												onChangeData(e.target.value, tipoAbono)
											}
											value={active}
											label="Moneda"
										>
											<MenuItem value={1}>
												{isDolar
													? `CORDOBA - ${new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
													  }).format(venta)}`
													: 'CORDOBA'}
											</MenuItem>
											<MenuItem value={2}>
												{isDolar
													? 'DOLAR'
													: `DOLAR - ${new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
													  }).format(compra)}`}
											</MenuItem>
										</Select>
									</FormControl>
								</Stack>

								{tipoAbono === 3 ? (
									<TextField
										fullWidth
										value={montoVenta}
										label={'Monto de Venta'}
										onChange={(e) => fnSetMontoVenta(e.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{active === 2 ? '$' : 'C$'}
												</InputAdornment>
											)
										}}
									/>
								) : (
									<Stack direction="row" spacing={2}>
										<TextField
											disabled
											fullWidth
											value={montoPagar}
											label={'Monto a pagar'}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{active === 2 ? '$' : 'C$'}
													</InputAdornment>
												)
											}}
										/>

										<TextField
											fullWidth
											value={sobrante}
											label={'Sobrante'}
											onChange={(e) => funtionSetSobrante(e.target.value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{active === 2 ? '$' : 'C$'}
													</InputAdornment>
												)
											}}
										/>
									</Stack>
								)}
								<FormControl fullWidth style={{ textAlign: 'left' }}>
									<InputLabel id="demo-simple-select-standard-label">
										Tipo de Abono
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										onChange={(e) => onChangeData(active, e.target.value)}
										value={tipoAbono}
										label="Tipo de abono"
									>
										<MenuItem value={1}>PAGO INTERES + ATRASO</MenuItem>
										<MenuItem value={2}>PAGO TOTAL</MenuItem>
										<MenuItem value={3}>CANCELACION POR VENTA</MenuItem>
									</Select>
								</FormControl>

								<Button
									variant="outlined"
									startIcon={
										<FontAwesomeIcon
											icon={faSave}
											style={{ marginRight: 20 }}
										/>
									}
									fullWidth
									onClick={() => addCuota()}
									style={{
										color: '#00a152',
										borderColor: '#00a152'
									}}
									size="large"
								>
									Agregar Cuota
								</Button>
							</Stack>
						</Paper>

						<Paper
							style={{
								padding: 10
							}}
						>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-around"
								divider={<Divider orientation="vertical" flexItem />}
							>
								<Stack spacing={1} justifyContent={'center'}>
									<Typography
										fontWeight={'bold'}
										textAlign="center"
										style={{
											color: '#00a152'
										}}
									>
										Saldo Total:
									</Typography>
									<Typography
										textAlign="center"
										style={{
											fontWeight: 'bold'
										}}
									>
										{`${new Intl.NumberFormat(
											active === 2 ? 'en-US' : 'es-NI',
											{
												style: 'currency',
												currency: active === 2 ? 'USD' : 'NIO'
											}
										).format(
											tipoAbono === 1
												? saldoInteres + montoAtraso
												: saldoCapital + saldoInteres + montoAtraso
										)}`}
									</Typography>
								</Stack>
								<Stack spacing={1} justifyContent={'center'}>
									<Typography
										textAlign="center"
										fontWeight={'bold'}
										style={{
											color: '#f50057'
										}}
									>
										Nuevo Saldo:
									</Typography>
									<Typography
										textAlign="center"
										style={{
											fontWeight: 'bold'
										}}
									>
										{`${new Intl.NumberFormat(
											active === 2 ? 'en-US' : 'es-NI',
											{
												style: 'currency',
												currency: active === 2 ? 'USD' : 'NIO'
											}
										).format(nuevoSaldo)}`}
									</Typography>
								</Stack>
							</Stack>
						</Paper>
					</Stack>
				</Stack>
			</Container>
		</div>
	);
};

export default AbonoNominal;
