import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Container,
  Button,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Save } from "@mui/icons-material";
import { DataContext } from "../../../../context/DataContext";
import { getRuta, simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";
import { useNavigate, useParams } from "react-router-dom";
import {
  addAsignacionesAsync,
  addDeduccionesAsync,
  getNominaDetailAsync,
} from "../../../../api/Employees";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import { PrintReport } from "../../../../components/PrintReport";

const NominaEmployee = () => {
  const [detalleNomina, setDetalleNomina] = useState([]);

  const [montoBono, setMontoBono] = useState(0);
  const [montoComision, setMOntoComision] = useState(0);
  const [hExtra, setHExtra] = useState(0);
  const [montoOtrasDeducciones, setMontoOtrasDeducciones] = useState(0);
  const [isCerrado, setIsCerrado] = useState(false);

  const [localReload, setLocalReload] = useState(false);

  const compRef = useRef();

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const { id, ideployee } = useParams();
  const getNomina = async () => {
    setIsLoading(true);
    const result = await getNominaDetailAsync(token, ideployee);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsCerrado(result.data.result.nomina.estado.id === 2);
    setDetalleNomina(result.data.result);
    setMontoBono(result.data.result.bono);
    setMOntoComision(result.data.result.comision);
    setMontoOtrasDeducciones(result.data.result.otrasDeducciones);
    setHExtra(result.data.result.horasExtra);
    setIsLoading(false);
  };

  useEffect(() => {
    getNomina();
  }, [localReload]);

  const funtionSetHoraExtra = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setHExtra(value);
      return;
    }
  };

  const funtionSetMontoBono = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMontoBono(value);
      return;
    }
  };

  const funtionSetMontoComision = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMOntoComision(value);
      return;
    }
  };

  const funtionSetOtrasDeducciones = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMontoOtrasDeducciones(value);
      return;
    }
  };

  const saveAsignacionAsync = async () => {
    if (montoBono.length === 0) {
      simpleMessage("Ingrese un valor de bono...", "error");
      return;
    }

    setIsLoading(true);
    const data = {
      id: ideployee,
      montoBono,
      montoComision,
      horasExtra: hExtra,
    };
    const result = await addAsignacionesAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Asignacion Agregada...!", "success");
    setLocalReload(!localReload);
  };

  const saveDeduccionesAsync = async () => {
    if (montoBono.length === 0) {
      simpleMessage("Ingrese un valor de bono...", "error");
      return;
    }

    setIsLoading(true);
    const data = { id: ideployee, montoOtrasDeducciones };
    const result = await addDeduccionesAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Deduccion Agregada...!", "success");
    setLocalReload(!localReload);
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 5mm; }
`;

  return isEmpty(detalleNomina) ? (
    <></>
  ) : (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={1}
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <Button
                onClick={() => {
                  navigate(`${ruta}/nominadetails/${id}`);
                }}
                style={{
                  color: "#2979ff",
                  borderColor: "#2979ff",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faChevronCircleLeft}
                />
                Regresar
              </Button>

              <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
                DETALLE NOMINA EMPLEADO
              </Typography>
            </Stack>
            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton variant="outlined">
                    <PrintRoundedIcon
                      style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>
          <hr />

          <Paper
            style={{
              padding: 20,
            }}
          >
            <Stack
              spacing={1}
              direction={{ xs: "column", sm: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    No EMPLEADO:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {detalleNomina.empleado.id}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    NOMBRE Y APELLIDO:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {detalleNomina.empleado.fullName}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    TASA ORDINARIA:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(detalleNomina.empleado.salario)}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    PERIODO:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {`DESDE ${moment(detalleNomina.desde).format(
                      "L"
                    )} HASTA  ${moment(detalleNomina.hasta).format("L")}`}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    No. RECIBO:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {detalleNomina.id}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                    FECHA PAGO:
                  </Typography>

                  <Typography style={{ textAlign: "left" }}>
                    {moment(detalleNomina.hasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>

          <Stack
            spacing={1}
            marginTop={2}
            direction={{ xs: "column", sm: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Stack spacing={1} flex={1}>
              <Paper
                component={Stack}
                justifyContent="space-between"
                style={{
                  padding: 20,
                  height: 400,
                }}
              >
                <Stack>
                  <Typography color={"#00a152"} fontWeight={"bold"}>
                    ASIGNACIONES
                  </Typography>

                  <hr />

                  <Stack spacing={1}>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Ordinario"}
                      value={detalleNomina.salario}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      spacing={2}
                    >
                      <TextField
                        disabled={isCerrado}
                        fullWidth
                        size="small"
                        onChange={(e) => funtionSetHoraExtra(e.target.value)}
                        label={"Horas Extra"}
                        value={hExtra}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={isCerrado}
                                onClick={() => saveAsignacionAsync()}
                              >
                                <Save style={{ color: "#2979ff" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        label={"Monto Horas Extra"}
                        value={detalleNomina.montoHorasExtra}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">C$</InputAdornment>
                          ),
                        }}
                      />
                    </Stack>

                    <TextField
                      fullWidth
                      disabled={isCerrado}
                      size="small"
                      onChange={(e) => funtionSetMontoBono(e.target.value)}
                      label={"Monto Bono"}
                      value={montoBono}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={isCerrado}
                              onClick={() => saveAsignacionAsync()}
                            >
                              <Save style={{ color: "#2979ff" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      disabled={isCerrado}
                      fullWidth
                      size="small"
                      onChange={(e) => funtionSetMontoComision(e.target.value)}
                      label={"Monto Comision"}
                      value={montoComision}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={isCerrado}
                              onClick={() => saveAsignacionAsync()}
                            >
                              <Save style={{ color: "#2979ff" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack>
                  <hr />
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      color={"#00a152"}
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      TOTAL
                    </Typography>

                    <Typography
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(
                        detalleNomina.salario +
                          montoBono +
                          montoComision +
                          detalleNomina.montoHorasExtra
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>

            <Stack spacing={1} flex={1}>
              <Paper
                component={Stack}
                justifyContent="space-between"
                style={{
                  padding: 20,
                  height: 400,
                }}
              >
                <Stack>
                  <Typography color={"#00a152"} fontWeight={"bold"}>
                    DEDUCCIONES
                  </Typography>

                  <hr />

                  <Stack spacing={1}>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Aporte INSS"}
                      value={detalleNomina.aporteINSS}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Aporte IR"}
                      value={detalleNomina.aporteIR}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Adelanto de Salario"}
                      value={detalleNomina.adelanto}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Prestamo Efectivo"}
                      value={detalleNomina.prestamo}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      disabled={isCerrado}
                      size="small"
                      onChange={(e) =>
                        funtionSetOtrasDeducciones(e.target.value)
                      }
                      label={"Otras Deducciones"}
                      value={montoOtrasDeducciones}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={isCerrado}
                              onClick={() => saveDeduccionesAsync()}
                            >
                              <Save style={{ color: "#2979ff" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack>
                  <hr />
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography
                      color={"#00a152"}
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      TOTAL
                    </Typography>

                    <Typography
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(
                        detalleNomina.aporteINSS +
                          detalleNomina.aporteIR +
                          detalleNomina.adelanto +
                          detalleNomina.prestamo +
                          detalleNomina.otrasDeducciones
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>

            <Stack spacing={1} flex={1}>
              <Paper
                component={Stack}
                justifyContent="space-between"
                style={{
                  padding: 20,
                  height: 400,
                }}
              >
                <Stack>
                  <Typography color={"#00a152"} fontWeight={"bold"}>
                    ACUMULADO
                  </Typography>

                  <hr />

                  <Stack spacing={1}>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Vacaciones"}
                      value={detalleNomina.vacaciones}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      label={"Aguinaldo"}
                      value={detalleNomina.aguinado}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      label={"Indemnización"}
                      value={detalleNomina.indemnizacion}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">C$</InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack>
                  <hr />
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography
                      color={"#00a152"}
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      TOTAL
                    </Typography>

                    <Typography
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(
                        detalleNomina.indemnizacion +
                          detalleNomina.vacaciones +
                          detalleNomina.aguinado
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
          </Stack>

          <Paper
            style={{
              marginTop: 20,
              padding: 20,
              textAlign: "center",
            }}
          >
            <Stack spacing={1} direction="row" justifyContent="center">
              <Typography
                color={"#00a152"}
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                TOTAL A RECIBIR
              </Typography>

              <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
                {new Intl.NumberFormat("es-NI", {
                  style: "currency",
                  currency: "NIO",
                }).format(detalleNomina.totalPercibir)}
              </Typography>
            </Stack>
          </Paper>
        </Paper>
      </Container>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport ref={compRef} titulo={"RECIBO DE PAGO"}>
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    No EMPLEADO:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {detalleNomina.empleado.id}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    NOMBRE Y APELLIDO:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {detalleNomina.empleado.fullName}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    TASA ORDINARIA:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(detalleNomina.empleado.salario)}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    PERIODO:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {`DESDE ${moment(detalleNomina.desde).format(
                      "L"
                    )} HASTA  ${moment(detalleNomina.hasta).format("L")}`}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    No. RECIBO:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {detalleNomina.id}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography
                    color={"#2196f3"}
                    style={{ textAlign: "left", fontSize: 11 }}
                  >
                    FECHA PAGO:
                  </Typography>

                  <Typography style={{ textAlign: "left", fontSize: 11 }}>
                    {moment(detalleNomina.hasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <hr />

            <TableContainer>
              <Table
                size="small"
                aria-label="simple table"
                sx={{ border: 1, borderColor: "#212121" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#2196f3",
                      }}
                    >
                      ASIGNACIONES
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#2196f3",
                      }}
                    >
                      DEDUCCIONES
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#2196f3",
                      }}
                    >
                      ACUMULADO
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow
                    key={1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          ORDINARIO
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.totalPercibir)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          HORAS EXTRA
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.montoHorasExtra)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          BONO
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(montoBono)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          COMISION
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(montoComision)}
                        </Typography>
                      </Stack>

                      {/* <Stack>
                        <hr />

                        <Stack
                          spacing={1}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography
                            color={"#00a152"}
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: 11,
                            }}
                          >
                            TOTAL
                          </Typography>

                          <Typography
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: 11,
                            }}
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(
                              detalleNomina.salario +
                                montoBono +
                                montoComision +
                                detalleNomina.montoHorasExtra
                            )}
                          </Typography>
                        </Stack>
                      </Stack> */}
                    </TableCell>

                    <TableCell
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          APORTE INSS
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.aporteINSS)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          APORTE IR
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.aporteIR)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          ADELANTO DE SALARIO
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.adelanto)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          PRESTAMO
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.prestamo)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          OTRAS DEDUCCIONES
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(montoOtrasDeducciones)}
                        </Typography>
                      </Stack>

                      {/* <Stack>
                        <hr />

                        <Stack
                          spacing={1}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography
                            color={"#00a152"}
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: 11,
                            }}
                          >
                            TOTAL
                          </Typography>

                          <Typography
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: 11,
                            }}
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(
                              detalleNomina.aporteINSS +
                                detalleNomina.aporteIR +
                                detalleNomina.adelanto +
                                detalleNomina.prestamo +
                                detalleNomina.otrasDeducciones
                            )}
                          </Typography>
                        </Stack>
                      </Stack> */}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          VACACIONES
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.vacaciones)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          AGUINALDO
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.aguinado)}
                        </Typography>
                      </Stack>

                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#2196f3"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          INDEMNIZACION
                        </Typography>

                        <Typography style={{ textAlign: "left", fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(detalleNomina.indemnizacion)}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key={1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#00a152"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          TOTAL
                        </Typography>

                        <Typography
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            detalleNomina.salario +
                              montoBono +
                              montoComision +
                              detalleNomina.montoHorasExtra
                          )}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#00a152"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          TOTAL
                        </Typography>

                        <Typography
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            detalleNomina.aporteINSS +
                              detalleNomina.aporteIR +
                              detalleNomina.adelanto +
                              detalleNomina.prestamo +
                              detalleNomina.otrasDeducciones
                          )}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontSize: 11,
                        color: "#212121",
                        verticalAlign: "top",
                      }}
                    >
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          color={"#00a152"}
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          TOTAL
                        </Typography>

                        <Typography
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: 11,
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            detalleNomina.indemnizacion +
                              detalleNomina.vacaciones +
                              detalleNomina.aguinado
                          )}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              spacing={1}
              direction="row"
              justifyContent="space-around"
              marginTop={10}
            >
              <Stack spacing={1} direction="row">
                <Typography
                  color={"#00a152"}
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 11,
                  }}
                >
                  TOTAL A RECIBIR
                </Typography>

                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 11,
                  }}
                >
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(detalleNomina.totalPercibir)}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 11,
                    textDecoration: "overline",
                  }}
                >
                  RECIBI CONFORME MI SALARIO A LA FECHA ARRIBA INDICADA
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default NominaEmployee;
