import React, { useContext, useState, useEffect } from "react";
import { Paper, Avatar, Grid, Typography, Button } from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../context/DataContext";
import {
  deleteToken,
  deleteUserData,
  getToken,
  getUserByUsername,
} from "../../../api/Account";
import { simpleMessage } from "../../../helpers/Helpers";
import { Stack } from "@mui/system";
import SmallModal from "../../../components/modals/SmallModal";
import ChangePass from "./ChangePass";
moment.locale("es");

const MyAccount = () => {
  const { setIsLogged, setIsLoading, setAccess } = useContext(DataContext);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState("");
  const token = getToken();

  useEffect(() => {
    if (token === null || user === null) {
      setIsLogged(false);
      setIsLoading(false);
      return;
    } else {
      const getUserData = async () => {
        setIsLoading(true);
        const result = await getUserByUsername(token);
        if (!result.statusResponse) {
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }

          simpleMessage(result.error, "error");
          return;
        }
        setAccess(result.data.userRol.permissions);
        setUser(result.data);
        setIsLoading(false);
      };
      getUserData();
    }
  }, []);

  return (
    <div>
      <Paper style={{ padding: 20 }}>
        <Grid align="center">
          <Avatar
            sx={{ width: 120, height: 120, bgcolor: "#00a152" }}
            style={{ marginTop: 30 }}
          />
          <hr />
          <Stack direction="row" spacing={1} justifyContent="center">
            <Typography
              style={{
                color: "#2196f3",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Usuario:
            </Typography>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              {user.userName}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} justifyContent="center">
            <Typography
              style={{
                color: "#2196f3",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              Rol de Usuario:
            </Typography>
            <Typography
              style={{
                fontSize: 15,
              }}
            >
              {user ? user.userRol.rolName : ""}
            </Typography>
          </Stack>

          <Stack spacing={1} justifyContent="center">
            <Typography
              style={{
                fontSize: 14,
              }}
            >
              {user.fullName}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
              }}
            >
              {user.address}
            </Typography>
            <Typography
              style={{
                fontSize: 14,
              }}
            >
              {` Telefono: ${user.phone}`}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} justifyContent="center">
            <Typography
              style={{
                color: "#2196f3",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Fecha:
            </Typography>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              {moment(user.fecha).format("LL")}
            </Typography>
          </Stack>
        </Grid>

        <hr />
        <Button
          style={{
            color: "#ff9100",
            borderColor: "#ff9100",
          }}
          variant="outlined"
          fullWidth
          startIcon={<FontAwesomeIcon icon={faSync} />}
          onClick={() => setShowModal(true)}
        >
          Cambiar Contraseña
        </Button>
      </Paper>

      <SmallModal
        isVisible={showModal}
        setVisible={setShowModal}
        titulo="Cambiar Contraseña"
      >
        <ChangePass setShowModal={setShowModal} />
      </SmallModal>
    </div>
  );
};

export default MyAccount;
