import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, trim } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Send } from "@mui/icons-material";
import { DataContext } from "../../../context/DataContext";
import {
  deleteToken,
  deleteUserData,
  getToken,
  getUser,
  getUserByUsername,
  updateMyAccountAsync,
} from "../../../api/Account";
import { simpleMessage } from "../../../helpers/Helpers";
import { Stack } from "@mui/system";

const UpdateUserData = () => {
  const { setIsLoading, isDarkMode, reload, setReload, setIsLogged } =
    useContext(DataContext);
  const [firstName, setfirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondlastName, setSecondLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const user = getUser();
  const token = getToken();

  useEffect(() => {
    setIsLoading(true);
    if (token === null || user === null) {
      setIsLogged(false);
      setIsLoading(false);
      return;
    } else {
      (async () => {
        const result = await getUserByUsername(token);
        if (!result.statusResponse) {
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          simpleMessage(result.error, "error");
          return;
        }

        setfirstName(result.data.firstName);
        setSecondName(result.data.secondName);
        setPhone(result.data.phone);

        setLastName(result.data.lastName);
        setSecondLastName(result.data.secondLastName);
        setAddress(result.data.address);
      })();
      setIsLoading(false);
    }
  }, []);

  const setData = () => {
    const data = {
      firstName: trim(firstName),
      secondName: trim(secondName),
      lastName: trim(lastName),
      secondlastName: trim(secondlastName),
      phone: trim(phone),
      address: trim(address),
    };
    return data;
  };

  const setNewFirstName = async () => {
    if (isEmpty(firstName)) {
      simpleMessage("No ha ingresado un nombre", "error");
      return;
    }
    await execute();
    simpleMessage("Nombre actualizado", "success");
  };

  const setNewSecondName = async () => {
    if (isEmpty(secondName)) {
      simpleMessage("No ha ingresado el segundo nombre", "error");
      return;
    }
    await execute();
    simpleMessage("Segundo nombre actualizado", "success");
  };

  const setNewLastName = async () => {
    if (isEmpty(lastName)) {
      simpleMessage("No ha ingresado un apellido", "error");
      return;
    }
    await execute();
    simpleMessage("Apellido actualizado", "success");
  };

  const setNewSecondLastName = async () => {
    if (isEmpty(secondlastName)) {
      simpleMessage("No ha ingresado el segundo apellido", "error");
      return;
    }
    await execute();
    if (isEmpty(secondName)) {
      simpleMessage("No ha ingresado el segundo nombre", "error");
      return;
    }
    await execute();
    simpleMessage("Segundo nombre actualizado", "success");
  };

  const setNewAddress = async () => {
    if (isEmpty(address)) {
      simpleMessage("No ha ingresado la direccion", "error");
      return;
    }
    await execute();
    simpleMessage("Direccion actualizada", "success");
  };

  const setNewPhoneNumber = async () => {
    if (isEmpty(phone)) {
      simpleMessage("No ha ingresado un numero telefonico", "error");
      return;
    }
    await execute();
    simpleMessage("Numero telefonico actualizado", "success");
  };

  const execute = async () => {
    setIsLoading(true);
    const data = setData();
    const result = await updateMyAccountAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }

      simpleMessage(result.error, "error");
      return;
    }
    setReload(!reload);
    setIsLoading(false);
  };

  return (
    <div>
      <Paper style={{ padding: 30 }}>
        <Stack spacing={1} direction="row">
          <Typography
            variant="h6"
            style={{
              color: "#ff9100",
              fontWeight: "bold",
            }}
          >
            Editar datos:
          </Typography>
          <Typography variant="h6">{user}</Typography>
        </Stack>

        <hr />

        <Grid container style={{ marginTop: 10, marginBottom: 10 }} spacing={2}>
          <Grid item sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setfirstName(e.target.value.toUpperCase())}
              value={firstName}
              label="Primer Nombre"
              placeholder="Ingrese primer nombre"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUserEdit}
                      style={{
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewFirstName()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setSecondName(e.target.value.toUpperCase())}
              value={secondName}
              label={"Segundo nombre"}
              placeholder={"Ingrese segundo nombre"}
              style={{ marginTop: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUserEdit}
                      style={{
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewSecondName()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setPhone(e.target.value.toUpperCase())}
              value={phone}
              label={"Numero telefonico"}
              placeholder={"Ingrese numero telefonico"}
              style={{ marginTop: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewPhoneNumber()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setLastName(e.target.value.toUpperCase())}
              value={lastName}
              label={"Primer apellido"}
              placeholder={"Ingrese primer apellido"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUserEdit}
                      style={{
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewLastName()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setSecondLastName(e.target.value.toUpperCase())}
              value={secondlastName}
              label={"Segundo apellido"}
              placeholder={"Ingrese segundo apellido"}
              style={{ marginTop: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUserEdit}
                      style={{
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewSecondLastName()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              variant="outlined"
              onChange={(e) => setAddress(e.target.value.toUpperCase())}
              value={address}
              label={"Direccion"}
              placeholder={"Ingrese direccion"}
              style={{ marginTop: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{
                        marginRight: 10,
                        color: isDarkMode ? "#7bc0c5" : "#105155",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setNewAddress()}>
                      <Send
                        style={{
                          color: isDarkMode ? "#7bc0c5" : "#105155",
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default UpdateUserData;
