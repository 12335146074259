import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCirclePlus,
  faTrashAlt,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isUndefined } from "lodash";

import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import Swal from "sweetalert2";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { deleteEmployeeAsync, getEmployeesAsync } from "../../../api/Employees";

const EmployeeList = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [localReload, setLocalReload] = useState(false);
  const [active, setActive] = useState(1);

  const {
    setIsLoading,
    setIsUnautorized,
    access,
    selectedSucursal,
    setSelectedSucursal,
  } = useContext(DataContext);

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const [sucursalList, setSucursalList] = useState([]);
  const withSearch = employeeList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.id.toString().includes(searchTerm) ||
      val.fullName.toString().includes(searchTerm) ||
      val.identification.toString().includes(searchTerm)
    ) {
      return val;
    }
  });

  useEffect(() => {
    getSucursals();
  }, [localReload]);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setSucursalList(result.data.result);

    if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
      setSelectedSucursal(result.data.result[0].id);
      await getEmployees(result.data.result[0].id, active);
      setIsLoading(false);
      return;
    }
    await getEmployees(selectedSucursal, active);
    setIsLoading(false);
  };

  const getEmployees = async (sucursalId, isActive) => {
    const data = {
      sucursalId,
      isActive,
    };
    const result = await getEmployeesAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setEmployeeList(result.data.result);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const onSelectChange = async (value) => {
    setIsLoading(true);
    setActive(value);
    getEmployees(selectedSucursal, value);
    setIsLoading(false);
  };

  const onChangeSucursal = async (value) => {
    setSelectedSucursal(value);
    await getEmployees(value, active);
  };

  const deleteEmployee = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteEmployeeAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }

          setIsLoading(false);
          setLocalReload(!localReload);

          Swal.fire("Eliminado!", "Empleado Eliminado.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Empleados
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Sucursal
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onChangeSucursal(e.target.value)}
                  value={selectedSucursal}
                  label="Sucursal"
                >
                  {sucursalList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Estado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onSelectChange(e.target.value)}
                  value={active}
                  label="Estado"
                >
                  <MenuItem value={1}>Activos</MenuItem>
                  <MenuItem value={2}>Inactivos</MenuItem>
                </Select>
              </FormControl>

              {isAccess(access, "NOMINA CREAR") ? (
                <Button
                  fullWidth
                  onClick={() => {
                    navigate(`${ruta}/add-employees`);
                  }}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                  variant="outlined"
                  style={{
                    color: "#00a152",
                    borderColor: "#00a152",
                  }}
                >
                  Agregar Empleado
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar empleado"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#2979ff" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Empleado
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Cedula
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha Inicio
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #INSS
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Puesto
                      </TableCell>
                      {isAccess(access, "NOMINA UPDATE") ||
                      isAccess(access, "NOMINA DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.fullName}</TableCell>
                          <TableCell align="center">
                            {row.identification}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.fechaInicioContrato).format("L")}
                          </TableCell>
                          <TableCell align="center">{row.inssNumber}</TableCell>
                          <TableCell align="center">{row.puesto}</TableCell>
                          <TableCell align="center">
                            {isAccess(access, "NOMINA UPDATE") ? (
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  navigate(`${ruta}/employee/${row.id}`);
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLink} />
                              </IconButton>
                            ) : (
                              <></>
                            )}

                            {isAccess(access, "NOMINA DELETE") &&
                            active === 1 ? (
                              <IconButton
                                style={{
                                  color: "#f50057",
                                  width: 40,
                                  height: 40,
                                }}
                                onClick={() => deleteEmployee(row)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default EmployeeList;
