import React, { useContext } from "react";
import { Dialog, Container, Paper, Stack } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

import { DataContext } from "../context/DataContext";
import Lottie from "lottie-react";
import off from "./media/serverOff.json";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServerOff = () => {
  const { title, icon } = useContext(DataContext);

  const tema = createTheme({
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 20,
        },
        styleOverrides: {
          root: {
            borderRadius: 30,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backdropFilter: "blur(10px)",
            borderRadius: 0,
          },
        },
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={tema}>
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <img
                loading="lazy"
                src={icon}
                alt="logo"
                style={{ height: 40 }}
              />
              <Typography
                sx={{ ml: 2, flex: 1, textAlign: "center" }}
                variant="h4"
                component="div"
              >
                {`${title}`}
              </Typography>
            </Toolbar>
          </AppBar>

          <Container maxWidth="xl">
            <Paper
              style={{
                padding: 20,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#f50057",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 1,
                }}
                variant="h4"
              >
                Servidor Fuera de Acceso
              </Typography>

              <hr />

              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <div
                  style={{
                    width: 800,
                  }}
                >
                  <Lottie
                    animationData={off}
                    loop={true}
                    style={{
                      //   width: 400,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <Stack>
                  <Paper
                    style={{
                      padding: 20,
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h5" style={{ color: "#0A1929" }}>
                      No tienes Acceso al Servidor!
                    </Typography>
                    <hr />
                    <p style={{ color: "#0A1929" }}>Comunicate con Sistemas</p>
                  </Paper>
                </Stack>
              </Stack>
            </Paper>
          </Container>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default ServerOff;
