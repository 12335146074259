import React, { useState, useEffect, useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";
import { getCreditsByPeriodicidadAsync } from "../../../api/Dashboard";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { isEmpty } from "lodash";
import NoData from "../../../components/NoData";

ChartJS.register(ArcElement, Tooltip, Legend);

const CreditsByPeriodicidad = ({ selectedSucursal }) => {
  const [datos, setDatos] = useState([]);
  const [total, setTotal] = useState(0);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getCreditsByPeriodicidadAsync(
        token,
        selectedSucursal
      );
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);

      setDatos(result.data.result);
      let suma = 0;
      result.data.result.map((item) => (suma += item.contador));
      setTotal(suma);
    })();
  }, [selectedSucursal]);

  const data = {
    labels: datos.map((item) => {
      return `${item.periocidad} ${Math.round((item.contador / total) * 100)}%`;
    }),
    datasets: [
      {
        data: datos.map((item) => {
          return item.contador;
        }),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ heigth: 20 }}>
      {isEmpty(datos) ? (
        <NoData />
      ) : (
        <Pie
          data={data}
          options={{
            responsive: true,
            // aspectRatio: 2,
            maintainAspectRatio: false,
            plugins: {
              legend: { position: "right" },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.label || "";
                    return label;
                  },
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default CreditsByPeriodicidad;
