import React, { useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { getColocacionSemanalAsync } from "../../../api/Dashboard";
import { simpleMessage } from "../../../helpers/Helpers";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip
);

const ColocacionSemanal = ({ selectedSucursal }) => {
  const [labels, setLabels] = useState([]);
  const [colocacion, setColocacion] = useState([]);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const dayName = (fecha) =>
    ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"][
      new Date(fecha).getDay()
    ];

  const graphicData = {
    labels: labels,
    datasets: [
      {
        datalabels: { color: "rgba(54, 162, 235, 1)" },
        label: "Colocacion",
        data: colocacion,
        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
        pointStyle: "circle",
        pointRadius: 8,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },

      legend: {
        position: "top",
        align: "center",
      },
      title: {
        display: false,
      },
    },

    elements: {
      line: {
        borderJoinStyle: "round",
      },
    },

    scales: {
      y: {
        ticks: {
          callback: (value, index, values) => {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(value);
          },
        },
        beginAtZero: true,
      },
    },
  };

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getColocacionSemanalAsync(token, selectedSucursal);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      let dias = [];
      let coloc = [];

      result.data.result.map((item) => {
        dias.push(dayName(item.fecha));
        coloc.push(item.colocacion);
      });

      setLabels(dias);
      setColocacion(coloc);

      setIsLoading(false);
    })();
  }, [selectedSucursal]);

  return (
    <div>
      <Line options={options} data={graphicData} />
    </div>
  );
};

export default ColocacionSemanal;
