import React, { useContext } from "react";

import { Typography, Stack } from "@mui/material";
import moment from "moment";
import { DataContext } from "../../../../context/DataContext";
import { conv2Letras } from "../../../../helpers/n2Letras";

const PrintBill = React.forwardRef((props, ref) => {
  const { title, slogan, icon, dataAbono } = useContext(DataContext);
  const {
    id,
    client,
    concepto,
    cuotaDia,
    diasMora,
    fechaPago,
    montoAtrasado,
    montoCancelacion,
    nuevoSaldo,
    numeroAbono,
    realizadoPor,
    sucursal,
    totalAbonado,
    quote,
  } = dataAbono;

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  const { isDolar } = props;

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"start"}
        >
          <img src={icon} style={{ width: 50, height: 50 }} />
          <Stack>
            <Typography variant="h5" textAlign={"center"}>
              {title}
            </Typography>
            <Typography textAlign={"center"}>
              {"Aceptamos joyas, electrodomésticos"}
            </Typography>
            <Typography textAlign={"center"}>
              {"Vehículos y electronicós"}
            </Typography>
            <Typography variant="h6" textAlign={"center"}>
              {`SUCURSAL - ${sucursal.description}`}
            </Typography>
          </Stack>
          <Stack>
            <img src={icon} style={{ width: 50, height: 50 }} />
            <Typography
              variant="h6"
              color="#f50057"
              textAlign="left"
              marginTop={2}
            >
              {`N° ${quote.lastNumRoc}`}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography textAlign={"right"}>
            FECHA:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {`${moment(fechaPago).format("dddd,")} ${moment(fechaPago).format(
                "D"
              )} de ${moment(fechaPago).format("MMMM")} de ${moment(
                fechaPago
              ).format("YYYY")}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            RECIBIMOS DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${client}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(
                totalAbonado,
                isDolar
              )}  -  (${new Intl.NumberFormat(isDolar ? "en-US" : "es-NI", {
                style: "currency",
                currency: isDolar ? "USD" : "NIO",
              }).format(totalAbonado)})`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${concepto}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" marginTop={5} spacing={3}>
          <Typography textAlign={"center"}>
            Firma:_______________________
          </Typography>
          <Typography textAlign={"left"}>
            Contrato:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${quote.credit.numeroCredito}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={10}>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>ENTREGUE CONFORME</Typography>
          </Stack>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>RECIBI CONFORME</Typography>
          </Stack>
        </Stack>

        <hr />

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"start"}
        >
          <img src={icon} style={{ width: 50, height: 50 }} />
          <Stack>
            <Typography variant="h5" textAlign={"center"}>
              {title}
            </Typography>
            <Typography textAlign={"center"}>
              {"Aceptamos joyas, electrodomésticos"}
            </Typography>
            <Typography textAlign={"center"}>
              {"Vehículos y electronicós"}
            </Typography>
            <Typography variant="h6" textAlign={"center"}>
              {`SUCURSAL - ${sucursal.description}`}
            </Typography>
          </Stack>
          <Stack>
            <img src={icon} style={{ width: 50, height: 50 }} />
            <Typography
              variant="h6"
              color="#f50057"
              textAlign="left"
              marginTop={2}
            >
              {`N° ${quote.lastNumRoc}`}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography textAlign={"right"}>
            FECHA:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {`${moment(fechaPago).format("dddd,")} ${moment(fechaPago).format(
                "D"
              )} de ${moment(fechaPago).format("MMMM")} de ${moment(
                fechaPago
              ).format("YYYY")}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            RECIBIMOS DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${client}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(
                totalAbonado,
                isDolar
              )}  -  (${new Intl.NumberFormat(isDolar ? "en-US" : "es-NI", {
                style: "currency",
                currency: isDolar ? "USD" : "NIO",
              }).format(totalAbonado)})`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${concepto}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" marginTop={5} spacing={3}>
          <Typography textAlign={"center"}>
            Firma:_______________________
          </Typography>
          <Typography textAlign={"left"}>
            Contrato:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${quote.credit.numeroCredito}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={10}>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>ENTREGUE CONFORME</Typography>
          </Stack>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>RECIBI CONFORME</Typography>
          </Stack>
        </Stack>
      </>
    </div>
  );
});

export default PrintBill;
