import React, { useState, useContext } from "react";
import { TextField, Container, Stack, Button, Paper } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { addGastoAministrativoAsync } from "../../../api/Credits";

const AddGastoAdministrativo = ({
  setShowModal,
  id,
  localReload,
  setLocalreload,
  isDolar,
}) => {
  const [gastosAdministrativos, setGastosAdministrativos] = useState("");
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const agregarGastoAdmin = async () => {
    if (gastosAdministrativos.length === 0) {
      simpleMessage("Debe ingresar un monto", "error");
      return;
    }
    setIsLoading(true);
    const data = {
      creditId: id,
      gastosAdministrativos,
    };
    const result = await addGastoAministrativoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setLocalreload(!localReload);
    Swal.fire("Listo!", "", "success");
    setIsLoading(false);
    setShowModal(false);
  };

  const funcGastosAdministrativos = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setGastosAdministrativos(value);
      return;
    }
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Stack direction="column" spacing={1}>
          <Paper
            style={{
              padding: 20,
            }}
          >
            <Stack spacing={2}>
              <TextField
                required
                fullWidth
                multiline
                onChange={(e) => funcGastosAdministrativos(e.target.value)}
                value={gastosAdministrativos}
                label={isDolar ?"Gastos Administrativos en Dolares": "Gastos Administrativos"}
              />

              <Button
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => agregarGastoAdmin()}
                style={{
                  color: "#ffc400",
                  borderColor: "#ffc400",
                }}
                size="large"
              >
                Agregar gastos Administrativos
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </div>
  );
};

export default AddGastoAdministrativo;
