import React, { useContext } from "react";
import { Backdrop } from "@mui/material";
import { DataContext } from "../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const Loading = () => {
  const { isLoading } = useContext(DataContext);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={isLoading}
      >
        <FontAwesomeIcon
          icon={faGear}
          className="fa-spin"
          style={{
            fontSize: 110,
            stroke: "#2a3eb1",
            strokeWidth: 20,
            color: "#5393ffC3",
          }}
        />
      </Backdrop>
    </div>
  );
};

export default Loading;
