import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Container,
  Button,
  Stack,
} from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";
import {
  getGestoresAsync,
  getGestoresBySucursalAccessAsync,
  getSucursalByUserAsync,
} from "../../../../api/Users";
import { getRuta, simpleMessage } from "../../../../helpers/Helpers";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectorGarantias = () => {
  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("t");
  const [tipoCredito, setTipoCredito] = useState("t");
  const [estado, setEstado] = useState("t");
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();

  useEffect(() => {
    getSucursals();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
  };

  const verReport = () => {
    if (tipoCredito.length === 0) {
      simpleMessage("Seleccione una modalidad de crédito", "error");
      return;
    }
    if (estado.length === 0) {
      simpleMessage("Seleccione un estado", "error");
      return;
    }

    var params = `${selectedSucursal}/${tipoCredito}/${estado}`;
    window.open(`${ruta}/r-garantias/${params}`);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => onChangeSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
                <MenuItem key={"t"} value={"t"}>
                  TODAS...
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Tipo de Cédito
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setTipoCredito(e.target.value)}
                value={tipoCredito}
                label="tipo de crédito"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione una Opción</em>
                </MenuItem>

                <MenuItem key={0} value={1}>
                  EMPEÑO
                </MenuItem>
                <MenuItem key={1} value={2}>
                  CREDITO
                </MenuItem>
                <MenuItem key={"t"} value={"t"}>
                  TODOS...
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Estado de Cédito
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setEstado(e.target.value)}
                value={estado}
                label="Estado de crédito"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione una Opción</em>
                </MenuItem>

                <MenuItem key={0} value={1}>
                  CREDITOS ACTIVOS
                </MenuItem>
                <MenuItem key={1} value={2}>
                  CREDITOS VENCIDOS
                </MenuItem>
                <MenuItem key={"t"} value={"t"}>
                  TODOS...
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => verReport()}
              style={{
                color: "#4caf50",
                borderColor: "#4caf50",
              }}
              size="large"
            >
              Generar Reporte
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default SelectorGarantias;
