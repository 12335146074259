import React, { useState, useContext } from "react";
import { Paper, Container, Button, Stack, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";

import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";

import { simpleMessage } from "../../../../helpers/Helpers";
import { updateVacacionesAsync } from "../../../../api/Employees";

const UpdateVacaciones = ({ selected, setShowModal, reload, setReload }) => {
  const [motivo, setMotivo] = useState(selected.motivo);
  const [desde, setDesde] = useState(new Date(selected.desde));
  const [hasta, setHasta] = useState(new Date(selected.hasta));

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  const token = getToken();

  const saveChanges = async () => {
    if (motivo.length === 0) {
      simpleMessage("Ingrese un motivo", "error");
      return;
    }

    if (!moment(desde).isValid()) {
      simpleMessage("Seleccione una fecha válida", "error");
      return;
    }
    if (!moment(hasta).isValid()) {
      simpleMessage("Seleccione una fecha válida", "error");
      return;
    }

    const data = {
      id: selected.id,
      motivo,
      desde: moment(desde).format("YYYY-MM-DD"),
      hasta: moment(hasta).format("YYYY-MM-DD"),
    };

    setIsLoading(true);
    const result = await updateVacacionesAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Registro Editado...!", "success");

    setReload(!reload);
    setShowModal(false);
  };
  
  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              onChange={(e) => setMotivo(e.target.value.toUpperCase())}
              value={motivo}
              label={"Descripcion"}
            />

            <DesktopDatePicker
              closeOnSelect
              label="Inicio"
              inputFormat="DD/MM/YYYY"
              value={desde}
              onChange={(newValue) => setDesde(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <DesktopDatePicker
              closeOnSelect
              label="Retorno"
              inputFormat="DD/MM/YYYY"
              value={hasta}
              onChange={(newValue) => setHasta(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => saveChanges()}
              style={{
                borderRadius: 30,
                color: "#4caf50",
                borderColor: "#4caf50",
                padding: 12,
              }}
              size="large"
            >
              Actualizar Vacaciones
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default UpdateVacaciones;
