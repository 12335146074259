import React, { useContext } from "react";
import { Typography, Stack } from "@mui/material";
import { DataContext } from "../context/DataContext";

export const PrintReport = React.forwardRef((props, ref) => {
  const { title, slogan, icon } = useContext(DataContext);
  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <Stack justifyContent={"space-between"} direction="row">
        <img loading="lazy" src={icon} alt="logo" style={{ height: 50 }} />
        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >{`${title}`}</Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 12,
            }}
          >{`${slogan}`}</Typography>
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
            }}
          >
            {props.titulo}
          </Typography>
        </Stack>
        <img loading="lazy" src={icon} alt="logo" style={{ height: 50 }} />
      </Stack>
      <div style={{ fontSize: 8 }}>{props.children}</div>
    </div>
  );
});
