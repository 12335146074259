import React, { useState, useContext, useEffect } from 'react';
import {
	Button,
	Paper,
	Typography,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	IconButton,
	Stack
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { deleteToken, deleteUserData, getToken } from '../../../api/Account';
import { DataContext } from '../../../context/DataContext';

import { isAccess, simpleMessage } from '../../../helpers/Helpers';

import NoData from '../../../components/NoData';
import SmallModal from '../../../components/modals/SmallModal';
import MediumModal from '../../../components/modals/MediumModal';

import Swal from 'sweetalert2';
import AddAbono from './quoteComponents/AddAbono';
import {
	deleteQuoteAsync,
	deleteQuoteNominalAsync,
	getBillDetailsAsync,
	getCreditQuotesAsync
} from '../../../api/Credits';
import moment from 'moment/moment';
import DetalleAbono from './quoteComponents/DetalleAbono';

const CreditAbono = ({ id, isNominal, dataCredit }) => {
	const [quotesList, setQuotesList] = useState([]);
	const {
		setIsLoading,
		setIsLogged,
		setIsUnautorized,
		access,
		showPrintBillModal,
		setShowPrintBillModal,
		setDataAbono
	} = useContext(DataContext);
	const token = getToken();
	const [localReload, setLocalReload] = useState(false);

	const { isCanceled, isDolar } = dataCredit;

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getCreditQuotesAsync(token, id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setQuotesList(result.data.result);
		})();
	}, [localReload]);

	const [addQuoteModal, setAddQuoteModal] = useState(false);

	const AnulateQuote = async (item) => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'No podras revertir esto...!',
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				(async () => {
					setIsLoading(true);
					const result = isNominal
						? await deleteQuoteNominalAsync(token, item)
						: await deleteQuoteAsync(token, item);
					if (!result.statusResponse) {
						setIsLoading(false);
						simpleMessage(result.error, 'error');
						return;
					}
					if (result.error === 'Unauthorized') {
						setIsLoading(false);
						deleteUserData();
						deleteToken();
						setIsLogged(false);
						return;
					}
					setIsLoading(false);
					setLocalReload(!localReload);
					Swal.fire('Eliminada!', 'Quota Eliminada.', 'success');
				})();
			}
		});
	};

	const reprintBill = async (quoteId) => {
		setIsLoading(true);
		const result = await getBillDetailsAsync(token, quoteId);
		if (!result.statusResponse) {
			setIsLoading(false);
			simpleMessage(result.error, 'error');
			return;
		}
		if (result.error === 'Unauthorized') {
			setIsLoading(false);
			deleteUserData();
			deleteToken();
			setIsLogged(false);
			return;
		}
		setIsLoading(false);
		setDataAbono(result.data.result);
		setShowPrintBillModal(true);
	};

	return (
		<div>
			<Paper
				style={{
					padding: 10
				}}
			>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					alignItems="center"
					justifyContent={'space-between'}
				>
					<Typography
						textAlign={'left'}
						color={'#00a152'}
						variant="h6"
						fontWeight={'bold'}
					>
						Abonos
					</Typography>
					{isAccess(access, 'CREDITS CREATE') && !isCanceled ? (
						<Button
							onClick={() => {
								setAddQuoteModal(true);
							}}
							style={{
								color: '#00a152',
								borderColor: '#00a152'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faCirclePlus}
							/>
							Agregar
						</Button>
					) : (
						<></>
					)}
				</Stack>
				<hr />
				{isEmpty(quotesList) ? (
					<NoData />
				) : (
					<TableContainer>
						<Table aria-label="simple table" size="small">
							<TableHead>
								<TableRow>
									<TableCell align="center" className="text-primary fw-bold">
										#
									</TableCell>
									<TableCell align="center" className="text-primary fw-bold">
										Fecha Pago
									</TableCell>
									<TableCell align="center" className="text-primary fw-bold">
										Capital
									</TableCell>
									<TableCell align="center" className="text-primary fw-bold">
										Interes C.
									</TableCell>
									<TableCell align="center" className="text-primary fw-bold">
										Interes M.
									</TableCell>
									<TableCell align="center" className="text-primary fw-bold">
										Abono T.
									</TableCell>
									<TableCell align="left" className="text-primary fw-bold">
										Concepto
									</TableCell>
									{isAccess(access, 'CREDITS DELETE') ? (
										<TableCell align="center" className="text-primary fw-bold">
											Acciones
										</TableCell>
									) : (
										<></>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{quotesList.map((row) => {
									return (
										<TableRow key={row.id}>
											<TableCell align="center" component="th" scope="row">
												{row.id}
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												{moment(row.fechaPago).format('L')}
											</TableCell>
											<TableCell align="center">
												{new Intl.NumberFormat(
													isDolar ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: isDolar ? 'USD' : 'NIO'
													}
												).format(row.ac)}
											</TableCell>
											<TableCell align="center">
												{new Intl.NumberFormat(
													isDolar ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: isDolar ? 'USD' : 'NIO'
													}
												).format(row.ic)}
											</TableCell>
											<TableCell align="center">
												{new Intl.NumberFormat(
													isDolar ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: isDolar ? 'USD' : 'NIO'
													}
												).format(row.aim)}
											</TableCell>
											<TableCell align="center">
												{new Intl.NumberFormat(
													isDolar ? 'en-US' : 'es-NI',
													{
														style: 'currency',
														currency: isDolar ? 'USD' : 'NIO'
													}
												).format(row.totalRecibido)}
											</TableCell>
											<TableCell align="left">{row.concepto}</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												{isAccess(access, 'CREDITS DELETE') ? (
													<IconButton
														style={{
															color: '#f50057',
															width: 40,
															height: 40
														}}
														onClick={() => AnulateQuote(row.id)}
													>
														<FontAwesomeIcon icon={faTrashAlt} />
													</IconButton>
												) : (
													<></>
												)}
												<IconButton
													style={{
														color: '#2979ff',
														width: 40,
														height: 40
													}}
													onClick={() => reprintBill(row.id)}
												>
													<FontAwesomeIcon icon={faPrint} />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Paper>

			<SmallModal
				titulo={'Agregar Abono'}
				isVisible={addQuoteModal}
				setVisible={setAddQuoteModal}
			>
				<AddAbono
					setShowModal={setAddQuoteModal}
					id={id}
					localReload={localReload}
					setLocalReload={setLocalReload}
					isNominal={isNominal}
				/>
			</SmallModal>

			<MediumModal
				titulo={'Detalle Abono'}
				isVisible={showPrintBillModal}
				setVisible={setShowPrintBillModal}
			>
				<DetalleAbono setShowModal={setShowPrintBillModal} isDolar={isDolar} />
			</MediumModal>
		</div>
	);
};

export default CreditAbono;
