import React, { useState, useContext } from "react";

import { TextField, Divider, Container, Stack, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { isEmpty } from "lodash";
import { updatePeriodicidadAsync } from "../../../api/Periodicidad";

const EditPeriodicidad = ({ selected, setShowModal }) => {
  const { id, description, cuotaDias, cantidadDias } = selected;
  const token = getToken();
  const { reload, setReload, setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const [newDescription, setNewDescription] = useState(description);
  const [newCantDias, setNewCantDias] = useState(cantidadDias.toString());
  const [newCuotaDias, setNewCuotaDias] = useState(cuotaDias.toString());

  const saveChangesAsync = async () => {
    if (validate()) {
      const data = {
        id,
        description: newDescription,
        cantidadDias: newCantDias,
        cuotaDias: newCuotaDias,
      };

      setIsLoading(true);
      const result = await updatePeriodicidadAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      simpleMessage("Periodicidad Actualizada...!", "success");
      setReload(!reload);
      setShowModal(false);
    }
  };

  const funtionSetCantidadDias = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setNewCantDias(value);
      return;
    }
  };

  //Devuelve un entero positivo
  const funtionSetCuotaDias = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setNewCuotaDias(value);
      return;
    }
  };

  //Validando campos ingresados
  const validate = () => {
    let isValid = true;
    if (isEmpty(newDescription)) {
      simpleMessage("Debe ingresar una descripcion", "error");
      return (isValid = false);
    }

    if (isEmpty(newCantDias)) {
      simpleMessage("Debe ingresar una cantidad de días", "error");
      return (isValid = false);
    }

    if (isEmpty(newCuotaDias)) {
      simpleMessage("Debe ingresar cada cuantos días se cobra", "error");
      return (isValid = false);
    }

    if (newCantDias === 0 || newCuotaDias === 0) {
      simpleMessage("Debe ingresar una cantidad mayor que cero", "error");
      return (isValid = false);
    }
    return isValid;
  };

  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            required
            onChange={(e) => setNewDescription(e.target.value.toUpperCase())}
            value={newDescription}
            label={"Descripcion Periocidad"}
          />

          <TextField
            fullWidth
            required
            onChange={(e) => funtionSetCantidadDias(e.target.value)}
            value={newCantDias}
            label={"Cantidad de días"}
          />
          <TextField
            fullWidth
            required
            onChange={(e) => funtionSetCuotaDias(e.target.value)}
            value={newCuotaDias}
            label={"Cuota días"}
          />

          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
            }
            fullWidth
            onClick={() => saveChangesAsync()}
            style={{
              color: "#4caf50",
              borderColor: "#4caf50",
            }}
            size="large"
          >
            Guardar Cambios
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default EditPeriodicidad;
