import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  Container,
  Button,
  Paper,
  Typography,
  Stack,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCirclePlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { getToken } from "../../../../api/Account";
import { isAccess, simpleMessage } from "../../../../helpers/Helpers";

import { DataContext } from "../../../../context/DataContext";
import {
  deleteVacacionesByIdAsync,
  getVacacionesAsync,
} from "../../../../api/Employees";
import { isEmpty } from "lodash";
import NoData from "../../../../components/NoData";
import AddVacaciones from "../vacaciones/AddVacaciones";
import SmallModal from "../../../../components/modals/SmallModal";
import UpdateVacaciones from "../vacaciones/UpdateVacaciones";

const Vacaciones = ({ id }) => {
  const token = getToken();
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const [dataEmployee, setDataEmployee] = useState([]);
  const [histoyVac, setHistoryBac] = useState([]);
  const [selectedVac, setSelectedBac] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [localReload, setLocalReload] = useState(false);

  const withSearch = histoyVac.filter((val) => {
    return val;
  });

  useEffect(() => {
    getEmployee();
  }, [localReload]);

  const getEmployee = async () => {
    setIsLoading(true);
    const result = await getVacacionesAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setDataEmployee(result.data.result);
    setHistoryBac(result.data.result.historialVac);
    setIsLoading(false);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const deleteAdelanto = async (id) => {
    Swal.fire({
      title: "¿Confirmar Eliminar?",
      // text: "...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);

          const result = await deleteVacacionesByIdAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Solicitud Eliminada.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Paper
            style={{
              padding: 20,
            }}
          >
            <Stack
              spacing={1}
              direction={{ xs: "column", sm: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Stack spacing={1} direction="row">
                <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                  Antiguedad:
                </Typography>
                <Typography style={{ textAlign: "left" }}>
                  {dataEmployee.antiguedad}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                  Vac Acum:
                </Typography>
                <Typography style={{ textAlign: "left" }}>
                  {dataEmployee.vacAcum}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                  Vac Tomadas:
                </Typography>
                <Typography style={{ textAlign: "left" }}>
                  {dataEmployee.vacTomadas}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography color={"#ffc400"} style={{ textAlign: "left" }}>
                  Saldo Vac:
                </Typography>
                <Typography style={{ textAlign: "left" }}>
                  {dataEmployee.saldoVac}
                </Typography>
              </Stack>
            </Stack>
          </Paper>

          <Stack
            marginTop={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Historial de Vacaciones
            </Typography>

            {dataEmployee.isActive ? (
              <Button
                onClick={() => {
                  setShowModal(!showModal);
                }}
                style={{
                  color: "#2979ff",
                  borderColor: "#2979ff",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faCirclePlus}
                />
                Agregar
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        F.Solicitud
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Desde
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Hasta
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Dias Efectivos
                      </TableCell>

                      {dataEmployee.isActive &&
                      (isAccess(access, "NOMINA UPDATE") ||
                        isAccess(access, "NOMINA DELETE")) ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.fechaSolicitud).format("L")}
                          </TableCell>
                          <TableCell align="left">{row.motivo}</TableCell>
                          <TableCell align="center">
                            {moment(row.desde).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {moment(row.hasta).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {row.diasEfectivos}
                          </TableCell>
                          {dataEmployee.isActive ? (
                            <TableCell align="center">
                              {isAccess(access, "NOMINA UPDATE") ? (
                                <IconButton
                                  style={{
                                    color: "#ffc400",
                                  }}
                                  onClick={() => {
                                    setShowEditModal(true);
                                    setSelectedBac(row);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                              {isAccess(access, "NOMINA DELETE") ? (
                                <IconButton
                                  style={{
                                    color: "#f50057",
                                    width: 40,
                                    height: 40,
                                  }}
                                  // onClick={() => deleteClient(row)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Vacaciones"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddVacaciones
          id={id}
          setShowModal={setShowModal}
          reload={localReload}
          setReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={`Editar Vacaciones`}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <UpdateVacaciones
          selected={selectedVac}
          setShowModal={setShowEditModal}
          reload={localReload}
          setReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default Vacaciones;
