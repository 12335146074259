import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../context/DataContext";
import { getRuta, simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";
import { PrintReport } from "../../../../components/PrintReport";
import { getArqueoSucursalByIdAsync } from "../../../../api/ArqueoCaja";
import moment from "moment";

const ArqueoSucursalDetails = () => {
  const [arqueo, setArqueo] = useState([]);

  const { id } = useParams();

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();
  let ruta = getRuta();
  let navigate = useNavigate();

  useEffect(() => {
    getArqueoById();
  }, []);

  const getArqueoById = async () => {
    setIsLoading(true);
    const result = await getArqueoSucursalByIdAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setArqueo(result.data.result);
  };

  const compRef = useRef();

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 8mm; }
`;

  return arqueo.length === 0 ? (
    <></>
  ) : (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/arqueouser`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              Detalle Arqueo
            </Typography>
          </Stack>

          <hr />

          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography color={"#2979ff"} fontWeight={"bold"}>
                  {` Arqueo #: ${arqueo.id} `}
                </Typography>

                <hr />
                <Stack spacing={2}>
                  <Stack spacing={1} direction="row">
                    <Typography color={"#2979ff"} fontWeight={"bold"}>
                      Fecha:
                    </Typography>
                    <Typography color={"#00a152"} fontWeight={"bold"}>
                      {moment(arqueo.fechaCreacion).format("L")}
                    </Typography>
                  </Stack>

                  <Stack spacing={1} direction="row">
                    <Typography color={"#2979ff"} fontWeight={"bold"}>
                      Sucursal:
                    </Typography>
                    <Typography color={"#00a152"} fontWeight={"bold"}>
                      {arqueo.sucursal.description}
                    </Typography>
                  </Stack>

                  <Stack spacing={1} direction="row">
                    <Typography color={"#2979ff"} fontWeight={"bold"}>
                      Total Recuperacion:
                    </Typography>
                    <Typography color={"#00a152"} fontWeight={"bold"}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(arqueo.recuperacion)}
                    </Typography>
                  </Stack>

                  <Stack spacing={1} direction="row">
                    <Typography color={"#2979ff"} fontWeight={"bold"}>
                      Billetaje
                    </Typography>
                    <Typography color={"#ffc400"} fontWeight={"bold"}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(arqueo.efectivo)}
                    </Typography>
                  </Stack>

                  <Stack spacing={1} direction="row">
                    <Typography color={"#2979ff"} fontWeight={"bold"}>
                      Diferencia:
                    </Typography>
                    <Typography color={"#f50057"} fontWeight={"bold"}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(arqueo.diferencia)}
                    </Typography>
                  </Stack>

                  <hr />

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button
                          style={{
                            color: "#2979ff",
                            borderColor: "#2979ff",
                            padding: 13,
                            borderRadius: 30,
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: 10, fontSize: 20 }}
                            icon={faPrint}
                          />
                          Imprimir Arqueo
                        </Button>
                      );
                    }}
                    content={() => compRef.current}
                    pageStyle={pageStyle}
                  />
                </Stack>
              </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <Typography color={"#00a152"} fontWeight={"bold"}>
                  Detalle Billetaje
                </Typography>

                <hr />

                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className="text-primary fw-bold"
                        >
                          Denominacion
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Billetaje
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          C$
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arqueo.aqueoSucursalDetails.map((row) => {
                        const { cantidad, demonimacion, id, total } = row;

                        return (
                          <TableRow key={id}>
                            <TableCell align="left">
                              {demonimacion.description}
                            </TableCell>
                            <TableCell align="center">{cantidad}</TableCell>
                            <TableCell align="center">{total}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Arqueo de Sucursal"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Stack>
            <Typography color={"#2979ff"} fontWeight={"bold"} align="center">
              {` Arqueo sucursal #: ${arqueo.id} `}
            </Typography>
            <Typography color={"#2979ff"} align="center">
              {` Fecha: ${moment(arqueo.fechaCreacion).format("L")} `}
            </Typography>

            <hr />
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack spacing={1} direction="row">
                <Typography fontWeight={"bold"}>Sucursal:</Typography>
                <Typography>{arqueo.sucursal.description}</Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography fontWeight={"bold"}>Recuperacion:</Typography>
                <Typography fontWeight={"bold"}>
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(arqueo.recuperacion)}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography fontWeight={"bold"}>Billetaje</Typography>
                <Typography fontWeight={"bold"}>
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(arqueo.efectivo)}
                </Typography>
              </Stack>

              <Stack spacing={1} direction="row">
                <Typography fontWeight={"bold"}>Diferencia:</Typography>
                <Typography fontWeight={"bold"}>
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(arqueo.diferencia)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <hr />

          <Stack
            style={{
              padding: 20,
            }}
          >
            <Typography color={"#00a152"} fontWeight={"bold"} align="center">
              Detalle Billetaje
            </Typography>

            <hr />

            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="text-primary fw-bold">
                      Denominacion
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      Billetaje
                    </TableCell>
                    <TableCell align="center" className="text-primary fw-bold">
                      C$
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arqueo.aqueoSucursalDetails.map((row) => {
                    const { cantidad, demonimacion, id, total } = row;

                    return (
                      <TableRow key={id}>
                        <TableCell align="left" className="text-dark">
                          {demonimacion.description}
                        </TableCell>
                        <TableCell align="center" className="text-dark">
                          {cantidad}
                        </TableCell>
                        <TableCell align="center" className="text-dark">
                          {total}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              color={"#2979ff"}
              fontWeight={"bold"}
              align="center"
              style={{ textDecoration: "overline", marginTop: 150 }}
            >
              {` Recibe #: ${arqueo.recibidoPor.fullName} `}
            </Typography>
          </Stack>
        </PrintReport>
      </div>
    </div>
  );
};

export default ArqueoSucursalDetails;