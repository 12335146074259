import React, { useContext, useRef } from "react";

import { Typography, IconButton, Stack, Paper } from "@mui/material";
import moment from "moment";

import { DataContext } from "../../../context/DataContext";
import { conv2Letras } from "../../../helpers/n2Letras";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import PrintGasto from "./PrintGasto";

const DetalleGasto = ({ gasto, setShowModal }) => {
  const { title, slogan, icon } = useContext(DataContext);
  const {
    id,
    description,
    entradas,
    fecha,
    sucursal,
    beneficiario,
    numeroRecibo,
  } = gasto;

  const compRef = useRef();

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { margin: 10mm; }
`;

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  return (
    <div>
      <Paper
        style={{
          padding: 20,
          textAlign: "center",
        }}
      >
        <Stack display="flex" justifyContent="center">
          <img src={icon} style={{ width: 50, height: 50 }} />
          <Stack>
            <Typography variant="h5" textAlign={"center"}>
              {title}
            </Typography>
            <Typography textAlign={"center"}>{slogan}</Typography>
            <Typography variant="h6" textAlign={"center"}>
              {`${sucursal} - NICARAGUA`}
            </Typography>
          </Stack>
          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "absolute", right: 50, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          paddingLeft={20}
          paddingRight={5}
        >
          <Stack />
          <Stack>
            <Typography variant="h4" textAlign={"center"}>
              Recibo
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`N° ${numeroRecibo}`}
            </Typography>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`Valor: ${entradas}`}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography textAlign={"left"}>
            NOMBRE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${beneficiario}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(entradas)}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${description}`}
            </Typography>
          </Typography>

          <Typography textAlign={"right"}>
            {`fecha: ${moment(fecha).format("dddd,")} ${moment(fecha).format(
              "D"
            )} de ${moment(fecha).format("MMMM")} de ${moment(fecha).format(
              "YYYY"
            )}`}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={15}>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>ENTREGUE CONFORME</Typography>
          </Stack>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>RECIBI CONFORME</Typography>
          </Stack>
        </Stack>
      </Paper>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintGasto gasto={gasto} ref={compRef} />
      </div>
    </div>
  );
};

export default DetalleGasto;
