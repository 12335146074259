import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faExternalLinkAlt,
  faPenToSquare,
  faTrashAlt,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../../context/DataContext";
import { getToken } from "../../../../api/Account";
import { getRuta, isAccess, simpleMessage } from "../../../../helpers/Helpers";
import { getPlazosByPeriodicidadIdAsync } from "../../../../api/Periodicidad";
import NoData from "../../../../components/NoData";
import { PaginationComponent } from "../../../../components/PaginationComponent";
import SmallModal from "../../../../components/modals/SmallModal";
import AddPlazo from "./AddPlazo";
import UpdatePlazo from "./UpdatePlazo";
import { deletePlazoAsync } from "../../../../api/Plazo";
const PlazoList = () => {
  const [periodicidad, setPeriodicidad] = useState([]);
  const [plazoList, setPlazoList] = useState([]);
  const [selectedPlazo, setSelectedPlazo] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = plazoList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [localReload, setLocalReload] = useState(false);

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  const token = getToken();
  let navigate = useNavigate();
  const { id } = useParams();
  let ruta = getRuta();

  useEffect(() => {
    loadData();
  }, [localReload]);

  const loadData = async () => {
    setIsLoading(true);

    const resultDepto = await getPlazosByPeriodicidadIdAsync(token, id);
    if (!resultDepto.statusResponse) {
      setIsLoading(false);
      if (resultDepto.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(resultDepto.error, "error");
      return;
    }
    setPeriodicidad(resultDepto.data.result);
    setPlazoList(resultDepto.data.result.plazos);
    setIsLoading(false);
  };

  const deletePlazo = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deletePlazoAsync(token, item);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }

          setIsLoading(false);
          setLocalReload(!localReload);

          Swal.fire("Eliminado!", "Plazo Eliminado.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/periodicidad`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              Detalle Periodicidad:
            </Typography>

            <Typography variant="h5" fontWeight={"bold"}>
              {periodicidad.description}
            </Typography>
          </Stack>

          <hr />

          <Stack direction={"row"} justifyContent="space-between">
            <Typography color={"#00a152"} variant="h5" fontWeight={"bold"}>
              Lista de Plazos
            </Typography>

            {isAccess(access, "CONFIG CREATE") ? (
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Plazo
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Plazo
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Cuotas
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="center">{row.cuotas}</TableCell>
                          <TableCell align="center">
                            <Stack
                              direction={"row"}
                              spacing={2}
                              justifyContent="center"
                            >
                              <IconButton
                                style={{ color: "#ffc400" }}
                                onClick={() => {
                                  setSelectedPlazo(row);
                                  setShowEditModal(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </IconButton>
                              <IconButton
                                style={{ color: "#f50057" }}
                                onClick={() => {
                                  deletePlazo(row.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={plazoList}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Plazo"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddPlazo
          setShowModal={setShowModal}
          periodicidadId={id}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>

      <SmallModal
        titulo={"Editar Plazo"}
        isVisible={showEditModal}
        setVisible={setShowEditModal}
      >
        <UpdatePlazo
          setShowModal={setShowEditModal}
          selectedPlazo={selectedPlazo}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default PlazoList;
