import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faSearch } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { getListTipoCambioAsync } from "../../../api/TipoCambio";
import moment from "moment/moment";
import SmallModal from "../../../components/modals/SmallModal";
import AddValorCompra from "./AddValorCompra";

const TasaCambioList = () => {
  const [tasaCambioList, setTasaCambioList] = useState([]);
  const [selectedTC, setSelectedTC] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [localReload, setSetLocalReload] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = tasaCambioList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (moment(val.fecha).format("L").toString().includes(searchTerm)) {
      return val;
    }
  });

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getListTipoCambioAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setTasaCambioList(result.data);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const editTC = (val) => {
    setSelectedTC(val);
    setShowModal(true);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
            Historial de Tasas de Cambio
          </Typography>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar tipo de cambio"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        BCN
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Compra
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Venta
                      </TableCell>

                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Editar
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const { id, fecha, dolar, venta, bcn } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="center">
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(bcn)}
                          </TableCell>

                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(dolar)}
                          </TableCell>

                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(venta)}
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              style={{
                                color: "#ffc400",
                              }}
                              onClick={() => editTC(row)}
                            >
                              <FontAwesomeIcon icon={faExternalLink} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <PaginationComponent
                  data={withSearch}
                  paginate={paginate}
                  itemsperPage={itemsperPage}
                />
              </TableContainer>
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar valor de compra"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddValorCompra
          localReload={localReload}
          setLocalReload={setSetLocalReload}
          setShowModal={setShowModal}
          selectedTC={selectedTC}
        />
      </SmallModal>
    </div>
  );
};

export default TasaCambioList;
