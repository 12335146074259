import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  Badge,
} from "@mui/material";

import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCloudArrowDown,
  faTag,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment/moment";
import { DataContext } from "../context/DataContext";
import {
  atendNotificationAsync,
  getAllNotificationsAsync,
} from "../api/Notifications";
import { simpleMessage } from "../helpers/Helpers";
import { getToken } from "../api/Account";

const Notifications = () => {
  const [localReload, setLocalReload] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [untatended, setUnatended] = useState([]);
  const { setIsUnautorized, isDarkMode, setIsLoading } =
    useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getAllNotificationsAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setTaskList(result.data.result);
      let unat = result.data.result.filter((item) => item.isLaunched === false);
      setUnatended(unat);
    })();
  }, [localReload]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const atender = async (id) => {
    setIsLoading(true);
    const result = await atendNotificationAsync(id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setLocalReload(!localReload);
  };

  return (
    <div>
      <Badge
        badgeContent={untatended.length}
        color="secondary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <IconButton
          style={{
            color: "#2979ff",
            width: 40,
            height: 40,
            marginRight: 10,
          }}
          onClick={handleMenu}
        >
          <FontAwesomeIcon icon={faBell} />
        </IconButton>
      </Badge>

      {isEmpty(taskList) ? (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            //   onClick={myAccount}
            style={{ color: isDarkMode ? "#66B2FF" : "" }}
          >
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              style={{ marginRight: 20 }}
            />
            No hay datos
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {taskList.map((row) => {
            return (
              <MenuItem
                key={row.id}
                style={{
                  color: isDarkMode ? "#66B2FF" : "",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Card sx={{ maxWidth: 350 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: "#00a152" }}>
                        <FontAwesomeIcon icon={faTag} />
                      </Avatar>
                    }
                    title={row.credit.client.fullName}
                    subheader={`Creado: ${moment(row.fechaCreacion).format(
                      "L"
                    )}`}
                  />
                  <CardContent style={{ width: 345 }}>
                    <Typography
                      textAlign={"left"}
                      noWrap={false}
                      variant="body2"
                      style={{ fontSize: 10, whiteSpace: "break-spaces" }}
                    >
                      {row.description}
                    </Typography>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Tooltip
                      title={row.isLaunched ? "Atendida" : "No Atendida"}
                    >
                      <IconButton
                        onClick={() => atender(row.id)}
                        aria-label="Atenida"
                        style={{
                          color: row.isLaunched ? "inherit" : "#2979ff",
                        }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
};

export default Notifications;
