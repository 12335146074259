import React, { useState, useEffect, useContext } from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
	Container,
	Button,
	Stack
} from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import { getSucursalByUserAsync } from '../../../../api/Users';
import { getRuta, simpleMessage } from '../../../../helpers/Helpers';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getHeaderAgrupadosAsync } from '../../../../api/Contabilidad';

const SelectorBalanceGeneral = () => {
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState('t');

	const [headerList, setHeaderList] = useState([]);
	const [selectedYear, setSelectedYear] = useState(0);
	const [selectedMonth, setSelectedMonth] = useState(1);
	const [presentacion, setPresentacion] = useState(1);
	const [cuentas0, setCuentas0] = useState(1);

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();

	useEffect(() => {
		getSucursals();
		getGetHeaders(selectedSucursal);
	}, []);

	const verReport = () => {
		if (selectedYear === 0) {
			simpleMessage('Seleccione un año válido', 'error');
			return;
		}

		var params = `${selectedSucursal}/${selectedYear}/${selectedMonth}/${presentacion}/${cuentas0}`;
		window.open(`${ruta}/r-balancegral/${params}`);
	};

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
	};

	const getGetHeaders = async (sucursal) => {
		let suc = sucursal === 't' ? 0 : sucursal;
		setIsLoading(true);
		const result = await getHeaderAgrupadosAsync(token, suc);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setHeaderList(result.data.result);
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getGetHeaders(value);
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					elevation={10}
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => onChangeSucursal(e.target.value)}
								value={selectedSucursal}
								label="Sucursal"
							>
								{sucursalList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
								<MenuItem key={'t'} value={'t'}>
									TODAS...
								</MenuItem>
							</Select>
						</FormControl>

						<Stack spacing={2} direction={'row'}>
							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Seleccione un año
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setSelectedYear(e.target.value)}
									value={selectedYear}
									label="Seleccione un año"
								>
									<MenuItem value="">
										<em>Seleccione un año</em>
									</MenuItem>
									{headerList.map((item) => {
										return (
											<MenuItem key={item.year} value={item.year}>
												{item.year}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setSelectedMonth(e.target.value)}
									value={selectedMonth}
									label="Mes"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										ENERO
									</MenuItem>
									<MenuItem key={2} value={2}>
										FEBRERO
									</MenuItem>
									<MenuItem key={3} value={3}>
										MARZO
									</MenuItem>
									<MenuItem key={4} value={4}>
										ABRIL
									</MenuItem>
									<MenuItem key={5} value={5}>
										MAYO
									</MenuItem>
									<MenuItem key={6} value={6}>
										JUNIO
									</MenuItem>
									<MenuItem key={7} value={7}>
										JULIO
									</MenuItem>
									<MenuItem key={8} value={8}>
										AGOSTO
									</MenuItem>
									<MenuItem key={9} value={9}>
										SEPTIEMBRE
									</MenuItem>
									<MenuItem key={10} value={10}>
										OCTUBRE
									</MenuItem>
									<MenuItem key={11} value={11}>
										NOVIEMBRE
									</MenuItem>
									<MenuItem key={12} value={12}>
										DICIEMBRE
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>

						<Stack spacing={2} direction={'row'}>
							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Presentacion
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setPresentacion(e.target.value)}
									value={presentacion}
									label="Presentacion"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										COMPLETO
									</MenuItem>
									<MenuItem key={2} value={2}>
										RESUMIDO
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Cuentas en cero
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setCuentas0(e.target.value)}
									value={cuentas0}
									label="Cuentas en cero"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione una Opción</em>
									</MenuItem>

									<MenuItem key={1} value={1}>
										INCLUIR
									</MenuItem>
									<MenuItem key={2} value={2}>
										NO INCLUIR
									</MenuItem>
								</Select>
							</FormControl>
						</Stack>
						{/* <Stack spacing={2} direction={"row"}>
              <DesktopDatePicker
                closeOnSelect
                label="Desde"
                inputFormat="DD/MM/YYYY"
                value={fechaDesde}
                onChange={(newValue) => setFechaDesde(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                closeOnSelect
                label="Hasta"
                inputFormat="DD/MM/YYYY"
                value={fechaHasta}
                onChange={(newValue) => setFechaHasta(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack> */}

						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => verReport()}
							style={{
								color: '#4caf50',
								borderColor: '#4caf50'
							}}
							size="large"
						>
							Generar Reporte
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default SelectorBalanceGeneral;
