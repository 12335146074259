import React, { useState, useEffect, useContext } from 'react';
import { Paper, Grid } from '@mui/material';

import ClientCount from './homeInfoComponents/ClientCount';
import ActiveCredits from './homeInfoComponents/ActiveCredits';
import VecidoCredits from './homeInfoComponents/VecidoCredits';
import RecuperacionDiaria from './homeInfoComponents/RecuperacionDiaria';
import PendieteCredits from './homeInfoComponents/PendieteCredits';
import MoraTotal from './homeInfoComponents/MoraTotal';
import { getToken } from '../../api/Account';
import { DataContext } from '../../context/DataContext';
import { getDataDashBoardAsync } from '../../api/Dashboard';
import { simpleMessage } from '../../helpers/Helpers';
import OtrosIngresos from './homeInfoComponents/OtrosIngresos';
import Gastos from './homeInfoComponents/Gastos';
import CancecionSemanal from './homeInfoComponents/CancecionSemanal';
import CreditosNuevos from './homeInfoComponents/CreditosNuevos';

const HomeInfo = ({ selectedSucursal }) => {
	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	const [data, setData] = useState([]);
	const token = getToken();

	useEffect(() => {
		getData();
	}, [selectedSucursal]);

	const getData = async () => {
		setIsLoading(true);
		const result = await getDataDashBoardAsync(token, selectedSucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setData(result.data.result);
		setIsLoading(false);
	};

	return (
		<div>
			<Grid container spacing={2}>
				{/* Fila 1 */}
				<Grid item xs={12} sm={6} md={3}>
					<Paper>
						<ClientCount value={data.activeClients} />
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<Paper elevation={10}>
						<VecidoCredits value={data.creditosVencidos} />
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<Paper elevation={10}>
						<Gastos value={data.gastos} />
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<Paper elevation={10}>
						<PendieteCredits value={data.creditsPendienteEntrega} />
					</Paper>
				</Grid>

				{/* Fila 2 */}
				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<RecuperacionDiaria
							valueCor={data.recDiariaCordoba}
							valueDol={data.recDiariaDolar}
						/>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<OtrosIngresos value={data.otrosIngresos} />
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<MoraTotal valueCor={data.moraTotalCordoba} valueDol={data.empCancelados} />
					</Paper>
				</Grid>

				{/* Fila 3 */}
				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<CreditosNuevos valueC={data.credNuevos} valueE={data.empNuevos} />
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<CancecionSemanal
							valueC={data.credCancelados}
							valueE={data.empCancelados}
						/>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper elevation={10}>
						<ActiveCredits
							value={data.activeCredits}
							valueNominal={data.activeCreditsNominal}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default HomeInfo;
